import {
  Button,
  Chip,
  Divider,
  List,
  ListItem,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { format } from 'date-fns'
import { ArrowBack, DownloadForOffline, OpenInNew } from '@mui/icons-material'
import { useGetIndustryReportQuery } from 'src/api/industryReport'
import PageWrapper from 'src/components/PageWrapper/PageWrapper'
import downloadBlobHelper from 'src/utils/helpers/downloadBlobHelper'
import { useTranslation } from 'react-i18next'
import { useSelectedId } from 'src/utils/hooks/useSelectedId'
import { routes } from 'src/utils/router/Routes'
import isExternalURL from 'src/utils/helpers/isExternalURL'

const Rapport: React.FC = () => {
  const id = useSelectedId()
  const { data, isLoading, isError } = useGetIndustryReportQuery(id)
  const { t } = useTranslation('translation', {
    keyPrefix: 'rapport',
  })
  const { t: tKallor } = useTranslation('translation', { keyPrefix: 'kallor' })
  const { breakpoints } = useTheme()
  const smallScreensUp = useMediaQuery(breakpoints.up('sm'))

  const handleClickDownload = async () => {
    if (data?.id)
      await downloadBlobHelper(data?.id, data?.fileName ?? data.title)
  }

  return (
    <>
      <PageWrapper
        isLoading={isLoading}
        errorText={t('errorFetchReport')}
        errorLoadingData={isError}
      >
        <Typography
          variant="h1"
          marginBottom={3}
          sx={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}
        >
          {data?.title}
        </Typography>
        <Typography variant="preamble" component="p" marginBottom={3}>
          {data?.description}
        </Typography>

        <Table sx={{ mb: 3 }}>
          <TableBody>
            <TableRow>
              <TableCell
                component="th"
                scope="row"
                sx={{
                  paddingY: 2,
                  paddingX: 1,
                  width: '200px',
                  verticalAlign: 'top',
                }}
              >
                <Typography variant="body1">{t('typ')}</Typography>
              </TableCell>
              <TableCell
                sx={{ paddingY: 2, paddingX: 1, verticalAlign: 'top' }}
              >
                <Typography variant="body1">Rapport</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                component="th"
                scope="row"
                sx={{
                  paddingY: 2,
                  paddingX: 1,
                  width: '200px',
                  verticalAlign: 'top',
                }}
              >
                <Typography variant="body1">
                  {t('publiceringsdatum')}
                </Typography>
              </TableCell>
              <TableCell
                sx={{ paddingY: 2, paddingX: 1, verticalAlign: 'top' }}
              >
                <Typography variant="body1">
                  {data?.publicationDate
                    ? format(new Date(data.publicationDate), 'yyyy-MM-dd')
                    : ''}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                component="th"
                scope="row"
                sx={{
                  paddingY: 2,
                  paddingX: 1,
                  width: '200px',
                  verticalAlign: 'top',
                }}
              >
                <Typography variant="body1">{t('utgivare')}</Typography>
              </TableCell>
              <TableCell
                sx={{ paddingY: 2, paddingX: 1, verticalAlign: 'top' }}
              >
                <Typography variant="body1">{data?.publisher}</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                component="th"
                scope="row"
                sx={{
                  paddingY: 2,
                  paddingX: 1,
                  width: '200px',
                  verticalAlign: 'top',
                }}
              >
                <Typography variant="body1">{t('regioner')}</Typography>
              </TableCell>
              <TableCell
                sx={{ paddingY: '4px', paddingX: 1, verticalAlign: 'top' }}
              >
                <Stack
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '8px',
                    flexWrap: 'wrap',
                  }}
                >
                  <List
                    sx={{
                      display: 'flex',
                      flexFlow: 'row wrap',
                      paddingBottom: 0,
                    }}
                  >
                    {data?.allRegions ? (
                      <ListItem
                        sx={{
                          width: 'auto',
                          border: 'none',
                          padding: '0',
                          paddingRight: 1,
                          paddingBottom: 1,
                          display: 'inline',
                        }}
                      >
                        <Chip variant="outlined" label={t('nationell')} />
                      </ListItem>
                    ) : (
                      data?.regions?.map((region) => {
                        if (!region) return null
                        return (
                          <ListItem
                            key={region.id}
                            sx={{
                              width: 'auto',
                              border: 'none',
                              padding: '0',
                              paddingRight: 1,
                              paddingBottom: 1,
                              display: 'inline',
                            }}
                          >
                            <Chip variant="outlined" label={region.name} />
                          </ListItem>
                        )
                      })
                    )}
                  </List>
                </Stack>
              </TableCell>
            </TableRow>
            {data?.links && data?.links.length > 0 && (
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  sx={{
                    paddingY: 2,
                    paddingX: 1,
                    width: '200px',
                    verticalAlign: 'top',
                  }}
                >
                  <Typography variant="body1">{t('länkar')}</Typography>
                </TableCell>
                <TableCell sx={{ paddingY: '4px', paddingX: 1 }}>
                  {data.links && (
                    <Stack
                      direction={'row'}
                      useFlexGap
                      sx={{ flexWrap: 'wrap' }}
                      spacing={2}
                      divider={
                        smallScreensUp ? (
                          <Typography color="primary">•</Typography>
                        ) : null
                      }
                    >
                      {data.links.map((link, index) => (
                        <Button
                          sx={{ padding: 0, minWidth: 0 }}
                          key={index}
                          startIcon={
                            isExternalURL(link.url) ? <OpenInNew /> : null
                          }
                          aria-label={`${link.text}${isExternalURL(link.url) ? ` ${tKallor('openInNew')}` : ''}`}
                          target={isExternalURL(link.url) ? '_blank' : '_self'}
                          variant="text"
                          href={link.url}
                          size="small"
                        >
                          {link.text}
                        </Button>
                      ))}
                    </Stack>
                  )}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>

        <Button
          data-testid="download-button"
          startIcon={<DownloadForOffline />}
          variant="contained"
          size="medium"
          onClick={() => void handleClickDownload()}
          sx={{ mb: 3 }}
        >
          {t('laddaNer')} ({data?.fileName.split('.').pop()?.toUpperCase()})
        </Button>
        <Divider sx={{ mt: 4, mb: 3 }} />
        <Button
          sx={{ maxWidth: 'fit-content', ml: -3 }}
          startIcon={<ArrowBack />}
          variant="text"
          href={`${routes.start}rapporter`}
        >
          {t('tillbaka')}
        </Button>
      </PageWrapper>
    </>
  )
}

export default Rapport
