import { StudieersattningApiModel } from '@local/src/services/SwaggerApi/data-contracts'
import { object as yupObject, array as yupArray, SchemaOf, number as yupNumber } from 'yup'
import { FormValidationTexts } from '@local/src/content/resources'
import { maxLengthValidationText } from '@local/src/utils/Yup/YupHelper'

import { BeraknadUtbetalningsstartDraftSchema, BeraknadUtbetalningsstartSchema } from './BeraknadUtbetalningsstartSchema'

const StudieersattningField = (formValidationTexts?: FormValidationTexts) =>
    yupNumber().nullable().integer(formValidationTexts?.heltal).max(999999, maxLengthValidationText).min(0)

const KalenderhalvarField = ({
    formValidationTexts,
    isStudieersattningRequired,
}: {
    formValidationTexts?: FormValidationTexts
    isStudieersattningRequired: boolean
}) =>
    yupArray()
        .required()
        .min(1)
        .max(4)
        .of(
            yupObject({
                studieersattning: isStudieersattningRequired
                    ? StudieersattningField(formValidationTexts).required()
                    : StudieersattningField(formValidationTexts),
            })
        )

export const StudieersattningDraftSchema = (formValidationTexts?: FormValidationTexts): SchemaOf<StudieersattningApiModel> =>
    yupObject({
        beraknadUtbetalningsstart: BeraknadUtbetalningsstartDraftSchema(formValidationTexts),
        kalenderhalvar: KalenderhalvarField({ formValidationTexts, isStudieersattningRequired: false }),
    })

export const StudieersattningSchema = (formValidationTexts?: FormValidationTexts): SchemaOf<StudieersattningApiModel> =>
    yupObject({
        beraknadUtbetalningsstart: BeraknadUtbetalningsstartSchema(formValidationTexts),
        kalenderhalvar: KalenderhalvarField({ formValidationTexts, isStudieersattningRequired: true }),
    })
