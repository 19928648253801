import {
  useGetKanKompletteraQuery,
  useGetKompletteraStatusQuery,
} from '@local/services/API/tidigareSysselsattningar/tidigareSysselsattningarApi'
import useIsFeatureFlagEnabled from '@local/Utils/Hooks/useIsFeatureFlagEnabled'
import { useAuthentication } from '@trr/app-shell-data'

const useGetKanKomplettera = () => {
  const isNewKompletteraApiModelEnabled = useIsFeatureFlagEnabled(
    'KlientAnsokan-Frontend_newKompletteraApiModel_temp_241218'
  )

  const { sub: klientId } = useAuthentication()

  const getKompletteraStatus = isNewKompletteraApiModelEnabled
    ? useGetKanKompletteraQuery
    : useGetKompletteraStatusQuery

  const { data, isLoading, isError } = getKompletteraStatus(klientId)

  return { data, isLoading, isError }
}

export default useGetKanKomplettera
