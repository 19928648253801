import React, { useEffect } from 'react'
import { useCreateArbetsuppgiftMutation } from '@local/src/Api/Arbetsuppgift/arbetsuppgiftApi'
import { addSuccessMessage } from '@local/src/Blocks/UserFeedback/UserFeedback.actions'
import { useAppDispatch } from '@local/Store/configureStore'

import EgenArbetsuppgiftModalBase from '../EgenArbetsuppgiftModalBase'

interface CreateArbetsuppgiftModalProps {
  open: boolean
  onClose: () => void
  arendeId: string
}

const CreateArbetsuppgiftModal = ({
  open,
  onClose,
  arendeId,
}: CreateArbetsuppgiftModalProps) => {
  const dispatch = useAppDispatch()
  const [
    createArbetsuppgift,
    { isError, isSuccess, reset: resetQuery, isLoading },
  ] = useCreateArbetsuppgiftMutation()

  useEffect(() => {
    if (isSuccess) {
      dispatch(addSuccessMessage('Arbetsuppgiften skapades'))
      onClose()
    }
  }, [isSuccess])

  const handleSubmit = (header: string, body: string) => {
    createArbetsuppgift({
      header,
      body,
      arendeId,
    })
  }

  const handleClose = () => {
    resetQuery()
    onClose()
  }

  return (
    <EgenArbetsuppgiftModalBase
      open={open}
      onClose={handleClose}
      onSubmit={handleSubmit}
      isSuccess={isSuccess}
      isLoading={isLoading}
      isError={isError}
      modalTitle="Skapa uppgift"
      confirmText="Skapa"
      errorMessage="Något gick fel vid skapande av arbetsuppgiften"
    />
  )
}

export default CreateArbetsuppgiftModal
