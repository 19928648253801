import { JSX } from 'react'
import { useFormikContext } from 'formik'
import {
  IKompletteraFormValues,
  IAnstallningsuppgifterProps,
} from '@local/Views/TidigareSysselsattningarOld/KompletteraForm/KompletteraForm.types'
import { ITidigareSysselsattningarContent } from '@local/Views/TidigareSysselsattningarOld/TidigareSysselsattningar.types'
import {
  useIsMediumScreensOrAbove,
  usePickEpiContent,
} from '@local/Utils/Hooks'
import {
  getKompletteringErrors,
  getKompletteringTouched,
  isOvrigSysselsattning,
} from '@local/Utils/Helpers/TidigareSysselsattningar.helpers'
import {
  getMaxStartDate,
  dayAfterStartDate,
  startDate,
  today,
} from '@local/Utils/Helpers/index'
import { Grid2 as Grid, Typography } from '@mui/material'
import DateInput from '@local/Components/DateInput'
import { isNil } from 'ramda'
import { trrDateStrict } from '@local/Utils/Helpers/formatDate'

const Anstallningsuppgifter = ({
  komplettering,
  index,
}: IAnstallningsuppgifterProps): JSX.Element => {
  const isMediumScreenOrAbove = useIsMediumScreensOrAbove()

  const formikContext = useFormikContext<IKompletteraFormValues>()

  const {
    formular: { anstallningsUppgifter: anstallningsUppgifterContent },
  } = usePickEpiContent<ITidigareSysselsattningarContent>()

  const kompletteringErrors = getKompletteringErrors(
    index,
    formikContext.errors
  )
  const kompletteringTouched = getKompletteringTouched(
    index,
    formikContext.touched
  )

  const isErrorStartdatum =
    kompletteringTouched.anstallningStartDatum &&
    !isNil(kompletteringErrors.anstallningStartDatum)

  const isErrorSlutdatum =
    kompletteringTouched.anstallningSlutDatum &&
    !isNil(kompletteringErrors.anstallningSlutDatum)

  return (
    <>
      <Typography variant="h6">
        {isOvrigSysselsattning(komplettering.sysselsattningstyp)
          ? anstallningsUppgifterContent.headingOvrigSysselsattning
          : anstallningsUppgifterContent.heading}
      </Typography>

      <Grid container>
        <Grid
          size={{ xs: 12, md: 6 }}
          paddingRight={isMediumScreenOrAbove ? 2 : 0}
          paddingBottom={isMediumScreenOrAbove ? 0 : 2}
        >
          <DateInput
            formikFieldName={`kompletteringar.${String(index)}.anstallningStartDatum`}
            value={trrDateStrict(komplettering.anstallningStartDatum)}
            formikContext={formikContext}
            label={anstallningsUppgifterContent.startLabel}
            minDate={startDate}
            maxDate={getMaxStartDate(komplettering.anstallningSlutDatum)}
            error={isErrorStartdatum}
            errorMsg={kompletteringErrors.anstallningStartDatum as string}
          />
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          <DateInput
            formikFieldName={`kompletteringar.${String(index)}.anstallningSlutDatum`}
            value={trrDateStrict(komplettering.anstallningSlutDatum)}
            formikContext={formikContext}
            label={anstallningsUppgifterContent.slutLabel}
            minDate={dayAfterStartDate(komplettering.anstallningStartDatum)}
            maxDate={today}
            error={isErrorSlutdatum}
            errorMsg={kompletteringErrors.anstallningSlutDatum as string}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default Anstallningsuppgifter
