import { resetAuthOnExpiry } from 'features/Authentication/helpers'
import userManager, { IdServerUser } from 'features/Authentication/userManager'
import { useCallback, useEffect, useRef } from 'react'
import { useSliceDispatch } from 'slices/hooks'

const useUserManagerEventListeners = () => {
  const currentUserRef = useRef<IdServerUser>()
  const trigger = useSliceDispatch()

  const userLoadedCallback = useCallback(
    (user: IdServerUser) => {
      trigger('setAuthState', user)

      currentUserRef.current = user
    },
    [trigger]
  )

  const accessTokenExpiredCallback = useCallback(() => {
    void resetAuthOnExpiry()
  }, [])

  useEffect(() => {
    userManager.events.addUserLoaded(userLoadedCallback)
    userManager.events.addAccessTokenExpired(accessTokenExpiredCallback)
    return () => {
      userManager.events.removeUserLoaded(userLoadedCallback)
      userManager.events.removeAccessTokenExpired(accessTokenExpiredCallback)
    }
  }, [userLoadedCallback, accessTokenExpiredCallback])
}

export default useUserManagerEventListeners
