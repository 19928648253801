import { ListItemText, Box, type SxProps, type Theme } from '@mui/material'

import { useSliceStateSelector } from 'State/Slices'
import { useAuthentication } from '@trr/app-shell-data'
import getUserInformationFromJwt from 'Utils/Helpers/getUserInformationFromJwt'
import { pageIds } from 'State/Slices/pageSlice/constants'
import CompanyInformation from './CompanyInformation'

const nameAndPersonalNumberSx: SxProps<Theme> = {
  '&:first-of-type': {
    paddingRight: 3,
  },
  '&:last-child': {
    paddingLeft: { sm: 3 },
  },
  '&:only-child': {
    padding: 0,
  },
}
const personalInformationBoxSx: SxProps<Theme> = {
  display: 'flex',
  flexDirection: { xs: 'column', sm: 'row' },
  maxWidth: '600px',
  paddingBottom: 4,
}

const PersonalInformation = () => {
  const { idToken } = useAuthentication()
  const { name, socialSecurityNumber } = getUserInformationFromJwt(idToken)
  const { currentPage } = useSliceStateSelector((state) => state.slices.page)
  const showCompany = currentPage === pageIds.validateCompanyPage

  return (
    <Box sx={personalInformationBoxSx}>
      {currentPage !== pageIds.addEmailPage && (
        <Box sx={nameAndPersonalNumberSx}>
          <ListItemText
            primary={name ?? ''}
            secondary={socialSecurityNumber ?? ''}
            sx={{ padding: 0 }}
          />
        </Box>
      )}
      {showCompany && <CompanyInformation />}
    </Box>
  )
}

export default PersonalInformation
