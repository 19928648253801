import { kommentarApi } from '@local/src/Services/apiEndpointHelper'
import { apiRequestTryCatchWrapperV2 } from '@local/src/Services/apiRequestHelper'
import { UpdateKommentarApiModel } from '@local/src/Services/SwaggerApi/data-contracts'
import { useMutation } from '@tanstack/react-query'
import { ApiResponseModel } from '@local/src/Services/ApiResponseModel'

import { CsnPortalReferenceType, useKommentarQueryHelper } from './useKommentarQueryHelper'

export interface UpdateKommentarApiModelWithReferenceType extends Omit<UpdateKommentarApiModel, 'referenceType'> {
	kommentarId: string
	referenceType: CsnPortalReferenceType
}

export const useUpdateKommentar = (referenceId: string) => {
	const { invalidateGetKommentarQuery } = useKommentarQueryHelper(referenceId)

	const updateKommentar = ({ kommentarId, ...body }: UpdateKommentarApiModelWithReferenceType) =>
		apiRequestTryCatchWrapperV2('updateKommentar', kommentarApi.referenceKommentarUpdate, kommentarId, referenceId, body)

	const { mutate, ...query } = useMutation(updateKommentar, {
		onSuccess: invalidateGetKommentarQuery,
		onError: (err: ApiResponseModel<void>) => {
			console.error('updateKommentar mutation error:', err)
		},
	})

	return {
		updateKommentar: mutate,
		...query,
	}
}
