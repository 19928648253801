import { UserType } from '../constants'
import userManager from './../userManager'
import { applicationInsights } from '@trr/app-shell-communication'

export const getLoginUrl = (userType: UserType): string => {
  const url = '/login'
  return userType === UserType.customer ? `${url}/arbetsgivare` : url
}

export const removeRepeatedSlashesFromUrl = (url = '') => {
  try {
    const alldoublesRemoved = url.replace(/\/\/+/g, '/')
    return alldoublesRemoved.replace(':/', '://')
  } catch (error) {
    console.error(error)
    return url
  }
}

export const forceLogout = (): void => {
  const logoutFn = async () => {
    applicationInsights.__internalEvents.clearAuthenticatedUserContext()
    try {
      await userManager.clearStaleState()
      await userManager.signoutRedirect()
    } catch {
      location.assign('/')
    }
  }
  void logoutFn()
}
