import { useEffect, useState } from 'react'
import { loginFlowRefreshUserToken } from '@trr/app-shell-communication'
import { CreatingAccountLoader, PageTitle } from 'Components'
import { Typography, Box, TextField, Button } from '@mui/material'

import {
  useGetEmailVerificationCodeMutation,
  useSetEmailVerificationCodeMutation,
} from 'State/Api/Email'
import { useAuthentication } from '@trr/app-shell-data'
import userRoleChecks from 'Utils/Helpers/userRolesChecks'

const AddEmailPage = () => {
  const [verificationCode, setVerificationCode] = useState<string>('')
  const [emailAddress, setEmailAddress] = useState<string>('')
  const [verificationCodeSendError, setVerificationCodeSendError] =
    useState<string>('')
  const { userRoles } = useAuthentication()
  const { isMissingEmail } = userRoleChecks(userRoles)
  const [emailErrorMessage, setEmailErrorMessage] = useState<string>('')

  const [
    triggerGetEmailVerificationCode,
    {
      isLoading: getEmailVerificationCodeLoading,
      isSuccess: getEmailVerificationCodeSuccess,
    },
  ] = useGetEmailVerificationCodeMutation()
  const [
    triggerSetEmailVerificationCode,
    {
      isLoading: setEmailVerificationCodeLoading,
      isSuccess: setEmailVerificationCodeSuccess,
      error: setEmailVerificationCodeError,
    },
  ] = useSetEmailVerificationCodeMutation()

  const validateEmail = (email: string) => {
    const validEmailFormat = /^.+@.+$/

    if (email === undefined || email === '') {
      return 'Fyll i din e-postadress'
    } else if (email.length > 241) {
      return 'Max 241 tecken'
    } else if (!validEmailFormat.test(email)) {
      return 'Ange en giltig e-postadress'
    } else {
      return ''
    }
  }

  const sendEmailVerification = () => {
    const emailValidation = validateEmail(emailAddress)
    if (emailValidation === '') {
      void triggerGetEmailVerificationCode(emailAddress)
    } else {
      setEmailErrorMessage(emailValidation)
    }
  }

  const sendVerificationCode = () => {
    if (!verificationCode) {
      setVerificationCodeSendError('Ange en kod')
    } else {
      void triggerSetEmailVerificationCode(verificationCode).then(
        ({ error }) => {
          if (!error) {
            loginFlowRefreshUserToken()
          }
        }
      )
    }
  }

  useEffect(() => {
    if (setEmailVerificationCodeError) {
      setVerificationCodeSendError('Koden är fel eller gammal')
      setVerificationCode(undefined)
    }
  }, [setEmailVerificationCodeError])

  return (
    <>
      {!isMissingEmail ||
      setEmailVerificationCodeLoading ||
      getEmailVerificationCodeLoading ||
      setEmailVerificationCodeSuccess ? (
        <CreatingAccountLoader testSelector={'Loader'} />
      ) : (
        <>
          <PageTitle>Lägg till din e-postadress</PageTitle>
          <Typography
            gutterBottom
            variant={'h6'}
            sx={{ whiteSpace: { md: 'nowrap' } }}
          >
            Fyll i din e-postadress och beställ verifieringskod
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <TextField
              name="email"
              autoComplete="on"
              aria-invalid={emailErrorMessage.length > 0}
              helperText={emailErrorMessage}
              error={Boolean(emailErrorMessage)}
              inputProps={{ 'data-testid': 'emailInputField' }}
              label="E-postadress"
              value={emailAddress}
              onChange={(event) => {
                setEmailAddress(event.target.value)
                setEmailErrorMessage('')
              }}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  sendEmailVerification()
                }
              }}
              sx={{ paddingBottom: 1.9, width: { xs: '100%', sm: '450px' } }}
            />
            <Box
              sx={{ display: 'flex', flexDirection: 'column', marginBottom: 4 }}
            >
              <Button
                variant="outlined"
                sx={{ width: { xs: '100%', sm: '250px' } }}
                data-testid="emailSendButton"
                onClick={() => {
                  sendEmailVerification()
                }}
              >
                Beställ verifieringskod
              </Button>
              {getEmailVerificationCodeSuccess && (
                <Typography variant="body2">Koden har skickats</Typography>
              )}
            </Box>
          </Box>
          <Typography sx={{ paddingBottom: 1 }} variant={'h6'}>
            Ange verifieringskod
          </Typography>
          <Typography sx={{ paddingBottom: 2 }} variant={'body2'}>
            Koden skickas till din e-post. Det kan ta några minuter innan mejlet
            kommer fram.
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <TextField
              autoComplete="off"
              aria-invalid={verificationCodeSendError.length > 0}
              helperText={verificationCodeSendError}
              error={Boolean(verificationCodeSendError)}
              inputProps={{ 'data-testid': 'validationInput' }}
              label="Verifieringskod"
              onChange={(event) => {
                setVerificationCode(event.target.value)
                setVerificationCodeSendError('')
              }}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  sendVerificationCode()
                }
              }}
              sx={{ paddingBottom: 2.5, width: { xs: '100%', sm: '250px' } }}
            />

            <Button
              variant="contained"
              sx={{ marginBottom: 6, width: { xs: '100%', sm: '150px' } }}
              data-testid="validationSendButton"
              onClick={() => {
                sendVerificationCode()
              }}
            >
              Fortsätt
            </Button>
          </Box>
          <Typography variant={'subtitle1'}>Fick du ingen kod?</Typography>
          <Button
            data-testid="resendEmailVerificationCode"
            variant="text"
            sx={{ padding: 0 }}
            size="small"
            onClick={() => {
              sendEmailVerification()
            }}
          >
            Beställ igen
          </Button>
        </>
      )}
    </>
  )
}

export default AddEmailPage
