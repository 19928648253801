import { callbackUrl } from 'features/Authentication/constants'
import userManager, {
  setKlientUserManager,
  setKundUserManager,
  setMedarbetareUserManager,
} from 'features/Authentication/userManager'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

const processIdServerCallbackResponse = async (
  setProcessCompleted: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const kundScope = 'trr_kund_profile'
  const klientScope = 'trr_klient_profile'
  const medarbetareScope = 'trr_medarbetare'
  const scopeParam: string | null = new URLSearchParams(
    window.location.search
  ).get('scope')

  if (scopeParam?.includes(kundScope)) {
    setKundUserManager()
  }
  if (scopeParam?.includes(klientScope)) {
    setKlientUserManager()
  }
  if (scopeParam?.includes(medarbetareScope)) {
    setMedarbetareUserManager()
  }
  await userManager.signinRedirectCallback()
  setProcessCompleted(true)
}

const useProcessIdServerCallbackResponse = () => {
  const { pathname } = useLocation()
  const [processCompleted, setProcessCompleted] = useState(false)
  useEffect(() => {
    if (callbackUrl === pathname) {
      setProcessCompleted(false)
      void processIdServerCallbackResponse(setProcessCompleted)
    } else {
      setProcessCompleted(true)
    }
  }, [pathname])

  return processCompleted
}

export default useProcessIdServerCallbackResponse
