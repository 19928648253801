import { JSX } from 'react'
import { isNil } from 'ramda'
import { useFormikContext } from 'formik'
import { Grid2 as Grid, Typography } from '@mui/material'
import {
  IKompletteraFormValues,
  IForetagsuppgifterProps,
} from '@local/Views/TidigareSysselsattningarOld/KompletteraForm/KompletteraForm.types'
import { ITidigareSysselsattningarContent } from '@local/Views/TidigareSysselsattningarOld/TidigareSysselsattningar.types'
import {
  useIsMediumScreensOrAbove,
  usePickEpiContent,
} from '@local/Utils/Hooks'
import {
  getKompletteringErrors,
  getKompletteringTouched,
} from '@local/Utils/Helpers/TidigareSysselsattningar.helpers'
import Gutter from '@local/Components/Gutter'
import TextInput from '@local/Components/TextInput'

const Foretagsuppgifter = ({ index }: IForetagsuppgifterProps): JSX.Element => {
  const isMediumScreenOrAbove = useIsMediumScreensOrAbove()

  const formikContext = useFormikContext<IKompletteraFormValues>()
  const {
    formular: { foretagsuppgifter: foretagsuppgifterContent },
  } = usePickEpiContent<ITidigareSysselsattningarContent>()

  const kompletteringErrors = getKompletteringErrors(
    index,
    formikContext.errors
  )
  const kompletteringTouched = getKompletteringTouched(
    index,
    formikContext.touched
  )

  const isErrorForetagsnamn =
    kompletteringTouched.foretagsnamn &&
    !isNil(kompletteringErrors.foretagsnamn)

  const isErrorOrganisationsnummer =
    kompletteringTouched.organisationsnummer &&
    !isNil(kompletteringErrors.organisationsnummer)

  return (
    <>
      <Typography variant="h6">{foretagsuppgifterContent.heading}</Typography>

      <Gutter offset_xs={16} />

      <Grid container>
        <Grid
          size={{ xs: 12, md: 6 }}
          paddingRight={isMediumScreenOrAbove ? 2 : 0}
          paddingBottom={isMediumScreenOrAbove ? 0 : 2}
        >
          <TextInput
            formikContext={formikContext}
            label={foretagsuppgifterContent.namnLabel}
            name={`kompletteringar.${String(index)}.foretagsnamn`}
            error={isErrorForetagsnamn}
            errorMsg={kompletteringErrors.foretagsnamn}
          />
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          <TextInput
            withTrim
            formikContext={formikContext}
            label={foretagsuppgifterContent.orgnummerLabel}
            name={`kompletteringar.${String(index)}.organisationsnummer`}
            error={isErrorOrganisationsnummer}
            errorMsg={kompletteringErrors.organisationsnummer}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default Foretagsuppgifter
