import { Box, Typography, Button, Paper, Stack, Link } from '@mui/material'
import { PageTitle, ButtonRow } from 'Components'
import { loginFlowRefreshUserToken } from '@trr/app-shell-communication'
import { useCreateUserMutation } from 'State/Api/User'
import { useSliceDispatch, useSliceStateSelector } from 'State/Slices'
import { useGetCollectumQuery } from 'State/Api/Collectum'
import useTexts from 'Utils/Hooks/useTexts'
import texts from './texts'
import { userTypes } from 'State/Slices/inputSlice/constants'
import { pageFlows } from 'State/Slices/pageSlice/constants'

const collectumCompanyUserConversion = {
  [userTypes.tjanstemanWithCollectum]: userTypes.tjansteman,
}

const nonCollectumUserInitialPage = {
  [userTypes.tjansteman]: pageFlows.tjansteman[2],
} as const

const ValidateCollectumCompanyPage = () => {
  const triggerDispatch = useSliceDispatch()
  const userType = useSliceStateSelector(
    (state) => state.slices.inputs.userType
  ) as typeof userTypes.tjanstemanWithCollectum

  const workSituation = useSliceStateSelector(
    (state) => state.slices.inputs.workSituation
  )

  const formatDatePeriod = (start: string, end: string | null) => {
    return `${start} – ${end ?? 'tillsvidare'}`
  }

  const t = useTexts(texts)

  const { data } = useGetCollectumQuery()
  const collectumCompany = data?.employments[0]

  const [triggerCreateUser] = useCreateUserMutation()

  const handleCreateUser = () => {
    void triggerCreateUser({
      workSituation,
      companyId: collectumCompany.organizationId,
      orgNumber: collectumCompany.organizationNumber,
      workRole: [],
    }).then(({ error }) => {
      if (!error) {
        void loginFlowRefreshUserToken()
        triggerDispatch('goToPage', { direction: 'next', userType: userType })
      }
    })
  }

  const handleNotCorrectCompanyClick = () => {
    const convertedUser = collectumCompanyUserConversion[userType]
    triggerDispatch('setUserType', convertedUser)
    triggerDispatch('setWorkSituation', undefined)
    triggerDispatch(
      'goToSpecificPage',
      nonCollectumUserInitialPage[convertedUser]
    )
  }

  // Behövs för tester då vi kommer snurra en gång utan data första gången. Kan detta arbetas bort?
  if (!collectumCompany) {
    return null
  }

  return (
    <Box data-testid="collectumCompany">
      <PageTitle sx={{ mb: 0 }}>{t.heading}</PageTitle>

      <Typography variant={'subtitle1'} mb={4}>
        {t.subHeading}
      </Typography>
      <Typography variant={'body1'} mb={4}>
        Vi har hämtat information om dig från{' '}
        <Link
          target="_blank"
          aria-label="https://collectum.se/ - Öppnas i ny flik"
          href={'https://collectum.se/'}
        >
          Collectum
        </Link>
        . Kontrollera gärna att uppgifterna stämmer.
      </Typography>
      <Typography variant={'h6'} mb={2}>
        {t.companyHeading}
      </Typography>
      <Paper variant={'outlined'} sx={{ p: 3, borderRadius: 2 }}>
        <Typography variant={'subtitle1'}>
          {collectumCompany.organizationName}
        </Typography>
        <Stack spacing={2} direction={'row'}>
          <Typography variant={'body2'}>{t.employmentPeriod}</Typography>
          <Typography variant={'body2'}>
            {formatDatePeriod(
              collectumCompany.statuses[0].startDate,
              collectumCompany.statuses[0].endDate
            )}
          </Typography>
        </Stack>
      </Paper>
      <ButtonRow marginTop={6}>
        <Button
          sx={{ marginX: 1 }}
          data-testid="notCorrectCompanyButton"
          onClick={handleNotCorrectCompanyClick}
          variant="text"
        >
          {t.secondaryButton}
        </Button>
        <Button
          sx={{ marginX: 1 }}
          variant="contained"
          onClick={handleCreateUser}
          data-testid="forward-button"
        >
          {t.primaryButton}
        </Button>
      </ButtonRow>
    </Box>
  )
}

export default ValidateCollectumCompanyPage
