import { publicWebApi } from 'state/PublicWebApi'
import { UserInteractionsRequest, UserInteractionsResponse } from './types'

const userInteractionsApi = publicWebApi.injectEndpoints({
  endpoints: (builder) => ({
    getUserInteractions: builder.query<UserInteractionsResponse, void>({
      providesTags: ['UserInteractions'],
      query: () => `/user-interactions`,
    }),
    postUserInteractions: builder.mutation<
      UserInteractionsResponse,
      UserInteractionsRequest
    >({
      query: (request) => {
        return {
          url: '/user-interactions',
          method: 'POST',
          body: request,
        }
      },
    }),
  }),
})

export default userInteractionsApi
