import SubmitResponse from '@local/src/Components/SubmitResponse/SubmitResponse'
import SystemFeedback from '@local/src/Components/SystemFeedback/SystemFeedback'
import { useCommonTranslation, useCurrentUser } from '@local/src/Hooks'
import { usePostSvarBegaranYttrande } from '@local/src/Pages/BegaranYttrande/Hooks/usePostSvarBegaranYttrande'
import {
	BegaranCsnYttrandeApiModel,
	BegaranYttrandeStatus,
	KundTillhorTrr,
	YttrandeApiModel,
} from '@local/src/Services/SwaggerApi/data-contracts'
import { Box, Button, Stack, Typography } from '@mui/material'
import React from 'react'
import { FieldErrors, FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { BegaranYttrandeIsDeletedAlert } from '@local/src/Components'

import { Yttrande } from './Components'
import useBegaranYttrandeSvarForm from './Hooks/useBegaranYttrandeSvarForm'
import { BegaranYttrandeSvarFormData } from './Models/BegaranYttrandeSvarForm.types'

interface Props {
	defaultFormData: BegaranYttrandeSvarFormData
	yttrande: BegaranCsnYttrandeApiModel
	isReadOnly?: boolean
	status: BegaranYttrandeStatus
}

const BegaranYttrandeSvarForm = ({ defaultFormData, yttrande, status, isReadOnly = false }: Props) => {
	const { postSvarBegaranYttrandeMutation, apiResponse } = usePostSvarBegaranYttrande()
	const { t } = useTranslation('translation', { keyPrefix: 'begaranYttrande.svar' })
	const { tCommon } = useCommonTranslation()
	const { userHasRole } = useCurrentUser()
	const showShouldSendToCsn = userHasRole(['HANDLAGGARE-STUDIER', 'KOORDINATOR-STUDIER', 'RADGIVNINGS-ADMINISTRATOR'])

	const formMethods = useBegaranYttrandeSvarForm(defaultFormData, isReadOnly)
	const {
		handleSubmit,
		watch,
		setValue,
		formState: { errors, isSubmitting },
		hasInvalidAnsokanKomplettDatum,
	} = formMethods

	const { kundTillhorTrr } = watch()

	const onSubmit = async (formData: BegaranYttrandeSvarFormData) => {
		const yttrandeApiModel = {
			...formData.yttrande,
			datumAnsokanOo: formData.yttrande?.datumAnsokanOo && new Date(formData.yttrande?.datumAnsokanOo).toISOString(),
		} as YttrandeApiModel
		await postSvarBegaranYttrandeMutation.mutateAsync({
			grundansokanId: yttrande.grundansokanId,
			begaranId: yttrande.idBegaran,
			shouldSendToCsn: formData.shouldSendToCsnFormBool,
			data: {
				ansokanId: formData.ansokanId,
				idBegaran: formData.idBegaran,
				grundansokanId: formData.grundansokanId,
				kundTillhorTrr: formData.kundTillhorTrr,
				yttrande: yttrandeApiModel,
			},
		})
	}

	if (kundTillhorTrr === KundTillhorTrr.KundTillhorTrr && hasInvalidAnsokanKomplettDatum)
		return (
			<SystemFeedback
				testSelector={'trr-ansokan-datum-error'}
				hideCloseButton
				type="warning"
				content={
					<Box>
						<Typography variant="h6">Tekniskt fel:</Typography>
						<Typography>{t('errors.trrAnsokanDatumTeknisktFel')}</Typography>
					</Box>
				}
			/>
		)

	return (
		<FormProvider {...formMethods}>
			<form onSubmit={handleSubmit(onSubmit)} data-testid="svar-begaran-yttrande-form">
				<Stack spacing={3}>
					{(errors.yttrande as FieldErrors)?.message && <p>{errors.yttrande?.message?.toString()}</p>}

					<Yttrande isReadOnly={isReadOnly} utbildningarFromCsn={yttrande.utbildningar} />

					<BegaranYttrandeIsDeletedAlert status={status} />

					{!isReadOnly && (
						<Stack direction="row" gap={2}>
							<Button
								onClick={() => setValue('shouldSendToCsnFormBool', false)}
								disabled={isSubmitting}
								type="submit"
								data-testid="spara-svar-yttrande-button"
								variant="outlined"
							>
								{t('buttons.saveDraft')}
							</Button>

							{showShouldSendToCsn && (
								<Button
									onClick={() => setValue('shouldSendToCsnFormBool', true)}
									disabled={isSubmitting}
									type="submit"
									data-testid="skicka-svar-yttrande-button"
								>
									{tCommon('buttons.sendToCsn')}
								</Button>
							)}
						</Stack>
					)}

					<SubmitResponse apiResponse={apiResponse} />
				</Stack>
			</form>
		</FormProvider>
	)
}
export default BegaranYttrandeSvarForm
