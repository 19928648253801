import DownloadForOffline from '@mui/icons-material/DownloadForOffline'
import Description from '@mui/icons-material/Description'
import { Box, Typography, Card, Stack, Button, Chip } from '@mui/material'
import { format } from 'date-fns'

import { IndustryReport } from 'types'
import { useEpiContent, useEpiCustomDictionary } from '@trr/app-shell-data'
import { Content } from 'types/Content.type'
import { publicationTypeMapper } from 'utils'

import downloadBlobHelper from './utils/downloadBlobHelper'
import { OpenInNew } from '@mui/icons-material'
import isExternalURL from 'utils/isExternalUrl'

type PreviewProps = Pick<
  IndustryReport,
  | 'title'
  | 'description'
  | 'publisher'
  | 'publicationDate'
  | 'fileName'
  | 'id'
  | 'type'
  | 'links'
>

const Preview = ({
  id,
  title,
  description,
  publisher,
  publicationDate,
  fileName,
  type,
  links,
}: PreviewProps) => {
  const {
    redigeraPublikation: {
      previewPublicationHeading,
      formPublisherLabel,
      formPublicationDateLabel,
      formDescriptionLabel,
      previewDownloadButtonLabel,
      formTitleLabel,
    },
  } = useEpiContent<Content>()
  const customDictionary = useEpiCustomDictionary(['redigeraPublikation'])

  const handleClickDownload = async () => {
    if (id && fileName) await downloadBlobHelper(id, fileName)
  }

  return (
    <Box>
      <Typography variant="h6" component={'h2'} sx={{ paddingBottom: 2 }}>
        {previewPublicationHeading}
      </Typography>
      <Card
        sx={{
          background: (theme) => theme.palette.surface?.grey,
          padding: 5,
          border: 'none',
        }}
      >
        <Card
          sx={{
            background: (theme) => theme.palette.background.paper,
          }}
        >
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Stack sx={{ padding: 4 }}>
              <Typography variant="subtitle2" sx={{ paddingBottom: 1 }}>
                {`${customDictionary('previewPublicationFrom', {
                  publicationType: publicationTypeMapper(type),
                })} `}
                {publisher === '' ? formPublisherLabel : publisher} |{' '}
                {publicationDate instanceof Date &&
                isFinite(publicationDate.getDate())
                  ? format(publicationDate, 'yyyy-MM-dd')
                  : formPublicationDateLabel}
              </Typography>
              <Typography
                variant="h5"
                lineHeight={1.2}
                sx={{ paddingBottom: 2 }}
              >
                {title === '' ? formTitleLabel : title}
              </Typography>
              <Typography variant="body2" sx={{ paddingBottom: 1 }}>
                {description === '' ? formDescriptionLabel : description}
              </Typography>
              <Button
                variant="text"
                startIcon={<DownloadForOffline />}
                sx={{
                  padding: 0,
                  marginTop: 'auto',
                }}
                onClick={() => void handleClickDownload()}
              >
                {previewDownloadButtonLabel}
              </Button>

              {links &&
                links.map((link, index) => (
                  <Button
                    sx={{
                      padding: 0,
                      marginTop: 'auto',
                      minWidth: 0,
                    }}
                    startIcon={isExternalURL(link.url) && <OpenInNew />}
                    variant="text"
                    key={index}
                    href={link.url}
                    target={isExternalURL(link.url) ? '_blank' : '_self'}
                  >
                    {link.text}
                  </Button>
                ))}
            </Stack>
            <Box
              sx={{
                background: (theme) => theme.palette.surface?.purple,
                padding: 10,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Description
                sx={{
                  width: '96px',
                  height: '96px',
                  color: (theme) => theme.palette.primary.main,
                }}
              />
            </Box>
          </Stack>
        </Card>
      </Card>

      <Stack alignItems="center" sx={{ paddingTop: 2 }}>
        {fileName && <Chip variant="outlined" label={fileName} />}
      </Stack>
    </Box>
  )
}

export default Preview
