import { userTypes } from 'State/Slices/inputSlice/constants'
import { UserType } from 'State/Slices/inputSlice/types'
import { pageFlows, pageIds } from 'State/Slices/pageSlice/constants'
import { getUserTypeFromUrl } from 'Utils/Helpers/getUserTypeFromUrl'
import userRoleChecks from 'Utils/Helpers/userRolesChecks'

export const getPageIdsByUserType = <T extends UserType>(userType: T) =>
  Object.fromEntries(pageFlows[userType].map((key) => [key, key])) as Record<
    (typeof pageFlows)[T][number],
    (typeof pageFlows)[T][number]
  >

export const pageToStartOrReturnTo = (roles: string[]) => {
  const userType = getUserTypeFromUrl()
  if (roles.length === 0) {
    return pageIds.loginFlowStartPage
  }
  if (userType === userTypes.tjanstemanWithArbetsgivarinfo) {
    return getPageIdsByUserType(userType).validateArbetsgivarinfoPage
  }
  if (
    userType === userTypes.arbetsgivare &&
    userRoleChecks(roles).isOnboarding
  ) {
    return getPageIdsByUserType(userType).selectCompanyPage
  }
  if (userType === userTypes.tjansteman && userRoleChecks(roles).isOnboarding) {
    return getPageIdsByUserType(userType).loadCollectumPage
  }
  if (
    userType === userTypes.tjansteman &&
    userRoleChecks(roles).isMissingWorkSituation
  ) {
    return getPageIdsByUserType(userType).selectWorkSituationPage
  }
  if (userRoleChecks(roles).isMissingEmail) {
    return pageIds.addEmailPage
  }
  return pageIds.loginFlowStartPage
}
