import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { useGetSearchSuggestionsQuery } from '@local/src/Components/SearchSuggestions/services/searchSuggestionsService';
import SearchSuggestionList from '@local/src/Components/SearchSuggestions/SearchSuggestionList';
import { Box } from '@mui/material';
var SearchSuggestions = function () {
    var _a, _b;
    var _c = useGetSearchSuggestionsQuery(), response = _c.data, isError = _c.isError;
    if (isError || !response || ((_a = response.suggestions) === null || _a === void 0 ? void 0 : _a.length) === 0) {
        return null;
    }
    return (_jsx(Box, { maxWidth: "800px", "data-testid": "search-suggestions-wrapper", children: (_b = response.suggestions) === null || _b === void 0 ? void 0 : _b.map(function (suggestion) { return (_jsx(SearchSuggestionList, { suggestion: suggestion }, suggestion.category)); }) }));
};
export default SearchSuggestions;
