import createBaseRules from './createBaseRules'

const createUserRules = (
  roles: string[] = [],
  idp: string = '',
  arbetsgivarinfoLinkId: string | null = null
) => {
  const baseRules = createBaseRules(roles, idp, arbetsgivarinfoLinkId)

  const isUserProfileComplete = () => {
    if (baseRules.isMissingWorkSituation()) return false
    if (baseRules.isOnboarding()) return false

    // Only perform check if specific arbetsgivarinfo role exists when a link id is provided
    if (arbetsgivarinfoLinkId && !baseRules.isArbetsgivarinfoLinkIdRole())
      return false

    // If user is arbetsgivarinfo, they are allowed to be missing email
    if (baseRules.isMissingEmail() && !baseRules.isArbetsgivarinfo())
      return false

    return true
  }

  const shouldUserStayInArbetsgivarinfoLoginFlow = () => {
    if (
      baseRules.isArbetsgivarinfo() &&
      baseRules.isArbetsgivarinfoLinkIdRole()
    ) {
      return false
    }
    // If user does not have arbetsgivarinfo roles but is in arbetsgivarinfo login flow
    if (window.location.pathname.includes('/login/arbetsgivarinfo')) {
      return true
    }
  }

  const isUserProfileIncomplete = () => !isUserProfileComplete()

  const getLoginUrl = () => {
    if (arbetsgivarinfoLinkId)
      return `/login/arbetsgivarinfo?linkId=${arbetsgivarinfoLinkId}`

    return baseRules.isKundIdp() ? '/login/arbetsgivare' : '/login'
  }

  const getLoggedInRedirectUrl = (redirectUrl: string = undefined) => {
    const getUrl = (_redirectUrl: string) => {
      if (_redirectUrl) return _redirectUrl
      if (isUserProfileIncomplete()) return getLoginUrl()
      if (baseRules.isTjansteperson()) return '/mitt-trr'
      if (baseRules.isKundIdp()) return '/trr-foretag'

      return '/'
    }

    const url = getUrl(redirectUrl)
    return url
  }

  return {
    ...baseRules,
    getLoggedInRedirectUrl,
    isUserProfileIncomplete,
    shouldUserStayInArbetsgivarinfoLoginFlow,
  }
}

export default createUserRules
