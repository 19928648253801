import { FormikProps, withFormik } from 'formik'
import { JSX, useEffect } from 'react'
import { useIsFeatureFlagEnabled } from '@local/Utils/Hooks'
import { mapAnsokanToArbetsplatsFormValues } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/Arbetsplats/Helpers'
import TidsbegransadAnstallningStepper from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/TidsbegransadAnstallningForm/TidsbegransadAnstallningStepper'
import TidsbegransadAnstallningTracker from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/TidsbegransadAnstallningForm/TidsbegransadAnstallningTracker'
import {
  ITidsbegransadAnstallningFormProps,
  ITidsbegransadAnstallningFormValues,
} from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/TidsbegransadAnstallningForm/types'
import { mapAnsokanToAnstallningTbFormValues } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/AnstallningTB/Helpers'
import { mapAnsokanToUppsagningFormValues } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/Uppsagning/Helpers'
import { mapAnsokanToLonOchErsattningFormValues } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/LonOchErsattning/Helpers'
import { mapAnsokanToArbetslivserfarenhetTBFormValues } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/ArbetslivserfarenhetTB/Helpers'
import { initialGranskaAnsokanValues } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/GranskaAnsokan/GranskaAnsokan.schema'
import { tidsbegransadAnstallningValidationSchema } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/TidsbegransadAnstallningForm/tidsbegransadAnstallning.schema'
import useFormikWatch from '@local/Utils/Hooks/useFormikWatch'
import useReduxStore from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/TidsbegransadAnstallningForm/hooks/useReduxStore'
import usePatchAnsokan from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/TidsbegransadAnstallningForm/hooks/usePatchAnsokan'
import { tidsbegransadAnstallningWatcherMiddleware } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/TidsbegransadAnstallningForm/tidsbegransadAnstallningWatcherMiddleware'
import { AnsokanKeys } from '@local/Types'

const TidsbegransadAnstallningForm = ({
  values,
  initialValues,
  setFieldValue,
}: ITidsbegransadAnstallningFormProps &
  FormikProps<ITidsbegransadAnstallningFormValues>): JSX.Element => {
  const shouldTrack = useIsFeatureFlagEnabled(
    'gtm.enabled.tjanstepersonansokan'
  )

  const { setStoredValuesAfterPatch } = useReduxStore(initialValues, values)

  const { patchAnsokan } = usePatchAnsokan(setStoredValuesAfterPatch)

  useFormikWatch(tidsbegransadAnstallningWatcherMiddleware, patchAnsokan)

  useEffect(() => {
    setFieldValue(AnsokanKeys.OrganisationsnummerIsAffiliated, null)?.catch(
      (err) => {
        console.log(err)
      }
    )
  }, [setFieldValue, values.foretagorganisationsnummer])

  return (
    <>
      {shouldTrack && <TidsbegransadAnstallningTracker />}

      <TidsbegransadAnstallningStepper />
    </>
  )
}

const TidsbegransadAnstallningFormik = withFormik<
  ITidsbegransadAnstallningFormProps,
  ITidsbegransadAnstallningFormValues
>({
  validateOnBlur: true,
  mapPropsToValues: ({ ansokan, bilagor }) => ({
    ...mapAnsokanToArbetsplatsFormValues(ansokan),
    ...mapAnsokanToAnstallningTbFormValues(ansokan, bilagor),
    ...mapAnsokanToUppsagningFormValues(ansokan),
    ...mapAnsokanToLonOchErsattningFormValues(ansokan),
    ...mapAnsokanToArbetslivserfarenhetTBFormValues(ansokan),
    ...initialGranskaAnsokanValues,
  }),
  handleSubmit: (_, { setSubmitting }) => {
    setSubmitting(false)
  },
  validationSchema: ({ content }: ITidsbegransadAnstallningFormProps) =>
    tidsbegransadAnstallningValidationSchema(content),
  displayName: 'TidsbegransadAnstallningForm',
})(TidsbegransadAnstallningForm)

export default TidsbegransadAnstallningFormik
