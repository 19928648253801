import { IdServerUser } from '../types'
import { useRef } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import {
  getArbetsgivarinfoLinkId,
  shouldRedirect,
  trackUserLoadedRedirect,
  createUserRules,
} from './utils'

const useRedirectLoggedInUser = (oidcUser: IdServerUser) => {
  const { pathname } = useLocation()
  const history = useHistory()
  const currentOidcUserRef = useRef<IdServerUser>()
  const prevOidcUser = currentOidcUserRef.current
  currentOidcUserRef.current = oidcUser
  const userRules = createUserRules(
    oidcUser.profile.roles,
    oidcUser.profile.idp,
    getArbetsgivarinfoLinkId(oidcUser)
  )

  const hasIncompleteAccountOnNonCallbackPage =
    pathname.startsWith('/login') &&
    !pathname.includes('/callback') &&
    userRules.isUserProfileIncomplete()

  if (shouldRedirect(oidcUser, prevOidcUser, userRules)) {
    const redirectUrl = userRules.getLoggedInRedirectUrl(
      oidcUser.state?.redirectUrl
    )

    trackUserLoadedRedirect(
      oidcUser?.profile?.idp,
      oidcUser?.profile?.roles,
      prevOidcUser?.profile?.roles,
      oidcUser.state?.redirectUrl,
      redirectUrl
    )
    history.replace(redirectUrl)
  }

  return {
    shouldRenderApp:
      hasIncompleteAccountOnNonCallbackPage || !oidcUser.isLoggedIn,
  }
}

export default useRedirectLoggedInUser
