import { JSX } from 'react'
import {
  Box,
  Stepper as MuiStepper,
  Step,
  StepButton,
  StepLabel,
} from '@mui/material'
import Gutter from '@local/Components/Gutter'
import { useIsBelowMediumScreens } from '@local/Utils/Hooks'
import { styled } from '@mui/material/styles'

import { IStepper } from './Stepper.types'

const StyledStepper = styled('div')(({ theme }) => ({
  '&.sticky-vertical': {
    position: 'sticky',
    top: theme.spacing(5),
    bottom: 'auto',
  },
  '& .MuiStep-root > .MuiStepButton-root': {
    width: 'auto',
  },
}))

const Stepper = ({
  steps,
  activeStep,
  setActiveStep,
  orientation = 'vertical',
  stickyVertical = false,
}: IStepper): JSX.Element => {
  const isBelowMediumScreens = useIsBelowMediumScreens()

  return (
    <StyledStepper className={stickyVertical && 'sticky-vertical'}>
      <div role="navigation" aria-label="Steg i formuläret">
        <Box sx={{ width: '100%' }}>
          <MuiStepper
            nonLinear
            activeStep={activeStep}
            orientation={orientation}
          >
            {steps.map(
              (step) =>
                step.shouldShowStep && (
                  <Step key={step.title}>
                    <StepButton
                      disableRipple
                      color="inherit"
                      onClick={() => {
                        setActiveStep(step.step)
                      }}
                    >
                      <StepLabel error={step.stepHasErrors}>
                        {step.title}
                      </StepLabel>
                    </StepButton>
                  </Step>
                )
            )}
          </MuiStepper>
        </Box>

        {isBelowMediumScreens && <Gutter xs={24} />}
      </div>
    </StyledStepper>
  )
}

export default Stepper
