import { RootState } from '@local/Store/configureStore'
import { IKompletteraFormValues } from '@local/Views/TidigareSysselsattningar/KompletteraForm/KompletteraForm.types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface IKompletteraState {
  values: IKompletteraFormValues
  formIsDirty: boolean
}

const initialState: IKompletteraState = {
  values: null,
  formIsDirty: false,
}

export const kompletteraFormSlice = createSlice({
  name: 'kompletteraForm',
  initialState,
  reducers: {
    setValues: (state, action: PayloadAction<IKompletteraFormValues>) => {
      state.values = action.payload
    },
    setFormIsDirty: (state, action: PayloadAction<boolean>) => {
      state.formIsDirty = action.payload
    },
    resetState: () => initialState,
  },
})

export const { setValues, setFormIsDirty, resetState } =
  kompletteraFormSlice.actions

export const selectKompletteraForm = (state: RootState) => state.kompletteraForm

export default kompletteraFormSlice.reducer
