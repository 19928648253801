import { JSX } from 'react'
import { usePickEpiContent } from '@local/Utils/Hooks'
import { useFormikContext } from 'formik'
import HTMLMapper from '@local/Components/HTMLMapper'
import { ITidsbegransadAnstallningContent } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/TidsbegransadAnstallning.types'
import Tooltip from '@local/Components/Tooltip'
import NumberInput from '@local/Components/NumberInput'
import { ITidsbegransadAnstallningFormValues } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/TidsbegransadAnstallningForm/types'
import { AnsokanKeys } from '@local/Types'
import { isNil } from 'ramda'

const Manadslon = (): JSX.Element => {
  const {
    lonOchErsattning: { manadslon, tooltipManadslon },
  } = usePickEpiContent<ITidsbegransadAnstallningContent>()

  const formikContext = useFormikContext<ITidsbegransadAnstallningFormValues>()
  const { errors, touched, values } = formikContext

  return (
    <>
      <Tooltip
        heading={manadslon.titel}
        tooltipContent={<HTMLMapper body={tooltipManadslon.mainBody} />}
      />

      <NumberInput
        formikContext={formikContext}
        label={manadslon.textFaltTitel}
        name={AnsokanKeys.Manadslon}
        value={values.manadslon}
        error={touched?.manadslon && !isNil(errors?.manadslon)}
        errorMsg={errors?.manadslon}
      />
    </>
  )
}

export default Manadslon
