import { applicationInsights } from '@trr/app-shell-communication'
import { IdServerUser } from '../../types'
import createUserRules from './createUserRules'

export const getArbetsgivarinfoLinkId = (user: IdServerUser) => {
  const userState = user?.state?.arbetsgivarinfoLinkId
  const arbetsgivarinfoUrl = new URLSearchParams(window.location.search).get(
    'linkId'
  )
  return userState ?? arbetsgivarinfoUrl ?? undefined
}

export const trackUserLoadedRedirect = (
  idp: string,
  roles: string[],
  lastRoles: string[],
  stateRedirectUrl: string,
  redirectUrl: string
) => {
  applicationInsights.trackEvent({
    name: 'userLoadedRedirect',
    properties: {
      repo: 'loginflow',
      idp,
      roles,
      lastRoles,
      stateRedirectUrl,
      redirectUrl,
    },
  })
}

export const isSameProfile = (
  currentOidcUser: IdServerUser,
  prevOidcUser: IdServerUser
) => {
  const currOidcUserProfile = currentOidcUser?.profile
  const prevOidcUserProfile = prevOidcUser?.profile

  const allRolesMatch = currOidcUserProfile?.roles?.every((role) =>
    prevOidcUserProfile?.roles?.includes(role)
  )

  return (
    currOidcUserProfile?.sub === prevOidcUserProfile?.sub &&
    currOidcUserProfile?.roles?.length === prevOidcUserProfile?.roles?.length &&
    allRolesMatch
  )
}

export const shouldRedirect = (
  currentOidcUser: IdServerUser,
  prevOidcUser: IdServerUser,
  userRules: ReturnType<typeof createUserRules>
) => {
  if (currentOidcUser.state?.refreshUserProfile) return false
  if (currentOidcUser.state?.expiringTokenWasRefreshed) return false
  if (isSameProfile(currentOidcUser, prevOidcUser)) return false
  if (userRules.shouldUserStayInArbetsgivarinfoLoginFlow()) return false
  if (currentOidcUser.profile.roles?.length) return true // roles exists
  return false
}
