import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { baseApi } from '@local/services/API/baseApi'
import modalReducer from '@local/Components/SuccessModal/modalSlice'
import { useDispatch } from 'react-redux'
import { bilagorApi } from '@local/services/API/bilagorApi'
import { formikWatchSlice } from '@local/Utils/Hooks/formikWatchSlice'
import { ansokanDetailsFormSlice } from '@local/Views/AnsokanDetails/AnsokanDetailsForm/ansokanDetailsFormSlice'
import { kompletteraFormSlice } from '@local/Views/TidigareSysselsattningar/KompletteraForm/kompletteraFormSlice'

const rootReducer = combineReducers({
  [baseApi.reducerPath]: baseApi.reducer,
  [bilagorApi.reducerPath]: bilagorApi.reducer,
  ['modal']: modalReducer,
  ['formikWatch']: formikWatchSlice.reducer,
  ['ansokanDetailsForm']: ansokanDetailsFormSlice.reducer,
  ['kompletteraForm']: kompletteraFormSlice.reducer,
})

export const setupStore = (preloadedState?: DeepPartial<RootState>) =>
  configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      // adding the api middleware enables caching, invalidation, polling and other features of `rtk-query`
      getDefaultMiddleware().concat([
        baseApi.middleware,
        bilagorApi.middleware,
      ]),
    preloadedState,
  })

export type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>
    }
  : T

export const store = setupStore()
export type RootState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>()
