import { useCallback, useState } from 'react'
import { useCachedUserProfileState, useCachedContentQuery } from 'PublicWebApi'

import { alpha, Avatar, ButtonBase, styled, Typography } from '@mui/material'
import ArrowDropDownRounded from '@mui/icons-material/ArrowDropDownRounded'
import ArrowDropUpRounded from '@mui/icons-material/ArrowDropUpRounded'

import { useSliceStateSelector } from 'slices/hooks'
import AvatarPopover from '../AvatarPopover'

interface AvatarWrapperProps {
  withoutBorder?: boolean
  disableRipple?: boolean
}

const AvatarWrapper = styled(ButtonBase, {
  shouldForwardProp: (prop) => prop !== 'withoutBorder',
})<AvatarWrapperProps>(({ theme, withoutBorder }) => ({
  borderRadius: '24px',
  border: `1px solid ${alpha(theme.palette.primary.main, 0.5)}`,
  padding: theme.spacing(1.5, 3),
  gap: theme.spacing(1),

  ...(withoutBorder && {
    borderRadius: 0,
    border: '1px solid transparent',
    backgroundColor: 'transparent',
    padding: 0,
    margin: theme.spacing(1, 1.5),
  }),

  '&:hover': {
    backgroundColor: withoutBorder
      ? 'transparent'
      : theme.palette.action?.hover,
    p: {
      color: withoutBorder && theme.palette.primary?.main,
    },
  },
  '&:focus-visible': {
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: withoutBorder
      ? 'transparent'
      : theme.palette.action?.focus,
  },

  [theme.breakpoints.down('lg')]: {
    margin: 0,
    border: `1px solid transparent`,
    padding: theme.spacing(1),

    '&:hover': {
      backgroundColor: theme.palette.action?.hover,
      borderRadius: '24px',
      p: {
        color: theme.palette.primary?.main,
      },
    },
    '&:focus-visible': {
      backgroundColor: theme.palette.action?.focus,
      borderRadius: '24px',
      border: `1px solid transparent`,
    },
  },
}))

interface LoggedInAvatarButtonProps {
  withoutBorder?: boolean
}

const LoggedInAvatarButton = ({ withoutBorder }: LoggedInAvatarButtonProps) => {
  const { preferredFirstName, profileImages, imageFileName } =
    useCachedUserProfileState()
  const { data: contentData } = useCachedContentQuery()

  const { isMobile, isTablet } = useSliceStateSelector(
    (state) => state.slices.device.deviceSize
  )
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const openPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const isPublicPage = contentData?.properties.isPublic

  const isMobileOrTablet = isMobile || isTablet
  const firstLetter = preferredFirstName?.slice(0, 1)

  const showPreferredFirstName = !isMobileOrTablet

  const removeBorder = withoutBorder ? true : isPublicPage

  const showArrowDown = !isMobileOrTablet

  const toggleArrow = useCallback(() => {
    if (anchorEl) {
      return <ArrowDropUpRounded color="primary" />
    } else {
      return <ArrowDropDownRounded color="primary" />
    }
  }, [anchorEl])

  return (
    <>
      <AvatarWrapper
        withoutBorder={removeBorder}
        data-testid="loggedin-avatar-wrapper"
        onClick={openPopover}
        disableRipple
      >
        <Avatar
          sx={{ height: '24px', width: '24px', bgcolor: 'primary.main' }}
          {...(imageFileName && {
            src: profileImages?.small,
            alt: 'Profilbild',
          })}
          data-testid="loggedin-avatar"
        >
          <Typography variant="subtitle2">{firstLetter}</Typography>
        </Avatar>
        {showPreferredFirstName && (
          <Typography
            variant="body1"
            color={removeBorder ? 'text.primary' : 'primary.main'}
          >
            {preferredFirstName}
          </Typography>
        )}
        {showArrowDown && toggleArrow()}
      </AvatarWrapper>
      <AvatarPopover
        isPublicPage={isPublicPage}
        firstName={preferredFirstName}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
      />
    </>
  )
}

export default LoggedInAvatarButton
