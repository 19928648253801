import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Fade,
  IconButton,
} from '@mui/material'
import React, { useState } from 'react'
import CloseIcon from '@mui/icons-material/Close'

interface ModalProps {
  label: string
  open: boolean
  children: React.ReactNode
  fullScreen?: boolean
  disableSubmit?: boolean
  closeIcon?: boolean
  onClose?: () => void
  onCancel?: () => void
  onConfirm?: () => void
  confirmText?: string
  cancelText?: string
  testSelector?: string
  disableBackdropClick?: boolean
}

const Modal: React.FC<ModalProps> = ({
  label,
  children,
  closeIcon = false,
  fullScreen = false,
  onClose,
  onCancel,
  onConfirm,
  open,
  disableSubmit,
  confirmText = 'OK',
  cancelText = 'Avbryt',
  testSelector,
  disableBackdropClick,
}): JSX.Element => {
  const handleClose = (_?: unknown, reason?: string) => {
    if (disableBackdropClick && reason === 'backdropClick') {
      return
    }
    onClose?.()
    onCancel?.()
  }

  const [showDividers, setShowDividers] = useState(false)

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    setShowDividers(event.currentTarget.scrollTop > 0)
  }

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      fullScreen={fullScreen}
      data-testid={testSelector}
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
          },
        },
      }}
    >
      <DialogTitle
        display="flex"
        alignItems="center"
        justifyContent={closeIcon && 'space-between'}
      >
        <Box>{label}</Box>
        {closeIcon && (
          <Box>
            <IconButton onClick={handleClose} aria-label="Stäng">
              <CloseIcon />
            </IconButton>
          </Box>
        )}
      </DialogTitle>

      <Fade in={showDividers}>
        <Divider />
      </Fade>
      <DialogContent onScroll={handleScroll}>{children}</DialogContent>
      <Fade in={showDividers}>
        <Divider />
      </Fade>

      {((onCancel || onConfirm) && (
        <DialogActions>
          {onConfirm && (
            <Button
              variant="text"
              onClick={onConfirm}
              disabled={disableSubmit}
              data-cy="modal-confirm-button"
            >
              {confirmText}
            </Button>
          )}
          {onCancel && (
            <Button variant="text" onClick={onCancel}>
              {cancelText}
            </Button>
          )}
        </DialogActions>
      )) ||
        null}
    </Dialog>
  )
}

export default Modal
