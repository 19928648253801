import React from 'react'
import { veckorQuantity } from '@local/src/features/StudiestodAnsokan/Components/Steps/RadgivarensUnderlag/Helpers/veckorQuantity'
import { Box, Typography } from '@mui/material'
import { useRadgivarensUnderlagFormContext } from '@local/src/features/StudiestodAnsokan/Components/Steps/RadgivarensUnderlag/Hooks'
import { useTranslation } from 'react-i18next'
import NumberInputField from '@local/src/components/FormComponents/InputField/NumberInputField'

interface Props {
    kalenderhalvarIndex: number
    utbildningsIndex: number
    isReadonly: boolean
}

const Studieersattning = ({ kalenderhalvarIndex, utbildningsIndex, isReadonly }: Props) => {
    const { t } = useTranslation('translation', { keyPrefix: 'studiestodAnsokan.steps.radgivarensUnderlag' })

    const { watch } = useRadgivarensUnderlagFormContext()
    const studieersattningValue = watch(
        `utbildningar.${utbildningsIndex}.studieersattning.kalenderhalvar.${kalenderhalvarIndex}.studieersattning`
    )

    return (
        <Box flex={1}>
            <Typography mb={1}>{t('preliminarStudieersattningText')}</Typography>
            <NumberInputField
                name={`utbildningar.${utbildningsIndex}.studieersattning.kalenderhalvar.${kalenderhalvarIndex}.studieersattning`}
                label={t('kronorPerDagText')}
                disabled={isReadonly}
                testId={`utbildningar.${utbildningsIndex}.studieersattning.kalenderhalvar.${kalenderhalvarIndex}.studieersattning`}
            />
            <Typography>
                {veckorQuantity(studieersattningValue)} {t('kronorPerFyraVeckorText')}
            </Typography>
        </Box>
    )
}

export default Studieersattning
