import { Button, Chip, Stack, Typography, useTheme } from '@mui/material'
import Add from '@mui/icons-material/Add'
import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline'
import { useDispatch } from 'react-redux'

import { IEvaluation } from '../../../types/IEvaluation.types'
import { openModal } from '../evaluationReflectionSlice'

interface IEvaluationButtonProps {
  evaluation: IEvaluation
  disabled: boolean
}

const EvaluationButton = ({ evaluation, disabled }: IEvaluationButtonProps) => {
  const dispatch = useDispatch()
  const clickDisabled = evaluation.hasReflection || disabled
  const theme = useTheme()

  return (
    <>
      {evaluation.highlighted ? (
        <Button
          sx={{
            justifyContent: 'space-between',
            width: '240px',
            height: '58px',
            fontWeight: 'bold',
            borderRadius: 2,
            borderColor: theme.palette.grey[300],
          }}
          disabled={clickDisabled}
          onClick={() => dispatch(openModal(evaluation.id))}
          variant="outlined"
          endIcon={
            !evaluation.hasReflection ? (
              <Add />
            ) : (
              <CheckCircleOutline
                color="success"
                sx={{ opacity: clickDisabled ? 0.6 : 1 }}
              />
            )
          }
        >
          {evaluation.term}
        </Button>
      ) : (
        <Chip
          onClick={() => dispatch(openModal(evaluation.id))}
          component={'div'}
          label={
            <Stack
              direction={'row'}
              alignItems={'center'}
              justifyContent={'space-between'}
            >
              <Typography component={'span'} variant="subtitle2" pr={1}>
                {evaluation.term}
              </Typography>
              {evaluation.hasReflection ? (
                <CheckCircleOutline
                  color="success"
                  sx={{ opacity: clickDisabled ? 0.6 : 1 }}
                />
              ) : (
                <Add
                  sx={{ color: theme.palette.grey[700] }}
                  onClick={() => dispatch(openModal(evaluation.id))}
                />
              )}
            </Stack>
          }
          clickable
          disabled={clickDisabled}
          variant="outlined"
        />
      )}
    </>
  )
}

export default EvaluationButton
