import { Box, Typography } from '@mui/material'
import { useEpiContent, useEpiCustomDictionary } from '@trr/app-shell-data'
import { IFramtidsmalContent } from 'App/App.types'

type FramtidsmalProgressProps = {
  careerGoals: string
  studyGoals: string
  expectations: string
}

const FramtidsmalProgress = ({
  careerGoals,
  studyGoals,
  expectations,
}: FramtidsmalProgressProps) => {
  const {
    progress: { bodyCompleted, bodyUncompleted },
  } = useEpiContent<IFramtidsmalContent>()
  const epi = useEpiCustomDictionary(['progress'])
  const maxProgress = '3'
  const countProgress =
    [careerGoals, studyGoals, expectations]
      .filter((goal) => goal.trim().length > 0)
      .length.toString() || '0'
  const completed = countProgress === maxProgress

  return (
    <Box mt={2}>
      <Typography variant="h6" color={completed ? 'secondary' : ''}>
        {epi('header', { countProgress, maxProgress })}
      </Typography>
      <Typography
        variant="body2"
        data-testid={completed ? 'body-completed' : 'body-uncompleted'}
      >
        {completed ? bodyCompleted : bodyUncompleted}
      </Typography>
    </Box>
  )
}

export default FramtidsmalProgress
