import { JSX } from 'react'
import { useIsFeatureFlagEnabled, usePickEpiContent } from '@local/Utils/Hooks'
import { IDinaAnsokningarContent } from '@local/Views/DinaAnsokningar/DinaAnsokningar.types'
import TidigareSysselsattningListItemOld from '@local/Views/DinaAnsokningar/TidigareSysselsattningarList/TidigareSysselsattningListItemOld'
import Spinner from '@local/Components/Spinner'
import Alert from '@local/Components/Alert'
import { Typography } from '@mui/material'
import Preamble from '@local/Views/DinaAnsokningar/TidigareSysselsattningarList/Preamble'
import Gutter from '@local/Components/Gutter'
import { useTidigareSysselsattningar } from '@local/Views/DinaAnsokningar/TidigareSysselsattningarList/hooks'
import TidigareSysselsattningListItem from '@local/Views/DinaAnsokningar/TidigareSysselsattningarList/TidigareSysselsattningListItem'
import { TidigareSysselsattningResponseModel } from '@local/services/API/tidigareSysselsattningar/Types'
import { useGetKanKomplettera } from '@local/Utils/Hooks/TidigareSysselsattningar'

import {
  ITidigareSysselsattning,
  KompletteraStatusType,
} from './TidigareSysselsattningar.types'

const TidigareSysselsattningarList = (): JSX.Element => {
  const isNewKompletteraApiModelEnabled = useIsFeatureFlagEnabled(
    'KlientAnsokan-Frontend_newKompletteraApiModel_temp_241218'
  )

  const {
    data: sysselsattningar,
    isLoading: isLoadingSysselsattningar,
    isError: isErrorSysselsattningar,
  } = useTidigareSysselsattningar()

  const {
    data: kompletteraStatus,
    isLoading: isLoadingKompletteraStatus,
    isError: isErrorKompletteraStatus,
  } = useGetKanKomplettera()

  const { tidigareSysselsattningar: content, nagotGickFel } =
    usePickEpiContent<IDinaAnsokningarContent>()

  if (isLoadingSysselsattningar || isLoadingKompletteraStatus) {
    return (
      <>
        <Spinner centered />
        <Gutter offset_xs={40} />
      </>
    )
  }

  if (isErrorSysselsattningar || isErrorKompletteraStatus) {
    return (
      <>
        <Alert severity="error">{nagotGickFel.heading}</Alert>
        <Gutter offset_xs={40} />
      </>
    )
  }

  if (
    sysselsattningar?.length <= 0 &&
    kompletteraStatus === KompletteraStatusType.KanInteKomplettera
  ) {
    return null
  }

  return (
    <>
      <Typography variant="h5" gutterBottom>
        {content.heading}
      </Typography>

      <Preamble />

      {sysselsattningar?.length > 0 && (
        <>
          {sysselsattningar.map((tidigareSysselsattning) =>
            isNewKompletteraApiModelEnabled ? (
              <TidigareSysselsattningListItem
                key={tidigareSysselsattning.id}
                tidigareSysselsattning={
                  tidigareSysselsattning as TidigareSysselsattningResponseModel
                }
              />
            ) : (
              <TidigareSysselsattningListItemOld
                key={tidigareSysselsattning.id}
                tidigareSysselsattning={
                  tidigareSysselsattning as ITidigareSysselsattning
                }
              />
            )
          )}
          <Gutter offset_xs={40} />
        </>
      )}
    </>
  )
}

export default TidigareSysselsattningarList
