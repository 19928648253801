import { IAnsokanDetailsFormValues } from '@local/Views/AnsokanDetails/AnsokanDetailsForm/AnsokanDetailsForm.types'
import {
  IKompletteraFormValues,
  IKomplettering,
} from '@local/Views/TidigareSysselsattningar/KompletteraForm/KompletteraForm.types'

export interface IForetag {
  kontaktPerson: IForetagetsKontaktperson
  namn: string
  organisationsnummer: string
}

export interface IForetagetsKontaktperson {
  namn: string
  epostadress: string
  telefon: string
}

export interface IFackligKontaktPerson {
  fornamn: string
  efternamn: string
  fackforbund: string
}

export interface IStatusContent extends IKeyValue {
  ny?: string
  saknarKonto?: string
  godkand?: string
  borttagen?: string
  utredningEjKopplad?: string
  utredningKontrollera?: string
  avvisadAnnanOrsak?: string
  avvisadEjAnsluten?: string
  avvisadAnnatAvtal?: string
  atertagen?: string
  avvisadPreskriberad?: string
  kontoForsakranSaknas?: string
  forsakranSaknas?: string
  migreradKontoSaknas?: string
  migrerad?: string
  locked?: string
  needsGranskning?: string
  tvist?: string
  redigerasAvArbetsgivare?: string
}

export interface INagotGickFelContent {
  heading: string
}

export enum Status {
  Ny = 'ny',
  SaknarKonto = 'saknarkonto',
  Godkand = 'godkand',
  Borttagen = 'borttagen',
  UtredningEjKopplad = 'utredningejkopplad',
  UtredningKontrollera = 'utredningkontrollera',
  AvvisadAnnanOrsak = 'avvisadannanorsak',
  AvvisadEjAnsluten = 'avvisadejansluten',
  AvvisadAnnatAvtal = 'avvisadannatavtal',
  Atertagen = 'atertagen',
  AvvisadPreskriberad = 'avvisadpreskriberad',
  KontoForsakranSaknas = 'kontoforsakransaknas',
  ForsakranSaknas = 'forsakransaknas',
  MigreradKontoSaknas = 'migreradkontosaknas',
  Migrerad = 'migrerad',
  ArendeAtertagenUppsagning = 'arendeatertagenuppsagning',
  Locked = 'locked',
  NeedsGranskning = 'needsgranskning',
  Tvist = 'tvist',
  RedigerasAvArbetsgivare = 'redigerasavarbetsgivare',
}

export interface IOrsakTillUppsagningContent extends IKeyValue {
  arbetsbrist: string
  konkurs: string
  risk: string
  visstidsanstallningslut: string
  egenbegaran: string
  overenskommelse: string
  sjukdom: string
  annan: string
}

export interface ITidigareSysselsattningarEnumContent extends IKeyValue {
  tillsvidareanstallning: string
  provanstallning: string
  tidsbegransadanstallning: string
  foraldrapenning: string
  totalforsvarspliktpenning: string
  sjukpenning: string
  omstallningsstudiestod: string
  graviditetspenning: string
  narstaendepenning: string
  rehabiliteringspenning: string
  smittbararpenning: string
}

export enum TidigareSysselsattningar {
  Tillsvidareanstallning = 'tillsvidareanstallning',
  Provanstallning = 'provanstallning',
  TidsbegransadAnstallning = 'tidsbegransadanstallning',
  Foraldrapenning = 'foraldrapenning',
  Totalforsvarspliktpenning = 'totalforsvarspliktpenning',
  Sjukpenning = 'sjukpenning',
  Omstallningsstudiestod = 'omstallningsstudiestod',
  Graviditetspenning = 'graviditetspenning',
  Narstaendepenning = 'narstaendepenning',
  Rehabiliteringspenning = 'rehabiliteringspenning',
  Smittbararpenning = 'smittbararpenning',
}

export interface IKeyValue {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any
}

export interface ISelectOption {
  label: string
  value: string | number
  id?: string
}

export type ISelectOptionFormikWrapper = ISelectOption | string

export enum RadioChoice {
  Ja = 'true',
  Nej = 'false',
}

export interface IEditorialBlock {
  mainBody: string
}

export type FormikWatcherValues =
  | IAnsokanDetailsFormValues
  | IKompletteraFormValues
  | IKomplettering

export interface IDinaTrrAnsokningarLinks {
  dinaTrrAnsokningarUrl: string
  tidigareSysselsattningarUrl: string
  ansokanUrl: string
}
