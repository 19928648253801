import { useMemo, useCallback } from 'react'
import {
  redirectToAuthServer,
  redirectToAuthServerEmployer,
} from '@trr/app-shell-communication'
import { BankIdImage, PasswordImage, FrejaImage } from 'Assets'
import useTexts from 'Utils/Hooks/useTexts'
import { List, Divider } from '@mui/material'

import { RadioButtonGroup, RadioButton, PageTitle } from '../../Components'

import texts from './texts'
import { ListItem } from './Components'
import { useDevice, useIsFeatureEnabled } from '@trr/app-shell-data'
import { useSliceDispatch, useSliceStateSelector } from 'State/Slices'
import { userTypes } from 'State/Slices/inputSlice/constants'
import { UserType } from 'State/Slices/inputSlice/types'
import Cookies from 'js-cookie'

const LoginFlowStartPage = () => {
  const { userType } = useSliceStateSelector((state) => state.slices.inputs)
  const isCustomLogin = window.location.href.indexOf('/login/custom') >= 0
  const pageTexts = useTexts(texts)
  const triggerDispatch = useSliceDispatch()
  const changeUserType = (newUserType: UserType) => {
    triggerDispatch('setUserType', newUserType)
    history.replaceState(
      null,
      '',
      `/login${isCustomLogin ? '/custom' : ''}${
        newUserType === userTypes.arbetsgivare ? '/arbetsgivare' : ''
      }`
    )
  }

  const loginClicked = useCallback(
    (authType: string) => () => {
      if (userType === userTypes.tjansteman) {
        redirectToAuthServer(authType)
        return
      }
      if (userType === userTypes.tjanstemanWithArbetsgivarinfo) {
        redirectToAuthServer(authType)
        return
      }
      redirectToAuthServerEmployer(authType)
    },
    [userType]
  )

  const enabledUserNamePassword = useIsFeatureEnabled(
    'LoginFlow-MicroFrontend_usernamePassword_perma_240507'
  )
  const device = useDevice()
  const isDeviceLargerThanMobile = useMemo(() => !device.isMobile, [device])

  const shouldPasswordBeVisible =
    enabledUserNamePassword || Cookies.get('trr-loginflow-usernamepassword')

  return (
    <>
      <PageTitle>{pageTexts.header}</PageTitle>
      <RadioButtonGroup
        displayRow={isDeviceLargerThanMobile}
        isHidden={userType === userTypes.tjanstemanWithArbetsgivarinfo}
      >
        <RadioButton
          keyProp="tjansteman-radiobutton"
          checked={userType === userTypes.tjansteman}
          label={pageTexts.loginAtTrr}
          testId="tjansteman-radiobutton"
          onClick={() => changeUserType(userTypes.tjansteman)}
        />
        <RadioButton
          keyProp="arbetsgivare-radiobutton"
          checked={userType === userTypes.arbetsgivare}
          label={pageTexts.loginAsEmployer}
          testId="arbetsgivare-radiobutton"
          onClick={() => changeUserType(userTypes.arbetsgivare)}
        />
      </RadioButtonGroup>
      <List>
        <Divider />
        {isCustomLogin ? (
          <ListItem
            label={pageTexts.password}
            testId="password"
            icon={<PasswordImage aria-hidden="true" />}
            onClick={loginClicked('password')}
          />
        ) : (
          <>
            <ListItem
              label={pageTexts.bThis}
              testId="bankid-this-device"
              icon={<BankIdImage aria-hidden="true" />}
              onClick={loginClicked('bThis')}
            />
            <ListItem
              label={pageTexts.bOther}
              testId="bankid-other-device"
              icon={<BankIdImage aria-hidden="true" />}
              onClick={loginClicked('bOther')}
            />
            <ListItem
              label={pageTexts.username}
              testId="username"
              hidden={!shouldPasswordBeVisible}
              icon={<PasswordImage aria-hidden="true" />}
              onClick={loginClicked('username')}
            />
            <ListItem
              label={pageTexts.freja}
              testId="freja-other-device"
              icon={<FrejaImage aria-hidden="true" />}
              onClick={loginClicked('fOther')}
            />
            <ListItem
              label={pageTexts.password}
              testId="password"
              hidden={!shouldPasswordBeVisible}
              icon={<PasswordImage aria-hidden="true" />}
              onClick={loginClicked('password')}
            />
          </>
        )}
      </List>
    </>
  )
}

export default LoginFlowStartPage
