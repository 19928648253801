import { Divider, FormControlLabel, Radio, RadioGroup, Stack, TextField, Typography } from '@mui/material'
import React, { Fragment } from 'react'
import { useGetCurrentStudiestodansokan } from '@local/src/features/StudiestodAnsokan/Hooks'
import { useTranslation } from 'react-i18next'
import LabelValue from '@local/src/features/StudiestodAnsokan/Components/LabelValue/LabelValue'
import { formatToDate } from '@local/src/utils/helpers/dateTimeFormatHelper'
import { Termin } from '@local/src/services/SwaggerApi/data-contracts'

import { StudielonKalenderhalvar } from './Components'

interface Props {
    utbildningsId: string
}

const Studielon = ({ utbildningsId }: Props) => {
    const { t } = useTranslation('translation', { keyPrefix: 'studiestodAnsokan.steps.radgivarensUnderlag' })

    const { studiestodAnsokan } = useGetCurrentStudiestodansokan()

    const currentUtbildning = studiestodAnsokan?.radgivarunderlag?.utbildningar.find((utb) => utb?.utbildning?.id === utbildningsId) || {}

    const studielon = currentUtbildning?.studielon
    const studiestart = currentUtbildning?.utbildning?.studiestart

    return (
        <Fragment>
            <Divider />
            <Stack spacing={2}>
                <Typography data-testid="studielon-header" variant="h5">
                    Studielön
                </Typography>

                <TextField
                    label={t('skalTillStudielonText')}
                    value={t('skalTillStudielonOption', { context: studielon?.motivering?.anledning })}
                    disabled
                    fullWidth
                />
                <TextField label={t('kortfattadMotiveringText')} value={studielon?.motivering?.beskrivning} disabled fullWidth />

                <Stack spacing={2}>
                    <Typography variant="h6">{t('preliminarErsattningHeader')}</Typography>
                    <Stack spacing={1} direction={{ xs: 'column', sm: 'row' }} justifyContent={{ xs: 'flex-start', sm: 'space-between' }}>
                        <Stack flex={1}>
                            <LabelValue label={t('beraknadStudiestartText')} value={formatToDate(studiestart)} />
                        </Stack>
                        <Stack flex={1} spacing={1}>
                            <Typography variant="caption" color="text.secondary">
                                {t('beraknadUtbetalningstartHeader')}
                            </Typography>
                            <TextField label="År" value={studielon?.beraknadUtbetalningsstart?.year} disabled fullWidth />
                            <RadioGroup row>
                                <FormControlLabel
                                    control={<Radio />}
                                    label={t('varTerminLabel')}
                                    checked={studielon?.beraknadUtbetalningsstart?.termin === Termin.Vartermin}
                                    disabled
                                />
                                <FormControlLabel
                                    control={<Radio />}
                                    label={t('hostTerminLabel')}
                                    checked={studielon?.beraknadUtbetalningsstart?.termin === Termin.Hosttermin}
                                    disabled
                                />
                            </RadioGroup>
                        </Stack>
                    </Stack>
                </Stack>

                {studielon?.kalenderhalvar?.map((kh, index) => (
                    <StudielonKalenderhalvar key={`${utbildningsId}-${index}`} kalenderhalvarIndex={index} kalenderhalvar={kh} />
                ))}
            </Stack>
        </Fragment>
    )
}

export default Studielon
