import { useCallback } from 'react'
import { isNil } from 'ramda'
import { FormikProps, withFormik } from 'formik'
import {
  IAnsokanDetailsFormProps,
  IAnsokanDetailsFormValues,
} from '@local/Views/AnsokanDetails/AnsokanDetailsForm/AnsokanDetailsForm.types'
import { mapAnsokanToAnsokanDetailsFormValues } from '@local/Views/AnsokanDetails/AnsokanDetailsForm/Helpers'
import { ansokanDetailsValidationSchema } from '@local/Views/AnsokanDetails/AnsokanDetailsForm/AnsokanDetailsForm.schema'
import {
  useAnsokanNeedsGranskning,
  useIsTIA,
  usePickEpiContent,
  useSignalRConnectToGroup,
} from '@local/Utils/Hooks'
import AnsokanDetailsHeader from '@local/Views/AnsokanDetails/AnsokanDetailsForm/AnsokanDetailsHeader'
import {
  AnsokanType,
  IAnsokanDetailsContent,
} from '@local/Views/AnsokanDetails/AnsokanDetails.types'
import { forsakranKravs } from '@local/Utils/Helpers/Ansokan.helpers'
import ForsakraUppsagningSjukdom from '@local/Views/AnsokanDetails/AnsokanDetailsForm/ForsakraUppsagningSjukdom'
import { formatOrgNr } from '@local/Utils/Helpers'
import StatusChip from '@local/Components/StatusChip'
import Anstallning from '@local/Views/AnsokanDetails/AnsokanDetailsForm/Anstallning'
import Loneuppgifter from '@local/Views/AnsokanDetails/AnsokanDetailsForm/Loneuppgifter'
import Sambandsanstallningar from '@local/Views/AnsokanDetails/AnsokanDetailsForm/Sambandsanstallningar'
import FackligaKontaktpersoner from '@local/Views/AnsokanDetails/AnsokanDetailsForm/FackligaKontaktpersoner'
import Arbetslivserfarenhet from '@local/Views/AnsokanDetails/AnsokanDetailsForm/Arbetslivserfarenhet'
import GodkannAnsokan from '@local/Views/AnsokanDetails/AnsokanDetailsForm/GodkannAnsokan'
import ForetagetsKontaktperson from '@local/Views/AnsokanDetails/AnsokanDetailsForm/ForetagetsKontaktperson'
import useGetAnsokan from '@local/Views/AnsokanDetails/useGetAnsokan'
import Etableringsvillkor from '@local/Views/AnsokanDetails/AnsokanDetailsForm/Arbetslivserfarenhet/Etableringsvillkor'
import Spinner from '@local/Components/Spinner'
import AnsokanCard from '@local/Components/AnsokanCard'
import { styled } from '@mui/material/styles'
import Divider from '@local/Components/Divider'
import FelaktigaUppgifter from '@local/Views/AnsokanDetails/AnsokanDetailsForm/FelaktigaUppgifter'
import Gutter from '@local/Components/Gutter'
import { Status } from '@local/Common.types'
import { UUIDRegex, matchStringByRegex } from '@local/Utils/Helpers/regexes'
import { SignalRRoom } from '@local/App.types'
import { ansokanApi } from '@local/services/API/ansokanApi'
import { useAppDispatch } from '@local/Store/configureStore'
import RedigerasAvArbetsgivare from '@local/Views/AnsokanDetails/AnsokanDetailsForm/RedigerasAvArbetsgivare'
import Befattningar from '@local/Views/AnsokanDetails/AnsokanDetailsForm/Befattningar'
import useReduxStore from '@local/Views/AnsokanDetails/AnsokanDetailsForm/hooks/useReduxStore'
import usePatchAnsokan from '@local/Views/AnsokanDetails/AnsokanDetailsForm/hooks/usePatchAnsokan'
import useFormikWatch from '@local/Utils/Hooks/useFormikWatch'
import { ansokanDetailsWatcherMiddleware } from '@local/Views/AnsokanDetails/AnsokanDetailsForm/ansokanDetailsWatcherMiddleware'

const CardContent = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),

  [theme.breakpoints.up('md')]: {
    marginTop: theme.spacing(3),
    padding: `0 ${theme.spacing(3)}`,
  },
}))

const AnsokanDetailsForm = ({
  values,
  initialValues,
}: IAnsokanDetailsFormProps & FormikProps<IAnsokanDetailsFormValues>) => {
  const dispatch = useAppDispatch()
  const ansokanId = matchStringByRegex(window.location.pathname, UUIDRegex)

  const { setStoredValuesAfterPatch } = useReduxStore(initialValues, values)
  const { patchAnsokan } = usePatchAnsokan(setStoredValuesAfterPatch)
  useFormikWatch(ansokanDetailsWatcherMiddleware, patchAnsokan)

  const { data: ansokan, isLoading } = useGetAnsokan()

  useSignalRConnectToGroup({
    room: SignalRRoom.AnsokanUpdated,
    group: ansokanId,
    onEventReceived: useCallback(() => {
      dispatch(ansokanApi.util.invalidateTags([{ type: 'ansokan' }]))
    }, [dispatch]),
  })

  const { status } = usePickEpiContent<IAnsokanDetailsContent>()

  const isTIA = useIsTIA()
  const ansokanNeedsGranskning = useAnsokanNeedsGranskning(ansokan)

  const isEgenAnsokan =
    isTIA ||
    ansokan?.type ===
      AnsokanType.OmstallningTidsbegransadanstallningAvTjansteperson

  if (isLoading) {
    return <Spinner centered />
  }

  return (
    <section data-testid="ansokan-details">
      <AnsokanDetailsHeader isEgenAnsokan={isEgenAnsokan} />

      {ansokanNeedsGranskning && (
        <>
          <Befattningar />

          <Gutter offset_xs={40} />

          <Etableringsvillkor />

          <Gutter offset_xs={40} />
        </>
      )}

      {forsakranKravs(ansokan.status) && (
        <>
          <ForsakraUppsagningSjukdom />
          <Gutter offset_xs={40} />
        </>
      )}

      <RedigerasAvArbetsgivare ansokan={ansokan} />

      <AnsokanCard
        data-testid="ansokan-card"
        title={ansokan.foretag.namn}
        subtitle={formatOrgNr(ansokan.foretag.organisationsnummer)}
        action={
          <>
            {ansokan.isTvist && (
              <StatusChip status={Status.Tvist} statusContent={status} />
            )}
            <StatusChip status={ansokan.status} statusContent={status} />
          </>
        }
      >
        <CardContent>
          <Anstallning ansokan={ansokan} />

          {!isTIA && (
            <>
              <Divider offset_xs={64} offset_md={96} />
              <Loneuppgifter loneUppgifter={ansokan.loneUppgifter} />
            </>
          )}

          {ansokan.sambandsanstallningar.length > 0 && (
            <>
              <Divider offset_xs={64} offset_md={96} />
              <Sambandsanstallningar
                sambandsanstallningar={ansokan.sambandsanstallningar}
              />
            </>
          )}
          {ansokan.fackligaKontaktPersoner.length > 0 && (
            <>
              <Divider offset_xs={64} offset_md={96} />
              <FackligaKontaktpersoner
                fackligaKontaktPersoner={ansokan.fackligaKontaktPersoner}
              />
            </>
          )}
          {!isNil(ansokan.hasIntygatEtableringsvillkor) && (
            <>
              <Divider offset_xs={64} offset_md={96} />
              <Arbetslivserfarenhet ansokan={ansokan} />
            </>
          )}

          <Gutter offset_xs={0} offset_md={16} />
        </CardContent>
      </AnsokanCard>

      <Gutter offset_xs={40} />

      <GodkannAnsokan ansokan={ansokan} />

      <Gutter offset_xs={40} />

      <FelaktigaUppgifter ansokan={ansokan} />

      <ForetagetsKontaktperson ansokan={ansokan} />
    </section>
  )
}

const AnsokanDetailsFormik = withFormik<
  IAnsokanDetailsFormProps,
  IAnsokanDetailsFormValues
>({
  validateOnBlur: true,
  enableReinitialize: true,
  mapPropsToValues: ({ ansokan }) =>
    mapAnsokanToAnsokanDetailsFormValues(ansokan),
  displayName: 'AnsokanDetailsForm',
  handleSubmit: (_, { setSubmitting }) => {
    setSubmitting(false)
  },
  validationSchema: ({ content }: { content: IAnsokanDetailsContent }) =>
    ansokanDetailsValidationSchema(content),
})(AnsokanDetailsForm)

export default AnsokanDetailsFormik
