import {
    StudieersattningAndStudielonApiModel,
    StudiestodTypApiModel,
    UtbildningUnderlagApiModel,
} from '@local/src/services/SwaggerApi/data-contracts'
import { ShouldBeUndefinedObjectSchema } from '@local/src/utils/Yup/YupHelper'
import { object as yupObject, string as yupString, SchemaOf, boolean as yupBoolean } from 'yup'
import { ArendeTyp } from '@local/src/@types/Arenden.types'
import { KortvarigtStudiestodSchema } from '@local/src/features/StudiestodAnsokan/Components/Steps/RadgivarensUnderlag/Schemas/UtbildningUnderlagSchema/KortvarigtStudiestodSchema'
import { KoptUtbildningSchema } from '@local/src/features/StudiestodAnsokan/Components/Steps/RadgivarensUnderlag/Schemas/UtbildningUnderlagSchema/KoptUtbildningSchema'
import { KompletterandeStudiestodSchema } from '@local/src/features/StudiestodAnsokan/Components/Steps/RadgivarensUnderlag/Schemas/UtbildningUnderlagSchema/KompletterandeStudiestodSchema'
import {
    HasOnlyStudieersattningSchema,
    HasOnlyStudielonSchema,
    StudieersattningAndStudielonBaseSchema,
    StudieersattningAndStudielonSchema,
} from '@local/src/features/StudiestodAnsokan/Components/Steps/RadgivarensUnderlag/Schemas/UtbildningUnderlagSchema/StudieersattningAndStudielonSchema/StudieersattningAndStudielonSchema'
import { omstallningsinsatsTypSchema } from '@local/src/features/StudiestodAnsokan/Components/Steps/RadgivarensUnderlag/Schemas/UtbildningUnderlagSchema/OmstallningsinsatsTypSchema'
import { NekaStodSchema } from '@local/src/features/StudiestodAnsokan/Components/Steps/RadgivarensUnderlag/Schemas/UtbildningUnderlagSchema/NekaStodSchema'
import { FormValidationTexts } from '@local/src/content/resources'
import { StudieersattningSchema } from '@local/src/features/StudiestodAnsokan/Components/Steps/RadgivarensUnderlag/Schemas/UtbildningUnderlagSchema/StudieersattningSchema/StudieersattningSchema'

import StudiestodTypSchema, { HasKompletterandeStudiestodBooleanSchema } from './StudiestodTypSchema'

const mustHaveAtLeastOneStudiestod = (studiestodTyp: StudiestodTypApiModel) => Object.values(studiestodTyp).some((typ) => typ)

const UtbildningUnderlagSchema = (
    formValidationTexts?: FormValidationTexts,
    arendeTyp?: ArendeTyp,
    isLegacy?: boolean
): SchemaOf<UtbildningUnderlagApiModel> =>
    yupObject().shape({
        utbildningId: yupString().nullable(),
        nekaStod: NekaStodSchema(),
        kompletterandeStudiestod: KompletterandeStudiestodSchema(formValidationTexts).when('studiestodTyp.hasKompletterandeStudiestod', {
            is: (hasKompletterandeStudiestod: boolean) => hasKompletterandeStudiestod,
            then: KompletterandeStudiestodSchema(formValidationTexts),
            otherwise: ShouldBeUndefinedObjectSchema,
        }),
        koptUtbildning: KoptUtbildningSchema(formValidationTexts).when('studiestodTyp.hasKoptUtbildning', {
            is: (hasKoptUtbildning: boolean) => hasKoptUtbildning,
            then: KoptUtbildningSchema(formValidationTexts),
            otherwise: ShouldBeUndefinedObjectSchema,
        }),
        kortvarigtStudiestod: KortvarigtStudiestodSchema(formValidationTexts).when('studiestodTyp.hasKortvarigtStudiestod', {
            is: true,
            then: KortvarigtStudiestodSchema(formValidationTexts),
            otherwise: ShouldBeUndefinedObjectSchema,
        }),
        omstallningsinsatsTyp: omstallningsinsatsTypSchema(arendeTyp),
        studieersattningAndStudielon: StudieersattningAndStudielonBaseSchema.when(
            ['studiestodTyp.hasStudielon', 'studiestodTyp.hasStudieersattning'],
            {
                is: (hasStudielon: boolean, hasStudieersattning: boolean) => hasStudielon && !hasStudieersattning,
                then: HasOnlyStudielonSchema(formValidationTexts),
            }
        )
            .when(['studiestodTyp.hasStudielon', 'studiestodTyp.hasStudieersattning'], {
                is: (hasStudielon: boolean, hasStudieersattning: boolean) => !hasStudielon && hasStudieersattning,
                then: HasOnlyStudieersattningSchema(formValidationTexts),
            })
            .when(['studiestodTyp.hasStudielon', 'studiestodTyp.hasStudieersattning'], {
                is: (hasStudielon: boolean, hasStudieersattning: boolean) => hasStudielon && hasStudieersattning,
                then: StudieersattningAndStudielonSchema(formValidationTexts),
            })
            .when(['studiestodTyp.hasStudielon', 'studiestodTyp.hasStudieersattning'], {
                is: (hasStudielon: boolean, hasStudieersattning: boolean) => !hasStudielon && !hasStudieersattning,
                then: ShouldBeUndefinedObjectSchema,
            }),
        studiestodTyp: StudiestodTypSchema(arendeTyp, isLegacy)
            .when('nekaStod.isStodNekat', {
                is: (isStodNekat: boolean) => isStodNekat !== true,
                then: (studiestodTyp) =>
                    studiestodTyp.test(
                        'must have one studiestod selected',
                        formValidationTexts?.studiestodTyp,
                        mustHaveAtLeastOneStudiestod
                    ),
            })
            .when('yttrandeToCsn.hasYttrandeToCsn', {
                is: (hasYttrande: boolean) => hasYttrande !== true,
                then: StudiestodTypSchema(arendeTyp, isLegacy).shape({
                    hasKompletterandeStudiestod: HasKompletterandeStudiestodBooleanSchema,
                }),
            }),
        yttrandeToCsn: yupObject({
            hasYttrandeToCsn: yupBoolean().nullable().required('Yttrande till CSN behöver anges'),
        }).required(),
        studieersattning: yupObject(),
    })

export const UtbildningUnderlagSchemaV2 = (
    formValidationTexts?: FormValidationTexts,
    arendeTyp?: ArendeTyp,
    isLegacy?: boolean
): SchemaOf<Partial<UtbildningUnderlagApiModel>> =>
    UtbildningUnderlagSchema(formValidationTexts, arendeTyp, isLegacy).shape({
        studieersattningAndStudielon: yupObject().nullable() as SchemaOf<StudieersattningAndStudielonApiModel>,
        studieersattning: StudieersattningSchema().when('studiestodTyp.hasStudieersattning', {
            is: true,
            then: StudieersattningSchema(formValidationTexts).required(),
            otherwise: ShouldBeUndefinedObjectSchema,
        }),
    })

export default UtbildningUnderlagSchema
