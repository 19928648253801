import React from 'react'
import { useEpiPagePath } from '@trr/app-shell-data'
import FeatureFlag from '@local/src/Components/FeatureFlag'

import StudiestodStudieintyg from '../Features/StudiestodStudieintyg/StudiestodStudieintyg'
import StudiestodStudieintygV2 from '../Features/StudiestodStudieintygV2/StudiestodStudieintygV2'
import useStudieansokanUrlParams from '../Hooks/useStudieansokanUrlParams'

const StudiestodStudieintygPage = () => {
    const url = useEpiPagePath()
    const { params } = useStudieansokanUrlParams(url)
    return (
        <FeatureFlag
            name="Studiestod-Frontend-studieintygUppladdningV2_temp_250117"
            renderOnDisabled={<StudiestodStudieintyg arendeId={params.arendeId} ansokanId={params.ansokanId} />}
            renderOnEnabled={<StudiestodStudieintygV2 arendeId={params.arendeId} ansokanId={params.ansokanId} />}
        />
    )
}

export default StudiestodStudieintygPage
