import React from 'react'
import { Box, Stack, TextField, Typography, Checkbox, FormControlLabel } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { veckorQuantity } from '@local/src/features/StudiestodAnsokan/Components/Steps/RadgivarensUnderlag/Helpers/veckorQuantity'
import { StudielonKalenderhalvarApiModel } from '@local/src/services/SwaggerApi/data-contracts'

interface Props {
    kalenderhalvarIndex: number
    kalenderhalvar: StudielonKalenderhalvarApiModel
}

const StudielonKalenderhalvar = ({ kalenderhalvarIndex, kalenderhalvar }: Props) => {
    const { t } = useTranslation('translation', { keyPrefix: 'studiestodAnsokan.steps.radgivarensUnderlag' })

    return (
        <Stack data-testid={`kalenderhalvar-${kalenderhalvarIndex}`}>
            <Stack direction="row" alignItems="center" spacing={2}>
                <Typography variant="h5">
                    {t('kalenderhalvarTitle')} {kalenderhalvarIndex + 1}
                </Typography>
            </Stack>
            <Stack direction="row" spacing={2}>
                <Box flex={1}>
                    <Typography mb={1}>{t('preliminarStudielonText')}</Typography>
                    <TextField label={t('kronorPerDagText')} value={kalenderhalvar?.studielon} disabled fullWidth />
                    <FormControlLabel
                        control={<Checkbox title="Ingen studielön" disabled checked={kalenderhalvar?.hasNoStudielon} />}
                        label="Ingen studielön"
                    />
                    <Typography>
                        {veckorQuantity(kalenderhalvar?.studielon)} {t('kronorPerFyraVeckorText')}
                    </Typography>
                </Box>
                <Box flex={1} />
            </Stack>
        </Stack>
    )
}

export default StudielonKalenderhalvar
