import { useDispatch } from 'react-redux'
import { inputsSlice, pageSlice } from 'State/Slices'

const SLICE_ACTIONS = {
  ...inputsSlice.actions,
  ...pageSlice.actions,
}

const useSliceDispatch = () => {
  const dispatch = useDispatch()
  return <
    T extends keyof typeof SLICE_ACTIONS,
    K extends (typeof SLICE_ACTIONS)[T],
  >(
    type: T,
    payload: Parameters<K>[0]
  ) => {
    const actionFn: (payload: Parameters<K>[0]) => ReturnType<K> =
      SLICE_ACTIONS[type] as never
    dispatch(actionFn(payload))
  }
}

export default useSliceDispatch
