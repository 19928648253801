const userRoleChecks = (
  userRoles: string[]
): {
  isOnboarding: boolean
  isMissingEmail: boolean
  isMissingWorkSituation: boolean
  isArbetsgivarInfo: boolean
} => {
  const roleExists = (explicitRole: string): boolean => {
    return userRoles?.some((role) => role.toLowerCase() === explicitRole)
  }

  return {
    isMissingWorkSituation: roleExists('missingworksituation'),
    isMissingEmail: roleExists('missingemail'),
    isOnboarding: roleExists('onboarding'),
    isArbetsgivarInfo: roleExists('arbetsgivarinfo'),
  }
}

export default userRoleChecks
