import { jwtDecode } from 'jwt-decode'

const getUserInformationFromJwt = (idToken: string) => {
  const jwtData =
    idToken &&
    jwtDecode<{ name: string; sub: string; roles: string[] }>(idToken)
  // If length of sub is above 13 it's definetly not a socialSecurityNumber.
  // 13 if there by any chance is a '-' somewhere in the stored socialSecurityNumber
  let socialSecurityNumber = jwtData?.sub
  if (!jwtData?.sub || jwtData.sub?.length > 13) {
    socialSecurityNumber = ''
  }

  return {
    name: jwtData?.name || '',
    roles: jwtData?.roles || [],
    socialSecurityNumber,
  }
}

export default getUserInformationFromJwt
