import {
  useFordelaArendenMutation,
  useGetFordelningsArendenQuery,
} from '@local/src/Api/Fordelning/fordelningApi'
import { useGetFordelningsteamsQuery } from '@local/src/Api/Team/teamApi'
import React, { useEffect, useState } from 'react'
import { Box, Button, Grid } from '@mui/material'
import { useIsSmallScreen } from '@local/src/Hooks'
import { useGetAktivaRadgivareQuery } from '@local/src/Api/Radgivare/radgivareApi'
import Dropdown from '@local/src/Components/Dropdown/Dropdown'
import { Radgivare, Team } from '@local/src/Common.types'
import { buildFordelningsteamDropdownOptions } from '@local/src/Utils/helpers/fordelningsTeamDropdownHelpers/fordelningsTeamDropdownHelpers'

import { ArendeStagedForFordelning } from './Fordelning.types'
import FordelningTable from './FordelningTable/FordelningTable'
import RadgivarStatistik from './RadgivarStatistik/RadgivarStatistik'

const Fordelning = () => {
  const isMobile = useIsSmallScreen()
  const [selectedFordelningsteam, setSelectedFordelningsteam] =
    useState<Team>(null)
  const [selectedRadgivarteam, setSelectedRadgivarteam] = useState<Team>(null)
  const [stagedArenden, setStagedArenden] = useState<
    ArendeStagedForFordelning[]
  >([])

  const fordelningsTeamIsSet =
    selectedFordelningsteam !== null && selectedFordelningsteam !== undefined

  const { data: fordelningsTeam } = useGetFordelningsteamsQuery()

  const { data: fordelningsArenden, isFetching: isFetchingFordelningsArenden } =
    useGetFordelningsArendenQuery(selectedFordelningsteam?.id, {
      skip: !fordelningsTeamIsSet,
    })

  const { data: radgivareList } = useGetAktivaRadgivareQuery(
    selectedRadgivarteam?.id,
    {
      skip: selectedRadgivarteam === null || selectedRadgivarteam === undefined,
    }
  )

  const [
    fordelaArenden,
    { isLoading: fordelaIsLoading, isSuccess: fordelaIsSuccess },
  ] = useFordelaArendenMutation()

  const addStagedArende = (arendeId: string, radgivare: Radgivare) => {
    setStagedArenden([...stagedArenden, { arendeId, radgivare }])
  }

  const removeStagedArende = (arendeId: string) => {
    setStagedArenden(
      stagedArenden.filter((arenden) => arenden.arendeId !== arendeId)
    )
  }

  useEffect(() => {
    setSelectedFordelningsteam(fordelningsTeam?.find((team) => team.default))
  }, [fordelningsTeam])

  useEffect(() => {
    setSelectedRadgivarteam(selectedFordelningsteam)
    setStagedArenden([])
  }, [selectedFordelningsteam])

  useEffect(() => {
    if (fordelaIsSuccess) {
      setStagedArenden([])
    }
  }, [fordelaIsSuccess])

  const handleFordelaArenden = () => {
    const request = stagedArenden.map((arende) => ({
      arendeId: arende.arendeId,
      radgivareId: arende.radgivare.id,
    }))
    fordelaArenden({ fordelningList: request })
  }

  const fordelningsteamDropdown = (
    <Box width="132px">
      <Dropdown
        customOptions={buildFordelningsteamDropdownOptions(fordelningsTeam)}
        getOptionLabel={(option: Team) => option.namn}
        getOptionValue={(option: Team) => option.id}
        label="Fördelningsteam"
        value={selectedFordelningsteam?.id}
        size="small"
        onChange={(event) =>
          setSelectedFordelningsteam(
            fordelningsTeam?.find((t) => t.id === event.target.value)
          )
        }
      />
    </Box>
  )

  if (isMobile) {
    return (
      <>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          {fordelningsteamDropdown}
          <RadgivarStatistik
            selectedRadgivarteam={selectedFordelningsteam}
            onRadgivarteamChanged={(team) => setSelectedRadgivarteam(team)}
          />
        </Box>
        <FordelningTable
          arenden={fordelningsArenden}
          isFetching={isFetchingFordelningsArenden || !fordelningsTeamIsSet}
          teams={fordelningsTeam}
          aktivaRadgivare={radgivareList}
          stagedArenden={stagedArenden}
          addStagedArende={addStagedArende}
          removeStagedArende={removeStagedArende}
        />
        {stagedArenden.length > 0 && (
          <Box
            display="flex"
            justifyContent="center"
            position="fixed"
            bottom="0"
            left="0"
            pb={4}
            pt={2}
            sx={{ background: '#fff' }}
            zIndex="1"
            width="100%"
          >
            <Button
              size="medium"
              variant="contained"
              disabled={fordelaIsLoading}
              onClick={handleFordelaArenden}
            >
              {`Fördela ${stagedArenden.length} ${stagedArenden.length === 1 ? 'ärende' : 'ärenden'}`}
            </Button>
          </Box>
        )}
      </>
    )
  }
  return (
    <Grid container spacing={2}>
      <Grid item xs={9}>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            position: 'sticky',
            top: -1,
            zIndex: 10,
            backgroundColor: '#fff',
            py: 2,
          }}
        >
          {fordelningsteamDropdown}
          <Button
            variant="contained"
            size="small"
            disabled={stagedArenden.length === 0 || fordelaIsLoading}
            onClick={handleFordelaArenden}
          >
            {stagedArenden.length === 0
              ? 'Välj ett ärende'
              : `Fördela ${stagedArenden.length} ${stagedArenden.length === 1 ? 'ärende' : 'ärenden'}`}
          </Button>
        </Box>
        <FordelningTable
          arenden={fordelningsArenden}
          isFetching={isFetchingFordelningsArenden || !fordelningsTeamIsSet}
          teams={fordelningsTeam}
          aktivaRadgivare={radgivareList}
          stagedArenden={stagedArenden}
          addStagedArende={addStagedArende}
          removeStagedArende={removeStagedArende}
        />
      </Grid>
      <Grid item xs={3} mt={2}>
        <RadgivarStatistik
          selectedRadgivarteam={selectedFordelningsteam}
          onRadgivarteamChanged={(team) => setSelectedRadgivarteam(team)}
        />
      </Grid>
    </Grid>
  )
}

export default Fordelning
