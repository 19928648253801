import { useCallback, useState } from 'react'
import { Box, List, Typography } from '@mui/material'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'

import Logo from 'apps/Site/components/Logo'
import { filterOutDoubleRowBreakBlocks } from 'apps/Site/DashboardMenu/MenuArea/MenuArea'
import MenuBlockMapper from 'apps/Site/DashboardMenu/MenuBlockMapper'
import { StyledIconButton } from 'apps/Site/Header/NewHeader/Mobile/Menu/TopMenu/TopMenu'
import { useCachedSettingsState, useCachedUserProfileState } from 'PublicWebApi'
import { useSliceDispatch } from 'slices/hooks'

interface NewMenuAreaProps {
  isMobileOrTablet?: boolean
}
const NewMenuArea = ({ isMobileOrTablet }: NewMenuAreaProps) => {
  const { myTrrNavigation, trrBusinessNavigation } = useCachedSettingsState()
  const userProfile = useCachedUserProfileState()
  const companyName = userProfile?.kundProperties?.foretag?.name
  const [activeMenuElement, setActiveMenuElement] = useState(-1)

  const trigger = useSliceDispatch()

  const menuElements = filterOutDoubleRowBreakBlocks(
    myTrrNavigation?.menuElements || trrBusinessNavigation?.menuElements
  )

  const closeMenu = () => {
    trigger('setMobileLeftMenuStatus', 'closed')
  }

  const onActiveMenuElementToggle = useCallback(
    (index: number) => {
      if (index !== activeMenuElement) {
        setActiveMenuElement(index)
      } else {
        setActiveMenuElement(-1)
      }
    },
    [activeMenuElement, setActiveMenuElement]
  )
  return (
    <Box top="48px" position="relative" data-testid="new-menu-area">
      <Box
        paddingLeft={3}
        mb={2}
        display={'flex'}
        justifyContent={'space-between'}
      >
        <Logo />
        {isMobileOrTablet && (
          <StyledIconButton
            sx={{ marginRight: 3 }}
            data-testid="close-menu-button"
            onClick={closeMenu}
          >
            <CloseRoundedIcon sx={{ color: 'text.primary' }} />
          </StyledIconButton>
        )}
      </Box>
      <Box paddingTop={3}>
        {companyName && (
          <Typography
            pl={3}
            pb={1.5}
            color="text.secondary"
            variant="subtitle2"
            component="p"
          >
            {companyName}
          </Typography>
        )}
        <List disablePadding>
          {menuElements?.length > 0 &&
            menuElements.map((menuBlock, index) => (
              <MenuBlockMapper
                key={`menu-area-block-${index.toString()}`}
                {...{
                  ...menuBlock,
                  onClickAction: isMobileOrTablet ? closeMenu : () => {},
                  ...{
                    toggleActiveMenuElement: () =>
                      onActiveMenuElementToggle(index),
                    isMenuGroupOpen: activeMenuElement === index,
                  },
                }}
              />
            ))}
        </List>
      </Box>
    </Box>
  )
}

export default NewMenuArea
