import { useState } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Card,
  CardActionArea,
  Stack,
  Typography,
  useTheme,
} from '@mui/material'
import { useEpiCustomDictionary } from '@trr/app-shell-data'

import {
  IMilestone,
  LifeLineEventType,
  LifeLineFeeling,
} from '../../../App/App.types'
import { useGetMilestonesQuery } from '../../../utils/network/kompetensApi'

import MilestoneItemActionButton from './MilestoneItemActionButton'
import FeelingIcon from './FeelingIcon'
import TypeIcon from './TypeIcon'
import MilestonesForm from './MilestonesForm'

interface IMilestoneItemProps {
  milestone: IMilestone
  defaultExpanded?: boolean
}

const MilestoneItem = ({
  milestone,
  defaultExpanded = false,
}: IMilestoneItemProps) => {
  const { palette } = useTheme()
  const { title, reflection, description, startYear, endYear } = milestone
  const [expanded, setExpanded] = useState(defaultExpanded)
  const [isEditing, setIsEditing] = useState(false)
  const epi = useEpiCustomDictionary(['innehall'])
  const { isFetching } = useGetMilestonesQuery()
  const arialabel = `${milestone.title}. ${milestone.feeling === LifeLineFeeling.Negative ? epi('negativeLabel') : milestone.feeling === LifeLineFeeling.Positive ? epi('positiveLabel') : epi('neatralLabel')} ${epi('milestoneIn')} ${milestone.type === LifeLineEventType.Personal ? epi('personalLabel') : milestone.type === LifeLineEventType.Educational ? epi('educationalLabel') : epi('professionalLabel')} `
  if (!milestone) return null
  return (
    <>
      {!isFetching && (
        <MilestonesForm
          onCancel={() => {
            setIsEditing(false)
            setExpanded(true)
          }}
          open={isEditing}
          milestone={milestone}
          onSuccessfullySubmitted={() => {
            setIsEditing(false)
            setExpanded(true)
          }}
        />
      )}
      <Accordion
        sx={{ mb: 2, boxShadow: 'none' }}
        component={Card}
        raised={false}
        expanded={expanded}
      >
        <CardActionArea
          data-gtm-label={
            'Livshändelse: accordion' /* WARNING! Do not remove this because it would track PIIs */
          }
        >
          <AccordionSummary
            onClick={() => {
              setExpanded(!expanded)
            }}
            aria-controls="reflection-header"
            id="reflection-header"
            data-testid="reflection-header"
            aria-label={arialabel}
          >
            <Stack
              height="70px"
              width="100%"
              alignItems="center"
              justifyContent="space-between"
              direction="row"
            >
              <Stack alignItems="center" gap={2} direction="row">
                <Avatar
                  sizes="12px"
                  sx={{
                    backgroundColor: palette.surface?.purple,
                    width: '48px',
                    height: '48px',
                  }}
                >
                  <TypeIcon
                    type={milestone.type}
                    sx={{ color: palette.primary.main, fontSize: '24px' }}
                  />
                </Avatar>
                <Typography variant="h6">{title}</Typography>
              </Stack>
              <Stack alignItems="center" gap={1} direction="row">
                <FeelingIcon
                  sx={{ fontSize: '40px', color: palette.primary.dark }}
                  feeling={milestone.feeling}
                />
                <MilestoneItemActionButton
                  onUpdateClick={() => {
                    setIsEditing(true)
                  }}
                  milestone={milestone}
                />
              </Stack>
            </Stack>
          </AccordionSummary>
        </CardActionArea>
        <AccordionDetails>
          {reflection && (
            <Box sx={{ p: 2, backgroundColor: palette.surface?.purple, mb: 2 }}>
              <Typography variant="subtitle1" component="p">
                {epi('reflectionTitle')}
              </Typography>
              <Typography whiteSpace={'break-spaces'} variant="body2">
                {reflection.trim()}
              </Typography>
            </Box>
          )}
          <Typography whiteSpace={'break-spaces'} gutterBottom variant="body2">
            {description?.trim()}
          </Typography>
          <Typography variant="caption">
            {startYear} {endYear && `- ${endYear.toString()}`}
          </Typography>
        </AccordionDetails>
      </Accordion>
    </>
  )
}

export default MilestoneItem
