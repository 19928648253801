import {
  ICommonAnsokanValidering,
  ICommonDatumValideringContent,
  IDinaTrrAnsokningarLinks,
  INagotGickFelContent,
  IPatchGickFel,
  ITjanstepersonansokanLinks,
} from '@local/Types'
import { IArbetsplatsContent } from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/Arbetsplats/Types/types'
import { IGranskaAnsokanTIAContent } from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/GranskaAnsokan/Types/types'
import { IAnstallningTIAContent } from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/AnstallningTIA/Types/types'
import { IArbetslivserfarenhetTIAContent } from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/ArbetslivserfarenhetTIA/Types/types'

export interface ITjanstemanIAnstallningContent {
  steps: ITjanstemanIAnstallningStepsContent
  arbetsplats: IArbetsplatsContent
  anstallning: IAnstallningTIAContent
  arbetslivserfarenhet: IArbetslivserfarenhetTIAContent
  granskaAnsokan: IGranskaAnsokanTIAContent
  sidfot: ISidfotContent
  datumValidering: ICommonDatumValideringContent
  tjanstepersonansokanValidering: ICommonAnsokanValidering
  nagotGickFel: INagotGickFelContent
  patchGickFel: IPatchGickFel
  dinaTrrAnsokningarLinks: IDinaTrrAnsokningarLinks
  tjanstepersonansokanLinks: ITjanstepersonansokanLinks
}

export interface ITjanstemanIAnstallningStepsContent {
  heading: string
  omDinArbetsplats: string
  omDinAnstallning: string
  omDinSysselsattning: string
  granskaOchSkickaIn: string
  slutfordAnsokan: string
}

export interface ISidfotContent {
  foregaendeSteg: string
  nastaSteg: string
  slutfor: string
  kontrolleraUppgifter: string
  avbryt: string
}

export enum TjanstemanIAnstallningStep {
  OmDinArbetsplats = 0,
  OmDinAnstallning = 1,
  OmDinSysselsattning = 2,
  GranskaOchSkickaIn = 3,
}

export enum TjanstemanIAnstallningStepFormikId {
  OmDinArbetsplats = 'omDinArbetsplats',
  OmDinAnstallning = 'omDinAnstallning',
  OmDinSysselsattning = 'omDinSysselsattning',
  GranskaOchSkickaIn = 'granskaOchSkickaIn',
}
