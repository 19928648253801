import { jsx as _jsx } from "react/jsx-runtime";
import React, { createContext, useCallback, useEffect, useState, } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@local/src/Utils/Hooks';
export var SearchContext = createContext(null);
export var SearchContextProvider = function (_a) {
    var children = _a.children;
    var searchQuery = useQuery().searchQuery;
    var history = useHistory();
    var _b = useState(searchQuery.query), searchValue = _b[0], setSearchValue = _b[1];
    useEffect(function () {
        if (!searchQuery.query) {
            setSearchValue('');
        }
    }, [searchQuery.query, setSearchValue]);
    var performSearch = useCallback(function (manualValue) {
        if (manualValue || searchValue) {
            history.push({
                search: new URLSearchParams({
                    q: manualValue !== null && manualValue !== void 0 ? manualValue : searchValue,
                }).toString(),
            });
        }
    }, [history, searchValue]);
    return (_jsx(SearchContext.Provider, { value: {
            setSearchValue: setSearchValue,
            searchValue: searchValue,
            performSearch: performSearch,
        }, children: children }));
};
