import { ISelectOptionFormikWrapper } from '@local/Common.types'
import { IUniqueFile } from '@local/Components/FileUpload/FileUpload.types'
import { IKomplettering } from '@local/Views/TidigareSysselsattningar/KompletteraForm/KompletteraForm.types'

export interface IAnsokanPatchApiModel {
  hasIntygatEtableringsvillkor: string
}

export type IKompletteringPatchApiModel = IKomplettering

export enum PatchOps {
  Replace = 'replace',
}

export interface IPatchBodyApiModel {
  op: PatchOps
  path: string
  value:
    | string
    | number
    | boolean
    | Date
    | IUniqueFile[]
    | ISelectOptionFormikWrapper
}

export interface IPatchApiInput {
  values: Partial<IAnsokanPatchApiModel>
}
export interface IPatchForsakraUppsagningSjukdomPayloadModel {
  ansokanId: string
  villkorstext: string
  userSub: string
}

export interface IPatchKompletteringPayloadModel {
  values: Partial<IKompletteringPatchApiModel>
}
