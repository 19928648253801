/* eslint-disable @typescript-eslint/restrict-template-expressions */

import { Box, Divider, Typography } from '@mui/material'

import { IMilestone } from '../../../App/App.types'

import MilestoneItem from './MilestoneItem'
import AddMilestone from './AddMilestone'
import React from 'react'

interface IMilestonesProps {
  isLoading: boolean
  milestones: { [key: number]: IMilestone[] }
  focusedMilestone: string | undefined
  setFocusedMilestone: React.Dispatch<React.SetStateAction<string | undefined>>
}

const MilestonesList = ({
  milestones,
  setFocusedMilestone,
  focusedMilestone,
}: IMilestonesProps) => {
  const years = milestones && Object.keys(milestones).sort().map(Number)

  return (
    <Box>
      <AddMilestone
        onAdded={(v) => {
          setFocusedMilestone(v)
        }}
        formOpen={false}
      />
      {years.map((year) => (
        <React.Fragment key={year}>
          <Box display="flex" alignItems="center" gap={2} paddingBottom={4}>
            <Typography
              paddingRight={2}
              display={'inline-block'}
              aria-label="år"
              data-testid="year-heading"
              variant="body1"
              fontWeight={700}
              color="primary.dark"
              component="h2"
            >
              {year}
            </Typography>

            <Divider sx={{ flexGrow: 1 }} />
          </Box>

          <Box sx={{ mb: 3 }}>
            {milestones[year].map((milestone) => (
              <MilestoneItem
                defaultExpanded={focusedMilestone === milestone.id}
                key={`milestone-item-${milestone.id}`}
                milestone={milestone}
              />
            ))}
          </Box>
        </React.Fragment>
      ))}
    </Box>
  )
}

export default MilestonesList
