import { JSX, useCallback } from 'react'
import {
  useGetAnsokningarQuery,
  ansokanApi,
} from '@local/services/API/ansokanApi'
import { usePickEpiContent, useSignalRConnectToGroup } from '@local/Utils/Hooks'
import { IDinaAnsokningarContent } from '@local/Views/DinaAnsokningar/DinaAnsokningar.types'
import Hero from '@local/Views/DinaAnsokningar/Hero'
import Spinner from '@local/Components/Spinner'
import Alert from '@local/Components/Alert'
import TidigareSysselsattningarList from '@local/Views/DinaAnsokningar/TidigareSysselsattningarList'
import AnsokanListSection from '@local/Views/DinaAnsokningar/AnsokanListSection'
import ForandradAnstallningssituation from '@local/Views/DinaAnsokningar/ForandradAnstallningssituation'
import { SignalRRoom } from '@local/App.types'
import { useAuthentication } from '@trr/app-shell-data'
import { useAppDispatch } from '@local/Store/configureStore'

const DinaAnsokningar = (): JSX.Element => {
  const dispatch = useAppDispatch()
  const { sub: klientId } = useAuthentication()
  const {
    data: ansokningar,
    isLoading,
    isError,
  } = useGetAnsokningarQuery(klientId)
  const { ansokanList, nagotGickFel } =
    usePickEpiContent<IDinaAnsokningarContent>()

  useSignalRConnectToGroup({
    room: SignalRRoom.AnsokanUpdated,
    group: klientId,
    onEventReceived: useCallback(() => {
      dispatch(ansokanApi.util.invalidateTags(['ansokan', 'kompletteraStatus']))
    }, [dispatch]),
  })

  if (isLoading) {
    return <Spinner centered />
  }

  if (isError) {
    return <Alert severity="error">{nagotGickFel.heading}</Alert>
  }

  const hasTidigareAnsokningar = ansokningar.tidigareAnsokningar.length > 0

  return (
    <article>
      <Hero />

      {ansokningar.aktuellAnsokan && (
        <AnsokanListSection
          title={ansokanList.aktuell}
          preamble={ansokanList.aktuellPreamble}
          ariaLabel="Aktuell ansökan"
          ansokningar={[ansokningar.aktuellAnsokan]}
        />
      )}

      {hasTidigareAnsokningar && (
        <AnsokanListSection
          title={ansokanList.tidigareAnsokningar}
          preamble={ansokanList.tidigareAnsokningarPreamble}
          ariaLabel="Tidigare ansökningar"
          ansokningar={ansokningar.tidigareAnsokningar}
        />
      )}

      <TidigareSysselsattningarList />

      {ansokningar.aktuellAnsokan && <ForandradAnstallningssituation />}
    </article>
  )
}

export default DinaAnsokningar
