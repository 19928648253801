import React from 'react'
import { useLazyGetFacetsForPropertyQuery } from '@local/src/Api/Arbetsuppgift/arbetsuppgiftApi'
import { Radgivare } from '@local/src/Common.types'

import ChipWithPopper from '../../../../../Components/Chips/ChipWithPopover/ChipWithPopper'
import { useAssigneeFilter } from '../Hooks/useAssigneeFilter'
import AssigneeFilter from '../FilterTypes/AssigneeFilter'
import { ArbetsuppgiftFilter } from '../../types'

interface AssigneeChipProps {
  filter: Partial<ArbetsuppgiftFilter>
  advisors: Radgivare[]
  selectedAssignees: string[]
  onChange: (key: keyof ArbetsuppgiftFilter, values: string | string[]) => void
}

const AssigneeChip = ({
  filter,
  advisors,
  selectedAssignees = [],
  onChange,
}: AssigneeChipProps) => {
  const [fetchFacets, data] = useLazyGetFacetsForPropertyQuery()

  const {
    handleClose,
    searchValue,
    updateSearchValue,
    pickAdvisor,
    isRadgivareSelected,
    currentUserFacet,
    noOwnerFacet,
    filteredAdvisorList,
    ownerFacets,
    handleClear,
  } = useAssigneeFilter(
    data?.data?.Owner,
    advisors,
    selectedAssignees,
    onChange
  )

  return (
    <ChipWithPopper
      count={selectedAssignees.length}
      label="Ansvarig"
      onClose={handleClose}
      onClear={handleClear}
      width={360}
      onOpen={() => void fetchFacets({ key: 'Owner', filter: filter })}
      disableClear={selectedAssignees.length === 0}
    >
      <AssigneeFilter
        updateSearchValue={updateSearchValue}
        pickAdvisor={pickAdvisor}
        isRadgivareSelected={isRadgivareSelected}
        currentUserFacet={currentUserFacet}
        noOwnerFacet={noOwnerFacet}
        filteredAdvisorList={filteredAdvisorList}
        facets={ownerFacets}
        searchValue={searchValue}
      />
    </ChipWithPopper>
  )
}

export default AssigneeChip
