import { baseApi } from 'State/Api'

export const api = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    setEmailVerificationCode: builder.mutation<void, string>({
      query: (request) => {
        return {
          url: '/user/contact/email/verification',
          method: 'put',
          body: JSON.stringify(request),
          responseHandler: 'text',
        }
      },
    }),
    getEmailVerificationCode: builder.mutation<void, string>({
      query: (request) => {
        return {
          url: '/user/contact/email',
          method: 'put',
          body: JSON.stringify(request),
          responseHandler: 'text',
        }
      },
    }),
  }),
})

export const {
  useGetEmailVerificationCodeMutation,
  useSetEmailVerificationCodeMutation,
} = api

export default api
