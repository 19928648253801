import { useEffect } from 'react'
import {
  getAppConfig,
  useEpiContent,
  useEpiCustomDictionary,
  useSignalR,
} from '@trr/app-shell-data'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CircularProgress,
  Link,
  Typography,
} from '@mui/material'
import ExpandMore from '@mui/icons-material/ExpandMore'
import OpenInNew from '@mui/icons-material/OpenInNew'
import { HubConnectionState } from '@microsoft/signalr'
import { useTrackCustomClick } from '@trr/gtm-tracking'

import {
  useGetJmtLinkQuery,
  useGetJmtStatusQuery,
} from '../../utils/network/jmtApi'
import { JmtStatus } from '../../types/IJmtStatus.types'
import { IJobmatchContent, KlientHub, SignalRRoom } from '../../App/App.types'
import WysiwygEditor from '../../Components/WysiwygEditor'

import ComplementingReportSection from './components/ComplementingReportSection'
import { HTMLMapper } from '@trr/html-mapper'

interface IJobmatchProps {
  setShowReflection?: (v: boolean) => void
}

const Jobmatch = ({ setShowReflection }: IJobmatchProps) => {
  const {
    COMMON: { MEDIA_URL },
  } = getAppConfig()
  const trackCustomClick = useTrackCustomClick()
  const { instructionBlock, testResult, accordion } =
    useEpiContent<IJobmatchContent>()
  const {
    data: status,
    isLoading: isLoadingStatus,
    refetch,
  } = useGetJmtStatusQuery()

  const {
    data: linkData,
    isLoading: isLoadingLink,
    error: errorGetLink,
  } = useGetJmtLinkQuery()

  useEffect(() => {
    if (status === JmtStatus.Completed && setShowReflection) {
      setShowReflection(true)
    }
  }, [status, setShowReflection])

  const connection = useSignalR(
    `${getAppConfig().COMMON.PUBLIC_WEB_API_URL}/klient`
  )
  useEffect(() => {
    if (connection.state === HubConnectionState.Connected) {
      try {
        connection
          .invoke(KlientHub.AddToGroup, 'AddToGroup')
          .catch((e: unknown) => {
            console.log(e)
          })
        connection.on(SignalRRoom.JmtUpdated, () => {
          void refetch()
        })
      } catch (err) {
        console.error(err)
      }
    }
    return () => {
      if (connection.state === HubConnectionState.Connected) {
        void connection.invoke(KlientHub.RemoveFromGroup, 'AddToGroup')
        connection.off(SignalRRoom.JmtUpdated)
      }
    }
  }, [connection, connection.state, refetch])

  const isLoading = isLoadingStatus || isLoadingLink
  const epi = useEpiCustomDictionary(['innehall'])

  if (isLoading) return <CircularProgress sx={{ margin: 'auto' }} />

  if (status === undefined) return null

  if (status === JmtStatus.Completed) {
    trackCustomClick('jobmatch-karriarguide', { label: 'Genomförd' })
    return (
      <Box>
        {!errorGetLink && (
          <>
            <Typography mb={1} variant="h6" component="h2">
              {epi('myResult')}
            </Typography>

            <Link
              component={'a'}
              target="_blank"
              href={linkData?.link}
              aria-label={`${epi('resultLinkLabel')} ${epi('openInNew')}`}
              sx={{
                display: 'flex',
                alignItems: 'center',
                mb: 2,
                '&:hover': {
                  textDecoration: 'underline',
                },
              }}
            >
              <OpenInNew sx={{ mr: 1 }} /> {epi('resultLinkLabel')}
            </Link>
          </>
        )}
        <ComplementingReportSection />
      </Box>
    )
  }

  return (
    <Box>
      {instructionBlock.mainBody && (
        <WysiwygEditor
          styles={{
            mb: 2,
            fontSize: '16px',
          }}
          htmlFormattedString={instructionBlock.mainBody}
        />
      )}
      <Accordion component={Card} sx={{ boxShadow: 'none', mb: 4 }}>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="accordion-content"
        >
          <Typography>{accordion?.heading}</Typography>
        </AccordionSummary>
        <AccordionDetails id="accordion-content">
          <Typography>
            <HTMLMapper
              mediaUrl={MEDIA_URL}
              body={accordion?.accordionContent?.mainBody ?? ''}
            />
          </Typography>
        </AccordionDetails>
      </Accordion>
      {testResult.mainBody && (
        <WysiwygEditor
          styles={{
            mb: 7,
            fontSize: '16px',
          }}
          htmlFormattedString={testResult.mainBody}
        />
      )}
      <Button
        component={'a'}
        target="_blank"
        startIcon={<OpenInNew />}
        sx={{
          maxWidth: 'fit-content',
          '&:hover': {
            textDecoration: 'underline',
          },
        }}
        variant="outlined"
        href={linkData?.link}
        aria-label={`${epi('linkLabel')} ${epi('openInNew')}`}
        onClick={() => {
          trackCustomClick('jobmatch-karriarguide', { label: 'Påbörjad' })
        }}
      >
        {epi('linkLabel')}
      </Button>
    </Box>
  )
}

export default Jobmatch
