import { IAnsokanPatchApiModel } from '@local/Services/Types/apiTypes'
import { AnsokanKeys } from '@local/Types'
import { ISelectOption, RadioChoice } from '@local/Types/form.types'
import { trrDateUTC } from '@local/Utils/Helpers/formatDate'
import { GranskaAnsokanFormKeys } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/GranskaAnsokan/Components/GranskaAnsokanFormModal/types'
import { ITidsbegransadAnstallningFormValues } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/TidsbegransadAnstallningForm/types'
import { mapLonOchAnsokanRadioChoiceToPatch } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/LonOchErsattning/Helpers'
import { IMiddlewareField } from '@local/Utils/Hooks/useFormikWatch'

//Middleware to be used by formik watcher to transform data before patching to API
export const tidsbegransadAnstallningWatcherMiddleware = (
  field: IMiddlewareField<ITidsbegransadAnstallningFormValues>,
  defaultMiddleware: (
    field: IMiddlewareField<ITidsbegransadAnstallningFormValues>
  ) => Partial<IAnsokanPatchApiModel>
): Partial<IAnsokanPatchApiModel> => {
  switch (field.id as AnsokanKeys | GranskaAnsokanFormKeys) {
    case GranskaAnsokanFormKeys.ForsakratUppgifter:
    case AnsokanKeys.NuvarandeArbetsplats:
    case AnsokanKeys.Organisationsnummer:
    case AnsokanKeys.OrganisationsnummerIsAffiliated:
    case AnsokanKeys.AnstallningBilagor:
      return

    case AnsokanKeys.HarAgandeskap: {
      if (field.value === RadioChoice.No) return { agarandel: null }
      else return
    }

    case AnsokanKeys.Anstallningsdatum:
    case AnsokanKeys.SistaAnstallningsdatum:
      return {
        [field.id]: trrDateUTC(field.value as Date).toISOString(),
      }

    case AnsokanKeys.Befattning:
      return {
        befattning: (field.value as ISelectOption).label,
        befattningId: (field.value as ISelectOption).id,
      }

    case AnsokanKeys.Provision:
    case AnsokanKeys.Jour:
    case AnsokanKeys.SjukersattningsgradChoice: {
      if (field.value === RadioChoice.No) {
        return { [mapLonOchAnsokanRadioChoiceToPatch(field.id)]: null }
      } else return
    }

    default:
      return defaultMiddleware(field)
  }
}
