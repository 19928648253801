import {
  useGetTidigareSysselsattningarOldQuery,
  useGetTidigareSysselsattningarQuery,
} from '@local/services/API/tidigareSysselsattningar/tidigareSysselsattningarApi'
import { TidigareSysselsattningResponseModel } from '@local/services/API/tidigareSysselsattningar/Types'
import { useIsFeatureFlagEnabled } from '@local/Utils/Hooks'
import { ITidigareSysselsattning } from '@local/Views/DinaAnsokningar/TidigareSysselsattningarList/TidigareSysselsattningar.types'
import { useAuthentication } from '@trr/app-shell-data'

export interface IUseTidigareSysselsattningar {
  data: ITidigareSysselsattning[] | TidigareSysselsattningResponseModel[]
  isLoading: boolean
  isError: boolean
}

const useTidigareSysselsattningar = (): IUseTidigareSysselsattningar => {
  const isNewKompletteraApiModelEnabled = useIsFeatureFlagEnabled(
    'KlientAnsokan-Frontend_newKompletteraApiModel_temp_241218'
  )

  const { sub: klientId } = useAuthentication()

  const getTidigareSysselsattningarQuery = isNewKompletteraApiModelEnabled
    ? useGetTidigareSysselsattningarQuery
    : useGetTidigareSysselsattningarOldQuery

  const { data, isLoading, isError } =
    getTidigareSysselsattningarQuery(klientId)

  return { data, isLoading, isError }
}

export default useTidigareSysselsattningar
