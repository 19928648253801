// ** SelectCompanyPage **

import { userTypes } from 'State/Slices/inputSlice/constants'

const texts = {
  heading: 'Logga in',
  subHeading: {
    [userTypes.tjanstemanWithCollectum]: 'Privatperson',
  },
  ingress:
    'Vi har hämtat information om dig från Collectum. Kontrollera gärna att uppgifterna stämmer.',
  companyHeading: 'Anställning',
  employmentPeriod: 'Anställningsperiod',
  secondaryButton: 'Uppgifterna stämmer inte',
  primaryButton: 'Fortsätt',
} as const

export default texts
