import { useAuthentication } from '@trr/app-shell-data'
import { useEffect } from 'react'
import { useSliceDispatch } from 'State/Slices'
import { pageToStartOrReturnTo } from './helpers'

export const useSetStartOrReturnPage = () => {
  const triggerDispatch = useSliceDispatch()
  const { userRoles } = useAuthentication()
  useEffect(() => {
    triggerDispatch('goToSpecificPage', pageToStartOrReturnTo(userRoles))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export default useSetStartOrReturnPage
