import { DownloadForOffline, OpenInNew } from '@mui/icons-material'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
  Box,
  useTheme,
  Link,
  Stack,
  useMediaQuery,
} from '@mui/material'
import DescriptionIcon from '@mui/icons-material/Description'

import { format, parseISO } from 'date-fns'

import { IndustryArticle } from './types/IndustryArticle.type'
import downloadBlobHelper from 'src/utils/helpers/downloadBlobHelper'
import useDeviceSize from 'src/utils/hooks/useDeviceSize'
import { useTranslation } from 'react-i18next'
import { useEpiPagePath } from '@trr/app-shell-data'
import isExternalURL from 'src/utils/helpers/isExternalURL'

interface ArticleCardProps {
  values?: IndustryArticle
}

const ArticleCard = ({ values }: ArticleCardProps) => {
  const pagePath = useEpiPagePath()
  const { t } = useTranslation('translation', {
    keyPrefix: 'rapport',
  })
  const { t: tKallor } = useTranslation('translation', { keyPrefix: 'kallor' })
  const { palette, breakpoints } = useTheme()
  const smallScreensUp = useMediaQuery(breakpoints.up('sm'))
  const { mediumScreensUp } = useDeviceSize()
  const publicationDate = values?.publicationDate
    ? format(parseISO(values?.publicationDate as string), 'yyyy-MM-dd')
    : ''
  const type = 'Rapport'
  const handleClickDownload = async () => {
    if (values?.id)
      await downloadBlobHelper(values?.id, values?.fileName ?? values.title)
  }

  const ariaLabel = `${t('link')} ${values?.title ?? ''}`
  const ariaLabel2 = `${t('laddaNer')} ${values?.title ?? ''}`

  return (
    <Card
      data-testid="article-card"
      component="article"
      sx={{
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
      }}
    >
      <Box sx={{ width: '100%' }}>
        <CardContent sx={{ p: 3 }}>
          <Typography
            color={palette.text.secondary}
            variant="subtitle2"
            component="p"
          >
            {type} {t('fran')} {values?.publisher} | {publicationDate}
          </Typography>
          <Typography gutterBottom variant="h5" component="h2">
            {values?.title}
          </Typography>
          <Typography variant="body2">{values?.description}</Typography>
          {values?.links && (
            <Stack
              direction={smallScreensUp ? 'row' : 'column'}
              sx={{ flexWrap: 'wrap' }}
              useFlexGap
              spacing={2}
              mt={2}
              divider={
                smallScreensUp ? (
                  <Typography color="primary">•</Typography>
                ) : null
              }
            >
              {values.links.map((link, index) => (
                <Button
                  key={index}
                  sx={{ padding: 0, minWidth: 0 }}
                  aria-label={`${link.text}${isExternalURL(link.url) ? ` ${tKallor('openInNew')}` : ''}`}
                  startIcon={isExternalURL(link.url) ? <OpenInNew /> : null}
                  target={isExternalURL(link.url) ? '_blank' : '_self'}
                  variant="text"
                  href={link.url}
                  size="small"
                >
                  {link.text}
                </Button>
              ))}
            </Stack>
          )}
        </CardContent>
        <CardActions sx={{ p: 3, flexDirection: { xs: 'column', sm: 'row' } }}>
          <Button
            startIcon={<DownloadForOffline />}
            variant="outlined"
            size="medium"
            onClick={() => void handleClickDownload()}
            aria-label={ariaLabel2}
            sx={{ mb: { xs: 1, sm: 0 } }}
          >
            {t('laddaNer')} ({values?.fileName.split('.').pop()?.toUpperCase()})
          </Button>
          <Button
            component={Link}
            href={`${pagePath}rapport/${values?.id ?? ''}`}
            variant="text"
            size="small"
            aria-label={ariaLabel}
          >
            {t('link')}
          </Button>
        </CardActions>
      </Box>
      {mediumScreensUp && (
        <Box
          sx={{
            p: 5,
            backgroundColor: 'surface.purple',
            flexDirection: 'column',
            justifyContent: 'center',
            display: 'flex',
          }}
        >
          <DescriptionIcon color="primary" sx={{ fontSize: '96px' }} />
        </Box>
      )}
    </Card>
  )
}

export default ArticleCard
