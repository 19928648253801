import * as Yup from 'yup'
import { laggTillMedarbetareValidationSchema } from '@local/Views/SkapaAnsokningar/SkapaAnsokningarForm/LaggTillMedarbetare/laggTillMedarbetare.schema'
import { ISkapaAnsokningarContent } from '@local/Views/SkapaAnsokningar/types/types'
import { sammanfattningValidationSchema } from '@local/Views/SkapaAnsokningar/SkapaAnsokningarForm/Sammanfattning/Sammanfattning.schema'
import { OrsakerTillUppsagning } from '@local/Common.types'
import { IUppdragApiModel } from '@local/services/API/uppdrag/types'

export const skapaAnsokningarValidationSchema = (
  content: ISkapaAnsokningarContent,
  uppdrag: IUppdragApiModel,
  arbitraryTimeHasPassedSinceUpload: boolean,
  isTestUser: boolean
) => {
  const isRisk = uppdrag?.orsakTillUppsagning === OrsakerTillUppsagning.Risk
  const isTB =
    uppdrag?.orsakTillUppsagning ===
    OrsakerTillUppsagning.TidsbegransadAnstallning

  return Yup.object()
    .concat(
      laggTillMedarbetareValidationSchema(
        content.laggTillMedarbetare.validering
      )
    )
    .concat(
      sammanfattningValidationSchema(
        content.sammanfattning.validering,
        content.sammanfattning.ansokanValidering,
        content.sammanfattning.datumValidering,
        isRisk,
        isTB,
        arbitraryTimeHasPassedSinceUpload,
        isTestUser
      )
    )
}
