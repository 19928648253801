import { type PayloadAction, createSlice } from '@reduxjs/toolkit'
import { type PageState } from './types'
import { UserType } from '../inputSlice/types'
import { pageFlowsMap } from './constants'

const initialState: PageState<UserType> = {
  currentPage: undefined,
}

const goToPage: <T extends UserType>(
  state: PageState<T>,
  action: PayloadAction<{ userType: T; direction: 'next' | 'prev' }>
) => PageState<T> = (state, action) => {
  const pageToNavigateTo = pageFlowsMap[action.payload.userType].get(
    state.currentPage
  )[action.payload.direction]
  return {
    ...state,

    currentPage: pageToNavigateTo,
  }
}

const goToSpecificPage: <T extends UserType>(
  state: PageState<T>,
  action: PayloadAction<PageState<T>['currentPage']>
) => PageState<T> = (state, action) => {
  return {
    ...state,
    currentPage: action.payload,
  }
}

const pageSlice = createSlice({
  name: 'page',
  initialState: initialState,
  reducers: {
    goToPage,
    goToSpecificPage,
  },
})

export default pageSlice
