import { PropsWithChildren } from 'react'
import HTMLMapper from 'apps/Site/Epi/HTMLMapper'
import {
  goToEnforcedMissingContactInformation,
  isEnforcedToSetContactInformation,
} from 'utils/urlTransition'
import { useAuthentication } from 'features/Authentication/Hooks'

import { selectShouldEnforceRoleForFunctionTypes } from './EnforceContactInformation.selectors'
import IllustrationSmartphoneBubbla from './IllustrationSmartphoneBubbla'
import { Box, Button, styled, Typography } from '@mui/material'
import { useHistory, useLocation } from 'react-router-dom'
import { useCachedContentQuery } from 'state/PublicWebApi'
import { useSelector } from 'react-redux'
import { roles } from 'utils/roles'

export interface EnforcedFunctionType {
  functionType: string
  message: string
}

const PremableWrapper = styled(Box)(({ theme }) => ({
  p: {
    ...theme.typography.preamble,
  },
}))

const useMessageEnding = () => {
  const userRules = useAuthentication().profile.userRoles
  if (
    userRules.includes(roles.MissingEmail) &&
    userRules.includes(roles.MissingMobile)
  ) {
    return 'verifiera dina kontaktuppgifter.'
  }
  if (userRules.includes(roles.MissingEmail)) {
    return 'verifiera din e-postadress.'
  }
  if (userRules.includes(roles.MissingMobile)) {
    return 'verifiera ditt telefonnummer.'
  }
  return ''
}

export const functionTypesToEnforceRoleFor: EnforcedFunctionType[] = [
  {
    functionType: 'tjanstepersonansokan',
    message: 'För att göra din ansökan behöver du',
  },
  {
    functionType: 'klientansokan',
    message: 'För att se din ansökan behöver du',
  },
]

export const getKontoinstallningarLinkBase = (idp = '') =>
  idp === 'kund' ? '/trr-foretag' : '/mitt-trr'

const getMessageForFunctionType = (functionType: string) =>
  functionTypesToEnforceRoleFor.find(
    (enforcedFunctionType) => enforcedFunctionType.functionType === functionType
  )?.message

const selectShouldEnforce = (functionType: string) =>
  selectShouldEnforceRoleForFunctionTypes(
    functionTypesToEnforceRoleFor.map(
      (EnforcedFunctionType) => EnforcedFunctionType.functionType
    ),
    functionType
  )

const EnforceContactInformation = ({
  children,
}: PropsWithChildren<unknown>) => {
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { push } = useHistory<isEnforcedToSetContactInformation>()
  const { state } = useLocation<isEnforcedToSetContactInformation>()
  const { profile } = useAuthentication()
  const { data: contentData } = useCachedContentQuery()
  const getMessageEnding = useMessageEnding()
  const functionType = contentData.properties.functionType
  const heading = contentData.properties.heading
  const preamble = contentData.properties.preamble
  const shouldEnforce = useSelector(selectShouldEnforce(functionType))
  const kontoinstallningarLinkBase = getKontoinstallningarLinkBase(profile.idp)
  const message = getMessageForFunctionType(functionType)
  if (!shouldEnforce) return <>{children}</>

  return (
    <div>
      <Typography variant="h1" gutterBottom>
        {heading}
      </Typography>
      <PremableWrapper mb={5}>
        <HTMLMapper body={preamble} />
      </PremableWrapper>
      <Box
        component={'article'}
        sx={{
          padding: { xs: 3, lg: 5 },
          backgroundColor: 'surface.orange',
          borderRadius: 1,
        }}
      >
        <Box display={'flex'} data-testid="missing-mobile-highlightbox">
          <Box>
            <Typography variant="h3" component="h2" gutterBottom>
              {`${message} ${getMessageEnding}`}
            </Typography>
            <Typography variant="body1" gutterBottom>
              Dina kontaktuppgifter används för att vi ska kunna kontakta dig
              med viktig information om TRR
            </Typography>
            {profile.userRoles.includes(roles.MissingEmail) && (
              <Button
                data-testid="enforceEmailButton"
                variant="text"
                onClick={() =>
                  goToEnforcedMissingContactInformation(
                    `${kontoinstallningarLinkBase}/kontoinstallningar/andra-epost`,
                    push,
                    state
                  )
                }
              >
                Lägg till e-postadress
              </Button>
            )}
            {profile.userRoles.includes(roles.MissingMobile) && (
              <Button
                data-testid="enforceMobileButton"
                variant="text"
                onClick={() =>
                  goToEnforcedMissingContactInformation(
                    `${kontoinstallningarLinkBase}/kontoinstallningar/andra-mobilnummer`,
                    push,
                    state
                  )
                }
              >
                Lägg till mobilnummer
              </Button>
            )}
          </Box>
          <Box display={{ xs: 'none', md: 'block' }}>
            <IllustrationSmartphoneBubbla />
          </Box>
        </Box>
      </Box>
    </div>
  )
}

export default EnforceContactInformation
