const isExternalURL = (url: string | undefined) => {
  if (!url) return true

  try {
    const urlToCheck = new URL(url)
    return !urlToCheck.host.endsWith('trr.se')
  } catch {
    return true
  }
}

export default isExternalURL
