import { useContext } from 'react'

import { Box, IconButton, styled } from '@mui/material'
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'

import { useTrackCustomClick } from 'features/Telemetry/GoogleTagManager/Hooks/useTrackCustomClick'
import Logo from 'apps/Site/components/Logo'
import { LogoLinkWrapper } from '../../../NewHeader.styles'
import { SubMenuContext } from '../../Submenu/SubmenuContext'

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  alignSelf: 'flex-end',
  '&.Mui-focusVisible': {
    color: theme.palette.primary?.main,
  },
}))

interface TopMenuProps {
  onClickMenuButton: () => void
}

const TopMenu = ({ onClickMenuButton }: TopMenuProps) => {
  const trackCustomClick = useTrackCustomClick()
  const { setIsSubmenuOpen, isSubmenuOpen, submenuTitle } =
    useContext(SubMenuContext)

  const closeSubmenu = () => {
    trackCustomClick('Menu foldout', { label: `Close ${submenuTitle}` })
    setIsSubmenuOpen(false)
  }

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      pb={3.5}
      data-testid="TopMenu"
    >
      {isSubmenuOpen ? (
        <Box display="flex" flexGrow={1}>
          <StyledIconButton
            data-testid="close-submenu-button"
            onClick={closeSubmenu}
            sx={{ padding: 0 }}
          >
            <ArrowBackRoundedIcon />
          </StyledIconButton>
        </Box>
      ) : (
        <LogoLinkWrapper
          href="/privatperson"
          aria-label="Startsida TRR.se"
          onClick={onClickMenuButton}
        >
          <Logo data-testid="mobile-trr-logo" />
        </LogoLinkWrapper>
      )}

      <StyledIconButton
        onClick={onClickMenuButton}
        data-testid="close-menu-button"
        data-gtm-label="close-menu-button"
      >
        <CloseRoundedIcon />
      </StyledIconButton>
    </Box>
  )
}

export default TopMenu
