import { PropsWithChildren } from 'react'
import {
  IdServerUser,
  useRedirectLoggedInUser,
} from './useRedirectLoggedInUser'

const Redirect = ({
  children,
  oidcUser,
}: PropsWithChildren<{ oidcUser: IdServerUser }>) => {
  const { shouldRenderApp } = useRedirectLoggedInUser(oidcUser)

  if (shouldRenderApp) {
    return children
  }
  return null
}

export default Redirect
