import { BegaranYttrandeStatus, SearchYttrandenStatus } from '@local/src/Services/SwaggerApi/data-contracts'
import { Box, Chip } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
	status: BegaranYttrandeStatus | SearchYttrandenStatus
}

const YttrandeStatusChip = ({ status }: Props) => {
	const { t } = useTranslation('translation', { keyPrefix: 'common.status' })

	const getVariant = () => {
		switch (status) {
			case BegaranYttrandeStatus.SkickadTillCsn:
			case BegaranYttrandeStatus.BesvaradWithAdhocYttrande:
				return 'status-success'
			case BegaranYttrandeStatus.BorttagenHosCsn:
				return 'status-error'
			default:
				return 'status-info'
		}
	}

	const getStatusText = () => {
		switch (status) {
			case BegaranYttrandeStatus.Ny:
				return t('csnFormState_new')
			case BegaranYttrandeStatus.UnderBedomning:
				return t('csnFormState_edit')
			case BegaranYttrandeStatus.SkickadTillCsn:
				return t('csnFormState_sent')
			case BegaranYttrandeStatus.BesvaradWithAdhocYttrande:
				return t('csnFormState_adhoc')
			case BegaranYttrandeStatus.BorttagenHosCsn:
				return t('csnFormState_deleted')
			default:
				return t('csnFormState')
		}
	}

	const getTestId = () => {
		switch (status) {
			case BegaranYttrandeStatus.Ny:
				return 'new'
			case BegaranYttrandeStatus.UnderBedomning:
				return 'saved'
			case BegaranYttrandeStatus.SkickadTillCsn:
				return 'sent'
			case BegaranYttrandeStatus.BesvaradWithAdhocYttrande:
				return 'adhoc'
			case BegaranYttrandeStatus.BorttagenHosCsn:
				return 'deleted'
			default:
				return 'unknown'
		}
	}

	return (
		<Box display={'flex'} mb={2}>
			<Chip variant={getVariant()} label={getStatusText()} data-testid={`yttrande-status-chip-${getTestId()}`} />
		</Box>
	)
}

export default YttrandeStatusChip
