import { Box, styled, useMediaQuery, useTheme } from '@mui/material'

import { visitorContext } from 'slices/VisitorContext/types'
import { ContextButtonProps } from 'PublicWebApi/Settings/types'
import { useAuthentication } from 'features/Authentication/Hooks'
import {
  useCachedSettingsState,
  useCachedContentQuery,
} from 'state/PublicWebApi'
import { useSliceStateSelector } from 'slices/hooks'
import { cookieName, getCookieValue } from 'utils/cookies'
import { getLoggedInContext, getLoggedInProfileUrl } from './NewHeader.hooks'
import Logo from 'apps/Site/components/Logo'
import LoggedInHeader from 'apps/Site/LoggedInNavigation/LoggedInHeader'
import Navbar from './Mobile/Navbar'
import NavbarTop from './Desktop/NavbarTop'
import NavbarBottom from './Desktop/NavbarBottom'
import SecondaryActionButtons from './Desktop/SecondaryActionButtons'
import { LogoLinkWrapper } from './NewHeader.styles'

const HeaderWrapper = styled('header')(() => ({
  width: '100%',
}))

const SimplifiedHeaderWrapper = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  paddingX: 2,
  paddingY: 0.5,
}))

const NewHeader = () => {
  const { visitorContextStatus } = useSliceStateSelector(
    (state) => state.slices.visitorContexts
  )
  const { megaNavigation } = useCachedSettingsState()
  const { data: contentData } = useCachedContentQuery()
  const { isLoggedIn, profile } = useAuthentication()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'))

  const contextButtonData: ContextButtonProps[] = megaNavigation?.contexts?.map(
    (context) => ({
      title: context.title,
      url: context.url,
      context: context.role as visitorContext,
    })
  )
  const isPublicPage = contentData?.properties.isPublic

  const useNewLoggedInNavigationSystem = Boolean(
    getCookieValue(cookieName.newNavigationSystemCookie)
  )

  const showSimplifiedHeader = () => {
    if (useNewLoggedInNavigationSystem) {
      return location.pathname.includes('/medarbetare')
    } else {
      return !isPublicPage
    }
  }

  const showNewLoggedInHeader = () => {
    if (useNewLoggedInNavigationSystem) {
      return !isPublicPage
    } else {
      return false
    }
  }

  const getLogoLink = (): string => {
    return getLoggedInProfileUrl(
      getLoggedInContext(profile?.idp, visitorContextStatus)
    )
  }

  const getLogoAriaLabel = () => {
    if (getLogoLink() === '/trr-foretag') return 'TRR Företag'
    else if (getLogoLink() === '/medarbetare') return 'Medarbetare'
    else return 'Mitt TRR'
  }

  return isMobile ? (
    <HeaderWrapper>
      <Navbar />
    </HeaderWrapper>
  ) : (
    <HeaderWrapper data-testid="new-header">
      {isLoggedIn && showSimplifiedHeader() ? (
        <Box borderBottom={'1px solid'} borderColor={'neutral.divider'}>
          <SimplifiedHeaderWrapper data-testid="simplified-header">
            <LogoLinkWrapper
              sx={{ paddingLeft: 1 }}
              data-testid="simplified-header-logo"
              href={getLogoLink()}
              aria-label={getLogoAriaLabel()}
            >
              <Logo height={48} width={48} />
            </LogoLinkWrapper>
            <SecondaryActionButtons />
          </SimplifiedHeaderWrapper>
        </Box>
      ) : (
        isLoggedIn && showNewLoggedInHeader() && <LoggedInHeader />
      )}

      {!showSimplifiedHeader() && isPublicPage && (
        <Box borderBottom={'1px solid'} borderColor={'neutral.divider'}>
          <NavbarTop contextButtons={contextButtonData} />
          <NavbarBottom navbarBottomMenuItems={megaNavigation?.contexts} />
        </Box>
      )}
    </HeaderWrapper>
  )
}

export default NewHeader
