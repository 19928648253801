import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import userManager, {
  setMedarbetareUserManager,
} from 'features/Authentication/userManager'
import { medarbetarePathname } from 'features/Authentication/constants'

const tryForceLoginMedarbetare = async () => {
  const user = await userManager.getUser()
  if (!user || user.expired) {
    await userManager.signinRedirect({
      state: {
        redirectUrl: `${window.location.pathname}${window.location.search}${window.location.hash}`,
      },
    })
  }
}

const useForceMedarbetareLogin = () => {
  const { pathname } = useLocation()
  useEffect(() => {
    if (pathname.startsWith(medarbetarePathname)) {
      setMedarbetareUserManager()
      void tryForceLoginMedarbetare()
    }
  }, [pathname])
}

export default useForceMedarbetareLogin
