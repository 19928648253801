import { BegaranYttrandeIsDeletedAlert, GoBackButton } from '@local/src/Components'
import { PageWrapper } from '@local/src/Components/PageWrapper'
import useGetBegaranYttrande from '@local/src/Hooks/API/useGetBegaranYttrande'
import YttrandeInfo from '@local/src/Pages/BegaranYttrande/Components/YttrandeInfo/YttrandeInfo'
import { getBegaranYttrandeSvarFormData } from '@local/src/Utils/getBegaranYttrandeSvarFormData'
import { Alert, AlertTitle, Divider, Grid2 as Grid, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useRouteMatch } from 'react-router-dom'
import { BegaranYttrandeStatus } from '@local/src/Services/SwaggerApi/data-contracts'

import TillhorSvarForm from './Components/TillhorSvarForm/TillhorSvarForm'

interface BegaranYttrandeDetailStateParam {
	begaranId?: string
}

const BegaranYttrandeEditTillhorPage = () => {
	const pathEdit = `*/csn/begaran-yttrande/redigera-tillhor/:begaranId`
	const routeMatch = useRouteMatch<BegaranYttrandeDetailStateParam>(pathEdit)
	const { csnBegaranYttrande, isLoading, isError } = useGetBegaranYttrande(routeMatch?.params?.begaranId)
	const { t } = useTranslation('translation', { keyPrefix: 'begaranYttrande.tillhor' })

	const isStatusNew = csnBegaranYttrande?.status === BegaranYttrandeStatus.Ny
	const showAlert = csnBegaranYttrande?.ansokanId && csnBegaranYttrande?.status !== BegaranYttrandeStatus.SkickadTillCsn

	return (
		<PageWrapper isLoading={isLoading} errorLoadingData={isError} errorText={'Fel vid inläsning av yttrande'}>
			<Grid container spacing={4} maxWidth="md">
				<Grid size={{ xs: 12 }}>
					<GoBackButton />
				</Grid>
				<Grid size={{ xs: 12 }}>
					<YttrandeInfo begaranYttrande={csnBegaranYttrande} />
				</Grid>
				<Grid size={{ xs: 12 }}>
					<Divider />
				</Grid>
				<Grid size={{ xs: 12 }} container spacing={2} direction="column">
					<Typography variant="h4">{t('title')}</Typography>
					<BegaranYttrandeIsDeletedAlert status={csnBegaranYttrande?.status} />
				</Grid>
				<Grid size={{ xs: 12 }}>
					{showAlert ? (
						<Alert severity="info" data-testid={'yttrande-has-svar-alert'}>
							<AlertTitle>{t('alertHasSvar.title')}</AlertTitle>
							{t('alertHasSvar.description')}
						</Alert>
					) : (
						<TillhorSvarForm
							defaultFormData={getBegaranYttrandeSvarFormData(csnBegaranYttrande)}
							isReadOnly={csnBegaranYttrande?.shouldSendToCsn}
						/>
					)}
				</Grid>
				<Grid size={{ xs: 12 }}>
					{isStatusNew && (
						<Alert severity="info" data-testid={'continue-in-rad-alert'}>
							<AlertTitle>{t('alertContinueInRad.title')}</AlertTitle>
						</Alert>
					)}
				</Grid>
				<Grid size={{ xs: 12 }}>
					<GoBackButton />
				</Grid>
			</Grid>
		</PageWrapper>
	)
}

export default BegaranYttrandeEditTillhorPage
