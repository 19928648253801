import { baseApi } from 'State/Api'
import { type CreateUserRequestBody } from './types'

export const api = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    createUser: builder.mutation<void, CreateUserRequestBody>({
      query: (request) => {
        return {
          url: '/user',
          method: 'POST',
          body: request,
          responseHandler: 'text',
        }
      },
    }),
  }),
})

export const { useCreateUserMutation } = api

export default api
