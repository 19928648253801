import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import { useEffect } from 'react'
import { Controller, type UseFormReturn } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { Status } from 'types/IndustryReport'
import { useEpiContent } from '@trr/app-shell-data'
import { Content } from 'types/Content.type'

import { FormData } from '../IndustryReportEdit'

import OccupationGroupsAutocomplete from './OccupationGroupsAutocomplete'
import RegionsAutocomplete from './RegionsAutocomplete'
import Links from './Links/Links'

const Form = ({
  onSubmit,
  handleSave,
  form,
}: {
  onSubmit: (data: FormData) => Promise<void>
  handleSave: (status?: Status) => Promise<void>
  form: UseFormReturn<FormData, unknown>
}) => {
  const {
    redigeraPublikation: {
      formTitleLabel,
      formDescriptionLabel,
      formPublisherLabel,
      formPublicationDateLabel,
      formOccupationGroupLabel,
      formRegionLabel,
      formHeader,
      formRequiredExplanation,
      formOccupationGroupBody,
      formRegionsBody,
      formAllRegionsLabel,
      formInternalCommentBody,
      formInternalCommentLabel,
      formSaveButtonLabel,
      formLinksLabel,
      formLinksBody,
    },
  } = useEpiContent<Content>()
  const [allRegions] = form.watch(['allRegions'])
  const history = useHistory()
  const { register } = form
  const theme = useTheme()

  // When allRegions is enabled we clear the regions
  useEffect(() => {
    if (allRegions) form.setValue('regions', [])
  }, [allRegions, form])

  return (
    <Box sx={{ marginTop: 4 }}>
      <form
        onSubmit={(event) => {
          event.preventDefault()
          void form.handleSubmit(onSubmit)(event)
        }}
      >
        <Stack direction={'column'} spacing={3}>
          <Box>
            <Typography variant="h6">{formHeader}</Typography>
            <Typography
              variant="body1"
              sx={{ marginTop: 1 }}
              color="textSecondary"
            >
              {formRequiredExplanation}
            </Typography>
          </Box>

          <TextField
            label={formTitleLabel}
            type="text"
            required
            {...register('title')}
            name="title"
            error={form.formState.errors.title?.message ? true : false}
            helperText={form.formState.errors.title?.message}
            data-testid="title-field"
          />
          <TextField
            label={formDescriptionLabel}
            required
            multiline
            minRows={4}
            type="text"
            {...register('description')}
            name="description"
            error={form.formState.errors.description?.message ? true : false}
            helperText={form.formState.errors.description?.message}
            data-testid="description-field"
          />
          <TextField
            label={formPublisherLabel}
            required
            type="text"
            {...register('publisher')}
            name="publisher"
            error={form.formState.errors.publisher?.message ? true : false}
            helperText={form.formState.errors.publisher?.message}
            data-testid="publisher-field"
          />
          <Controller
            name="publicationDate"
            control={form.control}
            rules={{ required: true }}
            render={({ field: { ref, ...rest } }) => (
              <DatePicker
                {...rest}
                label={formPublicationDateLabel}
                inputRef={ref}
                desktopModeMediaQuery={theme.breakpoints.up('md')}
                slotProps={{
                  textField: {
                    name: rest.name,
                    required: true,
                    error: form.formState.errors.publicationDate?.message
                      ? true
                      : false,
                    helperText: form.formState.errors.publicationDate?.message,
                    inputProps: {
                      'data-testid': 'publicationdate-field',
                    },
                  },
                }}
              />
            )}
          />

          <Typography variant="h6">{formOccupationGroupLabel}</Typography>
          <Typography variant="body1">{formOccupationGroupBody}</Typography>

          <Controller
            name="occupationGroups"
            control={form.control}
            rules={{ required: true }}
            render={({ field: { ref, onChange }, fieldState }) => (
              <OccupationGroupsAutocomplete
                occupationgroups={form.getValues('occupationGroups')}
                onChange={onChange}
                ref={ref}
                fieldState={fieldState}
              />
            )}
          />

          <Typography variant="h6">{formRegionLabel}</Typography>
          <Typography variant="body1">{formRegionsBody}</Typography>
          <FormGroup>
            <FormControlLabel
              control={
                <Controller
                  name="allRegions"
                  control={form.control}
                  render={({ field: { ref, ...rest } }) => (
                    <Checkbox
                      data-testid="all-regions-field"
                      {...rest}
                      checked={rest.value}
                      inputRef={ref}
                      color="primary"
                    />
                  )}
                />
              }
              label={formAllRegionsLabel}
            />
          </FormGroup>
          {!allRegions && (
            <Controller
              name="regions"
              control={form.control}
              render={({ field: { onChange, ref }, fieldState }) => (
                <RegionsAutocomplete
                  regions={form.getValues('regions')}
                  onChange={onChange}
                  ref={ref}
                  fieldState={fieldState}
                />
              )}
            />
          )}

          <Typography variant="h6">{formLinksLabel}</Typography>
          <Typography variant="body1">{formLinksBody}</Typography>
          <Links form={form} />

          <Typography variant="h6">{formInternalCommentLabel}</Typography>
          <Typography variant="body1">{formInternalCommentBody}</Typography>
          <TextField
            multiline
            minRows={4}
            type="text"
            label="Kommentarer"
            {...register('internalComment')}
            name="internalComment"
            data-testid="internalcomment-field"
          />
        </Stack>

        <Stack direction={'row'} spacing={2} sx={{ marginTop: 6 }}>
          <Button onClick={() => void handleSave()} data-testid="save-button">
            {formSaveButtonLabel}
          </Button>

          <Box sx={{ alignSelf: 'flex-end' }}>
            <Button
              variant="outlined"
              onClick={() => {
                history.push('/medarbetare/prognosportalen-administration/')
              }}
              data-testid="cancel-button"
            >
              Avbryt
            </Button>
          </Box>
        </Stack>
      </form>
    </Box>
  )
}

export default Form
