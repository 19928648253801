import { Grid2 as Grid } from '@mui/material'
import { ArticleListItemProps } from 'apps/Site/components/ArticleList/ArticleListItem'
import RelatedContent from 'apps/Site/components/RelatedContent/index'
import {
  TableOfContents,
  TableOfContentsBox,
} from 'apps/Site/components/TableOfContents'
import blockMapper from 'apps/Site/Epi/BlockMapper'
import { IContentBlock } from 'apps/Site/Epi/BlockMapper/BlockMapper'
import { Introduction } from 'apps/Site/Epi/Blocks'
import { IntroductionContentProps } from 'apps/Site/Epi/Blocks/Introduction/Introduction'
import HTMLMapper from 'apps/Site/Epi/HTMLMapper'
import { ScrollWrapper } from 'apps/Site/Epi/ScrollWrapper'
import { useSliceStateSelector } from 'slices/hooks'
import { TableOfContentLink } from 'state/PublicWebApi/Content'

interface FaqProps {
  blocks?: IContentBlock[]
  editor?: string
  introduction?: IntroductionContentProps
  tableOfContents?: TableOfContentLink[]
  relatedContent?: ArticleListItemProps[]
}

const Faq = ({
  blocks,
  editor,
  introduction,
  tableOfContents,
  relatedContent,
}: FaqProps) => {
  const { isDesktop, isXL } = useSliceStateSelector(
    (state) => state.slices.device.deviceSize
  )
  const isDesktopOrXL = isDesktop || isXL

  return (
    <ScrollWrapper>
      <Grid container>
        <Grid size={{ xs: 12, md: 8 }}>
          <Introduction content={introduction} />
          {!isDesktopOrXL && (
            <TableOfContentsBox aria-hidden>
              <TableOfContents items={tableOfContents} />
            </TableOfContentsBox>
          )}
          <HTMLMapper body={editor} wrapper={'section'} />
          {relatedContent?.length > 0 && (
            <RelatedContent relatedContent={relatedContent} />
          )}
        </Grid>
        {blocks.length > 0 && (
          <Grid size={{ xs: 12 }}>{blockMapper({ blocks })}</Grid>
        )}
        {isDesktopOrXL && (
          <Grid size={{ md: 4 }}>
            <TableOfContentsBox aria-hidden>
              <TableOfContents items={tableOfContents} />
            </TableOfContentsBox>
          </Grid>
        )}
      </Grid>
    </ScrollWrapper>
  )
}

export default Faq
