import React, { useEffect } from 'react'
import { useUpdateArbetsuppgiftMutation } from '@local/src/Api/Arbetsuppgift/arbetsuppgiftApi'
import { useAppDispatch } from '@local/Store/configureStore'
import { addSuccessMessage } from '@local/src/Blocks/UserFeedback/UserFeedback.actions'
import { Arbetsuppgift } from '@local/Views/Arenden/Arbetsuppgift/types'

import EgenArbetsuppgiftModalBase from '../EgenArbetsuppgiftModalBase'

interface EditArbetsuppgiftModalProps {
  arbetsuppgift: Arbetsuppgift
  open: boolean
  onClose: () => void
}

const EditArbetsuppgiftModal = ({
  arbetsuppgift,
  open,
  onClose,
}: EditArbetsuppgiftModalProps) => {
  const dispatch = useAppDispatch()
  const [
    updateArbetsuppgift,
    { isError, isSuccess, reset: resetQuery, isLoading },
  ] = useUpdateArbetsuppgiftMutation()

  useEffect(() => {
    if (isSuccess) {
      dispatch(addSuccessMessage('Arbetsuppgiften uppdaterades'))
      onClose()
    }
  }, [isSuccess])

  const handleSubmit = (header: string, body: string) => {
    updateArbetsuppgift({ id: arbetsuppgift.id, header: header, body: body })
  }

  const handleClose = () => {
    resetQuery()
    onClose()
  }

  return (
    <EgenArbetsuppgiftModalBase
      initialHeader={arbetsuppgift.header}
      initialBody={arbetsuppgift.body}
      open={open}
      onClose={handleClose}
      onSubmit={handleSubmit}
      isSuccess={isSuccess}
      isLoading={isLoading}
      isError={isError}
      confirmText={'Spara'}
      errorMessage={'Något gick fel vid uppdatering av arbetsuppgiften'}
      modalTitle="Redigera uppgift"
    />
  )
}

export default EditArbetsuppgiftModal
