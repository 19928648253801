// ** LoginFlowStartPage **

const texts = {
  loginAtTrr: 'Privatperson',
  loginAsEmployer: 'Företagsrepresentant',
  saBehandlarVIDinaPersonuppgifter: 'Så behandlar vi dina personuppgifter',
  howToLogin: 'Hur vill du logga in?',
  bThis: 'BankID på den här enheten',
  bOther: 'BankID på annan enhet',
  freja: 'Freja eID+',
  username: 'Användarnamn',
  password: 'Användarnamn & lösenord',
  otherAlternatives: 'Andra alternativ',
  header: 'Välj hur du vill logga in',
} as const

export default texts
