import { Box, styled, Typography } from '@mui/material'
import { ContentProps } from 'apps/Site/components/CollectionPageCards/CollectionCard.types'
import LargeCard from 'apps/Site/components/CollectionPageCards/LargeCard/LargeCard'
import SmallCard from 'apps/Site/components/CollectionPageCards/SmallCard/SmallCard'

export const HeadingAndPreambleWrapper = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  flexGrow: 1,
  flexShrink: 1,
  flexBasis: 0,
  [theme.breakpoints.up('lg')]: {
    maxWidth: '800px',
  },
}))

export interface PrimaryContentBlockProps {
  content: {
    heading: string
    text: string
    blockArea: ContentProps[]
    mediaUrl?: string
    fallbackImage?: string
  }
}

const PrimaryContentBlock = ({
  content: { heading, text, blockArea, mediaUrl, fallbackImage },
}: PrimaryContentBlockProps) => {
  const minNumCards = 2
  const maxNumCards = 3
  const numCards = blockArea?.length

  const largeCard = blockArea[0]
  const smallCards = blockArea.slice(1, maxNumCards)

  return (
    numCards >= minNumCards && (
      <Box>
        <HeadingAndPreambleWrapper>
          {heading && (
            <Typography variant="h3" component="h2" mb={1}>
              {heading}
            </Typography>
          )}

          {text && (
            <Typography variant="body1" color="text.secondary">
              {text}
            </Typography>
          )}
        </HeadingAndPreambleWrapper>

        <Box
          sx={{
            display: 'grid',
            gridAutoRows: 'auto',
            gridTemplateColumns: { xs: '1fr', md: '1fr 1fr' },
            gap: 3,
          }}
        >
          <Box sx={{ gridRow: { md: 'span 2' } }}>
            <LargeCard
              cardContent={largeCard}
              mediaUrl={mediaUrl}
              fallbackImage={fallbackImage}
            />
          </Box>

          {smallCards.map((cards) => (
            <Box
              sx={{ gridRow: { md: 'span 1' } }}
              key={`cards-${cards.properties.heading}`}
            >
              <SmallCard
                cardContent={cards}
                mediaUrl={mediaUrl}
                fallbackImage={fallbackImage}
              />
            </Box>
          ))}
        </Box>
      </Box>
    )
  )
}

export default PrimaryContentBlock
