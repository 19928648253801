import { Grid2 as Grid, Stack, Typography } from '@mui/material'
import { ScrollWrapper } from 'apps/Site/Epi/ScrollWrapper'

import CollectionPageBlockMapper, {
  CollectionPageBlock,
} from './CollectionPageBlockMapper/CollectionPageBlockMapper'
import StartHereCardBlock, {
  StartHereCardBlockProps,
} from './CollectionPageBlocks/StartHereCardBlock/StartHereCardBlock'

export interface CollectionPageProps {
  heading: string
  text: string
  startHereArea: StartHereCardBlockProps[]
  blockArea: CollectionPageBlock[]
  fallbackUrl: string
}

const Collection = ({
  heading,
  text,
  startHereArea,
  blockArea,
  fallbackUrl,
}: CollectionPageProps) => {
  return (
    <ScrollWrapper>
      <Grid container mb={{ xs: 7, md: 10 }}>
        <Grid size={{ md: 8 }}>
          {heading && (
            <Typography variant="h2" component="h1" mb={{ xs: 3, md: 1 }}>
              {heading}
            </Typography>
          )}
          {text && <Typography variant="preamble">{text}</Typography>}
        </Grid>
        {startHereArea?.length > 0 && (
          <Grid size={{ xs: 12, md: 8 }}>
            <StartHereCardBlock {...startHereArea[0]} marginTop={5} />
          </Grid>
        )}
      </Grid>
      {blockArea?.length > 0 && (
        <Stack direction="column" spacing={{ xs: 7, md: 10 }}>
          {CollectionPageBlockMapper({
            blocks: blockArea,
            fallbackImage: fallbackUrl,
          })}
        </Stack>
      )}
    </ScrollWrapper>
  )
}

export default Collection
