import React from 'react'
import { Stack } from '@mui/material'
import useSetStudieersattningAndStudielon from '@local/src/features/StudiestodAnsokan/Hooks/useSetStudieersattningAndStudielon'
import { useIsFeatureEnabled } from '@trr/app-shell-data'

import { useRadgivarensUnderlagFormContext } from '../../Hooks'

import {
    KoptUtbildning,
    StudieersattningStudielon,
    ValjStod,
    KortvarigtStudiestod,
    KompletterandeStudiestod,
    Studieersattning,
    Studielon,
} from './Components'

interface Props {
    utbildningsIndex: number
    isReadonly: boolean
}

const Insats = ({ utbildningsIndex, isReadonly }: Props) => {
    const { watch } = useRadgivarensUnderlagFormContext()
    const { studiestodTyp, studieersattningAndStudielon, nekaStod, utbildningId } = watch(`utbildningar.${utbildningsIndex}`)
    const showInsats = nekaStod && nekaStod.isStodNekat === false
    const shouldRenderKoptUtbildning = studiestodTyp?.hasKoptUtbildning
    const shouldRenderKortvarigtStudiestod = studiestodTyp?.hasKortvarigtStudiestod
    const shouldRenderKompletterandeStudiestod = studiestodTyp?.hasKompletterandeStudiestod

    const featureRemoveStudielonEnabled = useIsFeatureEnabled('Medarbetare-Studier-Frontend-removeStudielon_temp_250107')

    const hasStudielonOrStudieersattning = studiestodTyp?.hasStudielon || studiestodTyp?.hasStudieersattning

    const shouldRenderStudieersattningStudielon = !featureRemoveStudielonEnabled && hasStudielonOrStudieersattning
    const shouldRenderStudielon = featureRemoveStudielonEnabled && studiestodTyp?.hasStudielon
    const shouldRenderStudieersattning = featureRemoveStudielonEnabled && studiestodTyp?.hasStudieersattning

    const shouldResetStudieersattningStudielon = shouldRenderStudieersattningStudielon && studieersattningAndStudielon === undefined
    useSetStudieersattningAndStudielon(shouldResetStudieersattningStudielon, utbildningsIndex)

    return showInsats ? (
        <Stack spacing={2}>
            <ValjStod utbildningsIndex={utbildningsIndex} isReadonly={isReadonly} />
            {shouldRenderKompletterandeStudiestod && (
                <KompletterandeStudiestod isReadonly={isReadonly} utbildningsIndex={utbildningsIndex} />
            )}
            {shouldRenderKortvarigtStudiestod && <KortvarigtStudiestod isReadonly={isReadonly} utbildningsIndex={utbildningsIndex} />}
            {shouldRenderKoptUtbildning && <KoptUtbildning isReadonly={isReadonly} utbildningsIndex={utbildningsIndex} />}
            {shouldRenderStudieersattningStudielon && (
                <StudieersattningStudielon isReadonly={isReadonly} utbildningsIndex={utbildningsIndex} />
            )}
            {shouldRenderStudieersattning && <Studieersattning isReadonly={isReadonly} utbildningsIndex={utbildningsIndex} />}
            {shouldRenderStudielon && <Studielon utbildningsId={utbildningId} />}
        </Stack>
    ) : null
}

export default Insats
