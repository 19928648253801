import { ExternalLink } from '@local/src/components/ExternalLink'
import { InformationWithWarningIcon } from '@local/src/features/StudiestodAnsokan/Components/Steps/RadgivarensUnderlag/Components'
import {
    StudieersattningKalenderhalvar,
    PreliminarErsattning,
    VarningarForEkonomiskStod,
} from '@local/src/features/StudiestodAnsokan/Components/Steps/RadgivarensUnderlag/Components/Insats/Components/Studieersattning/Components'
import { useRadgivarensUnderlagFormContext } from '@local/src/features/StudiestodAnsokan/Components/Steps/RadgivarensUnderlag/Hooks'
import { useGetCurrentStudiestodansokan } from '@local/src/features/StudiestodAnsokan/Hooks'
import { usePickEpiContentWithFallback } from '@local/src/hooks'
import { Alert, AlertTitle, Box, Button, Divider, Stack, Typography } from '@mui/material'
import React, { Fragment, useEffect } from 'react'
import { useFieldArray } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

interface Props {
    utbildningsIndex: number
    isReadonly: boolean
}
const Studieersattning = ({ utbildningsIndex, isReadonly }: Props) => {
    const { t } = useTranslation('translation', { keyPrefix: 'studiestodAnsokan.steps.radgivarensUnderlag' })
    const { control } = useRadgivarensUnderlagFormContext()
    const { fields, remove, append } = useFieldArray({
        control,
        name: `utbildningar.${utbildningsIndex}.studieersattning.kalenderhalvar`,
    })

    useEffect(() => {
        if (isReadonly) return
        if (fields.length === 0) {
            append({
                studieersattning: undefined,
            })
        }
    }, [fields, isReadonly])

    const showAddButton = fields.length < 4

    const handleAddKalenderhalvar = () => {
        append({
            studieersattning: undefined,
        })
    }

    const { studiestodAnsokan } = useGetCurrentStudiestodansokan()
    const {
        studierExcelRaknesnurrorFile: { studierExcelRaknesnurraStudieersattningLink },
    } = usePickEpiContentWithFallback('studiertab')

    const showYoungerThan40Alert = studiestodAnsokan?.isYoungerThan40

    return (
        <Fragment>
            <Divider />
            <Stack spacing={2}>
                <Typography data-testid="studieersattning-header" variant="h5">
                    Studieersättning
                </Typography>
                {showYoungerThan40Alert && (
                    <Alert severity="warning" data-testid="younger-than-40-alert">
                        <AlertTitle>{t('studieersattningStudielonYoungerThan40AlertHeader')}</AlertTitle>
                        {t('studieersattningStudielonYoungerThan40AlertDescription')}
                    </Alert>
                )}
                <PreliminarErsattning utbildningsIndex={utbildningsIndex} isReadonly={isReadonly} />
                <Stack>
                    <ExternalLink
                        linkText={t('studierExcelRaknesnurraStudieersattningLinkText')}
                        href={studierExcelRaknesnurraStudieersattningLink}
                        testId="studieersattning-link"
                    />
                </Stack>
                <VarningarForEkonomiskStod utbetalningsforutsattningar={studiestodAnsokan?.radgivarunderlag?.utbetalningsforutsattningar} />

                <Stack spacing={2}>
                    {fields?.map((f, kalenderhalvarIndex) => (
                        <StudieersattningKalenderhalvar
                            isReadonly={isReadonly}
                            key={f.id}
                            utbildningsIndex={utbildningsIndex}
                            kalenderhalvarIndex={kalenderhalvarIndex}
                            remove={remove}
                        />
                    ))}
                    {!isReadonly &&
                        (showAddButton ? (
                            <Box>
                                <Button data-testid="add-kalenderhalvar" onClick={handleAddKalenderhalvar} variant="outlined">
                                    {t('laggTillKalenderhalvarText')}
                                </Button>
                            </Box>
                        ) : (
                            <Typography data-testid="info-no-add-kalenderhalvar">{t('maxKalenderhalvarText')}</Typography>
                        ))}
                    <InformationWithWarningIcon warningText={t('aterbetalningsskyldigText')} />
                </Stack>
            </Stack>
        </Fragment>
    )
}

export default Studieersattning
