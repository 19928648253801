import { useIsFeatureEnabled } from '@trr/app-shell-data'
import React, { Fragment } from 'react'

interface Props {
    name: string
    renderOnEnabled: React.ReactNode
    renderOnDisabled?: React.ReactNode
}
const FeatureFlag = ({ name, renderOnEnabled, renderOnDisabled = null }: Props) => {
    const studiestodFeatureFlag = useIsFeatureEnabled(name)
    return <Fragment>{studiestodFeatureFlag ? renderOnEnabled : renderOnDisabled}</Fragment>
}

export default FeatureFlag
