import { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { shouldShowLoginFlow } from '../Helpers'
import {
  getLoginUrl,
  getPossibleArbetsgivarinfoLinkId,
  isOnboardingJwt,
} from 'utils/helpers'
import { useSliceStateSelector } from 'slices/hooks'
import { roles } from 'utils/roles'

const useRedirectToLogin = () => {
  const history = useHistory()
  const { pathname } = useLocation()

  const user = useSliceStateSelector((state) => state.slices.auth)

  const shouldBeAtLoginFlow =
    isOnboardingJwt(user.profile?.sub) ||
    (user.profile?.roles.includes(roles.MissingEmail) &&
      !user.profile?.roles.includes(roles.Arbetsgivarinfo))

  const arbetsgivarinfoLinkId = getPossibleArbetsgivarinfoLinkId(user)

  useEffect(() => {
    const isAtLoginFlow = shouldShowLoginFlow(pathname)

    if (isAtLoginFlow) return // no redirect needed
    if (shouldBeAtLoginFlow) {
      history.push(getLoginUrl(user.profile?.idp, arbetsgivarinfoLinkId))
    }
  }, [
    pathname,
    history,
    arbetsgivarinfoLinkId,
    shouldBeAtLoginFlow,
    user.profile?.idp,
  ])
}

export default useRedirectToLogin
