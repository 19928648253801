import LoginFlowStartPage from './LoginFlowStartPage'
import ValidateTjanstemanPage from './ValidateTjanstemanPage'
import SelectWorkSituationPage from './SelectWorkSituationPage'
import SelectCompanyPage from './SelectCompanyPage'
import ValidateCompanyPage from './ValidateCompanyPage'
import AddEmailPage from './AddEmailPage'
import LoadCollectumPage from './LoadCollectumPage'
import ValidateArbetsgivarinfoPage from './ValidateArbetsgivarinfoPage'
import { pageIds } from 'State/Slices/pageSlice/constants'
import ValidateCollectumCompanyPage from './ValidateCollectumCompanyPage'

export const pageIdMap: Record<string, () => JSX.Element> = {
  [pageIds.loginFlowStartPage]: LoginFlowStartPage,
  [pageIds.loadCollectumPage]: LoadCollectumPage,
  [pageIds.validateTjanstemanPage]: ValidateTjanstemanPage,
  [pageIds.selectCompanyPage]: SelectCompanyPage,
  [pageIds.validateCompanyPage]: ValidateCompanyPage,
  [pageIds.validateCollectumCompanyPage]: ValidateCollectumCompanyPage,
  [pageIds.selectWorkSituationPage]: SelectWorkSituationPage,
  [pageIds.addEmailPage]: AddEmailPage,
  [pageIds.validateArbetsgivarinfoPage]: ValidateArbetsgivarinfoPage,
}
