import { JSX } from 'react'
import { formatOrgNr, startDate } from '@local/Utils/Helpers'
import { getFormattedDatePeriod } from '@local/Utils/Helpers/Ansokan.helpers'
import { usePickEpiContent } from '@local/Utils/Hooks'
import { IDinaAnsokningarContent } from '@local/Views/DinaAnsokningar/DinaAnsokningar.types'
import { trrDateStrict, trrFormat } from '@local/Utils/Helpers/formatDate'
import { isOvrigSysselsattning } from '@local/Utils/Helpers/TidigareSysselsattningar.helpers'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import AnsokanCard from '@local/Components/AnsokanCard'
import { Grid2 as Grid } from '@mui/material'
import FolderList from '@local/Components/FolderList'
import Gutter from '@local/Components/Gutter'
import { TidigareSysselsattningResponseModel } from '@local/services/API/tidigareSysselsattningar/Types'
import { isAfter, subDays } from 'date-fns'

const TidigareSysselsattningListItem = ({
  tidigareSysselsattning,
}: {
  tidigareSysselsattning: TidigareSysselsattningResponseModel
}): JSX.Element => {
  const {
    tidigareSysselsattningar: {
      tidigareSysselsattning: content,
      tidigareSysselsattningar,
    },
  } = usePickEpiContent<IDinaAnsokningarContent>()

  const anstallningsPeriodText = getFormattedDatePeriod(
    tidigareSysselsattning.sysselsattningStart,
    tidigareSysselsattning.sysselsattningSlut,
    content.anstallningsperiodSeparator,
    content.ejFaststalltSistaDatum
  )

  const cardTitle = isOvrigSysselsattning(
    tidigareSysselsattning.kompletteringstyp
  )
    ? tidigareSysselsattningar[tidigareSysselsattning.kompletteringstyp]
    : `${
        tidigareSysselsattningar[tidigareSysselsattning.kompletteringstyp]
      } - ${tidigareSysselsattning.anstallning?.foretagsnamn}`

  const cardSubtitle =
    !isOvrigSysselsattning(tidigareSysselsattning.kompletteringstyp) &&
    formatOrgNr(tidigareSysselsattning.anstallning?.organisationsnummer)

  return (
    <article aria-label="Tidigare sysselsättning">
      <AnsokanCard title={cardTitle} subtitle={cardSubtitle}>
        <Grid container>
          <Grid size={{ xs: 12, sm: 6, md: 4 }}>
            <FolderList
              title={content.inskickad}
              subtitle={
                isAfter(
                  subDays(
                    trrDateStrict(tidigareSysselsattning.inskickadDatum),
                    1
                  ),
                  startDate
                )
                  ? trrFormat(tidigareSysselsattning.inskickadDatum)
                  : null
              }
              icon={<CheckCircleIcon />}
            />
          </Grid>

          <Grid size={{ xs: 12, sm: 6, md: 4 }}>
            <FolderList
              title={content.anstallningsperiod}
              subtitle={anstallningsPeriodText}
              icon={<CalendarMonthIcon />}
            />
          </Grid>
        </Grid>
      </AnsokanCard>
      <Gutter offset_xs={16} />
    </article>
  )
}

export default TidigareSysselsattningListItem
