import { ButtonRow, CreatingAccountLoader, PageTitle } from 'Components'
import { Typography, Button, Box } from '@mui/material'

import { useGetCompanyQuery } from 'State/Api/Company'
import { useSliceDispatch, useSliceStateSelector } from 'State/Slices'
import { useCreateUserMutation } from 'State/Api/User'
import { loginFlowRefreshUserToken } from '@trr/app-shell-communication'
import useTexts from 'Utils/Hooks/useTexts'
import texts from './texts'

const ValidateCompanyPage = () => {
  const triggerDispatch = useSliceDispatch()
  const { organizationNumber, workSituation, userType } = useSliceStateSelector(
    (state) => state.slices.inputs
  )
  const { data: company } = useGetCompanyQuery({
    organizationNumber,
    workSituation,
  })

  const t = useTexts(texts)

  const [triggerCreateUser, { isLoading, isSuccess }] = useCreateUserMutation()

  const handleCreateUser = () => {
    void triggerCreateUser({
      workSituation,
      companyId: company.id,
      orgNumber: company.organizationNumber,
      workRole: [],
    }).then(({ error }) => {
      if (!error) {
        void loginFlowRefreshUserToken()
        triggerDispatch('goToPage', { direction: 'next', userType: userType })
      }
    })
  }

  const handleGoBack = () => {
    triggerDispatch('setOrganizationNumber', undefined)
    triggerDispatch('goToPage', { direction: 'prev', userType: userType })
  }

  const showLoading = isLoading || isSuccess

  if (showLoading) {
    return <CreatingAccountLoader testSelector={'LoadingElement'} />
  }

  if (company) {
    return (
      <Box data-testid="organizationFound">
        <PageTitle>{t.foundStateHeader}</PageTitle>

        <Typography variant={'body2'} mb={5}>
          {t.foundStateBody}
        </Typography>

        <ButtonRow marginTop={6}>
          <Button
            sx={{ marginX: 1 }}
            variant="contained"
            onClick={handleCreateUser}
            data-testid="forward-button"
          >
            {t.foundStateContinueText}
          </Button>
          <Button
            sx={{ marginX: 1 }}
            data-testid="goBackButton"
            onClick={handleGoBack}
            variant="outlined"
          >
            {t.foundStateGoBackText}
          </Button>
        </ButtonRow>
      </Box>
    )
  }
  return (
    <div data-testid="OrganizationNotFound">
      <PageTitle>{t.failedStateHeader}</PageTitle>

      <Typography variant={'body2'} mb={5}>
        {t.failedStateBody}
      </Typography>

      <Typography variant={'body2'} mb={5}>
        {t.failedStateGdprInfo}
      </Typography>
      <Button
        data-testid="goBackButton"
        onClick={handleGoBack}
        variant="outlined"
      >
        {t.failedStateGoBackText}
      </Button>
    </div>
  )
}

export default ValidateCompanyPage
