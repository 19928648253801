import { formatDateTime } from '@local/src/Utils/dateFormatHelper'
import { Button, Stack, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { useBegaranYttrandeKommentar } from '../../Hooks/useBegaranYttrandeKommentar'

interface Props {
	referenceId: string
	setIsEditing: React.Dispatch<React.SetStateAction<boolean>>
}
const LatestKommentar = ({ referenceId, setIsEditing }: Props) => {
	const { t } = useTranslation('translation', { keyPrefix: 'begaranYttrande.info.begaranYttrandeKommentar' })
	const { latestKommentar, userHasValidRole } = useBegaranYttrandeKommentar(referenceId)

	const lastUpdatedByText = latestKommentar
		? t('lastUpdatedBy', {
				lastUpdatedAt: formatDateTime(latestKommentar?.lastUpdatedBy?.lastUpdatedAt),
				firstName: latestKommentar?.lastUpdatedBy?.firstName,
				lastName: latestKommentar?.lastUpdatedBy?.lastName,
			})
		: null

	return (
		<Stack data-testid="latest-kommentar" spacing={1}>
			<Typography fontWeight="bold">{t('title')}</Typography>
			<Typography fontStyle="italic" sx={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>
				{latestKommentar?.content}
			</Typography>
			{lastUpdatedByText && (
				<Typography mt={1} fontStyle="italic" variant="subtitle2" color="text.secondary">
					{lastUpdatedByText}
				</Typography>
			)}
			{userHasValidRole && (
				<Button size="small" onClick={() => setIsEditing(true)}>
					{t('editButtonText')}
				</Button>
			)}
		</Stack>
	)
}

export default LatestKommentar
