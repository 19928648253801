import { callbackUrl } from 'features/Authentication/constants'

const port = window.location.port ? `:${window.location.port}` : ''
const currentWindow = `${window.location.protocol}//${window.location.hostname}${port}`

export const getUserManagerSilentRedirectUri = (): string => {
  const azStorageFilePath = '/public-web/'
  const localFilePath = '/'
  const filePath =
    process.env.NODE_ENV === 'production' ? azStorageFilePath : localFilePath
  return `${currentWindow}${filePath}silent_renew.html`
}

export const userManagerRedirectUri = `${currentWindow}${callbackUrl}`
