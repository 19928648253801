import { isFuture, isValid, parse } from 'date-fns'
import { sv } from 'date-fns/locale'
import * as yup from 'yup'

import { RaknesnurraFormData } from '../Models/RaknesnurraForm.types'
import { RaknesnurraEpiContent } from '../StudieersattningRaknesnurraForm.content'

export const RaknesnurraFormSchema = (content: RaknesnurraEpiContent): yup.SchemaOf<RaknesnurraFormData> =>
    yup.object({
        fodelsedatum: yup
            .number()
            .nullable()
            .required(content.validationRequiredFodelsedatum)
            .test('Korrekt födelsedatum', content.validationRequiredFodelsedatum, (value: number) => {
                if (!value) {
                    return false
                }
                const fodelsedatum = value.toString()
                const allIntegers = new RegExp('^[0-9]{8}$')
                if (!allIntegers.test(fodelsedatum)) {
                    return false
                }
                const parsedDate = parse(fodelsedatum.toString(), 'yyyyMMdd', new Date(), { locale: sv })
                return isValid(parsedDate) && !isFuture(parsedDate)
            }),
        loneniva: yup.number().nullable().required(content.validationRequiredLoneniva).positive(content.validationPositiveLoneniva),
        studietakt: yup.number().nullable().required(content.validationRequiredStudietakt),
        beraknadStudiestart: yup
            .date()
            .nullable()
            .required(content.validationRequiredBeraknadStudiestart)
            .typeError(content.validationRequiredBeraknadStudiestart),
        antalKalenderhalvar: yup.number(),
        veckorKalenderHalvar1: yup
            .number()
            .nullable()
            .required(content.validationRequiredAntalVeckor)
            .positive(content.validationRequiredAntalVeckor)
            .min(1)
            .max(26),
        veckorKalenderHalvar2: yup
            .number()
            .nullable()
            .when('antalKalenderhalvar', {
                is: (antalKalenderhalvar: number) => antalKalenderhalvar > 1,
                then: (schema) =>
                    schema.required(content.validationRequiredAntalVeckor).positive(content.validationRequiredAntalVeckor).min(1).max(26),
            }),
        veckorKalenderHalvar3: yup
            .number()
            .nullable()
            .when('antalKalenderhalvar', {
                is: (antalKalenderhalvar: number) => antalKalenderhalvar > 2,
                then: (schema) =>
                    schema.required(content.validationRequiredAntalVeckor).min(1).max(26).positive(content.validationRequiredAntalVeckor),
            }),
        veckorKalenderHalvar4: yup
            .number()
            .min(1)
            .max(26)
            .nullable()
            .when('antalKalenderhalvar', {
                is: (antalKalenderhalvar: number) => antalKalenderhalvar > 3,
                then: (schema) =>
                    schema.required(content.validationRequiredAntalVeckor).min(1).max(26).positive(content.validationRequiredAntalVeckor),
            }),
    })
