import { formatDateTime } from '@local/src/Utils/dateFormatHelper'
import { Button, FormHelperText, Stack, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { ConfirmActionDialog } from '@local/src/Components'
import { useTranslation } from 'react-i18next'
import { useCommonTranslation } from '@local/src/Hooks'
import { useBegaranYttrandeKommentar } from '@local/src/Pages/BegaranYttrande/Components/YttrandeInfo/Components/YttrandeFranCsnInfo/Components/BegaranYttrandeKommentar/Hooks/useBegaranYttrandeKommentar'

interface Props {
	isEditing: boolean
	setIsEditing: React.Dispatch<React.SetStateAction<boolean>>
	referenceId: string
}

const KommentarInput = ({ isEditing, setIsEditing, referenceId }: Props) => {
	const { t } = useTranslation('translation', { keyPrefix: 'begaranYttrande.info.begaranYttrandeKommentar' })
	const { tCommon } = useCommonTranslation()

	const [draftKommentar, setDraftKommentar] = useState('')
	const initialValidationErrorState = { isError: false, errorText: '' }
	const [validationError, setValidationError] = useState(initialValidationErrorState)
	const [showDeleteModal, setShowDeleteModal] = useState(false)

	const resetValidationError = () => setValidationError(initialValidationErrorState)

	const { latestKommentar, createKommentar, updateKommentar, deleteKommentar } = useBegaranYttrandeKommentar(referenceId)

	const latestKommentarId = latestKommentar?.id

	const handleOnChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (e) => {
		const { value } = e.target

		setDraftKommentar(value)

		if (value.trim().length > 500) {
			setValidationError({ isError: true, errorText: t('maxLengthValidationErrorText') })
		} else resetValidationError()
	}

	const handleSave = () => {
		if (validationError.isError) return

		const trimmedKommentar = draftKommentar.trim()

		if (!trimmedKommentar) {
			setValidationError({ isError: true, errorText: t('emptyValidationErrorText') })
			return
		}

		if (latestKommentar) {
			updateKommentar({ content: trimmedKommentar, referenceType: 'BegaranYttrande', kommentarId: latestKommentarId })
		} else {
			createKommentar({ content: trimmedKommentar, referenceType: 'BegaranYttrande' })
		}
		setIsEditing(false)
	}

	const handleShowDeleteModal = () => setShowDeleteModal(true)

	const handleDelete = () => {
		if (latestKommentar) {
			deleteKommentar({ kommentarId: latestKommentarId })
		}
		setIsEditing(false)
		setShowDeleteModal(false)
		resetValidationError()
	}

	const handleUndo = () => {
		setDraftKommentar(latestKommentar?.content ?? '')
		setIsEditing(false)
		resetValidationError()
	}

	useEffect(() => {
		setDraftKommentar(latestKommentar?.content ?? '')
	}, [latestKommentar])

	const lastUpdatedByText = latestKommentar
		? t('lastUpdatedBy', {
				lastUpdatedAt: formatDateTime(latestKommentar?.lastUpdatedBy?.lastUpdatedAt),
				firstName: latestKommentar?.lastUpdatedBy?.firstName,
				lastName: latestKommentar?.lastUpdatedBy?.lastName,
			})
		: null

	return (
		<Stack spacing={1} data-testid="kommentar-input">
			<Stack>
				<Stack>
					<TextField
						type="text"
						label="Kommentar"
						fullWidth
						disabled={!isEditing}
						value={draftKommentar}
						error={validationError.isError}
						onChange={handleOnChange}
						multiline
						minRows={3}
						maxRows={5}
					/>

					{validationError.isError && <FormHelperText error>{validationError.errorText}</FormHelperText>}
				</Stack>
				{lastUpdatedByText && (
					<Typography mt={1} fontStyle="italic" variant="subtitle2" color="text.secondary">
						{lastUpdatedByText}
					</Typography>
				)}
			</Stack>

			<Stack direction="row" justifyContent="space-between">
				<Stack direction="row" spacing={2}>
					<Button size="small" onClick={handleSave}>
						{t('saveButtonText')}
					</Button>
					<Button size="small" onClick={handleUndo}>
						{tCommon('buttons.cancel')}
					</Button>
				</Stack>
				<Button size="small" onClick={handleShowDeleteModal}>
					{t('deleteButtonText')}
				</Button>
			</Stack>

			<ConfirmActionDialog
				content={t('deleteDialogContentText')}
				show={showDeleteModal}
				setShow={setShowDeleteModal}
				onConfirm={handleDelete}
			/>
		</Stack>
	)
}

export default KommentarInput
