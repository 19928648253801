import { useState } from 'react'
import {
  CardContent,
  Stack,
  Typography,
  CardActions,
  Button,
} from '@mui/material'
import { useEpiCustomDictionary } from '@trr/app-shell-data'
import { HiddenText } from 'Components/HiddenText'

import {
  IContactPerson,
  ContactStatus,
} from '../../../types/IContactPerson.types'
import useDeviceSize from '../../../utils/hooks/useDeviceSize'

import PersonCardActionButton from './PersonCardActionButton'
import AddFeedbackModal from './AddFeedbackModal'
import PersonCardAccordion from './PersonCardAccordion'

interface IPersonCardProps {
  person: IContactPerson
}

const PersonCard = ({ person }: IPersonCardProps) => {
  const epi = useEpiCustomDictionary(['innehall'])
  const [addFeedbackModalOpen, setAddFeedbackModalOpen] = useState(false)

  const { smallScreensUp } = useDeviceSize()

  if (person.status === ContactStatus.Completed)
    return <PersonCardAccordion person={person} />
  return (
    <>
      <AddFeedbackModal
        name={person.name}
        id={person.id}
        closeForm={() => {
          setAddFeedbackModalOpen(false)
        }}
        open={addFeedbackModalOpen}
      />

      <CardContent sx={{ p: smallScreensUp ? 3 : 2 }}>
        <Stack
          alignItems="center"
          justifyContent="space-between"
          direction="row"
        >
          <Stack>
            <Typography variant="h6" component="h2">
              {person.name}
            </Typography>
            <HiddenText>{epi('incomplete')}</HiddenText>
          </Stack>
          <Stack
            alignItems="center"
            justifyContent="space-between"
            direction="row"
          >
            <PersonCardActionButton person={person} />
          </Stack>
        </Stack>

        <Typography sx={{ mt: 2 }} gutterBottom variant="body1">
          {epi('awaitingFeedbackIngress')}
        </Typography>
      </CardContent>
      <CardActions>
        <Button
          onClick={() => {
            setAddFeedbackModalOpen(true)
          }}
          variant="text"
          sx={{ padding: 2 }}
        >
          {epi('addFeedbackButtonLabel')}
        </Button>
      </CardActions>
    </>
  )
}

export default PersonCard
