import {
  Card,
  CardContent,
  CardMedia,
  Stack,
  styled,
  Typography,
} from '@mui/material'
import {
  createImageAltTextFromFileName,
  getFullImageUrl,
  LazyLoadWrapper,
} from '@trr/internal-helpers'
import { getBackgroundColor } from 'apps/Site/hooks/getBackgroundColor'
import userInteractionsApi from 'PublicWebApi/UserInteractions/api'
import { useEffect, useState } from 'react'
import { useSliceStateSelector } from 'slices/hooks'
import ActionButtons from './ActionButtons'

const mediaUrl = window.APP_CONFIGURATION.COMMON.MEDIA_URL

interface StyledCardProps {
  backgroundColor: string
}

const StartHereCard = styled(Card, {
  shouldForwardProp: (prop: string) => prop != 'backgroundColor',
})<StyledCardProps>(({ theme, backgroundColor }) => ({
  backgroundColor: getBackgroundColor(theme, backgroundColor, true),
  padding: theme.spacing(3),
  borderWidth: 0,
  borderRadius: '16px',
  '.LazyLoadWrapper': {
    marginTop: 'auto',
  },
}))

export interface StartHereCardBlockProps {
  properties: {
    color: string
    heading: string
    image: string
    link: { text: string; url: string }
    text: string
  }
  marginTop: number | string
}

const StartHereCardBlock = ({
  properties: { color, heading, image, link, text },
  marginTop,
}: StartHereCardBlockProps) => {
  const [isClosed, setIsClosed] = useState(true)
  const { isMobile } = useSliceStateSelector(
    (state) => state.slices.device.deviceSize
  )

  const { isSuccess, currentData } =
    userInteractionsApi.endpoints.getUserInteractions.useQuery()
  const [postUserInteractions] =
    userInteractionsApi.endpoints.postUserInteractions.useMutation()

  const cardInteractionId = `closedStartHereCard-${link.url}`

  useEffect(() => {
    if (!isSuccess || !currentData?.interactions) return

    const hasClosedCard = currentData.interactions.some(
      (x) => x.id === cardInteractionId
    )
    setIsClosed(hasClosedCard)
  }, [isSuccess, currentData, cardInteractionId])

  const onClickClose = () => {
    setIsClosed(true)
    void postUserInteractions({
      interactions: [{ id: cardInteractionId }],
    })
  }

  return (
    !isClosed && (
      <StartHereCard backgroundColor={color} sx={{ marginTop: marginTop }}>
        <CardContent
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: 0,
            marginBottom: 3,
            gap: 5,
          }}
        >
          <Stack gap={1}>
            <Typography component="h2" variant="h5">
              {heading}
            </Typography>
            <Typography>{text}</Typography>
          </Stack>
          {image && !isMobile && (
            <LazyLoadWrapper className="LazyLoadWrapper">
              <CardMedia
                data-testid={'card-image'}
                component="img"
                image={getFullImageUrl(mediaUrl, image, 375)}
                alt={createImageAltTextFromFileName(image)}
                sx={{ width: '152px' }}
              />
            </LazyLoadWrapper>
          )}
        </CardContent>
        <ActionButtons
          text={link.text}
          url={link.url}
          isMobile={isMobile}
          onClick={onClickClose}
        />
      </StartHereCard>
    )
  )
}

export default StartHereCardBlock
