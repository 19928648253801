import { Theme } from '@mui/material'

export const getDatagridDefaultStyles = (
  theme: Theme,
  isMobile: boolean,
  removeBorder?: boolean
) => ({
  border: removeBorder && 0,
  '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
    outline: 'none !important',
  },
  '&.MuiDataGrid-root .MuiDataGrid-cell': {
    padding: isMobile ? 0 : undefined,
  },
  '& .MuiDataGrid-columnHeader': {
    padding: isMobile ? 0 : undefined,
  },
  '& .MuiDataGrid-columnHeaderTitleContainer': {
    flexDirection: 'initial !important',
  },
  '& .MuiDataGrid-row--detailPanelExpanded': {
    background: theme.palette?.surface?.grey,
  },
  '& .MuiDataGrid-row--detailPanelExpanded > .MuiDataGrid-cell': {
    borderBottom: 'none',
  },
  '& .MuiDataGrid-detailPanel': {
    background: theme.palette?.surface?.grey,
  },
  '& .handleArbetsuppgiftMenuHeader': {
    padding: 0,
  },
  '& .handleArbetsuppgiftMenuCell': {
    padding: 0,
  },
  '& .statusHeader .MuiDataGrid-columnHeaderTitleContainer > .MuiDataGrid-columnHeaderTitleContainerContent':
    {
      marginLeft: 'auto',
    },
})
