import { useEffect } from 'react'
import CookieBanner from 'features/CookieBanner'
import { Box } from '@mui/material'
import { useLoginFlowEventListeners } from './Hooks'
import MicroFrontendLoader from 'features/SystemLoaders/MicroFrontendLoader'
import { useApplicationInsightsEventListener } from 'features/Telemetry/ApplicationInsights'
import { useSliceStateSelector } from 'slices/hooks'

const LoginFlow = () => {
  const oidcUser = useSliceStateSelector((state) => state.slices.auth)
  useLoginFlowEventListeners()
  useApplicationInsightsEventListener()

  // Zendesk chat is hidden based on current page contentType,
  // Loginflow does not request new information from epi, so we explicitly
  // hide it here as well.
  useEffect(() => {
    window?.zE?.('messenger', 'hide')
  }, [])

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        flex: '1 1 auto',
        flexFlow: 'column nowrap',
        width: '100%',
      }}
    >
      <MicroFrontendLoader
        currentKey={'loginflow'}
        functionType={'loginflow'}
        oidcUser={oidcUser}
      />
      <CookieBanner />
    </Box>
  )
}

export default LoginFlow
