import { Box, Grid2 as Grid, Typography } from '@mui/material'
import { ContentProps as blockAreaProps } from 'apps/Site/components/CollectionPageCards/CollectionCard.types'
import SmallCard from 'apps/Site/components/CollectionPageCards/SmallCard/SmallCard'
import { HeadingAndPreambleWrapper } from '../PrimaryContentBlock/PrimaryContentBlock'

export interface SecondaryContentBlockProps {
  content: {
    heading: string
    text: string
    blockArea: blockAreaProps[]
    mediaUrl?: string
    fallbackImage?: string
  }
}

const SecondaryContentBlock = ({
  content: { heading, text, blockArea, mediaUrl, fallbackImage },
}: SecondaryContentBlockProps) => {
  return (
    blockArea?.length > 0 && (
      <Box
        display="flex"
        flexDirection="column"
        data-testid="secondary-content-block"
      >
        <HeadingAndPreambleWrapper>
          {heading && (
            <Typography variant="h3" component="h2" mb={1}>
              {heading}
            </Typography>
          )}

          {text && (
            <Typography variant="body1" color="text.secondary">
              {text}
            </Typography>
          )}
        </HeadingAndPreambleWrapper>

        <Grid container spacing={3}>
          {blockArea.map((card, index) => (
            <Grid size={{ xs: 12, lg: 6 }} key={`card-${index.toString()}`}>
              <SmallCard
                cardContent={card}
                mediaUrl={mediaUrl}
                fallbackImage={fallbackImage}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    )
  )
}

export default SecondaryContentBlock
