import { FormControlLabel, Radio } from '@mui/material'

interface RadioButtonProps {
  checked: boolean
  label: string
  testId?: string
  onClick: () => void
  keyProp: string
}

const RadioButton = ({
  checked,
  label,
  testId,
  onClick,
  keyProp,
}: RadioButtonProps) => {
  return (
    <FormControlLabel
      key={keyProp}
      role="radio"
      aria-checked={checked}
      data-testid={testId}
      checked={checked}
      control={<Radio />}
      label={label}
      onClick={onClick}
    />
  )
}

export default RadioButton
