import { Checkbox } from '@local/src/components/FormComponents'
import { useGetCurrentArende, useGetCurrentStudiestodansokan } from '@local/src/features/StudiestodAnsokan/Hooks'
import { OmstallningsinsatsTypApiModel } from '@local/src/services/SwaggerApi/data-contracts'
import { ArendeTyp } from '@local/src/@types/Arenden.types'
import { getDefaultFormData } from '@local/src/features/StudiestodAnsokan/Components/Steps/RadgivarensUnderlag/Helpers'
import React, { useState, ChangeEvent, Fragment, useMemo } from 'react'
import { Path } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Grid2 as Grid, Stack, Typography, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { useIsFeatureEnabled } from '@trr/app-shell-data'

import { useRadgivarensUnderlagFormContext } from '../../../../Hooks'
import {
    getStudiestodTypPath,
    handleClearStudiestod,
    handleClearStudiestodV2,
    handleResetStudiestod,
    handleResetStudiestodV2,
    StudiestodType,
} from '../../../../Helpers/studiestodHelper'

interface Props {
    utbildningsIndex: number
    isReadonly: boolean
}

const ValjStod = ({ utbildningsIndex, isReadonly }: Props) => {
    const {
        formState: { errors },
        watch,
        setValue,
        resetField,
    } = useRadgivarensUnderlagFormContext()

    const { t } = useTranslation('translation', { keyPrefix: 'studiestodAnsokan.steps.radgivarensUnderlag.valjStod' })
    const { t: tModal } = useTranslation('translation', { keyPrefix: 'studiestodAnsokan.steps.radgivarensUnderlag.confirmDeleteStod' })
    const { studiestodAnsokan } = useGetCurrentStudiestodansokan()
    const { arende } = useGetCurrentArende()
    const featureRemoveStudielonEnabled = useIsFeatureEnabled('Medarbetare-Studier-Frontend-removeStudielon_temp_250107')
    const currentUtbildning = watch(`utbildningar.${utbildningsIndex}`)
    const isOmstallningsArende = arende?.arendeTyp === ArendeTyp.Omstallning
    const studiestodTypPath = `utbildningar.${utbildningsIndex}.studiestodTyp`
    const omstallningsinsatsTypPath = `utbildningar.${utbildningsIndex}.omstallningsinsatsTyp`

    const defaultFormData = useMemo(
        () => getDefaultFormData(studiestodAnsokan, featureRemoveStudielonEnabled),
        [studiestodAnsokan, featureRemoveStudielonEnabled]
    )
    const getOmstallningsinsatsTypPath = (path: Path<OmstallningsinsatsTypApiModel>) => `${omstallningsinsatsTypPath}.${path}`

    const [showModal, setShowModal] = useState<boolean>(false)
    const [studiestodToDelete, setStudiestodToDelete] = useState<StudiestodType>()
    const isBeforeForstaITioende = studiestodAnsokan.isLegacyUnderlag
    const isAfterForstaITioende = !isBeforeForstaITioende
    const showKoptUtbildning = isOmstallningsArende || defaultFormData.utbildningar[utbildningsIndex].studiestodTyp.hasKoptUtbildning

    const handleCheckboxClicked = (e: ChangeEvent<HTMLInputElement>, studiestodType: StudiestodType) => {
        const studiestodTypePath = getStudiestodTypPath(utbildningsIndex, studiestodType)
        if (e.target.checked === true) {
            const defaultUtb = defaultFormData.utbildningar[utbildningsIndex]

            featureRemoveStudielonEnabled
                ? handleResetStudiestodV2(utbildningsIndex, studiestodType, resetField)
                : handleResetStudiestod(utbildningsIndex, studiestodType, currentUtbildning, defaultUtb, resetField)
            setValue(studiestodTypePath, true)
        } else {
            e.preventDefault()
            setStudiestodToDelete(studiestodType)
            setShowModal(true)
        }
    }

    const handleConfirmDelete = () => {
        featureRemoveStudielonEnabled
            ? handleClearStudiestodV2(utbildningsIndex, studiestodToDelete, setValue)
            : handleClearStudiestod(utbildningsIndex, studiestodToDelete, currentUtbildning, setValue)
        const studiestodTypePath = getStudiestodTypPath(utbildningsIndex, studiestodToDelete)
        setValue(studiestodTypePath, false)
        setShowModal(false)
    }
    const handleCancelDelete = () => setShowModal(false)

    const hasStudielon = studiestodAnsokan?.radgivarunderlag?.utbildningar?.find(
        (u) => u?.utbildning?.id === currentUtbildning?.utbildningId
    )?.studiestodTyp?.hasStudielon

    const showStudielonCheckbox = (featureRemoveStudielonEnabled && hasStudielon) || !featureRemoveStudielonEnabled

    return (
        <Stack spacing={1} data-testid={studiestodTypPath}>
            <Grid container spacing={1}>
                <Grid size={{ xs: 6 }} container direction="column">
                    <Grid>
                        <Typography variant="subtitle1">{t('studiestodHeader')}</Typography>
                    </Grid>
                    {isAfterForstaITioende && (
                        <Fragment>
                            <Grid>
                                <Checkbox
                                    label={t('kompletterandeStudiestodLabel')}
                                    name={getStudiestodTypPath(utbildningsIndex, 'hasKompletterandeStudiestod')}
                                    disabled={isReadonly}
                                    onChange={(e) => handleCheckboxClicked(e, 'hasKompletterandeStudiestod')}
                                />
                            </Grid>
                            <Grid>
                                <Checkbox
                                    label={t('kortvarigtStudiestodLabel')}
                                    name={getStudiestodTypPath(utbildningsIndex, 'hasKortvarigtStudiestod')}
                                    disabled={isReadonly}
                                    onChange={(e) => handleCheckboxClicked(e, 'hasKortvarigtStudiestod')}
                                />
                            </Grid>
                            {showKoptUtbildning && (
                                <Grid>
                                    <Checkbox
                                        label={t('koptUtbildningLabel')}
                                        name={getStudiestodTypPath(utbildningsIndex, 'hasKoptUtbildning')}
                                        disabled={isReadonly}
                                        onChange={(e) => handleCheckboxClicked(e, 'hasKoptUtbildning')}
                                    />
                                </Grid>
                            )}
                        </Fragment>
                    )}
                    {isOmstallningsArende && (
                        <Fragment>
                            <Grid>
                                <Checkbox
                                    disabled={isReadonly}
                                    label={t('studieersattningLabel')}
                                    name={getStudiestodTypPath(utbildningsIndex, 'hasStudieersattning')}
                                    onChange={(e) => handleCheckboxClicked(e, 'hasStudieersattning')}
                                />
                            </Grid>

                            {showStudielonCheckbox && (
                                <Grid data-testId="studielon-checkbox">
                                    <Checkbox
                                        disabled={isReadonly || featureRemoveStudielonEnabled}
                                        label={t('studielonLabel')}
                                        name={getStudiestodTypPath(utbildningsIndex, 'hasStudielon')}
                                        onChange={(e) => handleCheckboxClicked(e, 'hasStudielon')}
                                    />
                                </Grid>
                            )}
                        </Fragment>
                    )}
                </Grid>

                {isOmstallningsArende && (
                    <Grid size={{ xs: 6 }} container direction="column">
                        <Grid>
                            <Typography variant="subtitle1">{t('ovrigaInsatserHeader')}</Typography>
                        </Grid>
                        <Grid>
                            <Checkbox
                                disabled={isReadonly}
                                label={t('utbildningskostnaderLabel')}
                                name={getOmstallningsinsatsTypPath('hasUtbildningskostnader')}
                            />
                        </Grid>
                        <Grid>
                            <Checkbox
                                disabled={isReadonly}
                                label={t('enstakaResorLabel')}
                                name={getOmstallningsinsatsTypPath('hasEnstakaResor')}
                            />
                        </Grid>
                        <Grid>
                            <Checkbox
                                disabled={isReadonly}
                                label={t('regelbundnaResorLabel')}
                                name={getOmstallningsinsatsTypPath('hasRegelbundnaResor')}
                            />
                        </Grid>
                        <Grid>
                            <Checkbox disabled={isReadonly} label={t('logiLabel')} name={getOmstallningsinsatsTypPath('hasLogi')} />
                        </Grid>
                        <Grid>
                            <Checkbox
                                disabled={isReadonly}
                                label={t('programvarorLabel')}
                                name={getOmstallningsinsatsTypPath('hasProgramvaror')}
                            />
                        </Grid>
                    </Grid>
                )}
            </Grid>
            <Typography color="error" data-testid={`${studiestodTypPath}-error`}>
                {errors?.utbildningar?.[utbildningsIndex]?.studiestodTyp?.message}
            </Typography>
            <Dialog open={showModal} data-testid="modal-studiestodstyp">
                <DialogTitle>{tModal('header')}</DialogTitle>
                <DialogContent>{tModal('description')}</DialogContent>
                <DialogActions>
                    <Button data-testid="dialog--cancel-button" onClick={handleCancelDelete} variant="outlined">
                        {tModal('cancel')}
                    </Button>
                    <Button data-testid="dialog--confirm-button" onClick={handleConfirmDelete} variant="contained">
                        {tModal('confirm')}
                    </Button>
                </DialogActions>
            </Dialog>
        </Stack>
    )
}

export default ValjStod
