import { Box, Stack } from '@mui/material'
import {
  DataGridPro,
  DataGridProProps,
  GridCallbackDetails,
  GridColDef,
  GridColumnOrderChangeParams,
  GridColumnResizeParams,
  GridColumnVisibilityModel,
  GridPaginationModel,
  GridPinnedRowsProp,
  GridRowParams,
  GridSortModel,
  useGridApiRef,
} from '@mui/x-data-grid-pro'
import React, { useEffect } from 'react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import ArrowDropDownRounded from '@mui/icons-material/ArrowDropDownRounded'

import EmptyResult from '../EmptyResult/EmptyResult'
import Loading from '../Loading/Loading'

import { getDatagridDefaultStyles } from './defaultStyling'

interface DataGridWrapperProps extends DataGridProProps {
  columns: GridColDef<unknown>[]
  rows: unknown[]
  getRowId: (row: unknown) => string
  pagination?: boolean
  columnVisibilityModel?: GridColumnVisibilityModel
  paginationModel?: GridPaginationModel
  sortModel?: GridSortModel
  getDetailPanelContent?: (params: GridRowParams<unknown>) => React.ReactNode
  onSortModelChange?: (
    model: GridSortModel,
    details: GridCallbackDetails
  ) => void
  onPaginationModelChange?: (
    model: GridPaginationModel,
    details: GridCallbackDetails
  ) => void
  onColumnOrderChanged?: (columnOrder: string[]) => void
  onColumnVisibilityModelChange?: (model: GridColumnVisibilityModel) => void
  onColumnResized?: (columns: { field: string; width: number }[]) => void
  isMobile?: boolean
  rowCount?: number
  loading?: boolean
  removeBorder?: boolean
  labelRowsPerPage?: string
  customColumnSizes?: { field: string; width: number }[]
  enableColumnResize?: boolean
  pinnedRows?: GridPinnedRowsProp
  emptyResultHeader?: string
  emptyResultDescription?: string
  maxHeight?: string
  columnOrder?: string[]
  paginationMode?: 'client' | 'server'
  sortingMode?: 'client' | 'server'
}

const DataGridWrapper = ({
  rows,
  columns,
  pagination = true,
  columnVisibilityModel,
  paginationModel,
  sortModel,
  getRowId,
  onSortModelChange,
  onPaginationModelChange,
  getDetailPanelContent,
  onColumnVisibilityModelChange,
  onColumnOrderChanged,
  isMobile = false,
  rowCount = rows?.length,
  loading = false,
  removeBorder = false,
  customColumnSizes,
  onColumnResized,
  enableColumnResize = false,
  pinnedRows,
  emptyResultHeader = 'Inga resultat',
  emptyResultDescription = 'Din filtrering fick inga träffar.',
  labelRowsPerPage = 'Rader per sida',
  paginationMode = 'client',
  sortingMode = 'client',
  maxHeight = 'auto',
  slots,
  columnOrder = [],
}: DataGridWrapperProps) => {
  const apiRef = useGridApiRef()

  const hasPinnedRows = () => {
    if (!pinnedRows) {
      return false
    }
    return pinnedRows.top?.length > 0 || pinnedRows.bottom?.length > 0
  }

  const CustomNoRowsOverlay = () => {
    return (
      <Stack height="100%" alignItems="center" justifyContent="center">
        {!hasPinnedRows() && rows.length === 0 && (
          <EmptyResult
            heading={emptyResultHeader}
            description={emptyResultDescription}
          />
        )}
      </Stack>
    )
  }

  const CustomLoadingOverLay = () => {
    return (
      <Box
        height="100%"
        display="flex"
        justifyContent="center"
        sx={{ background: '#fff' }}
      >
        <Loading sx={{ mt: 4 }} />
      </Box>
    )
  }

  const onColumnOrderChange = (ev: GridColumnOrderChangeParams) => {
    if (columnOrder.length === 0) return
    const currentIndex = columnOrder.indexOf(ev.column.field)

    if (currentIndex === -1) {
      throw new Error('String not found in the array')
    }

    columnOrder.splice(currentIndex, 1)
    columnOrder.splice(ev.targetIndex, 0, ev.column.field)
    onColumnOrderChanged(columnOrder)
  }

  const onColumnResize = (col: GridColumnResizeParams) => {
    const column = customColumnSizes.find(
      (item) => item.field === col.colDef.field
    )
    column.width = col.colDef.width
    onColumnResized(customColumnSizes)
  }

  const getCustomColumnSizes = () => {
    const currentState = apiRef?.current?.exportState().columns.dimensions
    const customSizesMap = new Map(
      customColumnSizes?.map(({ field, width }) => [field, width])
    )
    if (currentState) {
      Object.keys(currentState).forEach((key) => {
        const customWidth = customSizesMap.get(key)
        if (customWidth !== undefined) {
          currentState[key].width = customWidth
        }
      })
      return currentState
    }
    return null
  }

  useEffect(() => {
    const columnSizes = getCustomColumnSizes()
    if (columnOrder.length > 0) {
      apiRef?.current?.setState((state) => ({
        ...state,
        columns: {
          ...state.columns,
          orderedFields: columnOrder,
        },
      }))
    }
    if (columnSizes !== null) {
      apiRef?.current?.restoreState({
        columns: {
          dimensions: columnSizes,
        },
      })
    }
  }, [apiRef, columnOrder, columns])

  return (
    <Box
      height={rows?.length > 0 ? (rows?.length < 25 ? 'auto' : maxHeight) : 600}
      maxHeight={maxHeight}
    >
      <DataGridPro
        apiRef={apiRef}
        pagination={pagination}
        hideFooterRowCount
        loading={loading}
        rows={rows || []}
        columns={columns}
        pageSizeOptions={[5, 10, 20, 50, 100]}
        pinnedRows={pinnedRows}
        getRowHeight={() => 'auto'}
        rowCount={rowCount}
        sortModel={sortModel}
        disableRowSelectionOnClick
        disableColumnMenu
        paginationMode={paginationMode}
        sortingMode={sortingMode}
        paginationModel={paginationModel}
        onColumnWidthChange={onColumnResize}
        columnVisibilityModel={columnVisibilityModel}
        onColumnOrderChange={onColumnOrderChange}
        onColumnVisibilityModelChange={onColumnVisibilityModelChange}
        getDetailPanelHeight={() => 'auto'}
        getRowId={getRowId}
        getDetailPanelContent={getDetailPanelContent}
        onSortModelChange={onSortModelChange}
        onPaginationModelChange={onPaginationModelChange}
        disableColumnResize={!enableColumnResize}
        columnBuffer={columns.length + 1} // +1 for testing purposes (can mess with rendering in headless.)
        slots={{
          noRowsOverlay: CustomNoRowsOverlay,
          noResultsOverlay: CustomNoRowsOverlay,
          detailPanelExpandIcon: KeyboardArrowDownIcon,
          detailPanelCollapseIcon: KeyboardArrowUpIcon,
          loadingOverlay: CustomLoadingOverLay,
          ...slots,
        }}
        slotProps={{
          pagination: {
            SelectProps: { IconComponent: ArrowDropDownRounded },
            labelRowsPerPage,
          },
          panel: {
            placement: 'bottom-end',
          },
        }}
        autoHeight={
          // when maxHeight is set and autoHeight is enabled, it can cause horizontal and vertical scrolls to appear
          maxHeight === 'auto' ? (rows?.length > 0 ? true : false) : false
        }
        sx={(theme) => getDatagridDefaultStyles(theme, isMobile, removeBorder)}
      />
    </Box>
  )
}

export default DataGridWrapper
