import { Grid2 as Grid } from '@mui/material'

import { ArticleListItemProps } from 'apps/Site/components/ArticleList/ArticleListItem'
import {
  TableOfContents,
  TableOfContentsBox,
} from 'apps/Site/components/TableOfContents'
import { Introduction } from 'apps/Site/Epi/Blocks'
import { ArticleVideoBlockProps } from 'apps/Site/Epi/Blocks/ArticleVideoBlock/ArticleVideoBlock'
import { IntroductionContentProps } from 'apps/Site/Epi/Blocks/Introduction/Introduction'
import { TableOfContentLink } from 'state/PublicWebApi/Content'

import DetailsBar from 'apps/Site/components/DetailsBar'
import RelatedContent from 'apps/Site/components/RelatedContent'
import ShareBar from 'apps/Site/components/ShareBar'
import ArticleTopMedia from 'apps/Site/Epi/Blocks/ArticleTopMedia'
import HTMLMapper from 'apps/Site/Epi/HTMLMapper'
import { useSliceStateSelector } from 'slices/hooks'

export interface ArticleContentProps {
  editor?: string
  introduction?: IntroductionContentProps
  tableOfContents?: TableOfContentLink[]
  customCategory?: string
  relatedContent?: ArticleListItemProps[]
  image?: string
  startPublish?: string
  videoArea?: ArticleVideoBlockProps[]
  isPublic?: boolean
}

const ArticleContent = ({
  introduction,
  tableOfContents,
  customCategory,
  image,
  editor,
  relatedContent,
  startPublish,
  videoArea,
  isPublic,
}: ArticleContentProps) => {
  const { isDesktop, isXL } = useSliceStateSelector(
    (state) => state.slices.device.deviceSize
  )
  const isDesktopOrXL = isDesktop || isXL

  return (
    <article id="parent">
      <Grid container direction={'row'}>
        <Grid size={{ xs: 12, md: 8 }}>
          <Introduction content={introduction} />
          {!isDesktopOrXL && (
            <TableOfContentsBox aria-hidden>
              <TableOfContents items={tableOfContents} />
            </TableOfContentsBox>
          )}
          <DetailsBar
            category={customCategory}
            isPublicPage={isPublic}
            {...(startPublish && { date: startPublish })}
          />

          {(videoArea?.length > 0 || image) && (
            <ArticleTopMedia
              image={image}
              videoProperties={{
                properties: { ...videoArea?.[0]?.properties },
              }}
            />
          )}

          <HTMLMapper body={editor} wrapper={'section'} />
          <ShareBar isPublicPage={isPublic} />
          {relatedContent?.length > 0 && (
            <RelatedContent relatedContent={relatedContent} />
          )}
        </Grid>
        {isDesktopOrXL && (
          <Grid size={{ md: 4 }}>
            <TableOfContentsBox aria-hidden>
              <TableOfContents items={tableOfContents} />
            </TableOfContentsBox>
          </Grid>
        )}
      </Grid>
    </article>
  )
}

export default ArticleContent
