import { kommentarApi } from '@local/src/Services/apiEndpointHelper'
import { apiGetTryCatchWrapper } from '@local/src/Services/apiRequestHelper'
import { useQuery } from '@tanstack/react-query'
import { KommentarApiModel } from '@local/src/Services/SwaggerApi/data-contracts'
import { ApiResponseModel } from '@local/src/Services/ApiResponseModel'

import { useKommentarQueryHelper } from './useKommentarQueryHelper'

export const useGetKommentar = (referenceId: string) => {
	const { getKommentarString, getKommentarQueryKey } = useKommentarQueryHelper(referenceId)

	const getKommentar = () => apiGetTryCatchWrapper(getKommentarString, kommentarApi.referenceKommentarDetail, referenceId)

	const { data, error, isError, isSuccess, ...query } = useQuery<KommentarApiModel[], Error>({
		queryKey: getKommentarQueryKey,
		queryFn: getKommentar,
		staleTime: 5000,
	})

	const getError: ApiResponseModel<void> = {
		hasError: isError,
		isSuccess,
		error: { errorMessage: error?.message, errorDetails: error?.name, status: 500 },
	}

	return {
		apiResponse: data,
		isError,
		error: getError,
		...query,
	}
}
