import {
  CreatingAccountLoader,
  RadioButtonGroup,
  RadioButton,
  PageTitle,
} from 'Components'
import { Button } from '@mui/material'

import userRoleChecks from 'Utils/Helpers/userRolesChecks'
import { useAuthentication } from '@trr/app-shell-data'
import { useSliceDispatch, useSliceStateSelector } from 'State/Slices'
import { WorkSituation } from 'State/Slices/inputSlice/types'
import { useCreateUserMutation } from 'State/Api/User'
import { loginFlowRefreshUserToken } from '@trr/app-shell-communication'
import { workSituations } from 'State/Slices/inputSlice/constants'

const SelectWorkSituationPage = () => {
  const { userRoles } = useAuthentication()
  const { isMissingWorkSituation } = userRoleChecks(userRoles)
  const triggerDispatch = useSliceDispatch()
  const setWorkSituation = (workSituation: WorkSituation) =>
    triggerDispatch('setWorkSituation', workSituation)
  const { workSituation, userType } = useSliceStateSelector(
    (state) => state.slices.inputs
  )

  const [triggerCreateUser, { isLoading, isSuccess }] = useCreateUserMutation()

  const handleCreateUser = () => {
    void triggerCreateUser({ workSituation }).then(({ error }) => {
      if (!error) {
        void loginFlowRefreshUserToken()
      }
    })
  }

  const showLoading = isLoading || isSuccess

  const nextClicked = () => {
    if (isMissingWorkSituation) {
      handleCreateUser()
    } else {
      triggerDispatch('goToPage', { direction: 'next', userType: userType })
    }
  }

  const nextEnabled = Object.values(workSituations).includes(workSituation)

  return (
    <>
      {showLoading ? (
        <CreatingAccountLoader testSelector={'LoadingElement'} />
      ) : (
        <>
          <PageTitle>Vad är din nuvarande arbetssituation?</PageTitle>
          <RadioButtonGroup displayRow={false} marginBottom={6}>
            <RadioButton
              keyProp="iAmEmployedRadioButton"
              checked={workSituation === workSituations.employed}
              label="Jag är anställd"
              testId="iAmEmployedRadioButton"
              onClick={() => setWorkSituation(workSituations.employed)}
            />
            <RadioButton
              keyProp="TIO-radio-button"
              checked={workSituation === workSituations.unemployed}
              label="Jag är uppsagd eller nyligen arbetslös"
              testId="TIO-radio-button"
              onClick={() => setWorkSituation(workSituations.unemployed)}
            />
          </RadioButtonGroup>

          <Button
            variant="contained"
            disabled={!nextEnabled}
            sx={{
              width: {
                xs: '100%',
                sm: 'auto',
              },
            }}
            data-testid="forward-button"
            onClick={nextClicked}
          >
            Fortsätt
          </Button>
        </>
      )}
    </>
  )
}

export default SelectWorkSituationPage
