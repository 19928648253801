import { JSX } from 'react'
import { AppShellDataProps } from '@trr/app-shell-data'
import { AppShellRouter, KeyRoute } from '@local/Router'
import { Routes } from '@local/Router/routes'
import OnboardingWrapper from '@local/Views/OnboardingWrapper'
import TjanstemanIAnstallning from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning'
import TidsbegransadAnstallning from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning'
import TackForDinAnsokan from '@local/Views/TackForDinAnsokan'

const Views = ({
  appShellData,
}: {
  appShellData: AppShellDataProps
}): JSX.Element => (
  <AppShellRouter currentKey={appShellData.currentKey}>
    <KeyRoute urlKey={Routes.Start}>
      <OnboardingWrapper />
    </KeyRoute>

    <KeyRoute urlKey={Routes.TiaAnsokan}>
      <TjanstemanIAnstallning />
    </KeyRoute>

    <KeyRoute urlKey={Routes.TbAnsokan}>
      <TidsbegransadAnstallning />
    </KeyRoute>

    <KeyRoute urlKey={Routes.TackForDinAnsokan}>
      <TackForDinAnsokan />
    </KeyRoute>
  </AppShellRouter>
)
export default Views
