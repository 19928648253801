import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from '@mui/material'
import React, { useState } from 'react'

interface Props {
    arendeId: string
    ansokanId: string
}

const StudiestodStudieintygV2 = ({ arendeId, ansokanId }: Props) => {
    console.log({ arendeId, ansokanId })

    return (
        <Stack>
            <div>StudiestodStudieintygV2</div>
            <div>{arendeId}</div>
            <div>{ansokanId}</div>
        </Stack>
    )
}

export default StudiestodStudieintygV2
