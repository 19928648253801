import React, { useEffect } from 'react'
import { Typography } from '@mui/material'
import {
  useSummarizeTextMutation,
  useFormatTextMutation,
} from 'src/api/formatText'
import DelayedSkeleton from 'src/components/DelayedSkeleton/DelayedSkeleton'
import { useTranslation } from 'react-i18next'
import { truncateText } from 'src/utils/helpers/truncateText'

interface EducationSummaryProps {
  text: string | undefined
  id: string | undefined
  lastEdited?: string
  showFormattedText: boolean
  isInView?: boolean
}

const EducationSummary: React.FC<EducationSummaryProps> = ({
  text,
  id,
  lastEdited,
  showFormattedText,
}) => {
  const [summerizeText, summerizeResult] = useSummarizeTextMutation()
  const [formatText, formatResult] = useFormatTextMutation()
  const { t } = useTranslation('translation', { keyPrefix: 'utbildning' })

  useEffect(() => {
    if (
      !summerizeResult.data &&
      !summerizeResult.isLoading &&
      text &&
      id &&
      showFormattedText
    ) {
      void summerizeText({
        id,
        text,
        lastUpdated: lastEdited ?? undefined,
      })
    }
  }, [text, summerizeResult, summerizeText, id, lastEdited, showFormattedText])

  useEffect(() => {
    if (!formatResult.data && !formatResult.isLoading && text && id) {
      void formatText({
        id,
        text,
        lastUpdated: lastEdited ?? undefined,
      })
    }
  }, [text, formatResult, formatText, id, lastEdited])

  if (summerizeResult.isLoading && showFormattedText) {
    return <DelayedSkeleton variant="rounded" width={'100%'} height={70} />
  }

  if (!text && !summerizeResult.isLoading) {
    return (
      <Typography data-testid="no-description" variant="body1">
        {t('noDesc')}
      </Typography>
    )
  }

  const summaryText = showFormattedText
    ? summerizeResult.data?.text
    : truncateText(text, 350, '..')

  if (summaryText) {
    return (
      <Typography gutterBottom variant="subtitle2" component="p">
        {summaryText}
      </Typography>
    )
  }

  return null
}

export default EducationSummary
