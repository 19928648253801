import React, { Fragment, useState } from 'react'
import { UseFieldArrayRemove } from 'react-hook-form'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material'
import Icons from '@local/src/components/Icons'
import { useTranslation } from 'react-i18next'
import useCommonTranslation from '@local/src/hooks/useCommonTranslation'

interface Props {
    kalenderhalvarIndex: number
    remove: UseFieldArrayRemove
}

const TaBortKalenderhalvarButton = ({ kalenderhalvarIndex, remove }: Props) => {
    const { t } = useTranslation('translation', { keyPrefix: 'studiestodAnsokan.steps.radgivarensUnderlag' })
    const { tCommon } = useCommonTranslation()

    const [showWarningDialog, setShowWarningDialog] = useState<boolean>(false)

    const handleShowConfirmDialog = () => {
        setShowWarningDialog(true)
    }

    const onConfirm = () => {
        setShowWarningDialog(false)
        remove(kalenderhalvarIndex)
    }

    const onCancel = () => {
        setShowWarningDialog(false)
    }
    const showRemoveButton = kalenderhalvarIndex > 0

    return (
        <Fragment>
            {showRemoveButton && (
                <IconButton onClick={handleShowConfirmDialog} data-testid="kalenderhalvar-remove-button">
                    <Icons.Delete size="small" color="success" />
                </IconButton>
            )}

            <Dialog open={showWarningDialog} data-testid="modal-studiestodstyp">
                <DialogTitle>{t('taBortKalenderhalvarModalHeader')}</DialogTitle>
                <DialogContent>{t('taBortKalenderhalvarModalDescription')}</DialogContent>
                <DialogActions>
                    <Button data-testid="dialog--cancel-button" onClick={onCancel} variant="outlined">
                        {tCommon('button_cancel')}
                    </Button>
                    <Button data-testid="dialog--confirm-button" onClick={onConfirm} variant="contained">
                        {tCommon('button_ok')}
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    )
}

export default TaBortKalenderhalvarButton
