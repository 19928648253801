import { Card, CardContent, styled } from '@mui/material'

export const CollectionCardWrapper = styled(Card)(({ theme }) => ({
  borderRadius: 16,
  [theme.breakpoints.up('md')]: {
    height: '100%',
  },
}))

export const CollectionCardContentWrapper = styled(CardContent)(
  ({ theme }) => ({
    padding: theme.spacing(3),
    height: '100%',
    flex: 1,
    width: '100%',
    marginBottom: 0,
    display: 'flex',
    flexDirection: 'column',
  })
)
