import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import { useTranslation } from 'react-i18next'

interface Props {
	title?: string
	content?: string
	show: boolean
	setShow: (show: boolean) => void
	onConfirm: (...params: unknown[]) => void
	testId?: string
}
const ConfirmActionDialog = ({ title, content, show, onConfirm, setShow, testId = 'confirm-action-dialog' }: Props) => {
	const { t } = useTranslation('translation', { keyPrefix: 'common.confirmAction' })

	const handleHideDialog = () => {
		setShow(false)
	}

	return (
		<Dialog data-testid={testId} open={show} fullWidth>
			<DialogTitle>{title || t('title')}</DialogTitle>
			<DialogContent>
				<DialogContentText>{content || t('content')}</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button data-testid={`${testId}-cancel-button`} onClick={handleHideDialog} variant="text">
					{t('cancel')}
				</Button>
				<Button data-testid={`${testId}-confirm-button`} onClick={onConfirm} variant="text">
					{t('confirm')}
				</Button>
			</DialogActions>
		</Dialog>
	)
}

export default ConfirmActionDialog
