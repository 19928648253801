import { ExpandMore, ContentCopy, CheckCircle } from '@mui/icons-material'
import {
  Accordion,
  Card,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Button,
} from '@mui/material'
import { useEpiContent, useEpiCustomDictionary } from '@trr/app-shell-data'
import { IPersonligSpeglingContent } from 'App/App.types'
import WysiwygEditor from 'Components/WysiwygEditor'
import { useEffect, useState } from 'react'

const FeedbackTemplateAccordion = () => {
  const [copied, setCopied] = useState(false)
  const epi = useEpiCustomDictionary(['innehall'])
  const {
    contactTemplate,
    feedbackModal: { accordionHeader },
  } = useEpiContent<IPersonligSpeglingContent>()

  const handleCopyClick = () => {
    navigator.clipboard
      .writeText(epi('templatePlain'))
      .then(() => {
        setCopied(true)
      })
      .catch((e: unknown) => {
        console.log(e)
      })
  }

  useEffect(() => {
    if (copied) {
      const timer = setTimeout(() => {
        setCopied(false)
      }, 10000)
      return () => {
        clearTimeout(timer)
      }
    }
  }, [copied])

  return (
    <>
      <Typography fontWeight={700} pb={2}>
        {accordionHeader}
      </Typography>

      <Accordion component={Card} sx={{ boxShadow: 'none', mb: 4 }}>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="mallinnehåll"
        >
          <Typography>{epi('questionTemplateHeader')}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <WysiwygEditor htmlFormattedString={contactTemplate.mainBody} />
          {navigator.clipboard && (
            <Button
              disabled={copied}
              onClick={handleCopyClick}
              startIcon={
                !copied ? <ContentCopy /> : <CheckCircle color="success" />
              }
              variant="text"
            >
              {copied ? epi('copyButtonLabelPending') : epi('copyButtonLabel')}
            </Button>
          )}
        </AccordionDetails>
      </Accordion>
    </>
  )
}

export default FeedbackTemplateAccordion
