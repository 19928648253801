import { useState } from 'react'
import useTexts from 'Utils/Hooks/useTexts'
import { Link, Button, Typography } from '@mui/material'

import { RadioButtonGroup, RadioButton, PageTitle } from '../../Components'

import texts from './texts'
import { TjanstemanDialog } from './Components'
import { useSliceDispatch } from 'State/Slices'
import { useTrackVirtualView } from '@trr/gtm-tracking'

const ValidateTjanstemanPage = () => {
  const triggerDispatch = useSliceDispatch()

  const [tjansteman, setTjansteman] = useState<boolean>()
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)

  const pageTexts = useTexts(texts)
  const trackVirtualView = useTrackVirtualView('login_wizard')

  const [showNotTjanstemanContent, setShowNotTjanstemanContent] =
    useState<boolean>(false)

  const nextClicked = () => {
    if (tjansteman) {
      triggerDispatch('goToPage', { direction: 'next', userType: 'tjansteman' })
    } else {
      setShowNotTjanstemanContent(true)
      // this used to be a page, but it didnt make sense. Now we push tracking here instead to be 1:1 with old tracking
      trackVirtualView('noTjanstemanPage')
    }
  }
  const nextEnabled = tjansteman === true || tjansteman === false
  const openDialog = () => {
    setDialogOpen(true)
  }

  if (showNotTjanstemanContent) {
    return (
      <>
        <PageTitle>{pageTexts.noSupportHeader}</PageTitle>
        <Typography
          data-testid="showNotTjanstemanContent"
          variant="body1"
          mb={5}
        >
          {pageTexts.noSupportText1}
        </Typography>
        <Typography variant="body1" mb={5}>
          {pageTexts.noSupportText2}
        </Typography>
      </>
    )
  }

  return (
    <>
      <PageTitle>{pageTexts.header}</PageTitle>
      <RadioButtonGroup displayRow={false} marginBottom={3}>
        <RadioButton
          keyProp="iAmTjansteman"
          label={pageTexts.yesLabel}
          checked={tjansteman === true}
          testId="iAmTjansteman"
          onClick={() => setTjansteman(true)}
        />
        <RadioButton
          keyProp="iAmNotTjansteman"
          label={pageTexts.noLabel}
          checked={tjansteman === false}
          testId="iAmNotTjansteman"
          onClick={() => setTjansteman(false)}
        />
      </RadioButtonGroup>

      <Link
        component="button"
        display="block"
        variant="body1"
        onClick={openDialog}
        data-testid="tjanstemanExplanationLink"
      >
        {pageTexts.tjanstemanExplanation}
      </Link>
      <Button
        data-testid="forward-button"
        variant="contained"
        onClick={nextClicked}
        disabled={!nextEnabled}
        sx={{ marginTop: 5, width: { xs: '100%', sm: 'auto' } }}
      >
        Fortsätt
      </Button>

      <TjanstemanDialog
        dialogOpen={dialogOpen}
        pageTexts={pageTexts}
        closeDialog={() => setDialogOpen(false)}
      />
    </>
  )
}

export default ValidateTjanstemanPage
