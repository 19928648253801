import { useState } from 'react'
import useTexts from 'Utils/Hooks/useTexts'
import isValidOrgNumber from 'Utils/Helpers/isValidOrgNumber'
import {
  Typography,
  TextField,
  Button,
  Box,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemButton,
} from '@mui/material'
import Launch from '@mui/icons-material/Launch'
import { CreatingAccountLoader, PageTitle } from 'Components'

import texts from './texts'
import { useLazyGetCompanyQuery } from 'State/Api/Company'
import { useSliceDispatch, useSliceStateSelector } from 'State/Slices'

const SelectCompanyPage = () => {
  const [fetchCompany, { isLoading }] = useLazyGetCompanyQuery()
  const triggerDispatch = useSliceDispatch()
  const { organizationNumber, workSituation, userType } = useSliceStateSelector(
    (state) => state.slices.inputs
  )
  const [orgFieldError, setOrgFieldError] = useState('')
  const [orgFieldHasError, setOrgFieldHasError] = useState(false)
  const [fetchCompanyHasError, setFetchCompanyHasError] = useState(false)

  const pageTexts = useTexts(texts)

  const fetchCompanyAndGotoNextPage = () => {
    if (isValidOrgNumber(organizationNumber)) {
      void fetchCompany({ organizationNumber, workSituation }).then(
        ({ error }) => {
          if (!error) {
            triggerDispatch('goToPage', {
              direction: 'next',
              userType: userType,
            })
            return
          }
          setFetchCompanyHasError(true)
        }
      )
    } else {
      setOrgFieldError(pageTexts.orgNumSyntaxError)
      setOrgFieldHasError(true)
    }
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    triggerDispatch('setOrganizationNumber', event.target.value)
    setOrgFieldError('')
    setOrgFieldHasError(false)
    setFetchCompanyHasError(false)
  }

  return (
    <>
      {isLoading ? (
        <CreatingAccountLoader>Laddar..</CreatingAccountLoader>
      ) : (
        <>
          <PageTitle sx={{ whiteSpace: { md: 'nowrap', lg: 'normal' } }}>
            {pageTexts.header}
          </PageTitle>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <TextField
              name="organisationsnummer"
              autoComplete="on"
              aria-required
              aria-invalid={orgFieldHasError}
              aria-describedby={
                orgFieldHasError ? 'a11y-error-orgnr' : undefined
              }
              sx={{ marginBottom: 2, width: { xs: '100%', sm: '400px' } }}
              label={pageTexts.labelForInputField}
              inputProps={{ 'data-testid': 'input-field-selector' }}
              onChange={handleInputChange}
              helperText={orgFieldError}
              error={orgFieldHasError}
            />
            <Box
              component="span"
              id="a11y-error-orgnr"
              aria-live={orgFieldHasError ? 'assertive' : 'off'}
              sx={{ display: 'none' }}
            >
              {pageTexts.a11yErrorMessageOrgNrIncorrectFormat}
            </Box>
            <Button
              variant="outlined"
              data-testid={'search-button'}
              onClick={fetchCompanyAndGotoNextPage}
              sx={{
                width: { xs: '100%', sm: '200px' },
                marginBottom: fetchCompanyHasError ? 1 : { xs: 4, sm: 8 },
              }}
            >
              Sök arbetsgivare
            </Button>
            {fetchCompanyHasError && (
              <Box
                aria-live="assertive"
                component="span"
                sx={{
                  color: 'error.main',
                  marginBottom: { xs: 4, sm: 8 },
                }}
                data-testid="fetch-company-error"
              >
                {pageTexts.companyFetchError}
              </Box>
            )}
          </Box>

          <Typography variant="subtitle1">
            {pageTexts.hjalpAttHittaOrgnummer}
          </Typography>
          <ListItem color="inherit" sx={{ padding: 0 }} divider={false}>
            <ListItemButton
              href="https://www.allabolag.se"
              target="_blank"
              sx={{ padding: 0, flexFlow: 'nowrap' }}
            >
              <ListItemIcon sx={{ minWidth: '32px' }}>
                <Launch />
              </ListItemIcon>
              <ListItemText
                primary={pageTexts.websiteLink}
                secondary={pageTexts.websiteInfo}
              />
            </ListItemButton>
          </ListItem>
        </>
      )}
    </>
  )
}

export default SelectCompanyPage
