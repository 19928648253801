export enum UserType {
  'client',
  'customer',
}

export const medarbetarePathname = '/medarbetare'
export const tjanstepersonPathname = '/mitt-trr'
export const foretagPathname = '/trr-foretag'

export const callbackUrl = '/login/callback'
