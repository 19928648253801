import { Button, Stack } from '@mui/material'

export interface ActionButtonProps {
  text: string
  url: string
  isMobile: boolean
  onClick: () => void
}

const ActionButtons = ({ text, url, isMobile, onClick }: ActionButtonProps) => {
  return (
    <Stack direction={isMobile ? 'column' : 'row'} spacing={1}>
      <Button href={url} sx={{ width: isMobile ? '100%' : undefined }}>
        {text}
      </Button>
      <Button
        variant="text"
        onClick={onClick}
        sx={{ width: isMobile ? '100%' : undefined }}
      >
        Inte intresserad
      </Button>
    </Stack>
  )
}

export default ActionButtons
