import React from 'react'
import { Provider } from 'react-redux'
import { createTheme, ThemeProvider } from '@mui/material'
import { defaultLocale, themeOptions } from '@trr/mui-theme'
import Home from '@local/Views/Home/Home'
import Arenden from '@local/src/Views/Arenden/Arenden'
import Case from '@local/src/Views/Case/Case'
import DinaArenden from '@local/Views/DinaArenden'
import Startklar from '@local/Views/Startklar/Startklar'
import StartklarForm from '@local/Views/Startklar/StartklarForm/StartklarForm'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { sv } from 'date-fns/locale'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import {
  AppShellDataProps,
  AppShellDataProvider,
  OptiKeySwitch,
  OptiKeyView,
} from '@trr/app-shell-data'
import UserFeedback from '@local/src/Blocks/UserFeedback/UserFeedback'
import Fordelning from '@local/Views/Fordelning/Fordelning'
import { svSE as svSEDataGrid } from '@mui/x-data-grid-pro'

import Store from './Store/configureStore'
import sanitizeLocalStorage from './Utils/helpers/LocalStorageSanitizer'
import { MicroFrontendLoaderProvider } from './AppShellData'

const App = ({ appShellData }: { appShellData: AppShellDataProps }) => {
  const theme = createTheme(themeOptions, defaultLocale, svSEDataGrid)
  sanitizeLocalStorage()

  return (
    <ThemeProvider theme={theme}>
      <AppShellDataProvider value={appShellData}>
        <MicroFrontendLoaderProvider value={appShellData}>
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={sv}>
            <Provider store={Store}>
              <UserFeedback />
              <OptiKeySwitch>
                <OptiKeyView optiKey="medarbetare">
                  <Home />
                </OptiKeyView>
                <OptiKeyView optiKey="arenden">
                  <Arenden key="arenden" />
                </OptiKeyView>
                <OptiKeyView optiKey="alla-arenden">
                  <Arenden key="alla-arenden" />
                </OptiKeyView>
                <OptiKeyView optiKey="omstallning">
                  <Arenden key="omstallning" />
                </OptiKeyView>
                <OptiKeyView optiKey="kompetensstod">
                  <Arenden key="kompetensstod" />
                </OptiKeyView>
                <OptiKeyView optiKey="att-gora">
                  <Arenden key="att-gora" />
                </OptiKeyView>
                <OptiKeyView optiKey="arende">
                  <Case />
                </OptiKeyView>
                <OptiKeyView optiKey="dina-arenden">
                  <DinaArenden />
                </OptiKeyView>
                <OptiKeyView optiKey="fordela-arenden">
                  <Fordelning />
                </OptiKeyView>
                <OptiKeyView optiKey="startklar">
                  <Startklar />
                </OptiKeyView>
                <OptiKeyView optiKey="startklar-arende">
                  <StartklarForm />
                </OptiKeyView>
              </OptiKeySwitch>
            </Provider>
          </LocalizationProvider>
        </MicroFrontendLoaderProvider>
      </AppShellDataProvider>
    </ThemeProvider>
  )
}

export default App
