import MoreVertIcon from '@mui/icons-material/MoreVert'
import React, { useRef, useState } from 'react'
import { MenuItem, MenuList } from '@mui/material'
import { useDeleteArbetsuppgiftMutation } from '@local/src/Api/Arbetsuppgift/arbetsuppgiftApi'
import { useAppDispatch } from '@local/Store/configureStore'
import {
  addErrorMessage,
  addSuccessMessage,
} from '@local/src/Blocks/UserFeedback/UserFeedback.actions'
import { Arbetsuppgift } from '@local/src/Views/Arenden/Arbetsuppgift/types'
import CustomPopper from '@local/src/Components/CustomPopper/CustomPopper'

import EditArbetsuppgiftModal from '../EditArbetsuppgiftModal/EditArbetsuppgiftModal'

interface HandleArbetsuppgiftMenuProps {
  arbetsuppgift: Arbetsuppgift
}

const HandleArbetsuppgiftMenu = ({
  arbetsuppgift,
}: HandleArbetsuppgiftMenuProps) => {
  const [menuOpen, setMenuOpen] = useState(false)
  const [redigeraModalOpen, setRedigeraModalOpen] = useState(false)
  const anchorRef = useRef(null)
  const dispatch = useAppDispatch()
  const [deleteArbetsuppgift] = useDeleteArbetsuppgiftMutation()

  const handleIconClick = () => {
    setMenuOpen(!menuOpen)
  }

  const handleClose = () => {
    setMenuOpen(false)
  }

  const handleTaBortClick = () => {
    deleteArbetsuppgift(arbetsuppgift.id)
      .unwrap()
      .then(() => {
        dispatch(addSuccessMessage('Arbetsuppgiften togs bort'))
      })
      .catch(() => {
        dispatch(
          addErrorMessage('Något gick fel vid borttagning av arbetsuppgiften')
        )
      })

    setMenuOpen(false)
  }

  const handleRedigeraClick = () => {
    setRedigeraModalOpen(true)
  }

  return (
    <>
      <MoreVertIcon
        ref={anchorRef}
        onClick={handleIconClick}
        sx={{ ':hover': { cursor: 'pointer' } }}
        data-cy={`handle-arbetsuppgift-menu-${arbetsuppgift.header}`}
      />
      <CustomPopper
        handleClose={handleClose}
        open={menuOpen}
        anchor={anchorRef.current}
        hideHeader
        width={150}
      >
        <MenuList>
          <MenuItem onClick={handleRedigeraClick} data-cy="edit-arbetsuppgift">
            Redigera
          </MenuItem>
          <MenuItem onClick={handleTaBortClick} data-cy="delete-arbetsuppgift">
            Ta bort
          </MenuItem>
        </MenuList>
      </CustomPopper>
      {redigeraModalOpen && (
        <EditArbetsuppgiftModal
          arbetsuppgift={arbetsuppgift}
          open={redigeraModalOpen}
          onClose={() => setRedigeraModalOpen(false)}
        />
      )}
    </>
  )
}

export default HandleArbetsuppgiftMenu
