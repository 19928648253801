const roles = {
  MissingEmail: 'MissingEmail',
  Onboarding: 'Onboarding',
  MissingWorkSituation: 'MissingWorkSituation',
  Arbetsgivarinfo: 'Arbetsgivarinfo',
  TIOOprovad: 'TIOOprovad',
  TIOAktiv: 'TIOAktiv',
  TIAOprovad: 'TIAOprovad',
  TIAAktiv: 'TIAAktiv',
}

export default roles
