import * as Yup from 'yup'
import { anstallningValidationSchema } from '@local/Views/SkapaAnsokan/SkapaAnsokanForm/Anstallning/Anstallning.schema'
import { medarbetareValidationSchema } from '@local/Views/SkapaAnsokan/SkapaAnsokanForm/Medarbetare/Medarbetare.schema'
import { lonValidationSchema } from '@local/Views/SkapaAnsokan/SkapaAnsokanForm/Lon/Lon.schema'
import {
  AnsokanKeys,
  ICommonAnsokanValideringContent,
  ICommonDatumValideringContent,
  ISelectOption,
  RadioChoice,
} from '@local/Common.types'
import {
  IFilAnsokanRowFormik,
  ISammanfattningValideringContent,
} from '@local/Views/SkapaAnsokningar/SkapaAnsokningarForm/Sammanfattning/types/types'
import {
  personnummerWithCentury,
  testPersonnummer,
  validatePersonnummer,
} from '@local/Utils/helpers'
import { getYupCurrentSchemaValues } from '@local/Utils/helpers/Forms/Forms.helpers'
import { isEmpty, isNil } from 'ramda'

export const sammanfattningValidationSchema = (
  sammanfattningValidering: ISammanfattningValideringContent,
  ansokanValidering: ICommonAnsokanValideringContent,
  datumValidering: ICommonDatumValideringContent,
  isRisk: boolean,
  isTB: boolean,
  arbitraryTimeHasPassedSinceUpload: boolean,
  isTestUser: boolean
) =>
  Yup.object().shape({
    filansokningar: Yup.array()
      .of(
        medarbetareValidationSchema(ansokanValidering, false)
          .concat(
            anstallningValidationSchema(
              ansokanValidering,
              datumValidering,
              isRisk,
              isTB
            ).concat(
              Yup.object().shape({
                [AnsokanKeys.HasSjukersattningsgrad]:
                  Yup.mixed<ISelectOption>().notRequired(),
              })
            )
          )
          .concat(
            lonValidationSchema(
              ansokanValidering,
              datumValidering,
              isRisk,
              isTB
            ).concat(
              Yup.object().shape({
                [AnsokanKeys.HasAvgangsvederlag]:
                  Yup.mixed<ISelectOption>().notRequired(),
              })
            )
          )
          .concat(
            Yup.object().shape({
              [AnsokanKeys.Personnummer]: Yup.string()
                .nullable()
                .required(ansokanValidering.personnummerSaknas)
                .matches(
                  isTestUser ? testPersonnummer : personnummerWithCentury,
                  {
                    message: ansokanValidering.personnummerOgiltigtFormat,
                  }
                )
                .test(
                  'validatePersonnummer',
                  ansokanValidering.personnummerOgiltigtFormat,
                  (personnummer) =>
                    isTestUser ? true : validatePersonnummer(personnummer)
                )
                .test(
                  'personnummerCanNotBeConnectedToExistingAnsokan',
                  sammanfattningValidering.ansokanExistsOnPersonnummer,
                  (_, context) => {
                    const schema =
                      getYupCurrentSchemaValues<IFilAnsokanRowFormik>(context)

                    return schema.hasMedarbetareAnsokanAtCurrentUppdrag ===
                      RadioChoice.Ja
                      ? false
                      : true
                  }
                )
                .test(
                  'currentUserIsSameAsApplicant',
                  sammanfattningValidering.currentUserIsSameAsApplicant,
                  (_, context) => {
                    const schema =
                      getYupCurrentSchemaValues<IFilAnsokanRowFormik>(context)

                    return schema.currentUserIsSameAsApplicant ===
                      RadioChoice.Ja
                      ? false
                      : true
                  }
                ),
              [AnsokanKeys.Namn]: Yup.string()
                .test(
                  'namnIsBeingFetchedFromSPAR',
                  sammanfattningValidering.namnIsRetrieving,
                  (namn) =>
                    (isNil(namn) || isEmpty(namn)) &&
                    !arbitraryTimeHasPassedSinceUpload
                      ? false
                      : true
                )
                .test(
                  'namnCanNotBeEmptyAfterFetchIsComplete',
                  sammanfattningValidering.namnRequired,
                  (namn) =>
                    (isNil(namn) || isEmpty(namn)) &&
                    arbitraryTimeHasPassedSinceUpload
                      ? false
                      : true
                ),
              [AnsokanKeys.Avgangsvederlag]: Yup.number()
                .nullable()
                .typeError(ansokanValidering.avgangsvederlagBeloppSaknas)
                .required(ansokanValidering.avgangsvederlagBeloppSaknas)
                .min(
                  0,
                  sammanfattningValidering.avgangsvederlagMinAmountRequired
                )
                .integer(ansokanValidering.avgangsvederlagInvalidFormat)
                .max(
                  99999999,
                  ansokanValidering.avgangsvederlagMaxAmountExceeded
                ),
            })
          )
      )
      .min(1, sammanfattningValidering.filansokningarMinAmountRequired),
  })
