import { TestUserRoles } from '@local/Common.types'
import { useAuthentication } from '@trr/app-shell-data'

const useIsTestUser = (): boolean => {
  const { userRoles } = useAuthentication()

  const isTestUser = Object.values<string>(TestUserRoles).some((testUserRole) =>
    userRoles.includes(testUserRole)
  )

  return isTestUser
}

export default useIsTestUser
