import { useEffect } from 'react'
import { useAuthentication } from 'features/Authentication/Hooks'
import { userProfileApi } from 'state/PublicWebApi/UserProfile'
import { isOnboardingJwt } from 'utils/helpers'

const useFetchUserProfile = () => {
  const [fetchUserProfile, userData] =
    userProfileApi.endpoints.getUserProfile.useLazyQuery()

  const {
    isLoggedIn,
    profile: { sub },
  } = useAuthentication()

  // When a user has these roles they cannot yet fetch a userprofile because it does not yet exist.
  const isOnboarding = isOnboardingJwt(sub)

  useEffect(() => {
    const tryFetchUserProfile = async () => {
      if (userData.isUninitialized && isLoggedIn && !isOnboarding) {
        await fetchUserProfile()
      }
    }
    void tryFetchUserProfile()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, sub])
  return {
    isSuccess: userData.isSuccess,
    isAnonymous: !isLoggedIn && !userData.isLoading && !userData.isFetching,
    isInLoginFlow: isOnboarding,
    isError: userData.isError,
  }
}

export default useFetchUserProfile
