import { JSX } from 'react'
import { useIsTestUser, usePickEpiContent } from '@local/Utils/hooks'
import { ILaggTillMedarbetareContent } from '@local/Views/LaggTillMedarbetare/LaggTillMedarbetare.types'

import LaggTillMedarbetareForm from './LaggTillMedarbetareForm'

const LaggTillMedarbetare = (): JSX.Element => {
  const { ansokanValidering } = usePickEpiContent<ILaggTillMedarbetareContent>()

  const isTestUser = useIsTestUser()

  return (
    <section>
      <LaggTillMedarbetareForm
        ansokanValidering={ansokanValidering}
        isTestUser={isTestUser}
      />
    </section>
  )
}

export default LaggTillMedarbetare
