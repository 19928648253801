import React, { useEffect, useState } from 'react'
import Modal from '@local/Components/Modal/Modal'
import { Alert, Box, TextField, Typography } from '@mui/material'
import LinkWithIcon from '@local/Components/LinkWithIcon/LinkWithIcon'
import LaunchRoundedIcon from '@mui/icons-material/LaunchRounded'

interface EgenArbetsuppgiftModalBaseProps {
  initialHeader?: string
  initialBody?: string
  open: boolean
  onClose: () => void
  onSubmit: (header: string, body: string) => void
  isSuccess: boolean
  isLoading: boolean
  isError: boolean
  resetQuery?: () => void
  modalTitle: string
  confirmText: string
  errorMessage: string
}

const EgenArbetsuppgiftModalBase = ({
  initialHeader = '',
  initialBody = '',
  open,
  onClose,
  onSubmit,
  isSuccess,
  isLoading,
  isError,
  modalTitle,
  confirmText,
  errorMessage,
}: EgenArbetsuppgiftModalBaseProps) => {
  const [header, setHeader] = useState<string>(initialHeader)
  const [body, setBody] = useState<string>(initialBody)

  useEffect(() => {
    if (isSuccess) {
      resetFields()
    }
  }, [isSuccess])

  const resetFields = () => {
    setHeader('')
    setBody('')
  }

  const handleSubmit = () => {
    onSubmit(header, body)
  }

  const handleClose = () => {
    resetFields()
    onClose()
  }

  return (
    <Modal
      label={modalTitle}
      open={open}
      disableBackdropClick
      confirmText={confirmText}
      onConfirm={handleSubmit}
      onCancel={handleClose}
      disableSubmit={isLoading || !header}
    >
      <Box display="grid" rowGap={2} data-testid="createArbetsuppgiftModal">
        <TextField
          placeholder="Rubrik*"
          value={header}
          onChange={(e) => setHeader(e.target.value)}
          required
          variant="outlined"
          fullWidth
          data-cy="arbetsuppgift-header"
        />
        <Alert
          severity="warning"
          action={
            <Box pt={0.25}>
              <LinkWithIcon
                text="GDPR-riktlinjer"
                href="https://trygghetsradet.sharepoint.com/sites/ledning/SitePages/GDPR.aspx"
                external
                typographyVariant="body2"
                icon={<LaunchRoundedIcon fontSize="small" />}
              />
            </Box>
          }
        >
          <Typography variant="body2">
            Följ alltid GDPR-riktlinjerna vid noteringar.
          </Typography>
        </Alert>
        <TextField
          placeholder="Valfri text om uppgiften. Max 160 tecken."
          value={body}
          onChange={(e) => setBody(e.target.value)}
          rows={4}
          inputProps={{ maxLength: 160 }}
          multiline
          fullWidth
          data-cy="arbetsuppgift-body"
        />
        {isError && <Alert severity="error">{errorMessage}</Alert>}
      </Box>
    </Modal>
  )
}

export default EgenArbetsuppgiftModalBase
