import InfoPopover from '@local/src/Components/InfoPopover'
import Separator from '@local/src/Components/Separator'
import { usePickEpiContentWithFallback } from '@local/src/Hooks'
import { Box, Fade, Stack, Typography } from '@mui/material'
import React, { Fragment, useEffect } from 'react'

import { RaknesnurraFormResultData } from '../../Models/RaknesnurraForm.types'

import RaknesnurraInvalidResult from './RaknesnurraInvalidResult'
import RaknesnurraResultKalenderhalvarItem from './RaknesnurraResultKalenderhalvarItem'

interface Props {
    formResult: RaknesnurraFormResultData
}

const RaknesnurraFormResult = ({ formResult }: Props) => {
    useEffect(() => {
        if (formResult) {
            const ref = document.getElementById('raknesnurra-result-box')
            ref.scrollIntoView({ behavior: 'smooth' })
        }
    }, [formResult])

    const { studiestodRaknesnurra: epiContent } = usePickEpiContentWithFallback()
    return (
        <Fade in timeout={800}>
            <Box id="raknesnurra-result-box" data-testid="raknesnurra-form-result">
                <Separator />

                <Stack direction="row" spacing={2} alignItems="center">
                    <Typography variant="h3">{epiContent.resultatHeader}</Typography>
                    <InfoPopover content={epiContent.resultatHeaderTooltip} />
                </Stack>

                {formResult.isInvalid ? (
                    <RaknesnurraInvalidResult
                        isYoungerThan40={formResult.isYoungerThan40}
                        isUnhandledError={formResult.isUnhandledError}
                        isTooOld={formResult.isTooOld}
                        isTooOldAge={formResult.isTooOldAge}
                    />
                ) : (
                    <Fragment>
                        {formResult.kalenderhalvar.map((item) => (
                            <RaknesnurraResultKalenderhalvarItem key={`${item.halvar}`} item={item} />
                        ))}
                    </Fragment>
                )}
            </Box>
        </Fade>
    )
}

export default RaknesnurraFormResult
