import { useQueryClient } from '@tanstack/react-query'

export type CsnPortalReferenceType = 'BegaranYttrande'

export const useKommentarQueryHelper = (referenceId: string) => {
	const queryClient = useQueryClient()
	const getKommentarString = 'getKommentar'
	const getKommentarQueryKey = [getKommentarString, referenceId]

	const invalidateGetKommentarQuery = () => queryClient.invalidateQueries(getKommentarQueryKey)

	return { invalidateGetKommentarQuery, getKommentarQueryKey, getKommentarString }
}
