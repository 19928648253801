export enum Routes {
  Start = 'ansokan',
  TiaAnsokan = 'tjansteman-i-anstallning',
  TbAnsokan = 'tidsbegransad-anstallning',
  TackForDinAnsokan = 'tack-for-din-ansokan',
}

export enum QueryParams {
  OgiltigtOrgnummer = 'organisationsnummer',
}
