import { useState, useCallback, useEffect } from 'react'
import { isLinkExternal } from '@trr/internal-helpers'

import { Box, Button, Typography, Link, styled } from '@mui/material'
import Info from '@mui/icons-material/Info'
import OpenInNew from '@mui/icons-material/OpenInNew'

import { cookieName, getCookieValue } from 'utils/cookies'
import {
  useCachedSettingsState,
  useCachedContentQuery,
} from 'state/PublicWebApi'
import { useSliceStateSelector } from 'slices/hooks'
import { useAuthentication } from 'features/Authentication/Hooks'

interface AsideWrapperProps {
  component: string
  adaptToNewMenu: boolean
}

const AsideWrapper = styled(Box, {
  shouldForwardProp: (prop: string) => !['adaptToNewMenu'].includes(prop),
})<AsideWrapperProps>(({ theme, adaptToNewMenu }) => ({
  padding: theme.spacing(3),
  backgroundColor: theme.palette.surface?.blue,
  display: 'flex',
  ...(adaptToNewMenu && {
    [theme.breakpoints.up('lg')]: {
      left: '288px',
      position: 'relative',
      maxWidth: `calc(100% - 288px)`,
    },
  }),
}))

const SystemInformation = () => {
  const {
    isEnabled = false,
    heading = '',
    text = '',
    url = '',
    urlLabel = '',
  } = useCachedSettingsState().systemInformation

  const { profile } = useAuthentication()
  const { data: contentData } = useCachedContentQuery()

  const [isInfoExpanded, setIsInfoExpanded] = useState(false)
  const [adaptToNewMenu, setAdaptToNewMenu] = useState(false)

  const { smallDeviceMenuStatus } = useSliceStateSelector(
    (state) => state.slices.menus
  )
  const { mobileLeftMenuStatus } = useSliceStateSelector(
    (state) => state.slices.mobileLeftMenuStatus
  )

  const isPublicPage = contentData?.properties.isPublic

  const useNewLoggedInMenu = Boolean(
    getCookieValue(cookieName.newNavigationSystemCookie)
  )
  const linkAriaLabel = isLinkExternal(url)
    ? `${urlLabel} (öppnas i ny flik)`
    : urlLabel

  const shownSideMenu =
    smallDeviceMenuStatus !== 'none' && mobileLeftMenuStatus === 'open'

  const toggleExpandedInfo = useCallback(() => {
    setIsInfoExpanded(!isInfoExpanded)
  }, [isInfoExpanded])

  useEffect(() => {
    if (
      profile?.idp === 'local' &&
      location.pathname.includes('/medarbetare')
    ) {
      setAdaptToNewMenu(false)
    } else {
      setAdaptToNewMenu(useNewLoggedInMenu && !isPublicPage)
    }
  }, [adaptToNewMenu, profile, useNewLoggedInMenu, isPublicPage])

  return (
    isEnabled &&
    !shownSideMenu && (
      <AsideWrapper
        component="aside"
        data-testid="SystemInformation"
        adaptToNewMenu={adaptToNewMenu}
      >
        <Info sx={{ mt: '2px' }} color="info" />
        <Box maxWidth="752px" ml={2}>
          <Typography component={'h2'} variant="h6">
            {heading}
          </Typography>
          {isInfoExpanded && (
            <Typography id="SystemInformation" fontSize={'body1'} mt={1}>
              {text}
              {url !== '' && (
                <Link
                  href={url}
                  underline="hover"
                  ml={1}
                  aria-label={linkAriaLabel}
                >
                  {isLinkExternal(url) && (
                    <OpenInNew
                      fontSize={'inherit'}
                      sx={{ mr: 1, position: 'relative', top: '0.15em' }}
                    />
                  )}
                  {urlLabel}
                </Link>
              )}
            </Typography>
          )}
          {text && (
            <Button
              sx={{ mt: 2 }}
              onClick={toggleExpandedInfo}
              aria-expanded={isInfoExpanded}
              aria-controls="SystemInformation"
            >
              {isInfoExpanded ? 'Läs mindre' : 'Läs mer'}
            </Button>
          )}
        </Box>
      </AsideWrapper>
    )
  )
}

export default SystemInformation
