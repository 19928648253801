import { KommentarApiModel } from '@local/src/Services/SwaggerApi/data-contracts'

export const getLatestKommentar = (kommentarer: KommentarApiModel[]): KommentarApiModel | null => {
	if (!kommentarer?.length) return null

	return kommentarer.reduce((latest, current) => {
		const currentDate = new Date(current.lastUpdatedBy.lastUpdatedAt)
		const latestDate = new Date(latest.lastUpdatedBy.lastUpdatedAt)
		return currentDate > latestDate ? current : latest
	})
}
