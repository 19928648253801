import { createElement } from 'react'
import PrimaryContentBlock from '../CollectionPageBlocks/PrimaryContentBlock/PrimaryContentBlock'
import SecondaryContentBlock from '../CollectionPageBlocks/SecondaryContentBlock'
import ToolsAndServicesBlock from '../CollectionPageBlocks/ToolsAndServicesBlock'

interface CollectionPageBlockMap {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any
}

const blockTypeMap: CollectionPageBlockMap = {
  PrimaryContentBlock,
  SecondaryContentBlock,
  ToolsAndServicesBlock,
}

export interface CollectionPageBlockMapper {
  blocks: CollectionPageBlock[]
  fallbackImage?: string
}

export interface CollectionPageBlock {
  id: number
  type: string
  properties: Record<string, unknown>
}

const CollectionPageBlockMapper = ({
  blocks = [],
  fallbackImage,
}: CollectionPageBlockMapper) => {
  return (
    blocks.length > 0 &&
    blocks.map(
      ({ type, properties, id }, index) =>
        !!blockTypeMap[type] &&
        createElement(blockTypeMap[type], {
          id,
          content: {
            ...properties,
            mediaUrl: window.APP_CONFIGURATION.COMMON.MEDIA_URL,
            fallbackImage,
          },
          key: `${type}${index}`,
        })
    )
  )
}

export default CollectionPageBlockMapper
