import { useCallback, useState } from 'react'

import { AppBar, Box, IconButton, Toolbar, styled } from '@mui/material'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import MenuRoundedIcon from '@mui/icons-material/MenuRounded'
import SearchIcon from '@mui/icons-material/Search'

import { useCachedContentQuery } from 'state/PublicWebApi'
import Logo from 'apps/Site/components/Logo'
import LoggedInAvatarButton from 'apps/Site/LoggedInNavigation/LoggedInAvatarButton'
import { useTrackCustomClick } from 'features/Telemetry/GoogleTagManager/Hooks/useTrackCustomClick'
import { useAuthentication } from 'features/Authentication/Hooks'
import { mobileLeftMenuStatus } from 'slices/mobileLeftMenu/types'
import { smallDeviceMenuStatus } from 'slices/Menus/types'
import { useSliceDispatch, useSliceStateSelector } from 'slices/hooks'
import { cookieName, getCookieValue } from 'utils/cookies'

import { LogoLinkWrapper } from '../../NewHeader.styles'
import { getLoginUrl } from '../../NewHeader.hooks'
import SubMenuContextWrapper from '../Submenu/SubmenuContext'

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  position: 'sticky',
  backgroundColor: theme.palette.background.default,
  width: '100vw',
  boxShadow: 'none',
}))

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  width: 'inherit',
  padding: theme.spacing(2.5, 2),
  justifyContent: 'space-between',
  [theme.breakpoints.up('sm')]: {
    padding: `${theme.spacing(2)}`,
  },
}))

const Navbar = () => {
  const { isLoggedIn } = useAuthentication()
  const { data: contentData } = useCachedContentQuery()
  const trackCustomClick = useTrackCustomClick()

  const { visitorContextStatus } = useSliceStateSelector(
    (state) => state.slices.visitorContexts
  )
  const { smallDeviceMenuStatus } = useSliceStateSelector(
    (state) => state.slices.menus
  )
  const [isSubmenuOpen, setIsSubmenuOpen] = useState(false)
  const [openMenu, setOpenMenu] = useState(false)

  const trigger = useSliceDispatch()

  const useNewLoggedInMenu = Boolean(
    getCookieValue(cookieName.newNavigationSystemCookie)
  )

  const isPublicPage = contentData?.properties?.isPublic

  const showTRRMenu = useCallback(
    (type: smallDeviceMenuStatus) => {
      if (type === smallDeviceMenuStatus || type === 'none') {
        trigger('setSmallDeviceMenuStatus', 'none')
      } else {
        trigger('setSmallDeviceMenuStatus', type)
      }
    },
    [smallDeviceMenuStatus, trigger]
  )

  const showNewTRRMenu = useCallback(
    (toggleMenu: mobileLeftMenuStatus) => {
      trigger('setMobileLeftMenuStatus', toggleMenu)
    },
    [trigger]
  )

  const onClickMenuButton = () => {
    if (isPublicPage) {
      setOpenMenu(!openMenu)
    } else if (!isPublicPage && useNewLoggedInMenu) {
      showNewTRRMenu('open')
    } else {
      showTRRMenu('right')
    }
  }

  const closeAllMenus = () => {
    setOpenMenu(false)
    setIsSubmenuOpen(false)

    if (useNewLoggedInMenu) {
      showNewTRRMenu('closed')
    } else {
      showTRRMenu('none')
    }
  }

  const onSearchButtonClick = () => {
    trackCustomClick('Menu action button', {
      label: `Sök`,
      href: '/sok',
    })
    closeAllMenus()
  }

  return (
    <>
      <StyledAppBar>
        <StyledToolbar>
          <LogoLinkWrapper
            href="/privatperson"
            aria-label="Startsida TRR.se"
            onClick={closeAllMenus}
          >
            <Logo data-testid="mobile-trr-logo" />
          </LogoLinkWrapper>
          <Box display="flex" gap={1} maxHeight={'42px'}>
            {!openMenu && (
              <>
                <IconButton
                  sx={{ width: '42px' }}
                  data-testid="navbar-search-button"
                  href="/sok/"
                  onClick={onSearchButtonClick}
                >
                  <SearchIcon />
                </IconButton>
                {isLoggedIn ? (
                  <LoggedInAvatarButton />
                ) : (
                  <IconButton
                    href={getLoginUrl('/login', visitorContextStatus as string)}
                    data-testid="header-avatar-button"
                    onClick={() => {
                      trackCustomClick('Menu action button', {
                        label: 'Logga in',
                        href: getLoginUrl(
                          '/login',
                          visitorContextStatus as string
                        ),
                      })
                    }}
                  >
                    <AccountCircleIcon data-testid="AccountCircleIcon-navbar" />
                  </IconButton>
                )}
                <IconButton
                  sx={{ maxWidth: '42px' }}
                  data-testid="open-menu-button"
                  data-gtm-label="open-menu-button"
                  onClick={onClickMenuButton}
                >
                  <MenuRoundedIcon />
                </IconButton>
              </>
            )}
          </Box>
        </StyledToolbar>
      </StyledAppBar>
      <SubMenuContextWrapper
        openMenu={openMenu}
        onClickMenuButton={onClickMenuButton}
        useNewMenu={useNewLoggedInMenu}
        isSubmenuOpen={isSubmenuOpen}
        setIsSubmenuOpen={setIsSubmenuOpen}
      />
    </>
  )
}

export default Navbar
