export function truncateText(text: string, maxLength: number): string {
  if (text.length <= maxLength) return text

  let truncatedText = text.slice(0, maxLength + 1).trim()
  if (truncatedText.length > maxLength) {
    const lastSpaceIndex = truncatedText.lastIndexOf(' ')
    if (lastSpaceIndex > 0) {
      truncatedText = truncatedText.slice(0, lastSpaceIndex)
    }
  }

  return `${truncatedText}...`
}
