import { baseApi } from 'State/Api'
import {
  type GetArbetsgivarinfoRequestParams,
  type GetArbetsgivarinfoResponse,
  type SetUserArbetsgivarInfoRequestBody,
} from './types'

export const api = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getArbetsgivarInfoById: builder.query<
      GetArbetsgivarinfoResponse,
      GetArbetsgivarinfoRequestParams
    >({
      query: ({ id }) => `/arbetsgivarinfo/${id}`,
    }),
    setExistingUserArbetsgivarInfo: builder.mutation<
      void,
      SetUserArbetsgivarInfoRequestBody
    >({
      query: (request) => {
        return {
          url: '/user/arbetsgivarinfo',
          method: 'POST',
          body: request,
        }
      },
    }),
  }),
})

export const {
  useSetExistingUserArbetsgivarInfoMutation,
  useGetArbetsgivarInfoByIdQuery,
} = api

export default api
