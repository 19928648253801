import { useCachedSettingsState, useCachedContentQuery } from 'PublicWebApi'
import { useMemo, createContext } from 'react'
import NewLeftMenu from 'apps/Site/LoggedInNavigation/NewLeftMenu'
import RightMenu from 'apps/Site/Header/RightMenu'
import Menu from '../Menu'

interface SubMenuContextWrapperProps {
  openMenu: boolean
  onClickMenuButton: () => void
  useNewMenu: boolean
  isSubmenuOpen: boolean
  setIsSubmenuOpen: (state: boolean) => void
}

interface SubMenuContextProps {
  isSubmenuOpen: boolean
  setIsSubmenuOpen: (state: boolean) => void
  submenuTitle?: string | null
  setSubmenuTitle?: (title: string) => void
}

export const SubMenuContext = createContext<SubMenuContextProps>({
  setIsSubmenuOpen: null,
  isSubmenuOpen: false,
  submenuTitle: null,
  setSubmenuTitle: null,
})

const renderLoggedInMenu = (useNewMenu: boolean) => {
  return useNewMenu ? (
    <NewLeftMenu />
  ) : (
    <RightMenu isNewHeader testSelector="new-header-mobile-right-menu" />
  )
}
const SubMenuContextWrapper = ({
  openMenu,
  onClickMenuButton,
  useNewMenu,
  isSubmenuOpen,
  setIsSubmenuOpen,
}: SubMenuContextWrapperProps) => {
  const menuContent = useCachedSettingsState().megaNavigation?.contexts
  const { data: contentData } = useCachedContentQuery()

  const isPublicPage = contentData?.properties.isPublic

  const submenuContext = useMemo(
    () => ({ isSubmenuOpen, setIsSubmenuOpen }),
    [isSubmenuOpen, setIsSubmenuOpen]
  )

  return (
    <SubMenuContext.Provider value={submenuContext}>
      {isPublicPage ? (
        <Menu
          menuContent={menuContent}
          openMenu={openMenu}
          onClickMenuButton={onClickMenuButton}
        />
      ) : (
        renderLoggedInMenu(useNewMenu)
      )}
    </SubMenuContext.Provider>
  )
}

export default SubMenuContextWrapper
