import { Box, Stack, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
    isYoungerThan40: boolean
    isUnhandledError: boolean
    isTooOld: boolean
    isTooOldAge: number
}
const RaknesnurraInvalidResult = ({ isYoungerThan40, isUnhandledError, isTooOld, isTooOldAge }: Props) => {
    const { t } = useTranslation('translation', { keyPrefix: 'studieersattningRaknesnurraForm' })
    if (isUnhandledError) {
        return (
            <Box data-testid="raknesnurra-invalid-result-unhandled">
                <Typography color="error">{t('berakningMisslyckadText')}</Typography>
            </Box>
        )
    }
    return (
        <Stack spacing={2} data-testid="raknesnurra-invalid-result-saknar-rattigheter">
            {isYoungerThan40 && <Typography>{t('isYoungerThan40Text')}</Typography>}
            {isTooOld && <Typography>{t('isTooOldText', { isTooOldAge })}</Typography>}
            <Typography>{t('saknarRattigheterText')}</Typography>
        </Stack>
    )
}

export default RaknesnurraInvalidResult
