import { JSX, useEffect } from 'react'
import { useFormikContext } from 'formik'
import { usePickEpiContent } from '@local/Utils/Hooks'
import Gutter from '@local/Components/Gutter'
import { RadioChoice } from '@local/Types/form.types'
import { ITidsbegransadAnstallningContent } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/TidsbegransadAnstallning.types'
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material'
import FormErrorMessage from '@local/Components/FormErrorMessage'
import NumberInput from '@local/Components/NumberInput'
import HTMLMapper from '@local/Components/HTMLMapper'
import { isNil } from 'ramda'
import Tooltip from '@local/Components/Tooltip'
import { ITidsbegransadAnstallningFormValues } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/TidsbegransadAnstallningForm/types'
import { AnsokanKeys } from '@local/Types'

const Sjukersattningsgrad = (): JSX.Element => {
  const {
    lonOchErsattning: {
      sjukersattningsgradChoice,
      sjukersattningsgrad,
      tooltipSjukersattningsgrad,
    },
  } = usePickEpiContent<ITidsbegransadAnstallningContent>()

  const formikContext = useFormikContext<ITidsbegransadAnstallningFormValues>()
  const {
    handleChange,
    handleBlur,
    errors,
    touched,
    values,
    setFieldValue,
    setFieldTouched,
  } = formikContext

  useEffect(() => {
    if (values.sjukersattningsgradChoice === RadioChoice.No) {
      setFieldValue(AnsokanKeys.Sjukersattningsgrad, null)?.catch((err) => {
        console.log(err)
      })
      setFieldTouched(AnsokanKeys.Sjukersattningsgrad, false)?.catch((err) => {
        console.log(err)
      })
    }
  }, [values.sjukersattningsgradChoice, setFieldValue, setFieldTouched])

  const isError =
    touched.sjukersattningsgradChoice &&
    !isNil(errors.sjukersattningsgradChoice)

  return (
    <FormControl error={isError} variant="standard" fullWidth>
      <Tooltip
        heading={sjukersattningsgradChoice.titel}
        tooltipContent={
          <HTMLMapper body={tooltipSjukersattningsgrad.mainBody} />
        }
      />

      <RadioGroup
        onChange={handleChange}
        onBlur={handleBlur}
        defaultValue={values.sjukersattningsgradChoice}
      >
        <FormControlLabel
          value={RadioChoice.Yes}
          control={<Radio color="primary" />}
          label={sjukersattningsgradChoice.ja}
          name={AnsokanKeys.SjukersattningsgradChoice}
          id={`${AnsokanKeys.SjukersattningsgradChoice}.ja`}
        />
        <FormControlLabel
          value={RadioChoice.No}
          control={<Radio color="primary" />}
          label={sjukersattningsgradChoice.nej}
          name={AnsokanKeys.SjukersattningsgradChoice}
          id={`${AnsokanKeys.SjukersattningsgradChoice}.nej`}
        />
      </RadioGroup>

      {isError && (
        <FormErrorMessage>{errors.sjukersattningsgradChoice}</FormErrorMessage>
      )}

      {values.sjukersattningsgradChoice === RadioChoice.Yes && (
        <>
          <Gutter xs={32} />

          <Typography variant="h6">{sjukersattningsgrad.titel}</Typography>

          <Gutter xs={16} />

          <NumberInput
            formikContext={formikContext}
            label={sjukersattningsgrad.textFaltTitel}
            name={AnsokanKeys.Sjukersattningsgrad}
            value={values.sjukersattningsgrad}
            error={
              touched?.sjukersattningsgrad &&
              !isNil(errors?.sjukersattningsgrad)
            }
            errorMsg={errors?.sjukersattningsgrad}
          />
        </>
      )}
    </FormControl>
  )
}

export default Sjukersattningsgrad
