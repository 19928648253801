import { Box, Typography } from '@mui/material'
import {
  GRID_DETAIL_PANEL_TOGGLE_FIELD,
  GridColumnVisibilityModel,
  GridPinnedRowsProp,
  GridRowParams,
  GridToolbarColumnsButton,
  GridToolbarContainer,
} from '@mui/x-data-grid-pro'
import React, { useEffect, useState } from 'react'
import DataGridWrapper from '@local/src/Components/DataGridWrapper/DataGridWrapper'
import { Radgivare, Team } from '@local/src/Common.types'
import { useCaseEnvBasePath, useLocalStorage } from '@local/src/Hooks'

import {
  ArendeStagedForFordelning,
  FordelningsArende,
  FordelningsRadgivare,
} from '../Fordelning.types'

import { getColumns } from './FordelningTableColumns'
import DetailPanel from './DetailPanel/DetailPanel'

interface FordelningTableProps {
  arenden: FordelningsArende[]
  teams: Team[]
  aktivaRadgivare: FordelningsRadgivare[]
  isFetching: boolean
  stagedArenden: ArendeStagedForFordelning[]
  addStagedArende: (arendeId: string, radgivare: Radgivare) => void
  removeStagedArende: (arendeId: string) => void
}

const FordelningTable = ({
  arenden,
  teams,
  aktivaRadgivare,
  isFetching = true,
  stagedArenden,
  addStagedArende,
  removeStagedArende,
}: FordelningTableProps) => {
  const arendeBasePath = useCaseEnvBasePath()
  const columns = getColumns(
    arendeBasePath,
    aktivaRadgivare,
    addStagedArende,
    removeStagedArende,
    stagedArenden
  )

  const [visibleColumns, setVisibleColumns] = useLocalStorage(
    'Fordelning-column-visibility',
    {
      sistaAnstDag: false,
      lon: false,
      orsakUppsagning: false,
    } as GridColumnVisibilityModel
  )
  const [columnOrder, setColumnOrder] = useLocalStorage(
    'Fordelning-column-order',
    [
      GRID_DETAIL_PANEL_TOGGLE_FIELD,
      'kund',
      'bostadsort',
      'foretag',
      'kommun',
      'sistaAnstDag',
      'lon',
      'orsakUppsagning',
      'fordelning',
    ] as string[]
  )
  const [columnSizes, setColumnSizes] = useLocalStorage(
    'Fordelning-column-sizes',
    columns.map((col) => ({ field: col.field, width: col.width }))
  )
  const [rows, setRows] = useState<FordelningsArende[]>([])
  const [pinnedRows, setPinnedRows] = useState<GridPinnedRowsProp>(null)

  useEffect(() => {
    if (stagedArenden?.length < pinnedRows?.top?.length) {
      // workaround for MUI bug: https://github.com/mui/mui-x/issues/8013
      setRows([])
      setRows([...arenden])
    }

    setPinnedRows({
      top: stagedArenden.map((arende) => ({
        ...rows.find((ar) => ar.id === arende.arendeId),
      })),
    })
  }, [stagedArenden])

  useEffect(() => {
    arenden && setRows([...arenden])
  }, [arenden])

  return (
    <Box display="flex" flexDirection="column" mt={2}>
      <DataGridWrapper
        loading={isFetching}
        pinnedRows={pinnedRows}
        columns={columns}
        columnVisibilityModel={visibleColumns}
        columnOrder={columnOrder}
        onColumnOrderChanged={setColumnOrder}
        onColumnVisibilityModelChange={setVisibleColumns}
        onColumnResized={setColumnSizes}
        rows={rows}
        customColumnSizes={columnSizes}
        getRowId={(row: FordelningsArende) => row.id}
        emptyResultHeader="Klart!"
        emptyResultDescription="Alla ärenden är fördelade. 🎉"
        getDetailPanelContent={(params: GridRowParams<FordelningsArende>) => (
          <DetailPanel params={params} teams={teams} />
        )}
        enableColumnResize
        slots={{
          toolbar: () => (
            <GridToolbarContainer
              sx={{
                justifyContent: 'space-between',
              }}
            >
              <Typography variant="body2" textAlign="end" px={1}>
                {rows?.length > 0 && (
                  <>
                    {`${rows.length} ${rows.length === 1 ? 'ärende' : 'ärenden'}`}
                  </>
                )}
              </Typography>
              <GridToolbarColumnsButton />
            </GridToolbarContainer>
          ),
        }}
      />
    </Box>
  )
}

export default FordelningTable
