import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import getConfig from '@local/Utils/getConfig'
import { prepareHeaders } from '@local/services/API/prepareHeaders'

const { API_URL } = getConfig()

// initialize an empty api service that we'll inject endpoints into later as needed
export const baseApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL,
    prepareHeaders,
  }),
  reducerPath: 'baseApi',
  /**
   * We normally invalidate cache with invalidateTags on affected APIs,
   * But ansokan data can be changed outside our control by going to Tjanstepersonansokan and creating an ansokan
   * So lower the timeout to handle this case
   * */
  keepUnusedDataFor: 30,
  tagTypes: [
    'ansokan',
    'tidigareAnstallningar',
    'tidigareSysselsattningarOld',
    'tidigareSysselsattningar',
    'kompletteraStatus',
    'kanKomplettera',
    'tidigareSysselsattningarDrafts',
  ],
  endpoints: () => ({}),
})
