import { CardActions, CardMedia, Typography } from '@mui/material'
import {
  createImageAltTextFromFileName,
  getFullImageUrl,
  isLinkExternal,
  LazyLoadWrapper,
} from '@trr/internal-helpers'
import { truncateText } from 'apps/Site/Utils/TextHelper/TextHelper'
import { CardActionAreaWrapper } from '../../Card/Card.styles'
import TagWithDetails from '../../TagWithDetails/TagWithDetails'
import {
  CollectionCardContentWrapper,
  CollectionCardWrapper,
} from '../CollectionCard.styles'
import { CollectionCardProps } from '../CollectionCard.types'
import { LargeCardImageWrapper } from './LargeCard.styles'

const LargeCard = ({
  cardContent: {
    properties: {
      category,
      heading,
      preamble,
      image,
      link,
      knowledgeLevel,
      selectedPageType,
      timeSpan,
    },
  },
  mediaUrl,
  fallbackImage,
}: CollectionCardProps) => {
  const externalLink = link.url ? isLinkExternal(link.url) : false
  const maxHeadingLength = 36
  const maxTextLength = 220
  const truncatedHeading = truncateText(heading, maxHeadingLength)
  const truncatedPreamble = truncateText(preamble, maxTextLength)
  if (image == null && fallbackImage) {
    image = fallbackImage
  }

  return (
    <CollectionCardWrapper data-testid="large-card">
      <CardActionAreaWrapper
        tabIndex={0}
        href={link.url}
        target={externalLink ? '__blank' : undefined}
        rel={externalLink ? 'noreferrer' : undefined}
        data-testid={
          externalLink ? 'external-actionarea-wrapper' : 'actionarea-wrapper'
        }
      >
        {image && (
          <LargeCardImageWrapper>
            <LazyLoadWrapper className="LazyLoadWrapper">
              <CardMedia
                data-testid={'card-image'}
                component="img"
                image={getFullImageUrl(mediaUrl, image, 768)}
                alt={createImageAltTextFromFileName(image)}
              />
            </LazyLoadWrapper>
          </LargeCardImageWrapper>
        )}
        <CollectionCardContentWrapper>
          {category && category.length > 0 && (
            <Typography
              variant="subtitle2"
              component="p"
              color="text.secondary"
              mb={1}
            >
              {category}
            </Typography>
          )}
          <Typography variant="h6" component="h3" mb={1.5}>
            {truncatedHeading}
          </Typography>
          <Typography variant="body2" component="p" color="text.secondary">
            {truncatedPreamble}
          </Typography>
        </CollectionCardContentWrapper>
        <CardActions sx={{ paddingLeft: 3, paddingBottom: 3 }}>
          <TagWithDetails
            knowledgeLevel={knowledgeLevel}
            selectedPageType={selectedPageType}
            timeSpan={timeSpan.value}
          />
        </CardActions>
      </CardActionAreaWrapper>
    </CollectionCardWrapper>
  )
}

export default LargeCard
