import { createTheme, ThemeProvider } from '@mui/material'
import { themeOptions, defaultLocale } from '@trr/mui-theme'
import { Provider } from 'react-redux'
import {
  type AppShellDataProps,
  AppShellDataProvider,
} from '@trr/app-shell-data'
import { store } from './State/Store'
import App from 'App'
import { GTMTracker } from '@trr/gtm-tracking'
import { Redirect, type IdServerUser } from 'Redirect'

const Index = ({
  appShellData,
  oidcUser,
}: {
  appShellData: AppShellDataProps
  oidcUser: IdServerUser
}) => {
  const theme = createTheme(themeOptions, defaultLocale)
  // Only keep the truthy statement when appshell is merged
  if (oidcUser) {
    return (
      <Redirect oidcUser={oidcUser}>
        <ThemeProvider theme={theme}>
          <AppShellDataProvider value={appShellData}>
            <Provider store={store}>
              <GTMTracker mfName={'loginflow'}>
                <App />
              </GTMTracker>
            </Provider>
          </AppShellDataProvider>
        </ThemeProvider>
      </Redirect>
    )
  }
  return (
    <ThemeProvider theme={theme}>
      <AppShellDataProvider value={appShellData}>
        <Provider store={store}>
          <GTMTracker mfName={'loginflow'}>
            <App />
          </GTMTracker>
        </Provider>
      </AppShellDataProvider>
    </ThemeProvider>
  )
}

export default Index
