import { alpha, Box, ButtonBase, styled } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import LoggedInAvatarButton from '../LoggedInAvatarButton'

interface SearchButtonWrapperProps {
  href?: string
}
const SearchButtonWrapper = styled(ButtonBase)<SearchButtonWrapperProps>(
  ({ theme }) => ({
    '&:hover': {
      backgroundColor: theme.palette.action?.hover,
    },
    '&:focus-visible': {
      border: `1px solid ${theme.palette.primary.main}`,
      backgroundColor: theme.palette.action?.focus,
    },
    [theme.breakpoints.down('lg')]: {
      border: 'none',
      padding: theme.spacing(1),
    },
    borderRadius: '24px',
    border: `1px solid ${alpha(theme.palette.primary.main, 0.5)}`,
    padding: theme.spacing(1.5, 3),
    gap: theme.spacing(1),
  })
)

const LoggedInHeader = () => {
  return (
    <Box
      display={'flex'}
      justifyContent={'flex-end'}
      mt={3}
      mr={2.5}
      gap={2.25}
      data-testid="loggedin-header"
    >
      <SearchButtonWrapper sx={{ width: '48px', height: '48px' }} href="/sok">
        <SearchIcon />
      </SearchButtonWrapper>
      <LoggedInAvatarButton />
    </Box>
  )
}

export default LoggedInHeader
