import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { SearchField } from '@local/src/Components/SearchField';
import { SearchResults } from '@local/src/Components/SearchResults';
import { SearchSuggestions } from '@local/src/Components/SearchSuggestions';
import store from '@local/src/Store/configureStore';
import { SearchContextProvider } from '@local/src/Utils/Context';
import { useQuery } from '@local/src/Utils/Hooks';
import { ThemeProvider, createTheme } from '@mui/material';
import { AppShellDataProvider, OptiKeySwitch, OptiKeyView, } from '@trr/app-shell-data';
import { defaultLocale, themeOptions } from '@trr/mui-theme';
import { Provider } from 'react-redux';
export var Pages;
(function (Pages) {
    Pages["Sok"] = "sok";
})(Pages || (Pages = {}));
var App = function (appShellData) {
    var searchQuery = useQuery().searchQuery;
    var theme = createTheme(themeOptions, defaultLocale);
    var shouldShowSearchSuggestions = searchQuery.query.length === 0;
    var shouldShowSearchResults = searchQuery.query.length !== 0;
    var content = appShellData.content;
    return (_jsx(ThemeProvider, { theme: theme, children: _jsx(Provider, { store: store, children: _jsx(AppShellDataProvider, { value: appShellData, children: _jsx(OptiKeySwitch, { children: _jsx(OptiKeyView, { optiKey: Pages.Sok, children: _jsx(SearchContextProvider, { children: _jsxs("section", { children: [_jsx(SearchField, {}), shouldShowSearchSuggestions && _jsx(SearchSuggestions, {}), shouldShowSearchResults && (_jsx(SearchResults, { noSearchResultContent: content }))] }) }) }) }) }) }) }));
};
export default App;
