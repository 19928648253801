import {
  Box,
  IconButton,
  MenuList,
  Popover,
  styled,
  Typography,
} from '@mui/material'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import LogoutIcon from '@mui/icons-material/Logout'

import { useAuthentication } from 'features/Authentication/Hooks'
import { useCachedSettingsState } from 'PublicWebApi/Settings'
import PopoverLink from '../PopoverLink'
import { StyledPopoverButton } from '../PopoverLink/PopoverLink'

const StyledPopover = styled(Popover)(() => ({
  '& .MuiPopover-paper': {
    width: '320px',
    borderRadius: '16px',
  },
}))

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  '&.Mui-focusVisible': {
    color: theme.palette.primary?.main,
  },
}))

const NameWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(0.5),
  padding: theme.spacing(1, 1),
}))

interface AvatarPopoverProps {
  firstName: string | undefined
  anchorEl: HTMLButtonElement | null
  setAnchorEl: (element: HTMLButtonElement | null) => void
  isPublicPage: boolean
}

const AvatarPopover = ({
  firstName,
  anchorEl,
  setAnchorEl,
  isPublicPage,
}: AvatarPopoverProps) => {
  const { logout } = useAuthentication()
  const { userProfileNavigation, authenticatedUserProfileNavigation } =
    useCachedSettingsState()

  const popoverElements = userProfileNavigation?.menuElements
  const popoverElementsAuthenticated =
    authenticatedUserProfileNavigation?.menuElements

  const closePopover = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  const elements = !isPublicPage
    ? popoverElementsAuthenticated
    : popoverElements

  return (
    <StyledPopover
      data-testid="avatar-popover"
      open={open}
      anchorEl={anchorEl}
      onClose={closePopover}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      sx={{ marginTop: 1 }}
    >
      <MenuList>
        <Box
          display={'flex'}
          width="100%"
          tabIndex={-1}
          paddingX={2}
          paddingY={1}
          justifyContent={'space-between'}
        >
          <NameWrapper>
            <Typography variant="subtitle2" color="text.secondary">
              Inloggad som
            </Typography>
            <Typography variant="subtitle1">{firstName}</Typography>
          </NameWrapper>

          <StyledIconButton
            sx={{ alignSelf: 'flex-start' }}
            onClick={closePopover}
          >
            <CloseRoundedIcon />
          </StyledIconButton>
        </Box>
        <Box marginTop={1}>
          {elements?.length > 0 &&
            elements.map((popoverElement, index) => (
              <PopoverLink
                key={`popover-link-${index.toString()}`}
                popoverElement={popoverElement}
                closePopover={closePopover}
              />
            ))}
          <StyledPopoverButton
            onClick={() => {
              logout()
              closePopover()
            }}
            data-testid="popover-logout-button"
          >
            <LogoutIcon />
            Logga ut
          </StyledPopoverButton>
        </Box>
      </MenuList>
    </StyledPopover>
  )
}

export default AvatarPopover
