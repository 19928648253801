import { useGetArbetsuppgifterQuery } from '@local/src/Api/Arbetsuppgift/arbetsuppgiftApi'
import {
  getDeadline,
  getQueryParameters,
  getStatusLabel,
} from '@local/src/Views/Arenden/Arbetsuppgift/helpers'
import {
  Box,
  Button,
  Card,
  Divider,
  Link,
  Stack,
  Typography,
} from '@mui/material'
import React, { useState } from 'react'
import { arendenPath, medarbetareBasepath } from '@local/src/basename'
import { formatDateWithMonthStringFromDateTime as formatMonth } from '@local/Utils/DateTime'
import {
  Actor,
  ArbetsuppgiftCategory,
  ArbetsuppgiftStatus,
} from '@local/src/Views/Arenden/Arbetsuppgift/types'
import {
  useIsFeatureEnabled,
  useUser,
  useEpiContent,
} from '@trr/app-shell-data'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import TogglestatusChip from '@local/src/Views/Arenden/Arbetsuppgift/Table/ToggleStatusChip'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import AddIcon from '@mui/icons-material/Add'
import { IArendeContent } from '@local/src/Views/Case/Case.types'
import HandleArbetsuppgiftMenu from '@local/Components/Arbetsuppgift/EgenArbetsuppgift/HandleArbetsuppgift/HandleArbetsuppgiftMenu'
import CreateArbetsuppgiftModal from '@local/Components/Arbetsuppgift/EgenArbetsuppgift/CreateArbetsuppgiftModal/CreateArbetsuppgiftModal'

interface ArendeArbetsuppgifterProps {
  arendeId: string
}

const ArendeArbetsuppgifter = ({ arendeId }: ArendeArbetsuppgifterProps) => {
  const { arendeArbetsuppgifter }: Partial<IArendeContent> = useEpiContent()
  const user = useUser()
  const { data, isLoading, isUninitialized } = useGetArbetsuppgifterQuery(
    getQueryParameters({
      arendeIds: [arendeId],
      statusar: [ArbetsuppgiftStatus.Ny, ArbetsuppgiftStatus.Pagaende],
      orderBy: { direction: 'desc', property: 'createdDate' },
    })
  )
  const [createArbetsuppgiftModalIsOpen, setCreateArbetsuppgiftModalIsOpen] =
    useState(false)
  const isCreateEgenAretsuppgiftFeatureEnabled = useIsFeatureEnabled(
    'Medarbetare-Microfrontend_createEgenArbetsuppgift_temp_241206'
  )

  if (isLoading || isUninitialized) {
    return <></>
  }

  return (
    <Card variant="outlined" tabIndex={0} sx={{ pb: 1 }}>
      {arendeArbetsuppgifter && (
        <>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            padding={2}
          >
            {isCreateEgenAretsuppgiftFeatureEnabled && (
              <>
                <Button
                  size="small"
                  startIcon={<AddIcon />}
                  onClick={() => setCreateArbetsuppgiftModalIsOpen(true)}
                  data-cy="create-arbetsuppgift-button"
                >
                  Ny uppgift
                </Button>
                <CreateArbetsuppgiftModal
                  arendeId={arendeId}
                  open={createArbetsuppgiftModalIsOpen}
                  onClose={() => setCreateArbetsuppgiftModalIsOpen(false)}
                />
              </>
            )}
            <Button variant="text" size="small" sx={{ p: 0 }}>
              <Link
                href={`${medarbetareBasepath}${arendenPath}/att-gora`}
                variant="body1"
                sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
              >
                Att göra
              </Link>
            </Button>
          </Box>
          <Divider />
        </>
      )}
      <Stack display="flex" flexDirection="column">
        {data?.results.length > 0 ? (
          <>
            {data?.results.map((arbetsuppgift, index) => (
              <Box
                key={arbetsuppgift.id}
                display="flex"
                flexDirection="column"
                gap={1}
                p={2}
                borderBottom={
                  index < data.results.length - 1 &&
                  '1px solid rgba(0, 0, 0, 0.12)'
                }
              >
                <Box display="flex" flexDirection="row" gap={0.5}>
                  {arbetsuppgift.status === ArbetsuppgiftStatus.Ny &&
                    arbetsuppgift?.owner?.id === user.mdid && (
                      <FiberManualRecordIcon
                        color="primary"
                        data-testid={`newstatusicon-${arbetsuppgift.id}`}
                      />
                    )}
                  <Typography variant="subtitle1">
                    {arbetsuppgift.header}
                  </Typography>
                  {arbetsuppgift.category === ArbetsuppgiftCategory.Egen && (
                    <Box sx={{ marginLeft: 'auto' }}>
                      <HandleArbetsuppgiftMenu arbetsuppgift={arbetsuppgift} />
                    </Box>
                  )}
                </Box>
                {arbetsuppgift.body && (
                  <Typography variant="subtitle2" color="text.secondary">
                    {arbetsuppgift.body}
                  </Typography>
                )}
                {getDeadline(arbetsuppgift.dueDate)}
                {arbetsuppgift.latestStatusUpdate?.triggeredBy ===
                  Actor.User && (
                  <Typography variant="caption" color="text.secondary">
                    {`${getStatusLabel(arbetsuppgift.status)} ${formatMonth(arbetsuppgift.latestStatusUpdate.occuredAt)} - `}
                    {arbetsuppgift.latestStatusUpdate.user.id === user.mdid
                      ? 'Jag'
                      : `${arbetsuppgift.latestStatusUpdate.user.firstName} ${arbetsuppgift.latestStatusUpdate.user.lastName}`}
                  </Typography>
                )}
                {arbetsuppgift.latestStatusUpdate?.triggeredBy ===
                  Actor.System && (
                  <Typography variant="caption" color="text.secondary">
                    {getStatusLabel(arbetsuppgift.status)}{' '}
                    {formatMonth(arbetsuppgift.latestStatusUpdate.occuredAt)} -
                    Systemet
                  </Typography>
                )}
                <TogglestatusChip
                  id={arbetsuppgift.id}
                  status={arbetsuppgift.status}
                  category={arbetsuppgift.category}
                />
              </Box>
            ))}
          </>
        ) : (
          <Typography variant="subtitle2" mb={1} mt={2} ml={2}>
            Just nu finns inga uppgifter
          </Typography>
        )}
      </Stack>
    </Card>
  )
}

export default ArendeArbetsuppgifter
