import PersonalInformation from 'Components/PersonalInformation'
import { Box, type SxProps, type Theme } from '@mui/material'
import userRoleChecks from 'Utils/Helpers/userRolesChecks'
import { useAuthentication } from '@trr/app-shell-data'
import { ReactNode } from 'react'

const BaseLayout = ({ children }: { children: ReactNode }) => {
  const { userRoles } = useAuthentication()
  const { isOnboarding, isMissingEmail } = userRoleChecks(userRoles)

  const shouldShowUserInformation = isOnboarding || isMissingEmail

  type StyleNames = 'baseLayout' | 'content'
  const baseLayoutStyles: Record<StyleNames, SxProps<Theme>> = {
    baseLayout: {
      minHeight: '100%',
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },
    content: {
      flexGrow: 1,
      marginBottom: 9,
    },
  }

  return (
    <Box sx={baseLayoutStyles.baseLayout}>
      {shouldShowUserInformation && <PersonalInformation />}
      <Box sx={baseLayoutStyles.content}>{children}</Box>
    </Box>
  )
}

export default BaseLayout
