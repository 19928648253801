import { useSliceStateSelector } from 'State/Slices'

interface SubCategory {
  [key: string]: string
}

interface TextsObject {
  [key: string]: string | SubCategory
}

type ReducedTextsObject<T extends TextsObject> = {
  [K in keyof T]: string
}

const useTexts = <T extends TextsObject>(texts: T): ReducedTextsObject<T> => {
  const { userType } = useSliceStateSelector((state) => state.slices.inputs)

  // Create key-value object based on user type
  const reducedTextsObject = Object.keys(texts).reduce((acc, key) => {
    const value = texts[key]
    acc[key as keyof T] = typeof value === 'object' ? value[userType] : value
    return acc
  }, {} as ReducedTextsObject<T>)

  return reducedTextsObject
}

export default useTexts
