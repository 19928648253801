import { JSX } from 'react'
import { usePickEpiContent } from '@local/Utils/Hooks'
import type { ITidigareSysselsattningarContent } from '@local/Views/TidigareSysselsattningarOld/TidigareSysselsattningar.types'
import { KompletteraStatusType } from '@local/Views/DinaAnsokningar/TidigareSysselsattningarList/TidigareSysselsattningar.types'
import Spinner from '@local/Components/Spinner'
import HTMLMapper from '@local/Components/HTMLMapper'
import { useGetKanKomplettera } from '@local/Utils/Hooks/TidigareSysselsattningar'

import KompletteraForm from './KompletteraForm'

const TidigareSysselsattningarOld = (): JSX.Element => {
  const content = usePickEpiContent<ITidigareSysselsattningarContent>()

  const { data: kompletteraStatus, isLoading } = useGetKanKomplettera()

  if (isLoading) {
    return <Spinner centered />
  }

  if (kompletteraStatus !== KompletteraStatusType.KanKomplettera) {
    return <HTMLMapper body={content?.duHarRedanKompletterat.mainBody} />
  }

  return <KompletteraForm content={content} />
}

export default TidigareSysselsattningarOld
