import Help from '@mui/icons-material/Help'
import Close from '@mui/icons-material/Close'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
} from '@mui/material'
import { useState } from 'react'
import { IILivslinjenContent } from 'App/App.types'
import { getAppConfig, useEpiContent } from '@trr/app-shell-data'
import { HTMLMapper } from '@trr/html-mapper'
import useDeviceSize from 'utils/hooks/useDeviceSize'

const HowTo = () => {
  const { smallScreensUp } = useDeviceSize()
  const {
    howTo: {
      heading,
      preamble,
      content,
      button: { label: buttonLabel },
    },
  } = useEpiContent<IILivslinjenContent>()
  const [open, setOpen] = useState(false)
  const {
    COMMON: { MEDIA_URL },
  } = getAppConfig()

  const handleClose = () => {
    setOpen(false)
  }

  const handleOpen = () => {
    setOpen(true)
  }

  return (
    <>
      <Button
        variant="text"
        size="medium"
        startIcon={<Help />}
        sx={{ ml: { sm: 0, md: 2 }, mt: { xs: 3, sm: 0 } }}
        onClick={handleOpen}
      >
        {buttonLabel}
      </Button>

      <Dialog
        fullScreen={!smallScreensUp}
        scroll="paper"
        open={open}
        fullWidth
        maxWidth="md"
        onClose={handleClose}
        aria-labelledby="howto-title"
        aria-describedby="howto-description"
      >
        <DialogTitle id="howto-title" sx={{ maxWidth: { xs: '90%' } }}>
          {heading}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <Close />
        </IconButton>
        <DialogContent>
          <DialogContentText tabIndex={0} id="howto-description" sx={{ mb: 3 }}>
            <Box pb={2}>{preamble}</Box>
            <Divider />
            <Box mt={2}>
              <HTMLMapper mediaUrl={MEDIA_URL} body={content?.mainBody ?? ''} />
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default HowTo
