import { Dropdown, RadioButtonGroup } from '@local/src/components/FormComponents'
import React from 'react'
import { useGetCurrentStudiestodansokan } from '@local/src/features/StudiestodAnsokan/Hooks'
import { formatToDate } from '@local/src/utils/helpers/dateTimeFormatHelper'
import { Box, Stack, Typography } from '@mui/material'
import LabelValue from '@local/src/features/StudiestodAnsokan/Components/LabelValue/LabelValue'
import { useTranslation } from 'react-i18next'
import { mojligaArUtbetalningsstart } from '@local/src/utils/helpers/studiestodAnsokanHelper'

interface Props {
    utbildningsIndex: number
    isReadonly: boolean
}

const PreliminarErsattning = ({ utbildningsIndex, isReadonly }: Props) => {
    const { t } = useTranslation('translation', { keyPrefix: 'studiestodAnsokan.steps.radgivarensUnderlag' })

    const { studiestodAnsokan } = useGetCurrentStudiestodansokan()
    const beraknadStudiestartValue = studiestodAnsokan?.utbildningar?.[utbildningsIndex]?.studiestart

    return (
        <Stack spacing={2}>
            <Typography variant="h6">{t('preliminarErsattningHeader')}</Typography>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <Box flex={1}>
                    <LabelValue label={t('beraknadStudiestartText')} value={formatToDate(beraknadStudiestartValue)} />
                </Box>
                <Stack flex={1}>
                    <Typography variant="caption" color="text.secondary">
                        {t('beraknadUtbetalningstartHeader')}
                    </Typography>
                    <Box mt={1}>
                        <Dropdown
                            name={`utbildningar.${utbildningsIndex}.studieersattning.beraknadUtbetalningsstart.year`}
                            label="År"
                            options={mojligaArUtbetalningsstart()}
                            disabled={isReadonly}
                        />
                    </Box>
                    <RadioButtonGroup
                        name={`utbildningar.${utbildningsIndex}.studieersattning.beraknadUtbetalningsstart.termin`}
                        testId={`utbildningar.${utbildningsIndex}.studieersattning.beraknadUtbetalningsstart.termin`}
                        label=""
                        valueType="number"
                        row
                        isReadOnly={isReadonly}
                        radios={[
                            {
                                label: t('varTerminLabel'),
                                value: 0,
                                disabled: isReadonly,
                            },
                            {
                                label: t('hostTerminLabel'),
                                value: 1,
                                disabled: isReadonly,
                            },
                        ]}
                    />
                </Stack>
            </Stack>
        </Stack>
    )
}

export default PreliminarErsattning
