import { JSX } from 'react'
import { usePickEpiContent } from '@local/Utils/Hooks'
import type { ITidigareSysselsattningarContent } from '@local/Views/TidigareSysselsattningar/TidigareSysselsattningar.types'
import { KompletteraStatusType } from '@local/Views/DinaAnsokningar/TidigareSysselsattningarList/TidigareSysselsattningar.types'
import Spinner from '@local/Components/Spinner'
import HTMLMapper from '@local/Components/HTMLMapper'
import { useGetKanKomplettera } from '@local/Utils/Hooks/TidigareSysselsattningar'
import { useGetTidigareSysselsattningarDraftsQuery } from '@local/services/API/tidigareSysselsattningar/tidigareSysselsattningarApi'
import { useAuthentication } from '@trr/app-shell-data'

import KompletteraForm from './KompletteraForm'

const TidigareSysselsattningar = (): JSX.Element => {
  const content = usePickEpiContent<ITidigareSysselsattningarContent>()
  const { sub: klientId } = useAuthentication()

  const { data: kompletteraStatus, isLoading } = useGetKanKomplettera()
  const {
    data: tidigareSysselsattningarDrafts,
    isLoading: isLoadingTidigareSysselsattningarDrafts,
  } = useGetTidigareSysselsattningarDraftsQuery(klientId)

  if (isLoading || isLoadingTidigareSysselsattningarDrafts) {
    return <Spinner centered />
  }

  if (kompletteraStatus !== KompletteraStatusType.KanKomplettera) {
    return <HTMLMapper body={content.duHarRedanKompletterat.mainBody} />
  }

  return (
    <KompletteraForm
      content={content}
      tidigareSysselsattningarDrafts={tidigareSysselsattningarDrafts}
    />
  )
}

export default TidigareSysselsattningar
