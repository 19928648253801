import { RootState } from 'store'
import { roles } from 'utils/roles'

const enforceRoles = [roles.MissingEmail, roles.MissingMobile] as const

const selectUserRoles = (state: RootState) => state.slices.auth.profile.roles

export const selectShouldEnforceRoleForFunctionTypes =
  (functionTypes: string[], currentFunctionType: string) =>
  (state: RootState) => {
    const userRoles = selectUserRoles(state)

    const shouldEnforceForFunctionType = functionTypes.some(
      (functionType) => functionType === currentFunctionType
    )
    const shouldEnforceForRole = enforceRoles.some((role) =>
      userRoles.includes(role)
    )
    const shouldEnforce = shouldEnforceForFunctionType && shouldEnforceForRole

    return shouldEnforce
  }
