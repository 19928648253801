import React from 'react'
import { Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { formatDateTime } from '@local/src/Utils/dateFormatHelper'
import { YttrandeKommentarApiModel } from '@local/src/Services/SwaggerApi/data-contracts'

interface Props {
	kommentar: YttrandeKommentarApiModel
}

const YttrandeCardKommentar = ({ kommentar }: Props) => {
	const { t } = useTranslation('translation', { keyPrefix: 'csnPortal.yttranden.yttrandeCard' })

	if (!kommentar?.content) return null

	const kommentarLastUpdatedAt = formatDateTime(kommentar?.lastUpdatedAt)

	return (
		<Stack maxWidth={0.5} data-testid="yttrande-card-kommentar">
			<Typography fontWeight="bold" variant="subtitle2">
				{t('yttrandeCardKommentarTitle')}
			</Typography>
			<Typography variant="subtitle2" fontStyle="italic" sx={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>
				{kommentar.content}
			</Typography>
			<Typography
				mt={1}
				fontStyle="italic"
				variant="subtitle2"
				color="text.secondary"
			>{`${t('yttrandeCardKommentarLastUpdatedAt')}: ${kommentarLastUpdatedAt}`}</Typography>
		</Stack>
	)
}

export default YttrandeCardKommentar
