import { Button, Typography } from '@mui/material'
import React, { useState } from 'react'
import { Loader } from '@local/src/Components'
import { useTranslation } from 'react-i18next'
import { useCommonTranslation } from '@local/src/Hooks'

import { useBegaranYttrandeKommentar } from './Hooks/useBegaranYttrandeKommentar'
import { KommentarInput, LatestKommentar } from './Components'

interface Props {
	referenceId: string
}

const BegaranYttrandeKommentar = ({ referenceId }: Props) => {
	const { t } = useTranslation('translation', { keyPrefix: 'begaranYttrande.info.begaranYttrandeKommentar' })
	const { tCommon } = useCommonTranslation()
	const [isEditing, setIsEditing] = useState(false)

	const { latestKommentar, isError: isApiError, error: apiError, isLoading, userHasValidRole } = useBegaranYttrandeKommentar(referenceId)

	if (isLoading) return <Loader />
	if (isApiError && !isLoading)
		return <Typography color="error">{apiError?.error?.errorMessage ?? tCommon('errors.generic_error')}</Typography>

	if (isEditing) {
		return <KommentarInput isEditing={isEditing} setIsEditing={setIsEditing} referenceId={referenceId} />
	}

	if (latestKommentar) {
		return <LatestKommentar setIsEditing={setIsEditing} referenceId={referenceId} />
	}

	return (
		userHasValidRole && (
			<Button size="small" onClick={() => setIsEditing(true)}>
				{t('createButtonText')}
			</Button>
		)
	)
}

export default BegaranYttrandeKommentar
