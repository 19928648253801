import { useMemo } from 'react'

interface LogoProps {
  color?: string
  height?: number
  width?: number
  filled?: boolean
  iconClass?: string
  viewBox?: string
  style?: React.CSSProperties
  title?: string
  description?: string
  testSelector?: string
  preserveAspectRatio?: string
}

const generateRandomId = () =>
  Math.random().toString(36).substring(2, 15) +
  Math.random().toString(36).substring(2, 15)

const logoHeightToWidthRatio = 26 / 11

const useHost = () => {
  const host = useMemo(() => {
    const hostname = window?.location?.hostname

    switch (hostname) {
      case 'localhost':
        return 'local'
      case 'dev-next.trr.se':
        return 'DEV'
      case 'test-next.trr.se':
        return 'TEST'
      default:
        return undefined
    }
  }, [])

  return host
}

const Logo = ({
  color,
  height = 32,
  width = height * logoHeightToWidthRatio,
  title = 'Logo',
  description = 'TRR',
  viewBox = '0 0 426 167',
  style = { fill: '#4c3e7a' },
  testSelector = 'Logo',
  ...props
}: LogoProps) => {
  const titleId = generateRandomId()
  const descId = generateRandomId()
  const host = useHost()

  return (
    <div style={{ position: 'relative' }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        role="img"
        aria-labelledby={`${titleId} ${descId}`}
        data-testid={testSelector}
        preserveAspectRatio="xMidYMid meet"
        style={style}
        width={width}
        height={height}
        viewBox={viewBox}
        {...props}
      >
        <title id={titleId}>{title}</title>
        <desc id={descId}>{description}</desc>

        <g id="Page-1" stroke="none" strokeWidth="1" fillRule="evenodd">
          <g
            id="Group"
            transform="translate(0.500000, 0.400000)"
            fillRule="nonzero"
          >
            <polygon
              id="Shape"
              fill={color}
              points="0 0 0 23.7 46.7 23.7 46.7 161 73.2 161 73.2 23.7 120.1 23.7 120.1 0"
            />
            <path
              fill={color}
              d="M188.2,89 C205.7,89 218.6,75.2 218.6,56.3 C218.6,37.4 205.7,23.7 188.2,23.7 L156.9,23.7 L156.9,89 L188.2,89 Z M210.7,109 L242.5,161 L212.4,161 L196.5,134.9 C188.1,121.1 173.1,112.7 157,112.7 L157,161 L130.5,161 L130.5,0 L189.9,0 C222.1,0 245.1,23.5 245.1,56.3 C245,81.4 231.4,101.2 210.7,109"
              id="Shape"
            />
            <path
              fill={color}
              d="M412.3,135.5 C406.2,138 403,139.5 394.4,140.5 C367.3,143.5 352.3,128.5 336.6,109 C357.3,101.2 370.9,81.4 370.9,56.3 C370.9,23.4 347.9,0 315.7,0 L256.3,0 L256.3,161 L282.8,161 L282.8,112.7 C295.2,112.7 307.1,117.7 315.8,126.5 C315.8,126.5 326,137.6 332.1,143.5 C340.3,151.4 361.9,168 397.5,165.4 C408.4,164.6 417.4,161.3 424.9,159 L412.3,135.5 Z M314.1,89 L282.8,89 L282.8,23.7 L314.1,23.7 C331.6,23.7 344.5,37.5 344.5,56.4 C344.5,75.3 331.6,89 314.1,89"
              id="Shape"
            />
          </g>
        </g>
      </svg>
      {host && (
        <svg
          width="50"
          height="30"
          xmlns="http://www.w3.org/2000/svg"
          className="logo-water-stamp"
          style={{
            position: 'absolute',
            transform: 'rotate(66deg)',
            right: -27,
            top: -2,
          }}
        >
          <text
            x="50%"
            y="50%"
            dominantBaseline="middle"
            textAnchor="middle"
            fontSize="16"
            fill="red"
            fontWeight="bold"
          >
            {host}
          </text>
        </svg>
      )}
    </div>
  )
}

export default Logo
