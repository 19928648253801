import React, { useEffect } from 'react'
import { GridPaginationModel, GridSortModel } from '@mui/x-data-grid-pro'
import { Box, useMediaQuery, useTheme } from '@mui/material'
import { useUser, useIsFeatureEnabled } from '@trr/app-shell-data'
import DataGridWrapper from '@local/src/Components/DataGridWrapper/DataGridWrapper'

import {
  Arbetsuppgift,
  ArbetsuppgiftFilter,
  ArbetsuppgiftOrderBy,
  ArbetsuppgiftPagination,
} from '../types'

import { getColumnVisibility, getColumns } from './ArbetsuppgiftTableColumns'

interface ArbetsuppgiftTableProps {
  rows: Arbetsuppgift[]
  totalHits?: number
  page: string
  pageSize: string
  orderBy: ArbetsuppgiftOrderBy
  onPaginationChange: (
    key: keyof ArbetsuppgiftFilter,
    value: ArbetsuppgiftPagination
  ) => void
  onSortChange: (
    key: keyof ArbetsuppgiftFilter,
    value: ArbetsuppgiftOrderBy
  ) => void
}

const ArbetsuppgiftTable = ({
  rows,
  totalHits,
  page,
  pageSize,
  orderBy,
  onPaginationChange,
  onSortChange,
}: ArbetsuppgiftTableProps) => {
  const [currentPage, setCurrentPage] = React.useState(page)
  const [currentPageSize, setCurrentPageSize] = React.useState(pageSize)
  const currentUser = useUser().mdid
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const paginationFilterKey = 'pagination'
  const orderByFilterKey = 'orderBy'
  const isCreateEgenAretsuppgiftFeatureEnabled = useIsFeatureEnabled(
    'Medarbetare-Microfrontend_createEgenArbetsuppgift_temp_241206'
  )

  const transformedRows: Arbetsuppgift[] = rows.map((row) => {
    const owner = row.owner
    let ownerName = ''

    if (owner) {
      ownerName = owner.id === currentUser ? 'Jag' : owner.fullName
    }

    return {
      ...row,
      owner: {
        ...row.owner,
        fullName: ownerName,
      },
    }
  })

  useEffect(() => {
    onPaginationChange(paginationFilterKey, {
      page: currentPage,
      pageSize: currentPageSize,
    })
  }, [currentPage])

  useEffect(() => {
    onPaginationChange(paginationFilterKey, {
      page: '1',
      pageSize: currentPageSize,
    })
  }, [currentPageSize])

  useEffect(() => {
    setCurrentPage(page)
    setCurrentPageSize(pageSize)
  }, [page, pageSize])

  const handlePaginationChange = (params: GridPaginationModel) => {
    setCurrentPage((params.page + 1).toString())
    setCurrentPageSize(params.pageSize.toString())
  }

  const handleSortModelChange = (model: GridSortModel) => {
    if (model.length === 0) {
      onSortChange(orderByFilterKey, undefined)
    } else {
      onSortChange(orderByFilterKey, {
        property: model[0].field,
        direction: model[0].sort,
      })
    }
  }

  return (
    <Box mt={2}>
      <DataGridWrapper
        rows={transformedRows}
        columns={getColumns(isMobile, currentUser)}
        sortModel={
          orderBy ? [{ field: orderBy.property, sort: orderBy.direction }] : []
        }
        paginationModel={{ page: Number(page) - 1, pageSize: Number(pageSize) }}
        columnVisibilityModel={getColumnVisibility(
          isMobile,
          isCreateEgenAretsuppgiftFeatureEnabled
        )}
        onSortModelChange={handleSortModelChange}
        onPaginationModelChange={handlePaginationChange}
        isMobile={isMobile}
        getRowId={(row: Arbetsuppgift) => row.id}
        rowCount={totalHits}
        paginationMode="server"
        sortingMode="server"
      />
    </Box>
  )
}

export default ArbetsuppgiftTable
