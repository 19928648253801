import React, { useRef, useState } from 'react'
import {
  Box,
  capitalize,
  FormControlLabel,
  List,
  ListItem,
  Stack,
  Switch,
  Typography,
} from '@mui/material'
import { LocationOn, Person, WatchLater, Event } from '@mui/icons-material'
import { useTrackCustomClick } from '@trr/gtm-tracking'
import { format } from 'date-fns'
import { sv } from 'date-fns/locale'
import { Location } from 'src/types/Location'
import { useGetMunicipalitiesQuery } from 'src/api/locations/locations'
import { IconText } from 'src/components/IconText/IconText'
import { CreditTypes } from 'src/types/CreditTypes'
import { EducationLanguage } from 'src/types/EducationLanguage'
import { EducationLevel } from 'src/types/EducationLevel'
import { RecommendedPriorKnowledge } from 'src/types/RecommendedPriorKnowledge'
import { TimeOfStudy } from 'src/types/TimeOfStudy'
import type { Education } from '../../types/Education.types'
import EducationDescription from '../EducationDescription/EducationDescription'
import EducationSummary from '../EducationDescription/EducationSummary'
import { useTranslation } from 'react-i18next'

interface EducationDetailsProps {
  isSummary?: boolean
  hideBorder?: boolean
  education: Education
  headerComponent: string
  subheader?: string
}

const EducationDetails: React.FC<EducationDetailsProps> = ({
  isSummary = true,
  education,
  headerComponent,
  subheader,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'utbildning' })
  const trackCustomClick = useTrackCustomClick()
  const [showFormattedText, setShowFormattedText] = useState<boolean>(true)

  const { isFetching: isFetchingMunicipalities, data = [] as Location[] } =
    useGetMunicipalitiesQuery()

  const ref = useRef<HTMLDivElement | null>(null)
  const HeaderComponent = ({ ...props }) =>
    React.createElement(headerComponent, props)

  const subHeading = () => {
    if (subheader) return subheader

    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    return `${education?.credits?.credits ? education?.credits?.credits : ''} ${CreditTypes[education?.credits?.code] ? CreditTypes[education?.credits?.code] + ',' : ''} ${education?.provider ? education?.provider + ',' : ''} ${education?.educationForm ? capitalize(education?.educationForm ?? '') + ',' : ''} ${education?.educationType ? capitalize(education?.educationType ?? '') : ''}`
  }

  return (
    <>
      <Stack
        data-testid="education-details"
        ref={ref}
        justifyContent="space-between"
        alignItems={'baseline'}
        direction={{ xs: 'column', sm: 'row' }}
      >
        <Typography
          data-testid="education-title"
          gutterBottom
          variant="h5"
          component={HeaderComponent}
          sx={{ flexBasis: { sm: '800px' } }}
        >
          {education?.title} {education?.code ? `(${education?.code})` : ''}
        </Typography>
        {!isSummary && (
          <FormControlLabel
            sx={{ flex: { sm: '0 0 232px' } }}
            control={
              <Switch
                data-testid="formatted-text-switch"
                color="primary"
                checked={showFormattedText}
                onChange={() => {
                  setShowFormattedText(!showFormattedText)

                  trackCustomClick(
                    `Visa ai-formaterad text: ${!showFormattedText ? 'på' : 'av'}`
                  )
                }}
                name={t('formateradText')}
              />
            }
            label={t('formateradText')}
          />
        )}
      </Stack>
      <Typography
        color="text.secondary"
        gutterBottom
        variant="subtitle2"
        component="p"
      >
        {subHeading()}
      </Typography>
      <Stack sx={{ mt: 2, mb: 2 }} direction={{ xs: 'column', md: 'row' }}>
        {education?.paceOfStudyPercentage && (
          <IconText
            label={`${t('takt')} ${education.paceOfStudyPercentage.toString()}%`}
            icon={<WatchLater color="primary" />}
          />
        )}
        {education?.distance ? (
          <IconText
            label={t('distans')}
            icon={<LocationOn color="primary" />}
          />
        ) : (
          <IconText
            label={
              !isFetchingMunicipalities
                ? (data.find((f) => f.id === education?.municipalityCodes[0])
                    ?.name ?? '')
                : ''
            }
            icon={<LocationOn color="primary" />}
          />
        )}

        <IconText
          label={
            education?.languageOfInstruction &&
            EducationLanguage[education.languageOfInstruction]
          }
          icon={<Person color="primary" />}
        />

        {education?.eventInformation?.startDate &&
          education?.eventInformation?.endDate && (
            <IconText
              label={
                education?.eventInformation.startDate
                  ? `${format(
                      new Date(education?.eventInformation.startDate),
                      'd MMM yyyy',
                      { locale: sv }
                    )} - ${format(
                      new Date(education?.eventInformation.endDate),
                      'd MMM yyyy',
                      { locale: sv }
                    )}`
                  : ''
              }
              icon={<Event color="primary" />}
            />
          )}
      </Stack>
      <Box sx={{ minHeight: isSummary ? '70px' : '300px' }}>
        {!isSummary ? (
          <EducationDescription
            lastEdited={education?.lastEdited}
            id={education?.id}
            showFormattedText={showFormattedText}
            text={education?.description}
          />
        ) : (
          <EducationSummary
            lastEdited={education?.lastEdited}
            id={education?.id}
            showFormattedText={showFormattedText}
            text={education?.description}
          />
        )}
      </Box>
      <List
        sx={{
          columns: { sx: '1', sm: '2' },
          maxWidth: '500px',
          marginBottom: 3,
          marginTop: 3,
          paddingTop: 0,
          paddingBottom: 0,
        }}
      >
        <ListItem sx={{ border: 'none', padding: '0  16px 0 0' }}>
          <Typography
            variant="subtitle2"
            component="span"
            color="text.secondary"
          >
            {t('niva')}:{' '}
            {education?.educationLevel
              ? EducationLevel[education.educationLevel]
              : '-'}
          </Typography>
        </ListItem>
        <ListItem sx={{ border: 'none', padding: '0  16px 0 0' }}>
          <Typography
            variant="subtitle2"
            component="span"
            color="text.secondary"
          >
            {t('forkunskap')}:{' '}
            {education?.recommendedPriorKnowledge
              ? RecommendedPriorKnowledge[education.recommendedPriorKnowledge]
              : '-'}
          </Typography>
        </ListItem>
        <ListItem sx={{ border: 'none', padding: '0  16px 0 0' }}>
          <Typography
            variant="subtitle2"
            component="span"
            color="text.secondary"
          >
            {t('typ')}: {capitalize(education?.educationForm ?? '')}
          </Typography>
        </ListItem>
        <ListItem sx={{ border: 'none', padding: '0  16px 0 0' }}>
          <Typography
            variant="subtitle2"
            component="span"
            color="text.secondary"
          >
            {t('poang')}: {education?.credits?.credits ?? '-'}{' '}
            {education?.credits?.code && CreditTypes[education.credits.code]}
          </Typography>
        </ListItem>
        <ListItem sx={{ border: 'none', padding: '0  16px 0 0' }}>
          <Typography
            variant="subtitle2"
            component="span"
            color="text.secondary"
          >
            {t('tid')}:{' '}
            {education?.timeOfStudy ? TimeOfStudy[education.timeOfStudy] : '-'}
          </Typography>
        </ListItem>
        <ListItem sx={{ border: 'none', padding: '0  16px 0 0' }}>
          <Typography
            variant="subtitle2"
            component="span"
            color="text.secondary"
          >
            {t('takt')}:{' '}
            {education?.paceOfStudyPercentage
              ? `${education.paceOfStudyPercentage.toString()}%`
              : '-'}
          </Typography>
        </ListItem>
        <ListItem sx={{ border: 'none', padding: '0  16px 0 0' }}>
          <Typography
            variant="subtitle2"
            component="span"
            color="text.secondary"
          >
            {t('sprak')}:{' '}
            {education?.languageOfInstruction
              ? EducationLanguage[education.languageOfInstruction]
              : '-'}
          </Typography>
        </ListItem>
      </List>
    </>
  )
}

export default EducationDetails
