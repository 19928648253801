import {
  IDeleteBilagaPayloadModel,
  IPostTidigareSysselsattningarPayloadModel,
  ITidigareSysselsattning,
  IUploadBilagaPayloadModel,
  KompletteraStatusType,
} from '@local/Views/DinaAnsokningar/TidigareSysselsattningarList/TidigareSysselsattningar.types'
import {
  ITidigareSysselsattningDraftsResponseModel,
  TidigareSysselsattningResponseModel,
} from '@local/services/API/tidigareSysselsattningar/Types'
import { IPatchKompletteringPayloadModel } from '@local/services/Types/apiTypes'
import { mapKompletteringValuesToPatchModel } from '@local/services/Helpers'

import { baseApi } from '../baseApi'

export const tidigareSysselsattningarApi = baseApi.injectEndpoints({
  overrideExisting: false,

  endpoints: (builder) => ({
    getTidigareSysselsattningarOld: builder.query<
      ITidigareSysselsattning[],
      string
    >({
      query: (userSub) => `/ansokan/tidigareanstallningar/klient/${userSub}`,
      providesTags: [{ type: 'tidigareSysselsattningarOld', id: 'LIST' }],
    }),
    getTidigareSysselsattningar: builder.query<
      TidigareSysselsattningResponseModel[],
      string
    >({
      query: (userSub) => `omfattningsbeslut/komplettering/klient/${userSub}`,
      providesTags: [{ type: 'tidigareSysselsattningar', id: 'LIST' }],
    }),
    getKompletteraStatus: builder.query<KompletteraStatusType, string>({
      query: (userSub) =>
        `/ansokan/tidigareanstallningar/klient/${userSub}/kankomplettera`,
      providesTags: [{ type: 'kompletteraStatus' }],
    }),
    getKanKomplettera: builder.query<KompletteraStatusType, string>({
      query: (userSub) =>
        `/omfattningsbeslut/komplettering/klient/${userSub}/kankomplettera`,
      providesTags: [{ type: 'kanKomplettera' }],
    }),
    postTidigareSysselsattningarOld: builder.mutation<
      Response,
      IPostTidigareSysselsattningarPayloadModel
    >({
      query: ({ kompletteringar, userSub }) => ({
        url: `/ansokan/tidigareanstallningar/klient/${userSub}/formular`,
        method: 'POST',
        body: kompletteringar,
      }),
      invalidatesTags: (_result, error) =>
        error
          ? []
          : [
              { type: 'tidigareSysselsattningar', id: 'LIST' },
              { type: 'tidigareSysselsattningarOld', id: 'LIST' },
              { type: 'kompletteraStatus' },
              { type: 'kanKomplettera' },
              { type: 'ansokan' },
            ],
    }),
    postTidigareSysselsattningar: builder.mutation<
      Response,
      IPostTidigareSysselsattningarPayloadModel
    >({
      query: ({ kompletteringar, userSub }) => ({
        url: `/omfattningsbeslut/komplettering/klient/${userSub}/skickain`,
        method: 'POST',
        body: kompletteringar,
      }),
      invalidatesTags: (_result, error) =>
        error
          ? []
          : [
              { type: 'tidigareSysselsattningar', id: 'LIST' },
              { type: 'tidigareSysselsattningarOld', id: 'LIST' },
              { type: 'kompletteraStatus' },
              { type: 'kanKomplettera' },
              { type: 'ansokan' },
            ],
    }),
    patchKomplettering: builder.mutation<void, IPatchKompletteringPayloadModel>(
      {
        query: ({ values }) => ({
          url: `tempPatchKomplettering/todo`,
          method: 'PATCH',
          body: mapKompletteringValuesToPatchModel(values),
        }),
      }
    ),
    uploadBilaga: builder.mutation<void, IUploadBilagaPayloadModel>({
      query: ({ formData, userSub }) => ({
        url: `ansokan/bilagor/komplettering/klient/${userSub}/bilaga`,
        method: 'POST',
        body: formData,
      }),
    }),
    deleteBilaga: builder.mutation<void, IDeleteBilagaPayloadModel>({
      query: ({ bilagaId, userSub }) => ({
        url: `ansokan/bilagor/komplettering/klient/${userSub}/bilaga/${bilagaId}`,
        method: 'DELETE',
      }),
    }),
    getTidigareSysselsattningarDrafts: builder.query<
      ITidigareSysselsattningDraftsResponseModel[],
      string
    >({
      query: (userSub) =>
        `/omfattningsbeslut/komplettering/klient/${userSub}/drafts`,
      providesTags: [{ type: 'tidigareSysselsattningarDrafts', id: 'LIST' }],
    }),
  }),
})

export const {
  useGetTidigareSysselsattningarOldQuery,
  useGetTidigareSysselsattningarQuery,
  useGetKompletteraStatusQuery,
  useGetKanKompletteraQuery,
  usePostTidigareSysselsattningarMutation,
  usePostTidigareSysselsattningarOldMutation,
  usePatchKompletteringMutation,
  useUploadBilagaMutation,
  useDeleteBilagaMutation,
  useGetTidigareSysselsattningarDraftsQuery,
} = tidigareSysselsattningarApi
