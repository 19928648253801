import { isOvrigSysselsattning } from '@local/Utils/Helpers/TidigareSysselsattningar.helpers'
import {
  IKomplettering,
  IKompletteringApiModel,
} from '@local/Views/TidigareSysselsattningarOld/KompletteraForm/KompletteraForm.types'
import { omit } from 'ramda'

export const getMappedKompletteraFormValues = (
  kompletteringar: IKomplettering[],
  villkorstext: string
): IKompletteringApiModel[] =>
  kompletteringar.map((komplettering) => ({
    ...omit(['arbetsgivarintyg'], komplettering),
    villkorstext: isOvrigSysselsattning(komplettering.sysselsattningstyp)
      ? villkorstext
      : null,
  }))
