/* eslint-disable no-case-declarations */
import { RadgivarensUnderlagForm } from '@local/src/features/StudiestodAnsokan/Components/Steps/RadgivarensUnderlag/Models/RadgivarensUnderlagForm'
import { StudiestodTypApiModel, UtbildningUnderlagApiModel } from '@local/src/services/SwaggerApi/data-contracts'
import { isNil, clone } from 'ramda'
import { FieldPath, UseFormResetField, UseFormSetValue } from 'react-hook-form'

export type StudiestodType = keyof StudiestodTypApiModel
export const getStudiestodTypPath = (utbildningsIndex: number, studiestodToDelete: StudiestodType): FieldPath<RadgivarensUnderlagForm> =>
    `utbildningar.${utbildningsIndex}.studiestodTyp.${studiestodToDelete}`

export const getStudiestodObjectPath = (
    utbildningsIndex: number,
    studiestodToDelete: StudiestodType
): FieldPath<RadgivarensUnderlagForm> | undefined => {
    switch (studiestodToDelete) {
        case 'hasKoptUtbildning':
            return `utbildningar.${utbildningsIndex}.koptUtbildning`
        case 'hasKortvarigtStudiestod':
            return `utbildningar.${utbildningsIndex}.kortvarigtStudiestod`
        case 'hasKompletterandeStudiestod':
            return `utbildningar.${utbildningsIndex}.kompletterandeStudiestod`
        case 'hasStudieersattning':
        case 'hasStudielon':
            return `utbildningar.${utbildningsIndex}.studieersattningAndStudielon`
        default:
            return undefined
    }
}
export const getStudiestodObjectPathV2 = (
    utbildningsIndex: number,
    studiestodToDelete: StudiestodType
): FieldPath<RadgivarensUnderlagForm> | undefined => {
    switch (studiestodToDelete) {
        case 'hasKoptUtbildning':
            return `utbildningar.${utbildningsIndex}.koptUtbildning`
        case 'hasKortvarigtStudiestod':
            return `utbildningar.${utbildningsIndex}.kortvarigtStudiestod`
        case 'hasKompletterandeStudiestod':
            return `utbildningar.${utbildningsIndex}.kompletterandeStudiestod`
        case 'hasStudieersattning':
            return `utbildningar.${utbildningsIndex}.studieersattning`
        default:
            return undefined
    }
}
export const handleClearStudiestod = (
    utbildningsIndex: number,
    studiestodToDelete: StudiestodType,
    utbildning: UtbildningUnderlagApiModel,
    setValue: UseFormSetValue<RadgivarensUnderlagForm>
) => {
    const objectPath = getStudiestodObjectPath(utbildningsIndex, studiestodToDelete)
    switch (studiestodToDelete) {
        case 'hasKoptUtbildning':
        case 'hasKortvarigtStudiestod':
        case 'hasKompletterandeStudiestod':
            setValue(objectPath, undefined)
            return
        case 'hasStudieersattning':
            if (utbildning.studiestodTyp.hasStudielon === false) {
                setValue(objectPath, undefined)
                return
            }

            utbildning.studieersattningAndStudielon.kalenderhalvar.forEach((_, i) => {
                setValue(`utbildningar.${utbildningsIndex}.studieersattningAndStudielon.kalenderhalvar.${i}.studieersattning`, undefined)
            })
            return
        case 'hasStudielon':
            if (utbildning.studiestodTyp.hasStudieersattning === false) {
                setValue(objectPath, undefined)
                return
            }
            utbildning.studieersattningAndStudielon.kalenderhalvar.forEach((_, i) => {
                setValue(`utbildningar.${utbildningsIndex}.studieersattningAndStudielon.kalenderhalvar.${i}.studielon`, undefined)
                setValue(`utbildningar.${utbildningsIndex}.studieersattningAndStudielon.kalenderhalvar.${i}.hasNoStudielon`, undefined)
            })
            setValue(`utbildningar.${utbildningsIndex}.studieersattningAndStudielon.motiveringStudielon`, undefined)
            return
        default:
            return
    }
}

export const handleResetStudiestod = (
    utbildningsIndex: number,
    studiestodToReset: StudiestodType,
    currentUtb: UtbildningUnderlagApiModel,
    defaultUtb: UtbildningUnderlagApiModel,
    resetField: UseFormResetField<RadgivarensUnderlagForm>
) => {
    const objectPath = getStudiestodObjectPath(utbildningsIndex, studiestodToReset)
    switch (studiestodToReset) {
        case 'hasKoptUtbildning':
        case 'hasKortvarigtStudiestod':
        case 'hasKompletterandeStudiestod':
            resetField(objectPath)
            return
        case 'hasStudieersattning':
        case 'hasStudielon':
            if (isNil(defaultUtb.studieersattningAndStudielon)) {
                return
            }

            const hasStudieersattning = studiestodToReset === 'hasStudieersattning' || currentUtb.studiestodTyp.hasStudieersattning
            const hasStudielon = studiestodToReset === 'hasStudielon' || currentUtb.studiestodTyp.hasStudielon
            const clonedStudieersattningAndStudielon = clone(defaultUtb.studieersattningAndStudielon)
            clonedStudieersattningAndStudielon.kalenderhalvar.forEach((kalender) => {
                kalender.studielon = hasStudielon ? kalender.studielon : undefined
                kalender.hasNoStudielon = hasStudielon ? kalender.hasNoStudielon : undefined
                kalender.studieersattning = hasStudieersattning ? kalender.studieersattning : undefined
            })
            clonedStudieersattningAndStudielon.motiveringStudielon = hasStudielon
                ? clonedStudieersattningAndStudielon.motiveringStudielon
                : undefined

            resetField(objectPath, { defaultValue: clonedStudieersattningAndStudielon })
            return
        default:
            return
    }
}

export const handleClearStudiestodV2 = (
    utbildningsIndex: number,
    studiestodToDelete: StudiestodType,
    setValue: UseFormSetValue<RadgivarensUnderlagForm>
) => {
    const objectPath = getStudiestodObjectPathV2(utbildningsIndex, studiestodToDelete)
    switch (studiestodToDelete) {
        case 'hasKoptUtbildning':
        case 'hasKortvarigtStudiestod':
        case 'hasKompletterandeStudiestod':
        case 'hasStudieersattning':
            setValue(objectPath, undefined)
            return

        default:
            return
    }
}

export const handleResetStudiestodV2 = (
    utbildningsIndex: number,
    studiestodToReset: StudiestodType,
    resetField: UseFormResetField<RadgivarensUnderlagForm>
) => {
    const objectPath = getStudiestodObjectPathV2(utbildningsIndex, studiestodToReset)
    switch (studiestodToReset) {
        case 'hasKoptUtbildning':
        case 'hasKortvarigtStudiestod':
        case 'hasKompletterandeStudiestod':
        case 'hasStudieersattning':
            resetField(objectPath)
            return
        default:
            return
    }
}
