import React from 'react'
import { Box, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { UseFieldArrayRemove } from 'react-hook-form'

import { Studieersattning, TaBortKalenderhalvarButton } from './Components'

interface Props {
    utbildningsIndex: number
    kalenderhalvarIndex: number
    isReadonly: boolean
    remove: UseFieldArrayRemove
}

const StudieersattningKalenderhalvar = ({ utbildningsIndex, kalenderhalvarIndex, isReadonly, remove }: Props) => {
    const { t } = useTranslation('translation', { keyPrefix: 'studiestodAnsokan.steps.radgivarensUnderlag' })

    return (
        <Stack>
            <Stack direction="row" alignItems="center" spacing={2}>
                {!isReadonly && <TaBortKalenderhalvarButton kalenderhalvarIndex={kalenderhalvarIndex} remove={remove} />}
                <Typography variant="h5">
                    {t('kalenderhalvarTitle')} {kalenderhalvarIndex + 1}
                </Typography>
            </Stack>
            <Stack direction="row" spacing={2}>
                <Studieersattning kalenderhalvarIndex={kalenderhalvarIndex} utbildningsIndex={utbildningsIndex} isReadonly={isReadonly} />
                <Box flex={1} />
            </Stack>
        </Stack>
    )
}

export default StudieersattningKalenderhalvar
