import { JSX } from 'react'
import { useFormikContext } from 'formik'
import { usePickEpiContent } from '@local/Utils/Hooks'
import { ITidigareSysselsattningarContent } from '@local/Views/TidigareSysselsattningarOld/TidigareSysselsattningar.types'
import {
  getKompletteringErrors,
  getKompletteringTouched,
} from '@local/Utils/Helpers/TidigareSysselsattningar.helpers'
import type { IKompletteraFormValues } from '@local/Views/TidigareSysselsattningarOld/KompletteraForm/KompletteraForm.types'
import Gutter from '@local/Components/Gutter'
import { Checkbox, FormControl, FormControlLabel } from '@mui/material'
import FormErrorMessage from '@local/Components/FormErrorMessage'
import { isNil } from 'ramda'

const Forsakran = ({ index }: { index: number }): JSX.Element => {
  const { values, errors, touched, handleChange, handleBlur } =
    useFormikContext<IKompletteraFormValues>()

  const {
    formular: { forsakran },
  } = usePickEpiContent<ITidigareSysselsattningarContent>()

  const kompletteringErrors = getKompletteringErrors(index, errors)
  const kompletteringTouched = getKompletteringTouched(index, touched)

  const isError =
    kompletteringTouched.forsakratUppgifter &&
    !isNil(kompletteringErrors.forsakratUppgifter)

  return (
    <>
      <Gutter offset_xs={16} />

      <FormControl error={isError} variant="standard" fullWidth>
        <FormControlLabel
          control={
            <Checkbox
              checked={values.kompletteringar[index].forsakratUppgifter}
              color="primary"
              onChange={handleChange}
              onBlur={handleBlur}
              name={`kompletteringar.${String(index)}.forsakratUppgifter`}
            />
          }
          label={forsakran.heading}
        />
        {isError && (
          <FormErrorMessage>
            {kompletteringErrors.forsakratUppgifter}
          </FormErrorMessage>
        )}
      </FormControl>
    </>
  )
}

export default Forsakran
