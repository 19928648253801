import { useLocation } from 'react-router-dom'
import { useAuthentication } from 'features/Authentication/Hooks'
import {
  useCachedUserProfileState,
  userProfileApi,
} from 'state/PublicWebApi/UserProfile'
import { Box, Button, Link, Paper, Typography } from '@mui/material'
import Info from '@mui/icons-material/Info'

const SETTINGS_URLS = {
  klient: '/mitt-trr/kontoinstallningar',
  kund: '/trr-foretag/mina-foretag',
}

const RequestContactUpdate = () => {
  const { mobilePhone, email, outdatedContact } = useCachedUserProfileState()
  const [trigger, result] =
    userProfileApi.endpoints.confirmUserProfileContactInformation.useLazyQuery()
  const location = useLocation()
  const idp = useAuthentication()?.profile?.idp as 'klient' | 'kund'
  const settingsLink = SETTINGS_URLS[idp]
  const isOutdated = outdatedContact ?? false
  const error = result?.isError
  const link: string = settingsLink

  const confirmHandler = () => {
    void trigger()
  }

  const disabled = result?.isFetching || result?.isLoading

  if (
    !isOutdated ||
    location.pathname.includes(settingsLink) ||
    !settingsLink ||
    result?.isSuccess
  ) {
    return null
  }

  return (
    <Paper
      elevation={0}
      sx={{
        my: 3,
        backgroundColor: 'info.light',
        display: 'flex',
        p: 2,
        flexWrap: 'wrap',
      }}
    >
      <Box pr={2}>
        <Info color="info" />
      </Box>
      <Box flex={1}>
        <Typography variant="subtitle1" component="h2" fontWeight={700} mb={2}>
          Stämmer dina kontaktuppgifter?
        </Typography>

        <Typography mb={2} variant="body1">
          Kontrollera gärna uppgifterna så att vi kan nå dig på bästa sätt.
        </Typography>
        <Box display="flex" flexWrap="wrap">
          <Typography variant="subtitle1" mr={1} component="span">
            Telefon:
          </Typography>
          <Typography variant="body1">{mobilePhone}</Typography>
        </Box>
        <Box display="flex" flexWrap="wrap">
          <Typography variant="subtitle1" mr={1} component="span">
            Mejl:
          </Typography>
          <Typography variant="body1">{email}</Typography>
        </Box>
        {error && (
          <Box display="flex" flexWrap="wrap" pt={2}>
            <Typography variant="body2" color="error">
              Ett fel uppstod. Försök igen eller kontakta support.
            </Typography>
          </Box>
        )}
        <Box
          pt={2}
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          gap={2}
          flexWrap="wrap"
        >
          <Link href={link} underline="hover">
            Ändra uppgifter
          </Link>
          <Button disabled={disabled} onClick={confirmHandler}>
            Ja, det stämmer
          </Button>
        </Box>
      </Box>
    </Paper>
  )
}

export default RequestContactUpdate
