import { BegaranYttrandeStatus, SearchYttrandenStatus } from '@local/src/Services/SwaggerApi/data-contracts'
import { Alert } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
	status: BegaranYttrandeStatus | SearchYttrandenStatus
}

const BegaranYttrandeIsDeletedAlert = ({ status }: Props) => {
	const { t } = useTranslation('translation', { keyPrefix: 'components.yttranden' })

	const isDeleted = status === BegaranYttrandeStatus.BorttagenHosCsn

	return isDeleted ? <Alert severity="error">{t('begaranYttrandeIsDeletedAlertDescription')}</Alert> : null
}

export default BegaranYttrandeIsDeletedAlert
