import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithHeaders } from 'state/helpers'

export const publicWebApi = createApi({
  reducerPath: 'publicWebApi',
  baseQuery: baseQueryWithHeaders(
    window.APP_CONFIGURATION.COMMON.PUBLIC_WEB_API_URL
  ),
  tagTypes: ['Settings', 'Content', 'UserProfile', 'UserInteractions'],
  endpoints: () => ({}),
})

export default publicWebApi
