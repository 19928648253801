import {
  IKeyValue,
  ISelectOptionFormikWrapper,
  RadioChoice,
} from '@local/Common.types'
import { IAnsokanPatchApiModel } from '@local/services/Types/apiTypes'
import {
  IAnsokanDetailsContent,
  IAnsokanItem,
} from '@local/Views/AnsokanDetails/AnsokanDetails.types'

export interface IAnsokanDetailsFormValues extends IKeyValue {
  [ArbetslivserfarenhetFormTypes.HasIntygatEtableringsvillkor]: RadioChoice
  [ArbetslivserfarenhetFormTypes.Befattning]: ISelectOptionFormikWrapper
}

export interface IAnsokanDetailsPatchModel
  extends Omit<
    IAnsokanDetailsFormValues,
    ArbetslivserfarenhetFormTypes.Befattning
  > {
  befattning: string
  befattningId: string
}

export enum ArbetslivserfarenhetFormTypes {
  HasIntygatEtableringsvillkor = 'hasIntygatEtableringsvillkor',
  Befattning = 'befattning',
}

export interface IAnsokanDetailsFormProps {
  ansokan: IAnsokanItem
  content: IAnsokanDetailsContent
}

export interface IValideringContent {
  todo: string
}

export interface IRequestedUpdates {
  updatesToPatch: Partial<IAnsokanPatchApiModel>
  updatesToStore: Partial<IAnsokanDetailsFormValues>
}
