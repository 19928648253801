// ** SelectCompanyPage **

import { userTypes } from 'State/Slices/inputSlice/constants'

const texts = {
  failedStateHeader: {
    [userTypes.tjansteman]: 'Din arbetsgivare är tyvärr inte ansluten till TRR',
    [userTypes.arbetsgivare]: 'Företaget är tyvärr inte anslutet till TRR',
  },
  failedStateBody: {
    [userTypes.tjansteman]: `För att ha rätt till vårt stöd behöver din arbetsgivare vara
            ansluten till oss. Fråga din arbetsgivare vilka stödverksamheter de
            samarbetar med.`,
    [userTypes.arbetsgivare]: `För att kunna ta del av våra tjänster behöver ditt företag vara
            anslutet till oss. Gör en ny sökning om du angett fel
            organisationsnummer.`,
  },
  failedStateGdprInfo: {
    [userTypes.tjansteman]: `Dina inloggningsuppgifter kommer att tas bort från vårt system i
            enlighet med GDPR. Du kan alltid prova att logga in igen om din
            situation skulle förändras.`,
    [userTypes.arbetsgivare]: `Om du avbryter inloggningen tas dina inloggningsuppgifter bort från
            vårt system i enlighet med GDPR.`,
  },
  failedStateGoBackText: 'Gör en ny sökning',
  foundStateHeader: {
    [userTypes.tjansteman]: `Toppen, som tjänsteman på det här företaget kan du ansöka om stöd av
          oss.`,
    [userTypes.arbetsgivare]: `Toppen, det här företaget är anslutet till TRR`,
  },
  foundStateBody: `Kontrollera att uppgifterna ovanför stämmer innan du fortsätter.`,
  foundStateContinueText: 'Fortsätt',
  foundStateGoBackText: 'Ange annan arbetsgivare',
} as const

export default texts
