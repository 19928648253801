import { JSX, useEffect } from 'react'
import Foretagsuppgifter from '@local/Views/TidigareSysselsattningarOld/KompletteraForm/Komplettering/Foretagsuppgifter'
import Anstallningsuppgifter from '@local/Views/TidigareSysselsattningarOld/KompletteraForm/Komplettering/Anstallningsuppgifter'
import Tjansteman from '@local/Views/TidigareSysselsattningarOld/KompletteraForm/Komplettering/Tjansteman/Tjansteman'
import Arbetsgivarintyg from '@local/Views/TidigareSysselsattningarOld/KompletteraForm/Komplettering/Arbetsgivarintyg'
import {
  IKompletteraFormValues,
  IKomplettering,
} from '@local/Views/TidigareSysselsattningarOld/KompletteraForm/KompletteraForm.types'
import IsVdEllerUndantagsgrupp from '@local/Views/TidigareSysselsattningarOld/KompletteraForm/Komplettering/IsVdEllerUndantagsgrupp'
import Gutter from '@local/Components/Gutter'
import { useFormikContext } from 'formik'

const TidsbegransadAnstallning = ({
  index,
  komplettering,
}: {
  index: number
  komplettering: IKomplettering
}): JSX.Element => {
  const { setFieldValue } = useFormikContext<IKompletteraFormValues>()

  useEffect(() => {
    setFieldValue(
      `kompletteringar.${String(index)}.orsakTillUppsagning`,
      null
    )?.catch((err) => {
      console.log(err)
    })
  }, [setFieldValue, index])

  return (
    <>
      <Gutter offset_xs={32} offset_md={40} />

      <Foretagsuppgifter index={index} />

      <Gutter offset_xs={32} offset_md={40} />

      <Anstallningsuppgifter komplettering={komplettering} index={index} />

      <Gutter offset_xs={32} offset_md={40} />

      <Tjansteman index={index} />

      <Gutter offset_xs={32} offset_md={40} />

      <IsVdEllerUndantagsgrupp index={index} />

      <Gutter offset_xs={32} offset_md={40} />

      <Arbetsgivarintyg komplettering={komplettering} index={index} />
    </>
  )
}

export default TidsbegransadAnstallning
