import React from 'react'
import { Box } from '@mui/material'
import EmptyResult from '@local/src/Components/EmptyResult/EmptyResult'
import { DataGridPro } from '@mui/x-data-grid-pro'
import { getDatagridDefaultStyles } from '@local/src/Components/DataGridWrapper/defaultStyling'

import { FordelningsRadgivare } from '../../Fordelning.types'

import { columns } from './RadgivarStatistikColumns'

interface RadgivarStatistikTableProps {
  radgivareList: FordelningsRadgivare[]
}

const RadgivarStatistikTable = ({
  radgivareList,
}: RadgivarStatistikTableProps) => {
  if (!radgivareList) {
    return <></>
  }

  return (
    <Box mt={2} height={`${56 + 58 * radgivareList.length}px`} maxHeight="90vh">
      {/* Above height code is set because mui datagrid is awful to put inside a sticky container with 'fixed' height. */}
      {radgivareList?.length > 0 ? (
        <DataGridPro
          columns={columns}
          rows={radgivareList}
          getRowId={(row: FordelningsRadgivare) => row.id}
          getRowHeight={() => 'auto'}
          sx={(theme) => getDatagridDefaultStyles(theme, false, true)}
          hideFooter
          disableColumnMenu
          disableColumnReorder
          disableColumnResize
        />
      ) : (
        <Box pt={3}>
          <EmptyResult description="" />
        </Box>
      )}
    </Box>
  )
}

export default RadgivarStatistikTable
