import UserRoles from './roles'

const createBaseRules = (
  roles: string[],
  idp: string,
  arbetsgivarinfoLinkId: string | null
) => {
  const hasRole = (role: string) => roles.includes(role)
  const hasRoles = (roles: string[]) => roles.some((role) => hasRole(role))

  return {
    isMissingEmail: () => hasRole(UserRoles.MissingEmail),
    isOnboarding: () => hasRole(UserRoles.Onboarding),
    isMissingWorkSituation: () => hasRole(UserRoles.MissingWorkSituation),
    isArbetsgivarinfo: () => hasRole(UserRoles.Arbetsgivarinfo),
    isArbetsgivarinfoLinkIdRole: () =>
      hasRole(`${UserRoles.Arbetsgivarinfo}-${arbetsgivarinfoLinkId}`),
    isTjansteperson: () =>
      hasRoles([
        UserRoles.TIAOprovad,
        UserRoles.TIAAktiv,
        UserRoles.TIOOprovad,
        UserRoles.TIOAktiv,
      ]),
    isKundIdp: () => idp === 'kund',
  }
}

export default createBaseRules
