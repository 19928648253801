import { useCurrentUser } from '@local/src/Hooks'
import { useCreateKommentar, useDeleteKommentar, useGetKommentar, useUpdateKommentar } from '@local/src/Hooks/API'
import { getLatestKommentar } from '@local/src/Pages/BegaranYttrande/Components/YttrandeInfo/Components/YttrandeFranCsnInfo/Components/BegaranYttrandeKommentar/Helpers/getLatestKommentar'

export const useBegaranYttrandeKommentar = (referenceId: string) => {
	const { apiResponse, isError: isGetError, isLoading: isGetLoading, error: getError } = useGetKommentar(referenceId)
	const { createKommentar, isError: isCreateError, isLoading: isCreateLoading, error: createError } = useCreateKommentar(referenceId)
	const { updateKommentar, isError: isUpdateError, isLoading: isUpdateLoading, error: updateError } = useUpdateKommentar(referenceId)
	const { deleteKommentar, isError: isDeleteError, isLoading: isDeleteLoading, error: deleteError } = useDeleteKommentar(referenceId)

	const isError = isGetError || isCreateError || isUpdateError || isDeleteError
	const error = getError || createError || updateError || deleteError
	const isLoading = isGetLoading || isCreateLoading || isUpdateLoading || isDeleteLoading

	const latestKommentar = getLatestKommentar(apiResponse)

	const { userHasRole } = useCurrentUser()
	const userHasValidRole = userHasRole(['HANDLAGGARE-STUDIER', 'KOORDINATOR-STUDIER', 'RADGIVNINGS-ADMINISTRATOR'])

	return {
		latestKommentar,
		isError,
		error,
		isLoading,
		createKommentar,
		updateKommentar,
		deleteKommentar,
		userHasValidRole,
	}
}
