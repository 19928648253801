import { ISelectOption, RadioChoice } from '@local/Common.types'
import { IMiddlewareField } from '@local/Utils/Hooks/useFormikWatch'
import {
  ArbetslivserfarenhetFormTypes,
  IAnsokanDetailsPatchModel,
  IAnsokanDetailsFormValues,
} from '@local/Views/AnsokanDetails/AnsokanDetailsForm/AnsokanDetailsForm.types'
import { isNil } from 'ramda'

export const ansokanDetailsWatcherMiddleware = (
  field: IMiddlewareField<IAnsokanDetailsFormValues>,
  next: (
    field: IMiddlewareField<IAnsokanDetailsFormValues>
  ) => Partial<IAnsokanDetailsPatchModel>
): Partial<IAnsokanDetailsPatchModel> => {
  if (field.id === ArbetslivserfarenhetFormTypes.HasIntygatEtableringsvillkor) {
    if (!isNil(field.value)) {
      return { hasIntygatEtableringsvillkor: field.value as RadioChoice }
    } else return
  }
  if (field.id === ArbetslivserfarenhetFormTypes.Befattning) {
    return {
      befattning: (field.value as ISelectOption).label,
      befattningId: (field.value as ISelectOption).id,
    }
  } else {
    return next(field)
  }
}
