import { JSX } from 'react'
import { useIsBelowMediumScreens, usePickEpiContent } from '@local/Utils/Hooks'
import { IDinaAnsokningarContent } from '@local/Views/DinaAnsokningar/DinaAnsokningar.types'
import { KompletteraStatusType } from '@local/Views/DinaAnsokningar/TidigareSysselsattningarList/TidigareSysselsattningar.types'
import HTMLMapper from '@local/Components/HTMLMapper'
import { Button } from '@mui/material'
import Gutter from '@local/Components/Gutter'
import useGetKanKomplettera from '@local/Utils/Hooks/TidigareSysselsattningar/useGetKanKomplettera'

interface IPreambleContent {
  preamble: string
  element: JSX.Element
}

const useGetPreambleContent = (): IPreambleContent => {
  const isBelowMediumScreens = useIsBelowMediumScreens()
  const { data: kompletteraStatus } = useGetKanKomplettera()

  const {
    tidigareSysselsattningar: { komplettering, dinKompletteringBehandlas },
    dinaTrrAnsokningarLinks,
  } = usePickEpiContent<IDinaAnsokningarContent>()

  switch (kompletteraStatus) {
    case KompletteraStatusType.KanKomplettera:
      return {
        preamble: komplettering.kanKompletteraPreamble,
        element: (
          <>
            <Button
              variant="outlined"
              href={dinaTrrAnsokningarLinks.tidigareSysselsattningarUrl}
            >
              {isBelowMediumScreens
                ? komplettering.buttonTextMobile
                : komplettering.buttonTextDesktop}
            </Button>
            <Gutter offset_xs={40} />
          </>
        ),
      }
    case KompletteraStatusType.KanInteKomplettera:
      return {
        preamble: komplettering.kanInteKompletteraPreamble,
        element: null,
      }
    case KompletteraStatusType.KompletteringUnderBearbetning:
      return {
        preamble: komplettering.kompletteringUnderBearbetningPreamble,
        element: <HTMLMapper body={dinKompletteringBehandlas.mainBody} />,
      }

    default:
      break
  }
}

export default useGetPreambleContent
