import { Box, Grid2 as Grid, Typography } from '@mui/material'
import userManager from 'features/Authentication/userManager'
import { useEffect } from 'react'

const SiteErrorPage = () => {
  useEffect(() => {
    const forceClearStorage = async () => {
      await userManager.clearStaleState()
      await userManager.removeUser()
    }
    void forceClearStorage()
  }, [])
  return (
    <Box
      data-testid={`SiteErrorPage`}
      sx={{
        display: 'flex',
        height: '100vh',
        maxWidth: '80%',
        m: 'auto',
        px: 4,
      }}
    >
      <Grid sx={{ alignItems: 'center' }} container columns={20}>
        <Grid size={{ xs: 20, md: 12 }}>
          <Typography
            sx={{ typography: { xs: 'h3', md: 'h1' } }}
            component={'h1'}
            mb={4}
          >
            TRR.se går inte att nå
          </Typography>
          <Typography variant="preamble">
            Sidan du försökte nå kunde inte laddas för tillfället. Försök igen
            senare.
          </Typography>
        </Grid>
      </Grid>
    </Box>
  )
}

export default SiteErrorPage
