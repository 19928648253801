import React from 'react'
import Modal from '@local/src/Components/Modal/Modal'
import { Typography } from '@mui/material'

interface RemoveEventModalProps {
  active: boolean
  close(): void
  submit(): void
}
const RemoveEventModal = ({
  active,
  close,
  submit,
}: RemoveEventModalProps): JSX.Element => {
  return (
    <Modal
      label="Ta bort händelse"
      open={active}
      onCancel={close}
      onConfirm={submit}
      confirmText="Ta bort händelse"
    >
      <Typography variant="body1">
        Är du säker på att du vill ta bort den här händelsen?
      </Typography>
    </Modal>
  )
}

export default RemoveEventModal
