// ** SelectCompanyPage **

import { userTypes } from 'State/Slices/inputSlice/constants'

const texts = {
  hjalpAttHittaOrgnummer: 'Hjälp att hitta organisationsnummer?',
  labelForInputField: 'Organisationsnummer',
  websiteLink: 'allabolag.se',
  websiteInfo: 'För de som vill veta mer om Sveriges bolag',
  companyFetchError:
    'Ett fel uppstod när vi hämtade företag, var vänlig försök igen',
  orgNumSyntaxError: 'Fel format på organisationsnumret',
  a11yErrorMessageOrgNrIncorrectFormat:
    'Fel format på organisationsnumret. Ange 10 siffror och ett skiljetecken efter de första 6 siffrorna',
  header: {
    [userTypes.tjansteman]: 'Är din arbetsgivare ansluten till TRR?',
    [userTypes.arbetsgivare]: 'Vilket företag representerar du?',
  },
} as const

export default texts
