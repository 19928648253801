import { useAppDispatch } from '@local/Store/configureStore'
import { IAnsokanDetailsFormValues } from '@local/Views/AnsokanDetails/AnsokanDetailsForm/AnsokanDetailsForm.types'
import {
  resetState,
  selectAnsokanDetailsForm,
  setFormIsDirty,
  setValues,
} from '@local/Views/AnsokanDetails/AnsokanDetailsForm/ansokanDetailsFormSlice'
import { ansokanDetailsStoreMiddleware } from '@local/Views/AnsokanDetails/AnsokanDetailsForm/ansokanDetailsStoreMiddleware'
import { equals } from 'ramda'
import { useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'

const useReduxStore = (
  initialValues: IAnsokanDetailsFormValues,
  values: IAnsokanDetailsFormValues
) => {
  const { formIsDirty, values: storedValues } = useSelector(
    selectAnsokanDetailsForm
  )

  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(setValues(initialValues))
    return () => {
      dispatch(resetState())
    }
  }, [dispatch, initialValues])

  useEffect(() => {
    if (equals(storedValues, values)) {
      dispatch(setFormIsDirty(false))
    } else {
      dispatch(setFormIsDirty(true))
    }
  }, [storedValues, values, dispatch])

  const setStoredValuesAfterPatch = useCallback(
    (valuesToStore: Partial<IAnsokanDetailsFormValues>) => {
      dispatch(
        setValues({
          ...storedValues,
          ...ansokanDetailsStoreMiddleware(valuesToStore),
        })
      )
    },
    [dispatch, storedValues]
  )

  return { setStoredValuesAfterPatch, storedValues, formIsDirty }
}

export default useReduxStore
