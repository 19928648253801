import { forceLogout } from 'features/Authentication/helpers'
import { goToLogin } from '@trr/app-shell-communication'
import { useSliceStateSelector } from 'slices/hooks'

const useAuthentication = () => {
  const authValues = useSliceStateSelector((state) => state.slices.auth)
  return {
    goToLogin,
    logout: forceLogout,
    ...authValues,
    profile: {
      ...authValues.profile,
      userRoles: authValues.profile.roles,
    },
  }
}

export default useAuthentication
