import {
  usePostTidigareSysselsattningarMutation,
  usePostTidigareSysselsattningarOldMutation,
} from '@local/services/API/tidigareSysselsattningar/tidigareSysselsattningarApi'
import useIsFeatureFlagEnabled from '@local/Utils/Hooks/useIsFeatureFlagEnabled'

const usePostTidigareSysselsattningar = () => {
  const isNewKompletteraApiModelEnabled = useIsFeatureFlagEnabled(
    'KlientAnsokan-Frontend_newKompletteraApiModel_temp_241218'
  )

  const postTidigareSysselsattningar = isNewKompletteraApiModelEnabled
    ? usePostTidigareSysselsattningarMutation
    : usePostTidigareSysselsattningarOldMutation

  return postTidigareSysselsattningar
}

export default usePostTidigareSysselsattningar
