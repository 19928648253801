import { CsnYttrandeApiModel } from '@local/src/Services/SwaggerApi/data-contracts'
import { Stack } from '@mui/material'
import React from 'react'
import { BegaranYttrandeIsDeletedAlert } from '@local/src/Components'

import { Arbetsgivare, Utbildningar, YttrandeFranCsnInfo } from './Components'

interface Props {
	begaranYttrande: CsnYttrandeApiModel
}

const YttrandeInfo = ({ begaranYttrande }: Props) => (
	<Stack spacing={2}>
		<BegaranYttrandeIsDeletedAlert status={begaranYttrande?.status} />
		<YttrandeFranCsnInfo yttrande={begaranYttrande} />
		<Arbetsgivare arbetsgivare={begaranYttrande?.begaranYttrande.arbetsgivare} />
		<Utbildningar utbildningar={begaranYttrande?.begaranYttrande.utbildningar} />
	</Stack>
)

export default YttrandeInfo
