import { JSX } from 'react'
import { AppShellRouter, KeyRoute } from '@local/Router'
import { AppShellDataProps, useIsFeatureEnabled } from '@trr/app-shell-data'
import DinaAnsokningar from '@local/Views/DinaAnsokningar'
import AnsokanDetails from '@local/Views/AnsokanDetails'
import TidigareSysselsattningarOld from '@local/Views/TidigareSysselsattningarOld'
import TidigareSysselsattningar from '@local/Views/TidigareSysselsattningar'

const Views = ({
  appShellData,
}: {
  appShellData: AppShellDataProps
}): JSX.Element => {
  const useNewTidigarerSysselsattningar = useIsFeatureEnabled(
    'KlientAnsokan-Frontend_newKompletteraPage_temp_241213'
  )

  return (
    <AppShellRouter currentKey={appShellData.currentKey}>
      <KeyRoute urlKey="dina-ansokningar">
        <DinaAnsokningar />
      </KeyRoute>
      <KeyRoute urlKey="ansokan">
        <AnsokanDetails />
      </KeyRoute>
      <KeyRoute urlKey="tidigare-sysselsattningar">
        {useNewTidigarerSysselsattningar ? (
          <TidigareSysselsattningar />
        ) : (
          <TidigareSysselsattningarOld />
        )}
      </KeyRoute>
    </AppShellRouter>
  )
}
export default Views
