import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback, useContext } from 'react';
import { SearchContext } from '@local/src/Utils/Context';
import { Box, IconButton, InputAdornment, TextField } from '@mui/material';
import Search from '@mui/icons-material/Search';
var SearchField = function () {
    var _a = useContext(SearchContext), setSearchValue = _a.setSearchValue, searchValue = _a.searchValue, performSearch = _a.performSearch;
    var onChange = useCallback(function (event) {
        var value = event.currentTarget.value;
        setSearchValue(value);
    }, [setSearchValue]);
    var onSubmit = useCallback(function () {
        performSearch();
    }, [performSearch]);
    var handleKeyPress = useCallback(function (_a) {
        var key = _a.key;
        key === 'Enter' && performSearch();
    }, [performSearch]);
    return (_jsx(Box, { width: "100%", maxWidth: "560px", mb: 1, children: _jsx(TextField, { fullWidth: true, value: searchValue, label: "S\u00F6k p\u00E5 webbplatsen", placeholder: "S\u00F6k p\u00E5 webbplatsen", onChange: onChange, onKeyDown: handleKeyPress, type: "search", inputProps: { enterKeyHint: 'search' }, InputProps: {
                endAdornment: (_jsx(InputAdornment, { position: "end", children: _jsx(IconButton, { "aria-label": "S\u00F6k", onClick: onSubmit, children: _jsx(Search, { color: "primary" }) }) })),
                sx: {
                    borderRadius: '2em',
                    paddingRight: 1,
                },
            } }) }));
};
export default SearchField;
