import { kommentarApi } from '@local/src/Services/apiEndpointHelper'
import { apiRequestTryCatchWrapperV2 } from '@local/src/Services/apiRequestHelper'
import { useMutation } from '@tanstack/react-query'
import { ApiResponseModel } from '@local/src/Services/ApiResponseModel'

import { useKommentarQueryHelper } from './useKommentarQueryHelper'

export interface DeleteKommentarApiModel {
	kommentarId: string
}

export const useDeleteKommentar = (referenceId: string) => {
	const { invalidateGetKommentarQuery } = useKommentarQueryHelper(referenceId)

	const deleteKommentar = ({ kommentarId }: DeleteKommentarApiModel) =>
		apiRequestTryCatchWrapperV2('deleteKommentar', kommentarApi.referenceKommentarDelete, kommentarId, referenceId)

	const { mutate, ...query } = useMutation(deleteKommentar, {
		onSuccess: invalidateGetKommentarQuery,
		onError: (err: ApiResponseModel<void>) => {
			console.error('deleteKommentar mutation error:', err)
		},
	})

	return {
		deleteKommentar: mutate,
		...query,
	}
}
