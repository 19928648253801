import { Box, CardActions, CardMedia, Stack, Typography } from '@mui/material'
import {
  createImageAltTextFromFileName,
  getFullImageUrl,
  isLinkExternal,
  LazyLoadWrapper,
} from '@trr/internal-helpers'
import { truncateText } from 'apps/Site/Utils/TextHelper/TextHelper'
import { useSliceStateSelector } from 'slices/hooks'
import { CardActionAreaWrapper } from '../../Card/Card.styles'
import TagWithDetails from '../../TagWithDetails/TagWithDetails'
import {
  CollectionCardContentWrapper,
  CollectionCardWrapper,
} from '../CollectionCard.styles'
import { CollectionCardProps } from '../CollectionCard.types'
import { SmallCardImageWrapper } from './SmallCard.styles'

const SmallCard = ({
  cardContent: {
    properties: {
      category,
      heading,
      preamble,
      image,
      link,
      knowledgeLevel,
      selectedPageType,
      timeSpan,
    },
  },
  mediaUrl,
  fallbackImage,
}: CollectionCardProps) => {
  const { isMobile, isTablet } = useSliceStateSelector(
    (state) => state.slices.device.deviceSize
  )

  const isMobileOrTablet = isMobile || isTablet
  const externalLink = link.url ? isLinkExternal(link.url) : false
  const maxHeadingLength = 36
  const maxTextLength = 140
  const truncatedHeading = truncateText(heading, maxHeadingLength)
  const truncatedPreamble = truncateText(preamble, maxTextLength)
  if (image == null && fallbackImage) {
    image = fallbackImage
  }

  return (
    <CollectionCardWrapper data-testid="small-card">
      <CardActionAreaWrapper
        tabIndex={0}
        href={link.url}
        target={externalLink ? '__blank' : undefined}
        rel={externalLink ? 'noreferrer' : undefined}
        data-testid={
          externalLink ? 'external-actionarea-wrapper' : 'actionarea-wrapper'
        }
      >
        <CollectionCardContentWrapper>
          <Stack
            direction={{ xs: 'column', lg: 'row' }}
            alignItems="flex-start"
            gap={3}
          >
            <Box flex="7 1 0">
              {category && category.length > 0 && (
                <Typography
                  variant="subtitle2"
                  component="p"
                  color="text.secondary"
                  mb={1}
                >
                  {category}
                </Typography>
              )}
              <Typography variant="h6" component="h3" mb={1.5}>
                {truncatedHeading}
              </Typography>
              <Typography variant="body2" component="p" color="text.secondary">
                {truncatedPreamble}
              </Typography>
            </Box>

            {image && !isMobileOrTablet && (
              <Box
                flex="3 0 0"
                display="flex"
                justifyContent="flex-end"
                alignItems="center"
              >
                <SmallCardImageWrapper>
                  <LazyLoadWrapper className="LazyLoadWrapper">
                    <CardMedia
                      data-testid={'card-image'}
                      component="img"
                      image={getFullImageUrl(mediaUrl, image, 375)}
                      alt={createImageAltTextFromFileName(image)
                      }
                    />
                  </LazyLoadWrapper>
                </SmallCardImageWrapper>
              </Box>
            )}
          </Stack>
        </CollectionCardContentWrapper>
        <CardActions sx={{ paddingLeft: 3, paddingBottom: 3 }}>
          <TagWithDetails
            knowledgeLevel={knowledgeLevel}
            selectedPageType={selectedPageType}
            timeSpan={timeSpan.value}
          />
        </CardActions>
      </CardActionAreaWrapper>
    </CollectionCardWrapper>
  )
}

export default SmallCard
