import { useEffect } from 'react'
import { useTrackVirtualView } from '@trr/gtm-tracking'
import { SiteTemplate } from 'Components'
import { pageIdMap } from 'Pages'
import { useSliceStateSelector } from 'State/Slices'
import { useSetStartOrReturnPage } from 'Utils/Hooks/useSetStartOrReturnPage'
import BaseLayout from 'Components/BaseLayout'

const App = () => {
  const { currentPage } = useSliceStateSelector((state) => state.slices.page)
  const trackVirtualView = useTrackVirtualView('login_wizard')
  useSetStartOrReturnPage()
  useEffect(() => {
    if (currentPage) {
      trackVirtualView(currentPage)
    }
  }, [currentPage, trackVirtualView])
  const CurrentPage = pageIdMap[currentPage]
  return (
    <SiteTemplate>
      {CurrentPage && (
        <BaseLayout>
          <CurrentPage />
        </BaseLayout>
      )}
    </SiteTemplate>
  )
}

export default App
