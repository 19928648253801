import { userTypes } from '../inputSlice/constants'
import { UserType } from '../inputSlice/types'

export const pageIds = {
  loginFlowStartPage: 'loginFlowStartPage',
  loadCollectumPage: 'loadCollectumPage',
  selectCompanyPage: 'selectCompanyPage',
  validateCompanyPage: 'validateCompanyPage',
  validateCollectumCompanyPage: 'validateCollectumCompanyPage',
  selectWorkSituationPage: 'selectWorkSituationPage',
  addEmailPage: 'addEmailPage',
  validateTjanstemanPage: 'validateTjanstemanPage',
  validateArbetsgivarinfoPage: 'validateArbetsgivarinfoPage',
} as const

export const pageFlows = {
  [userTypes.tjansteman]: [
    pageIds.loginFlowStartPage,
    pageIds.loadCollectumPage,
    pageIds.validateTjanstemanPage,
    pageIds.selectWorkSituationPage,
    pageIds.selectCompanyPage,
    pageIds.validateCompanyPage,
    pageIds.addEmailPage,
  ],
  [userTypes.tjanstemanWithCollectum]: [
    pageIds.selectWorkSituationPage,
    pageIds.validateCollectumCompanyPage,
    pageIds.addEmailPage,
  ],
  [userTypes.arbetsgivare]: [
    pageIds.loginFlowStartPage,
    // pageIds.loadCollectumPage,
    pageIds.selectCompanyPage,
    pageIds.validateCompanyPage,
    pageIds.addEmailPage,
  ],
  [userTypes.tjanstemanWithArbetsgivarinfo]: [
    pageIds.loginFlowStartPage,
    pageIds.validateArbetsgivarinfoPage,
  ],
} as const

export const pageFlowsMap: Record<
  UserType,
  Map<
    string,
    {
      next: (typeof pageFlows)[UserType][number]
      prev: (typeof pageFlows)[UserType][number]
    }
  >
> = {
  [userTypes.tjansteman]: new Map(
    pageFlows.tjansteman.map((page, i, arr) => [
      page,
      { next: arr[i + 1] || null, prev: arr[i - 1] || null },
    ])
  ),
  [userTypes.tjanstemanWithCollectum]: new Map(
    pageFlows.tjanstemanWithCollectum.map((page, i, arr) => [
      page,
      { next: arr[i + 1] || null, prev: arr[i - 1] || null },
    ])
  ),
  [userTypes.arbetsgivare]: new Map(
    pageFlows.arbetsgivare.map((page, i, arr) => [
      page,
      { next: arr[i + 1] || null, prev: arr[i - 1] || null },
    ])
  ),
  [userTypes.tjanstemanWithArbetsgivarinfo]: new Map(
    pageFlows.tjanstemanWithArbetsgivarinfo.map((page, i, arr) => [
      page,
      { next: arr[i + 1] || null, prev: arr[i - 1] || null },
    ])
  ),
}
