import { JSX, useState, useCallback, useRef, useEffect } from 'react'
import { isEmpty, isNil } from 'ramda'
import { useFormikContext } from 'formik'
import {
  IKompletteringProps,
  IKompletteraFormValues,
} from '@local/Views/TidigareSysselsattningar/KompletteraForm/KompletteraForm.types'
import ButtonArea from '@local/Views/TidigareSysselsattningar/KompletteraForm/Komplettering/ButtonArea'
import Header from '@local/Views/TidigareSysselsattningar/KompletteraForm/Komplettering/Header/Header'
import TaBortModal from '@local/Views/TidigareSysselsattningar/KompletteraForm/Komplettering/TaBortModal'
import TypAvSysselsattning from '@local/Views/TidigareSysselsattningar/KompletteraForm/Komplettering/TypAvSysselsattning'
import Gutter from '@local/Components/Gutter'
import Accordion from '@local/Components/Accordion'
import { Divider as MuiDivider } from '@mui/material'
import Divider from '@local/Components/Divider'
import { useGetSysselsattningForm } from '@local/Views/TidigareSysselsattningar/KompletteraForm/Komplettering/hooks'

const Komplettering = ({
  komplettering,
  index,
  arrayHelpers,
}: IKompletteringProps): JSX.Element => {
  const { validateForm, setTouched, touched, values } =
    useFormikContext<IKompletteraFormValues>()

  const isLastChild = index === values.kompletteringar?.length - 1

  const scrollElement = useRef<HTMLDivElement>(null)
  const form = useGetSysselsattningForm(komplettering, index)
  const [isExpanded, setIsExpanded] = useState(isLastChild)
  const [taBortModalOpen, setTaBortModalOpen] = useState(false)

  const scrollToElement = () => {
    scrollElement?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    })
  }

  useEffect(() => {
    if (isLastChild) {
      scrollToElement()
    }
  }, [isLastChild])

  const toggleIsExpanded = useCallback(() => {
    setIsExpanded(!isExpanded)
  }, [isExpanded])

  const handleLaggTill = useCallback(
    () =>
      void (async () => {
        const errors = await validateForm()

        if (isEmpty(errors) || isNil(errors.kompletteringar[index])) {
          toggleIsExpanded()

          setTouched({}).catch((err) => {
            console.log(err)
          })
          scrollToElement()
        } else {
          touched.kompletteringar = touched.kompletteringar || []
          touched.kompletteringar[index] = {
            sysselsattningstyp: true,
            organisationsnummer: true,
            foretagsnamn: true,
            anstallningSlutDatum: true,
            anstallningStartDatum: true,
            isTjansteman: true,
            isVdEllerUndantagsgrupp: true,
            orsakTillUppsagning: true,
            arbetsgivarintyg: [],
            forsakratUppgifter: true,
          }
          setTouched(touched).catch((err) => {
            console.log(err)
          })
        }
      })(),
    [validateForm, toggleIsExpanded, setTouched, index, touched]
  )

  const handleRemove = useCallback(() => {
    arrayHelpers.remove(index)

    setTimeout(() => {
      window.scroll({ behavior: 'smooth', top: 0 })
    }, 0)
  }, [index, arrayHelpers])

  return (
    <>
      <div ref={scrollElement} />

      <Accordion
        expanded={isExpanded}
        onChange={toggleIsExpanded}
        heading={<Header komplettering={komplettering} index={index} />}
        disableGutters
        ariaControls={`komplettering-${String(index)}-content`}
        ariaControlsId={`komplettering-${String(index)}-header`}
      >
        <MuiDivider />

        <Gutter offset_xs={32} />

        <TypAvSysselsattning komplettering={komplettering} index={index} />

        {form}

        <Gutter offset_xs={32} offset_md={40} />

        <Divider offset_xs={48} offset_md={64} />

        <ButtonArea
          index={index}
          handleLaggTill={handleLaggTill}
          toggleTaBortModal={() => {
            setTaBortModalOpen(true)
          }}
        />
      </Accordion>

      <Gutter offset_xs={32} />

      <TaBortModal
        modalOpen={taBortModalOpen}
        closeModal={() => {
          setTaBortModalOpen(false)
        }}
        handleRemove={handleRemove}
      />
    </>
  )
}

export default Komplettering
