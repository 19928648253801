import { StudieersattningAndStudielonApiModel, UtbildningUnderlagApiModel } from '@local/src/services/SwaggerApi/data-contracts'
import { ShouldBeUndefinedObjectSchema } from '@local/src/utils/Yup/YupHelper'
import { object as yupObject, SchemaOf, boolean as yupBoolean } from 'yup'
import { ArendeTyp } from '@local/src/@types/Arenden.types'
import StudiestodTypDraftSchema from '@local/src/features/StudiestodAnsokan/Components/Steps/RadgivarensUnderlag/Schemas/UtbildningUnderlagSchema/StudiestodTypDraftSchema'
import { KortvarigtStudiestodDraftSchema } from '@local/src/features/StudiestodAnsokan/Components/Steps/RadgivarensUnderlag/Schemas/UtbildningUnderlagSchema/KortvarigtStudiestodSchema'
import { KoptUtbildningDraftSchema } from '@local/src/features/StudiestodAnsokan/Components/Steps/RadgivarensUnderlag/Schemas/UtbildningUnderlagSchema/KoptUtbildningSchema'
import { KompletterandeStudiestodDraftSchema } from '@local/src/features/StudiestodAnsokan/Components/Steps/RadgivarensUnderlag/Schemas/UtbildningUnderlagSchema/KompletterandeStudiestodSchema'
import {
    HasOnlyStudieersattningDraftSchema,
    HasOnlyStudielonDraftSchema,
    StudieersattningAndStudielonBaseSchema,
    StudieersattningAndStudielonDraftSchema,
} from '@local/src/features/StudiestodAnsokan/Components/Steps/RadgivarensUnderlag/Schemas/UtbildningUnderlagSchema/StudieersattningAndStudielonSchema/StudieersattningAndStudielonSchema'
import { NekaStodDraftSchema } from '@local/src/features/StudiestodAnsokan/Components/Steps/RadgivarensUnderlag/Schemas/UtbildningUnderlagSchema/NekaStodSchema'
import { isNil } from 'ramda'
import { FormValidationTexts } from '@local/src/content/resources'
import { StudieersattningDraftSchema } from '@local/src/features/StudiestodAnsokan/Components/Steps/RadgivarensUnderlag/Schemas/UtbildningUnderlagSchema/StudieersattningSchema/StudieersattningSchema'

import UtbildningUnderlagSchema from './UtbildningUnderlagSchema'
import { HasKompletterandeStudiestodBooleanSchema } from './StudiestodTypSchema'

const UtbildningUnderlagDraftSchema = (
    formValidationTexts?: FormValidationTexts,
    arendeTyp?: ArendeTyp
): SchemaOf<UtbildningUnderlagApiModel> =>
    UtbildningUnderlagSchema(formValidationTexts, arendeTyp).shape({
        nekaStod: NekaStodDraftSchema(),
        yttrandeToCsn: yupObject({ hasYttrandeToCsn: yupBoolean().nullable() })
            .nullable()
            .transform((value) => (isNil(value?.hasYttrandeToCsn) ? null : value)),
        studiestodTyp: StudiestodTypDraftSchema().when('yttrandeToCsn.hasYttrandeToCsn', {
            is: (hasYttrande: boolean) => hasYttrande !== true,
            then: StudiestodTypDraftSchema().shape({
                hasKompletterandeStudiestod: HasKompletterandeStudiestodBooleanSchema,
            }),
        }),
        kortvarigtStudiestod: KortvarigtStudiestodDraftSchema(formValidationTexts).when('studiestodTyp.hasKortvarigtStudiestod', {
            is: true,
            then: KortvarigtStudiestodDraftSchema(formValidationTexts),
            otherwise: ShouldBeUndefinedObjectSchema,
        }),
        koptUtbildning: KoptUtbildningDraftSchema(formValidationTexts).when('studiestodTyp.hasKoptUtbildning', {
            is: true,
            then: KoptUtbildningDraftSchema(formValidationTexts),
            otherwise: ShouldBeUndefinedObjectSchema,
        }),
        kompletterandeStudiestod: KompletterandeStudiestodDraftSchema(formValidationTexts).when(
            'studiestodTyp.hasKompletterandeStudiestod',
            {
                is: true,
                then: KompletterandeStudiestodDraftSchema(formValidationTexts),
                otherwise: ShouldBeUndefinedObjectSchema,
            }
        ),
        studieersattningAndStudielon: StudieersattningAndStudielonBaseSchema.when(
            ['studiestodTyp.hasStudielon', 'studiestodTyp.hasStudieersattning'],
            {
                is: (hasStudielon: boolean, hasStudieersattning: boolean) => hasStudielon && !hasStudieersattning,
                then: HasOnlyStudielonDraftSchema(formValidationTexts),
            }
        )
            .when(['studiestodTyp.hasStudielon', 'studiestodTyp.hasStudieersattning'], {
                is: (hasStudielon: boolean, hasStudieersattning: boolean) => !hasStudielon && hasStudieersattning,
                then: HasOnlyStudieersattningDraftSchema(formValidationTexts),
            })
            .when(['studiestodTyp.hasStudielon', 'studiestodTyp.hasStudieersattning'], {
                is: (hasStudielon: boolean, hasStudieersattning: boolean) => hasStudielon && hasStudieersattning,
                then: StudieersattningAndStudielonDraftSchema(formValidationTexts),
            })
            .when(['studiestodTyp.hasStudielon', 'studiestodTyp.hasStudieersattning'], {
                is: (hasStudielon: boolean, hasStudieersattning: boolean) => !hasStudielon && !hasStudieersattning,
                then: ShouldBeUndefinedObjectSchema,
            }),
    })

export const UtbildningUnderlagDraftSchemaV2 = (
    formValidationTexts?: FormValidationTexts,
    arendeTyp?: ArendeTyp
): SchemaOf<UtbildningUnderlagApiModel> =>
    UtbildningUnderlagDraftSchema(formValidationTexts, arendeTyp).shape({
        studieersattningAndStudielon: yupObject().nullable() as SchemaOf<StudieersattningAndStudielonApiModel>,
        studieersattning: StudieersattningDraftSchema().when('studiestodTyp.hasStudieersattning', {
            is: true,
            then: StudieersattningDraftSchema(formValidationTexts).nullable().notRequired(),
            otherwise: ShouldBeUndefinedObjectSchema,
        }),
    })

export default UtbildningUnderlagDraftSchema
