import { kommentarApi } from '@local/src/Services/apiEndpointHelper'
import { apiRequestTryCatchWrapperV2 } from '@local/src/Services/apiRequestHelper'
import { CreateKommentarApiModel } from '@local/src/Services/SwaggerApi/data-contracts'
import { useMutation } from '@tanstack/react-query'
import { ApiResponseModel } from '@local/src/Services/ApiResponseModel'

import { CsnPortalReferenceType, useKommentarQueryHelper } from './useKommentarQueryHelper'

export interface CreateKommentarApiModelWithReferenceType extends Omit<CreateKommentarApiModel, 'referenceType'> {
	referenceType: CsnPortalReferenceType
}

export const useCreateKommentar = (referenceId: string) => {
	const { invalidateGetKommentarQuery } = useKommentarQueryHelper(referenceId)

	const createKommentar = (body: CreateKommentarApiModelWithReferenceType) =>
		apiRequestTryCatchWrapperV2('createKommentar', kommentarApi.referenceKommentarCreate, referenceId, body)

	const { mutate, ...query } = useMutation(createKommentar, {
		onSuccess: invalidateGetKommentarQuery,
		onError: (err: ApiResponseModel<void>) => {
			console.error('createKommentar mutation error:', err)
		},
	})

	return {
		createKommentar: mutate,
		...query,
	}
}
