import { CsnArbetsgivareApiModel } from '@local/Services/SwaggerApi/data-contracts'
import { Box, List, ListItem, ListItemText, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
	arbetsgivare: CsnArbetsgivareApiModel[]
}

const Arbetsgivare = ({ arbetsgivare }: Props) => {
	const { t } = useTranslation('translation', { keyPrefix: 'begaranYttrande.info.arbetsgivare' })

	return (
		<Box>
			<Typography variant="h4" data-testid="utb-header">
				{t('title', { count: arbetsgivare.length })}
			</Typography>
			<List>
				{arbetsgivare.map((a) => (
					<ListItem key={a.organisationsnummer} divider={false} disablePadding>
						<ListItemText primary={t('organisationsnummer')} secondary={a.organisationsnummer} />
					</ListItem>
				))}
			</List>
		</Box>
	)
}

export default Arbetsgivare
