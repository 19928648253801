import { type ReactNode } from 'react'
import { Button, type SxProps, type Theme, useTheme, Box } from '@mui/material'

import { TRRLogo } from '../../Assets'
import {
  TRRIllustrationCogwheels,
  TRRIllustrationWayForward,
} from '../TRRIllustrations'
import { abortLogin } from '@trr/app-shell-communication'
import { useSliceStateSelector } from 'State/Slices'
import { userTypes } from 'State/Slices/inputSlice/constants'

const SiteTemplate = ({ children }: { children: ReactNode }) => {
  const { userType } = useSliceStateSelector((state) => state.slices.inputs)
  const theme = useTheme()
  type StyleNames =
    | 'container'
    | 'header'
    | 'mainSection'
    | 'imageBlock'
    | 'imageContainer'
    | 'mainContent'
  const styles: Record<StyleNames, SxProps<Theme>> = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      height: '100vh',
      minWidth: '360px',
      overflowX: 'scroll',
      background: {
        lg:
          userType === userTypes.arbetsgivare
            ? 'linear-gradient(to right, #fff 0%, #fff 55%, #e7f4f3 55%, #e7f4f3 100%)'
            : 'linear-gradient(to right, #fff 0%, #fff 55%, #eff0f9 55%, #eff0f9 100%)',
      },
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      minHeight: '80px',
      p: {
        xs: theme.spacing(4, 3, 0, 3),
        sm: theme.spacing(10, 10, 0, 10),
      },
    },
    mainSection: {
      flexGrow: 1,
      minWidth: 0,
      display: { xs: 'block', md: 'grid' },
      gridAutoFlow: { md: 'column' },
      gridAutoColumns: { md: '1fr' },
    },
    imageBlock: {
      display: { xs: 'none', lg: 'flex' },
      flexDirection: { lg: 'column' },
      justifyContent: { lg: 'flex-start' },
    },
    imageContainer: {
      display: { xs: 'none', lg: 'flex' },
      width: { lg: '80%' },
      height: { lg: '80%' },
    },
    mainContent: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      overflowY: 'auto',
      minWidth: {
        xs: '100%',
        md: 0,
        lg: '100%',
        xl: 0,
      },
      p: {
        xs: theme.spacing(6, 3),
        sm: theme.spacing(12, 10),
        lg: theme.spacing(12, 10),
      },
      width: {
        md: '700px',
        lg: '100%',
        xl: '900px',
      },
    },
  }

  return (
    <Box sx={styles.container}>
      <Box sx={styles.header}>
        <TRRLogo />
        <Button variant="outlined" size="large" onClick={abortLogin}>
          Avbryt
        </Button>
      </Box>

      <Box sx={styles.mainSection}>
        <Box sx={styles.mainContent}>{children}</Box>
        <Box sx={styles.imageBlock}>
          <Box sx={styles.imageContainer}>
            {userType === userTypes.arbetsgivare ? (
              <TRRIllustrationCogwheels />
            ) : (
              <TRRIllustrationWayForward />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default SiteTemplate
