import { BegaranCsnYttrandeListItemApiModel, CsnUtbildningYttrandeApiModel } from '@local/src/services/SwaggerApi/data-contracts'
import React from 'react'
import { Box, Card, CardActionArea, CardContent, List, ListItem, ListItemText, Stack, Typography } from '@mui/material'
import { formatToDate } from '@local/src/utils/helpers/dateTimeFormatHelper'
import { useNavigateToPage } from '@local/src/hooks'
import { useTranslation } from 'react-i18next'

import { BegaranYttrandeListItemChip, BegaranYttrandeIsDeletedAlert } from './Components'

interface Props {
    yttrande: BegaranCsnYttrandeListItemApiModel
    isKoppladeToAnsokan?: boolean
    arendeId?: string
}

const BegartYttrandeListItemCard = ({ yttrande, arendeId, isKoppladeToAnsokan = false }: Props) => {
    const { t } = useTranslation('translation', { keyPrefix: 'begardaYttrandenList.begartYttrandeListItemCard' })

    const { navigateOutsideMf } = useNavigateToPage()
    const begaranDetailUrl = `${t('linkToBegaranYttrandeRedigera')}${yttrande.idBegaran}/arende/${arendeId}`
    const gotoBegartYttrande = () => navigateOutsideMf(begaranDetailUrl)

    const getUtbildningsNamn = (utbildning: CsnUtbildningYttrandeApiModel) => {
        const kurserAmount = utbildning?.kurserUtbildning?.length
        return kurserAmount > 0 ? `${kurserAmount} st ${utbildning.utbildningsnamn}` : utbildning.utbildningsnamn
    }

    return (
        <Card sx={{ p: 0 }} id={yttrande.idBegaran} data-testid={'begart-yttrande-list-item'}>
            <CardActionArea onClick={gotoBegartYttrande}>
                <CardContent>
                    <Stack spacing={3}>
                        <Box>
                            <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="space-between">
                                <Typography variant="h6">
                                    {isKoppladeToAnsokan ? t('begaranYttrandeText') : yttrande.malMedStudier}
                                </Typography>
                                <BegaranYttrandeListItemChip status={yttrande.status} />
                            </Stack>
                            <Typography variant="subtitle2">
                                {t('inkommenText')} {formatToDate(yttrande.tidpunktBegaran)}
                            </Typography>
                        </Box>

                        <BegaranYttrandeIsDeletedAlert status={yttrande?.status} />

                        <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="space-between">
                            <Typography flex={2} fontWeight="bold">
                                {`${t('svaraSenast')}${formatToDate(yttrande?.lastDateToSvaraExtension?.time ?? yttrande.lastDateToSvara)}`}
                            </Typography>
                            {!isKoppladeToAnsokan && (
                                <List disablePadding sx={{ flex: 1 }}>
                                    <ListItem disablePadding divider={false}>
                                        <ListItemText
                                            sx={{ m: 0, overflowWrap: 'break-word' }}
                                            primary={t('orsakTillBegaran.label')}
                                            secondary={t('orsakTillBegaran.orsak', { context: yttrande.orsakBegaran })}
                                        />
                                    </ListItem>
                                </List>
                            )}
                        </Stack>
                    </Stack>
                    <List>
                        {yttrande.utbildningar?.map((utbildning, index) => (
                            <ListItem key={utbildning.idUtbildning} disablePadding divider={index !== yttrande.utbildningar?.length - 1}>
                                <ListItemText
                                    title="Skola/Anordnare"
                                    primary={getUtbildningsNamn(utbildning)}
                                    secondary={utbildning.utbildningsanordnare}
                                />
                            </ListItem>
                        ))}
                    </List>
                </CardContent>
            </CardActionArea>
        </Card>
    )
}
export default BegartYttrandeListItemCard
