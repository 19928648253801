import { useEffect } from 'react'
import { trackPage } from './track'
import { useCachedContentQuery } from 'state/PublicWebApi'
import { useSliceStateSelector } from 'slices/hooks'
import { narrowHandledErrorCode } from 'apps/Site/Epi/Utils'
import { useLocation } from 'react-router-dom'

export const useTrackPage = (): void => {
  const { data: contentData, error } = useCachedContentQuery()
  const contentUrl = contentData?.properties?.url
  const isTabPage = contentData?.properties?.hasTabNavigation
  const tabUrls = contentData?.properties?.tabUrls
  const { pathname } = useLocation()
  const isTrrCookiesApproved = useSliceStateSelector(
    (state) => state.slices.cookies.isTrrCookiesApproved
  )

  // track on loginflow if cookies were accepted at that point
  useEffect(() => {
    if (
      pathname.startsWith('/login') &&
      !pathname.startsWith('/login/callback') &&
      isTrrCookiesApproved &&
      contentUrl === undefined
    ) {
      trackPage()
    }
  }, [pathname, isTrrCookiesApproved, contentUrl])

  // track non tab pages
  useEffect(() => {
    if (!isTabPage && contentUrl && isTrrCookiesApproved) {
      trackPage()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentUrl, isTrrCookiesApproved])

  // track tab pages
  useEffect(() => {
    const isOnTabUrl = tabUrls?.find((tabUrl) =>
      window.location.pathname.startsWith(tabUrl)
    )
    if (isOnTabUrl && isTabPage && isTrrCookiesApproved) {
      trackPage()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname, tabUrls, isTabPage, isTrrCookiesApproved])

  // track error pages
  useEffect(() => {
    if (error && isTrrCookiesApproved) {
      const contentError = narrowHandledErrorCode(error)
      trackPage(contentError)
    }
  }, [error, isTrrCookiesApproved])
}
