import { styled } from '@mui/material/styles'
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material'
import { IFolderList } from '@local/Components/FolderList/Types'

const FolderList = styled(
  ({ title, subtitle, icon, className }: IFolderList) => (
    <List
      disablePadding
      className={className}
      sx={{ height: '100%', display: 'flex', alignItems: 'center' }}
    >
      <ListItem sx={{ padding: 1 }}>
        {icon && <ListItemIcon>{icon}</ListItemIcon>}
        <ListItemText
          disableTypography
          primary={
            typeof title === 'string' ? (
              <Typography variant="subtitle1">{title}</Typography>
            ) : (
              title
            )
          }
          secondary={
            subtitle && typeof subtitle === 'string' ? (
              <Typography variant="subtitle2">{subtitle}</Typography>
            ) : (
              (subtitle ?? null)
            )
          }
        />
      </ListItem>
    </List>
  )
)(({ theme }) => ({
  '& .MuiListItem-root': {
    border: 'none',
  },
  '& .MuiListItemIcon-root': {
    color: theme.palette.common.black,
    minWidth: theme.spacing(6),
  },
  '& .MuiSvgIcon-root': {
    fontSize: 32,
  },
}))

export default FolderList
