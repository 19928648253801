import { IContactSearchResult } from '@local/src/Models/ContactSearch/ContactSearchResult.model'
import { IPagingModel } from '@local/src/Models/Misc/Paging.model'
import { ISortingModel } from '@local/src/Models/Misc/Sorting.model'
import { IContactsSearchApiModel } from '@local/src/Utils/network/endpoints/ContactsSearch/IContactsSearchApiModel'
import { useLazySearchContactsQuery } from '@local/src/Utils/network/endpoints/ContactsSearch/contactsSearchApi'
import { useCallback, useState } from 'react'
import { isEmail } from '@local/src/Utils/helpers'

import { IContactSearchFilterData } from '../Filters/useContactSearchFilters'
import { IsMainContact } from '../Filters/useIsMainContactFilter'

export interface IContactSearchQueryData {
  result?: IContactSearchResult
  performSearch: (filters: IContactSearchFilterData, paging: IPagingModel, sorting: ISortingModel) => void
  isSearching: boolean
  didSearchFail: boolean
  hasSearched: boolean
  clearSearch: () => void
  apiModel?: IContactsSearchApiModel
}

export const useContactSearchQuery = (): IContactSearchQueryData => {
  const [searchContacts, { data, isFetching, isError }] = useLazySearchContactsQuery()
  const [hasSearched, setHasSearched] = useState<boolean>(false)
  const [apiModel, setApiModel] = useState<IContactsSearchApiModel>()

  const performSearch = useCallback(
    (filters: IContactSearchFilterData, paging: IPagingModel, sorting: ISortingModel) => {
      const mappedApiModel = mapToContactsSearchApiModel(filters, paging, sorting)
      void searchContacts(mappedApiModel)
      setHasSearched(true)
      setApiModel(mappedApiModel)
    },
    [searchContacts]
  )

  const clearSearch = useCallback(() => {
    setHasSearched(false)
  }, [])

  return {
    result: data,
    performSearch,
    isSearching: isFetching,
    didSearchFail: isError,
    hasSearched,
    clearSearch,
    apiModel,
  }
}

const mapToContactsSearchApiModel = (
  filters: IContactSearchFilterData,
  paging: IPagingModel,
  sorting: ISortingModel
): IContactsSearchApiModel => ({
  fritext: mapFritext(filters.freeTextSearchData.value),
  arHuvudkontakt: mapArHuvudkontakt(filters.isMainContactData.selection),
  epost: mapEpost(filters.freeTextSearchData.value),
  rollNiva1Idn: filters.contactCategoryData.selection.map((s) => s.id),
  rollNiva2Idn: filters.contactRoleData.selection.map((s) => s.id),
  statusar: filters.contactStatusData.selection.map((s) => Number(s.id)),
  fackforbundIdn: filters.unionData.selection.map((s) => s.id),
  arbetsstalle: {
    lanskoder: filters.countyData.selection.map((s) => s.id),
    kommunkoder: filters.municipalityData.selection.map((s) => s.id),
    kundkontaktIdn: filters.contactResponsibleData.selection.map((s) => s.id),
    trrBranschkoder: filters.trrIndustryData.selection.map((s) => s.id),
    omfattningsstatusKoder: filters.eligibilityData.selection.map((e) => e.id),
    kontorstypIdn: filters.workplaceTypeData.selection.map((t) => t.id),
  },
  skip: paging.skip,
  count: paging.take,
  orderBy: [
    {
      property: sorting.property,
      order: sorting.order,
    },
  ],
})

const mapArHuvudkontakt = (isMainContact: IsMainContact) => {
  if (isMainContact === 'Ja') return true
  if (isMainContact === 'Nej') return false
  return undefined
}

const mapFritext = (freeTextSearch: string) => {
  if (isEmail(freeTextSearch)) return undefined

  return freeTextSearch
}

const mapEpost = (freeTextSearch: string) => {
  if (isEmail(freeTextSearch)) return freeTextSearch

  return undefined
}
