import { Box, Button, Typography } from '@mui/material'
import { ButtonRow, CreatingAccountLoader, PageTitle } from 'Components'
import { useGetCompanyQuery } from 'State/Api/Company'
import {
  useGetArbetsgivarInfoByIdQuery,
  useSetExistingUserArbetsgivarInfoMutation,
} from 'State/Api/Arbetsgivarinfo'
import {
  abortLogin,
  loginFlowRefreshUserToken,
} from '@trr/app-shell-communication'
import { getArbetsgivarinfoLinkId } from 'Utils/Helpers/getArbetsgivarinfoLinkId'
import userRoleChecks from 'Utils/Helpers/userRolesChecks'
import { useAuthentication } from '@trr/app-shell-data'
import { workSituations } from 'State/Slices/inputSlice/constants'
import { useCreateUserMutation } from 'State/Api/User'

const ValidateArbetsgivarinfoPage = () => {
  const { userRoles } = useAuthentication()
  const { isOnboarding } = userRoleChecks(userRoles)
  const [triggerSetExistingUserArbetsgivarInfo, userArbetsgivarInfo] =
    useSetExistingUserArbetsgivarInfoMutation()
  const linkIdFromUrl = getArbetsgivarinfoLinkId()
  const {
    data: getArbetsgivarinfo,
    isSuccess: getArbetsgivarinfoSuccess,
    isError: getArbetsgivarinfoError,
  } = useGetArbetsgivarInfoByIdQuery(
    { id: linkIdFromUrl },
    { skip: !linkIdFromUrl }
  )

  const { data: company, isError: isCompanyError } = useGetCompanyQuery(
    {
      organizationNumber: getArbetsgivarinfo?.organizationNumber,
      workSituation: workSituations?.employed,
    },
    {
      skip:
        getArbetsgivarinfoSuccess !== true && getArbetsgivarinfo === undefined,
    }
  )

  const [triggerCreateUser, { isLoading, isSuccess }] = useCreateUserMutation()

  // If user creation OR add arbetsgivarinfo is successful (i.e user has succesfully verified the company)
  // app shell will redirect us after the event is processed, so to avoid glitching UI we simply display the loader
  // and app shell will redirect us after a short delay.
  const shouldDisplayLoaderWhileRedirecting =
    isSuccess || userArbetsgivarInfo.isSuccess

  const displayLoader =
    !company ||
    !getArbetsgivarinfo ||
    isLoading ||
    shouldDisplayLoaderWhileRedirecting

  const displayError =
    getArbetsgivarinfoError || isCompanyError || userArbetsgivarInfo.isError

  const handleContinueAsNewUser = () => {
    const companyTriggerArguments = {
      companyId: company?.id,
      orgNumber: company?.organizationNumber,
      arbetsgivarinfoId: linkIdFromUrl,
      workSituation: workSituations.employed,
    }
    void triggerCreateUser(companyTriggerArguments).then(() => {
      void loginFlowRefreshUserToken()
    })
  }

  const handleContinueAsExistingUser = () => {
    void triggerSetExistingUserArbetsgivarInfo({ id: linkIdFromUrl }).then(
      ({ error }) => {
        if (!error) {
          void loginFlowRefreshUserToken()
        }
      }
    )
  }

  if (displayError)
    return (
      <>
        <PageTitle sx={{ mb: 8 }}>Något gick fel</PageTitle>
        <Button onClick={abortLogin}>Gå till startsidan</Button>
      </>
    )

  if (displayLoader)
    return (
      <CreatingAccountLoader testSelector="loader">
        Laddar..
      </CreatingAccountLoader>
    )

  return (
    <>
      <PageTitle sx={{ mb: 8 }}>Verifiera din arbetsgivare</PageTitle>
      <Box>
        <Typography mb={5}>
          Stämmer det att du arbetar för{' '}
          <Typography component="span" fontWeight="bold">
            {company?.name}
          </Typography>
          ?
        </Typography>
        <ButtonRow>
          <Button
            onClick={
              isOnboarding
                ? handleContinueAsNewUser
                : handleContinueAsExistingUser
            }
            data-testid="continue-button"
          >
            Ja, det stämmer
          </Button>
          <Button onClick={abortLogin} variant="text">
            Nej, det stämmer inte
          </Button>
        </ButtonRow>
      </Box>
    </>
  )
}

export default ValidateArbetsgivarinfoPage
