export const userTypes = {
  arbetsgivare: 'arbetsgivare',
  tjansteman: 'tjansteman',
  tjanstemanWithCollectum: 'tjanstemanWithCollectum',
  tjanstemanWithArbetsgivarinfo: 'tjanstemanWithArbetsgivarinfo',
} as const

export const workSituations = {
  employed: 'employed',
  unemployed: 'unemployed',
} as const
