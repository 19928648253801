// ** ValidateTjanstemanPage **

const texts = {
  header: 'Arbetar du som tjänsteman?',
  yesLabel: 'Ja, jag arbetar som tjänsteman',
  noLabel: 'Nej, jag arbetar inte som tjänsteman',
  tjanstemanExplanation: 'Är du tjänsteman i ett TRR-anslutet företag?',
  tjanstemanExplanationBody:
    'Vårt stöd är tillgängligt för dig som arbetar i ett TRR-anslutet företag och omfattas av ett kollektivavtal för tjänstemän. Detta avtal är tecknat mellan din arbetsgivare och ett TCO- och/eller SACO-förbund. Välkommen med din ansökan till TRR!',
  continueButton: 'Fortsätt',
  startPageButton: 'Till startsidan',
  noSupportHeader: 'Tyvärr, du kan inte ansöka om stöd av TRR.',
  noSupportText1:
    'Våra stöd kan endast nyttjas av de som arbetar som tjänstemän på ett TRR-anslutet företag, dvs att din anställning går under ett tjänstemannaavtal.',
  noSupportText2:
    'För dig som är exempelvis är arbetare, och har arbetsuppgifter där ett arbetareavtal gäller, finns andra trygghetsorganisationer som erbjuder stöd. Fråga din arbetsgivare vilka stödverksamheter de samarbetar med.',
  options: 'Andra alternativ',
  otherActorsLink: 'Visa andra aktörer för arbetslivsstöd',
} as const

export default texts
