import { GridRowParams } from '@mui/x-data-grid-pro'
import React, { useState } from 'react'
import {
  Box,
  Button,
  Link,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material'
import { format } from 'date-fns'
import Dropdown from '@local/src/Components/Dropdown/Dropdown'
import { useChangeFordelningsteamMutation } from '@local/src/Api/Fordelning/fordelningApi'
import Loading from '@local/src/Components/Loading/Loading'
import { formatAmount } from '@local/src/Utils/smallfuncs'
import { useGetFordelningsDetaljerQuery } from '@local/src/Api/Arenden/arendenApi'
import { getUrlByCaseEnv } from '@local/src/basename'
import { useCaseEnvBasePath } from '@local/src/Hooks'
import { Team } from '@local/src/Common.types'
import { buildFordelningsteamDropdownOptions } from '@local/src/Utils/helpers/fordelningsTeamDropdownHelpers/fordelningsTeamDropdownHelpers'

import { FordelningsArende } from '../../Fordelning.types'

interface DetailPanelProps {
  params: GridRowParams<FordelningsArende>
  teams: Team[]
}

const DetailPanel = ({ params, teams }: DetailPanelProps) => {
  const arendeBasePath = useCaseEnvBasePath()
  const [selectedTeam, setSelectedTeam] = useState<Team>(null)
  const [changeTeam, { isLoading }] = useChangeFordelningsteamMutation()
  const { data: detaljer, isLoading: isLoadingDetaljer } =
    useGetFordelningsDetaljerQuery({
      arendeId: params.row.id,
      klientId: params.row.klientId,
    })

  const handleChangeTeam = () => {
    changeTeam({ arendeId: params.row.id, teamId: selectedTeam.id })
  }

  return (
    <Stack
      gap={1}
      pt={1}
      pb={2}
      pl={7.5}
      borderBottom="1px solid"
      borderColor="neutral.divider"
      minWidth="800px"
    >
      <Box display="flex">
        <ListItemText
          primary="Orsak till uppsägning"
          secondary={params.row.orsakTillUppsagningTxt}
        />
        <ListItemText
          primary="Sista anst. dag"
          secondary={
            params.row.sistaAnstallningsdag
              ? format(new Date(params.row.sistaAnstallningsdag), 'yyyy-MM-dd')
              : 'Inget datum'
          }
        />
        <ListItemText primary="Lön" secondary={formatAmount(params.row.lon)} />
        {isLoadingDetaljer ? (
          <Loading sx={{ width: '25%' }} />
        ) : (
          <>
            <ListItemText
              primary={<Typography variant="body1">Aktuellt ärende</Typography>}
              disableTypography
              secondary={
                detaljer.tidigareRadgivare.length > 0 ? (
                  <Box>
                    {detaljer.tidigareRadgivare.map((radgivare) => (
                      <Typography
                        key={radgivare.id}
                        variant="body2"
                        color="text.secondary"
                      >{`${radgivare.fornamn} ${radgivare.efternamn}`}</Typography>
                    ))}
                  </Box>
                ) : (
                  <Typography variant="body2" color="text.secondary">
                    Ingen tidigare rådgivare
                  </Typography>
                )
              }
            />
            <ListItemText
              primary={
                <Typography variant="body1">Tidigare ärenden</Typography>
              }
              disableTypography
              secondary={
                detaljer.ovrigaArenden.length > 0 ? (
                  <Box display="flex" flexDirection="column">
                    {detaljer.ovrigaArenden.map((arende) => {
                      const radgivarNamn = `${arende.radgivareFornamn ? `${arende.radgivareFornamn} ${arende.radgivareEfternamn}` : 'Rådgivare saknas'}`
                      return (
                        <Link
                          key={arende.arendeId}
                          href={getUrlByCaseEnv(
                            arendeBasePath,
                            arende.arendeId
                          )}
                          target="_blank"
                        >{`${format(new Date(arende.avslutsDatum), 'yyyy-MM-dd')} ${radgivarNamn}`}</Link>
                      )
                    })}
                  </Box>
                ) : (
                  <Typography variant="body2" color="text.secondary">
                    Inga tidigare ärenden
                  </Typography>
                )
              }
            />
          </>
        )}
      </Box>
      <Box display="flex" gap={1}>
        <Box width={217}>
          <Dropdown
            customOptions={buildFordelningsteamDropdownOptions(teams)}
            label="Byt fördelningsteam"
            size="small"
            getOptionLabel={(team: Team) => team.namn}
            getOptionValue={(team: Team) => team.id}
            onChange={(team) =>
              setSelectedTeam(teams.find((t) => team.target.value === t.id))
            }
            value={selectedTeam?.id}
            disabled={isLoading}
          />
        </Box>
        {isLoading ? (
          <Loading />
        ) : (
          <Button
            size="small"
            variant="outlined"
            onClick={handleChangeTeam}
            disabled={selectedTeam === null}
            sx={{ background: '#fff' }}
          >
            Spara
          </Button>
        )}
      </Box>
    </Stack>
  )
}

export default DetailPanel
