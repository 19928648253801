import { JSX, useEffect } from 'react'
import { useIsFeatureFlagEnabled } from '@local/Utils/Hooks'
import TjanstemanIAnstallningTracker from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/TjanstemanIAnstallningForm/TjanstemanIAnstallningTracker'
import TjanstemanIAnstallningStepper from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/TjanstemanIAnstallningForm/TjanstemanIAnstallningStepper'
import { FormikProps, withFormik } from 'formik'
import {
  ITjanstemanIAnstallningFormProps,
  ITjanstemanIAnstallningFormValues,
} from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/TjanstemanIAnstallningForm/types'
import { mapAnsokanToArbetsplatsFormValues } from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/Arbetsplats/Helpers'
import { tjanstemanIAnstallningValidationSchema } from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/TjanstemanIAnstallningForm/tjanstemanIAnstallning.schema'
import { mapAnsokanToAnstallningFormValues } from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/AnstallningTIA/Helpers'
import { mapAnsokanToArbetslivserfarenhetTIAFormValues } from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/ArbetslivserfarenhetTIA/Helpers'
import { initialGranskaAnsokanValues } from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/GranskaAnsokan/GranskaAnsokan.schema'
import { tjanstemanIAnstallningWatcherMiddleware } from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/TjanstemanIAnstallningForm/tjanstemanIAnstallningWatcherMiddleware'
import useReduxStore from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/TjanstemanIAnstallningForm/hooks/useReduxStore'
import usePatchAnsokan from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/TjanstemanIAnstallningForm/hooks/usePatchAnsokan'
import useFormikWatch from '@local/Utils/Hooks/useFormikWatch'
import { AnsokanKeys } from '@local/Types'

const TjanstemanIAnstallningForm = ({
  values,
  initialValues,
  setFieldValue,
}: ITjanstemanIAnstallningFormProps &
  FormikProps<ITjanstemanIAnstallningFormValues>): JSX.Element => {
  const shouldTrack = useIsFeatureFlagEnabled(
    'gtm.enabled.tjanstepersonansokan'
  )

  const { setStoredValuesAfterPatch } = useReduxStore(initialValues, values)

  const { patchAnsokan } = usePatchAnsokan(setStoredValuesAfterPatch)

  useFormikWatch(tjanstemanIAnstallningWatcherMiddleware, patchAnsokan)

  useEffect(() => {
    setFieldValue(AnsokanKeys.OrganisationsnummerIsAffiliated, null)?.catch(
      (err) => {
        console.log(err)
      }
    )
  }, [setFieldValue, values.foretagorganisationsnummer])

  return (
    <>
      {shouldTrack && <TjanstemanIAnstallningTracker />}

      <TjanstemanIAnstallningStepper />
    </>
  )
}

const TjanstemanIAnstallningFormik = withFormik<
  ITjanstemanIAnstallningFormProps,
  ITjanstemanIAnstallningFormValues
>({
  validateOnBlur: true,
  mapPropsToValues: ({ ansokan, bilagor }) => ({
    ...mapAnsokanToArbetsplatsFormValues(ansokan),
    ...mapAnsokanToAnstallningFormValues(ansokan, bilagor),
    ...mapAnsokanToArbetslivserfarenhetTIAFormValues(ansokan),
    ...initialGranskaAnsokanValues,
  }),
  handleSubmit: (_, { setSubmitting }) => {
    setSubmitting(false)
  },
  validationSchema: ({ content }: ITjanstemanIAnstallningFormProps) =>
    tjanstemanIAnstallningValidationSchema(content),
  displayName: 'TjanstemanIAnstallningForm',
})(TjanstemanIAnstallningForm)

export default TjanstemanIAnstallningFormik
