import { Box, ButtonBase, styled } from '@mui/material'
import { FontIcon } from '@trr/internal-helpers'
import { MenuBlock } from 'PublicWebApi/Settings'

interface PopoverLinkProps {
  popoverElement: MenuBlock
  closePopover: () => void
}

interface PopoverButtonProps {
  href?: string
}

export const StyledPopoverButton = styled(ButtonBase)<PopoverButtonProps>(
  ({ theme }) => ({
    ...theme.typography.body1,
    display: 'flex',
    gap: theme.spacing(3),
    width: '100%',
    justifyContent: 'flex-start',
    border: `2px solid transparent`,
    padding: theme.spacing(2),
    ':hover': {
      backgroundColor: theme.palette.action.hover,
      color: theme.palette.primary.main,
    },
    ':focus': {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.main,
      border: `2px solid ${theme.palette.primary.main}`,
    },
  })
)

const PopoverLink = ({ popoverElement, closePopover }: PopoverLinkProps) => {
  return (
    (popoverElement.blockType === 'MenuSingleLinkBlock' && (
      <StyledPopoverButton
        onClick={closePopover}
        data-testid={`popover-element-${popoverElement.heading}`}
        href={popoverElement.url}
      >
        {popoverElement.icon && (
          <FontIcon iconName={popoverElement.icon} size="small" />
        )}
        {popoverElement.heading}
      </StyledPopoverButton>
    )) ||
    (popoverElement.blockType === 'RowBreakBlock' && (
      <Box
        data-testid={`popover-rowbreak`}
        sx={{
          height: '1px',
          backgroundColor: 'divider',
          width: '100%',
        }}
      />
    ))
  )
}

export default PopoverLink
