import { RadgivarensUnderlagForm } from '@local/src/features/StudiestodAnsokan/Components/Steps/RadgivarensUnderlag/Models/RadgivarensUnderlagForm'
import {
    StudiestodApiModel,
    UnderlagApiModel,
    UtbildningarApiModel,
    YttrandeToCsnApiModel,
} from '@local/src/services/SwaggerApi/data-contracts'
import { isNotNil } from 'ramda'

const getDefaultFormData = (data: StudiestodApiModel, featureRemoveStudielonEnabled: boolean): RadgivarensUnderlagForm => ({
    ...data?.radgivarunderlag,
    utbildningar: data?.radgivarunderlag?.utbildningar.map((u) => ({
        utbildningId: u?.utbildning?.id,
        kortvarigtStudiestod: u?.kortvarigtStudiestod ?? undefined,
        nekaStod: u?.nekaStod ?? undefined,
        studieersattningAndStudielon: featureRemoveStudielonEnabled ? undefined : (u?.studieersattningAndStudielon ?? undefined),
        kompletterandeStudiestod: u?.kompletterandeStudiestod ?? undefined,
        koptUtbildning: u?.koptUtbildning ?? undefined,
        studiestodTyp: u?.studiestodTyp ?? {
            hasStudieersattning: false,
            hasStudielon: false,
            hasKoptUtbildning: false,
            hasKortvarigtStudiestod: false,
            hasKompletterandeStudiestod: false,
        },
        omstallningsinsatsTyp: u?.omstallningsinsatsTyp ?? {
            hasUtbildningskostnader: false,
            hasEnstakaResor: false,
            hasRegelbundnaResor: false,
            hasLogi: false,
            hasProgramvaror: false,
        },
        yttrandeToCsn: mapYttrandeToCsn(data?.radgivarunderlag, u),
        studieersattning: featureRemoveStudielonEnabled ? (u?.studieersattning ?? undefined) : undefined,
    })),
})

const mapYttrandeToCsn = (underlag: UnderlagApiModel, utbildning: UtbildningarApiModel): YttrandeToCsnApiModel | null => {
    if (isNotNil(utbildning.yttrandeToCsn)) {
        return utbildning.yttrandeToCsn
    }

    if (isNotNil(underlag?.publiceringsdatum)) {
        return {
            hasYttrandeToCsn: false,
        }
    }

    return undefined
}

export default getDefaultFormData
