import {
  Box,
  Divider,
  ListItemText,
  type SxProps,
  type Theme,
} from '@mui/material'
import { useGetCompanyQuery } from 'State/Api/Company'
import { useSliceStateSelector } from 'State/Slices'

const nameAndPersonalNumberSx: SxProps<Theme> = {
  '&:first-of-type': {
    paddingRight: 3,
  },
  '&:last-child': {
    paddingLeft: { sm: 3 },
  },
  '&:only-child': {
    padding: 0,
  },
}

const CompanyInformation = () => {
  const { organizationNumber, workSituation } = useSliceStateSelector(
    (state) => state.slices.inputs
  )
  const { data: company } = useGetCompanyQuery({
    organizationNumber,
    workSituation,
  })

  if (!company) return null

  return (
    <>
      <Divider
        orientation="vertical"
        sx={{
          display: { xs: 'none', sm: 'initial' },
          backgroundColor: 'primary.light',
        }}
      />
      <Box sx={nameAndPersonalNumberSx}>
        <ListItemText
          primary={company.name}
          secondary={company.organizationNumber}
          sx={{ padding: 0 }}
        />
      </Box>
    </>
  )
}

export default CompanyInformation
