import { styled, Box } from '@mui/material'

export const LargeCardImageWrapper = styled(Box)(() => ({
  // aspect ratio in Figma is not a common one (desktop: 588/225, mobile: 342/156).
  // 16/6 is a compromise between the two
  aspectRatio: '16/6',
  display: 'flex',
  width: '100%',
  overflow: 'hidden',
  img: {
    height: '100%',
    objectFit: 'cover',
    verticalAlign: 'middle',
    width: '100%',
  },
  '.LazyLoadWrapper': {
    height: '100%',
    width: '100%',
  },
}))
