import { Stack, Typography, useTheme } from '@mui/material'
import { useEpiCustomDictionary } from '@trr/app-shell-data'

import useDeviceSize from '../../../utils/hooks/useDeviceSize'

export interface IReflectionsLeftBoxProps {
  amountLeft: number
}

const ReflectionsLeftBox = ({ amountLeft }: IReflectionsLeftBoxProps) => {
  const epi = useEpiCustomDictionary(['reflect'])
  const { palette } = useTheme()

  const { smallScreensUp } = useDeviceSize()

  if (amountLeft < 1) return null
  return (
    <Stack
      justifyContent="center"
      direction={smallScreensUp ? 'row' : 'column'}
      bgcolor={palette.surface?.purple}
    >
      <Stack
        alignItems="center"
        justifyContent="center"
        direction="row"
        component="h2"
      >
        <Typography sx={{ pr: 1 }} variant="h2" component="span">
          {amountLeft}
        </Typography>

        <Typography variant="h6" component="span">
          {epi('reflectionsLeft1')}
        </Typography>
      </Stack>
    </Stack>
  )
}

export default ReflectionsLeftBox
