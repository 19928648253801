import { userTypes } from 'State/Slices/inputSlice/constants'

export const getUserTypeFromUrl = () => {
  if (window.location.pathname.includes('/arbetsgivarinfo')) {
    return userTypes.tjanstemanWithArbetsgivarinfo
  }
  if (window.location.pathname.includes('/arbetsgivare')) {
    return userTypes.arbetsgivare
  }
  return userTypes.tjansteman
}
