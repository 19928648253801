import { forwardRef } from 'react'
import { useFieldArray, type UseFormReturn } from 'react-hook-form'
import {
  maxLinks,
  maxLinkTextLength,
  type FormData,
} from '../../IndustryReportEdit'
import { Stack, Box, TextField, Button } from '@mui/material'
import { useEpiContent } from '@trr/app-shell-data'
import { Content } from 'types/Content.type'

interface LinksProps {
  form: UseFormReturn<FormData, unknown>
}

const Links = ({ form }: LinksProps) => {
  const {
    redigeraPublikation: {
      formLinkAddressLabel,
      formLinkTextLabel,
      formLinkRemoveLink,
      formLinkAddLink,
    },
  } = useEpiContent<Content>()
  const control = form.control
  const register = form.register
  const errors = form.formState.errors
  const { fields, append, remove } = useFieldArray({
    name: 'links',
    control,
  })
  const { links } = form.watch()

  return (
    <>
      {fields.map((field, index) => (
        <Stack
          data-testid="links-stack"
          key={field.id}
          direction={'row'}
          spacing={2}
        >
          <Box width={'40%'}>
            <TextField
              {...register(`links.${index}.url`)}
              name={`links.${index}.url`}
              fullWidth
              label={formLinkAddressLabel}
              type="url"
              error={
                errors.links?.[index]?.url?.message ||
                errors.links?.root?.message
                  ? true
                  : false
              }
              helperText={errors.links?.[index]?.url?.message}
            />
          </Box>
          <Box width={'40%'}>
            <TextField
              {...register(`links.${index}.text`)}
              name={`links.${index}.text`}
              fullWidth
              label={formLinkTextLabel}
              type="text"
              slotProps={{ htmlInput: { maxLength: maxLinkTextLength } }}
              error={
                errors.links?.[index]?.text?.message ||
                errors.links?.root?.message
                  ? true
                  : false
              }
              helperText={errors.links?.[index]?.text?.message}
            />
          </Box>
          <Button
            data-testid="remove-link-button"
            sx={{ maxHeight: '56px' }}
            variant="outlined"
            onClick={() => {
              remove(index)
            }}
          >
            {formLinkRemoveLink}
          </Button>
        </Stack>
      ))}

      <Button
        data-testid="add-link-button"
        disabled={!links || links.length >= maxLinks}
        variant="outlined"
        onClick={() => {
          if (links && links.length < maxLinks) {
            append({ url: '', text: '' })
          }
        }}
      >
        {formLinkAddLink}
      </Button>
    </>
  )
}

export default forwardRef(Links)
