import { JSX } from 'react'
import { useFormikContext } from 'formik'
import { usePickEpiContent } from '@local/Utils/Hooks'
import { ITidigareSysselsattningarContent } from '@local/Views/TidigareSysselsattningarOld/TidigareSysselsattningar.types'
import {
  getKompletteringErrors,
  getKompletteringTouched,
} from '@local/Utils/Helpers/TidigareSysselsattningar.helpers'
import type { IKompletteraFormValues } from '@local/Views/TidigareSysselsattningarOld/KompletteraForm/KompletteraForm.types'
import { RadioChoice } from '@local/Common.types'
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material'
import Gutter from '@local/Components/Gutter'
import FormErrorMessage from '@local/Components/FormErrorMessage'
import { isNil } from 'ramda'

const IsVdEllerUndantagsgrupp = ({ index }: { index: number }): JSX.Element => {
  const { handleChange, handleBlur, errors, touched, values } =
    useFormikContext<IKompletteraFormValues>()

  const {
    formular: { vd: vdContent },
  } = usePickEpiContent<ITidigareSysselsattningarContent>()

  const kompletteringErrors = getKompletteringErrors(index, errors)
  const kompletteringTouched = getKompletteringTouched(index, touched)

  const isError =
    kompletteringTouched.isVdEllerUndantagsgrupp &&
    !isNil(kompletteringErrors.isVdEllerUndantagsgrupp)

  return (
    <FormControl error={isError} variant="standard" fullWidth>
      <Typography variant="h6">{vdContent.heading}</Typography>

      <Gutter offset_xs={16} />

      <RadioGroup
        onChange={handleChange}
        onBlur={handleBlur}
        defaultValue={values.kompletteringar[index].isVdEllerUndantagsgrupp}
      >
        <FormControlLabel
          value={RadioChoice.Ja}
          control={<Radio color="primary" />}
          label={vdContent.ja}
          name={`kompletteringar.${String(index)}.isVdEllerUndantagsgrupp`}
          id={`kompletteringar.${String(index)}.isVdEllerUndantagsgrupp.ja`}
        />

        <FormControlLabel
          value={RadioChoice.Nej}
          control={<Radio color="primary" />}
          label={vdContent.nej}
          name={`kompletteringar.${String(index)}.isVdEllerUndantagsgrupp`}
          id={`kompletteringar.${String(index)}.isVdEllerUndantagsgrupp.nej`}
        />
      </RadioGroup>

      {isError && (
        <FormErrorMessage>
          {kompletteringErrors.isVdEllerUndantagsgrupp}
        </FormErrorMessage>
      )}
    </FormControl>
  )
}

export default IsVdEllerUndantagsgrupp
