import { StudieansokanBackendLink } from '@local/src/Components'
import LabelValueField from '@local/src/Components/LabelValueField/LabelValueField'
import { TechnicalDetailPopover } from '@local/src/Components/TechnicalDetailPopover'
import { useCommonTranslation } from '@local/src/Hooks'
import { CsnYttrandeApiModel } from '@local/src/Services/SwaggerApi/data-contracts'
import { formatDateTime } from '@local/src/Utils/dateFormatHelper'
import { Box, Stack, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { BegaranYttrandeKommentar, MarkeraForlangning, YttrandeStatusChip } from './Components'

interface Props {
	yttrande: CsnYttrandeApiModel
}

const YttrandeFranCsnInfo = ({ yttrande }: Props) => {
	const { t } = useTranslation('translation', { keyPrefix: 'begaranYttrande.info' })
	const { tCommon } = useCommonTranslation()
	const isDisabled = yttrande?.shouldSendToCsn

	return (
		<Box>
			<Box display="flex">
				<Box flexDirection="column" display="flex" flex={1}>
					<Typography variant="subtitle1" gutterBottom={false}>
						{t('title')}
					</Typography>

					<Typography variant="h3" gutterBottom>
						{tCommon('begaranYttrande.orsakTillBegaran.orsak', { context: yttrande.begaranYttrande.orsakBegaran })}
					</Typography>
				</Box>

				<YttrandeStatusChip status={yttrande.status} />
			</Box>
			<TechnicalDetailPopover data={yttrande} />
			<MarkeraForlangning yttrande={yttrande} isReadOnly={isDisabled} />
			<Box mb={3}>
				<BegaranYttrandeKommentar referenceId={yttrande?.begaranYttrande?.idBegaran} />
			</Box>
			<LabelValueField.Row>
				<LabelValueField.Column label={tCommon('glossary.personnummer')} value={yttrande.begaranYttrande.personnummer} />
				<LabelValueField.Column label={tCommon('glossary.namn')} value={yttrande.begaranYttrande.kundnamn} />
			</LabelValueField.Row>

			<LabelValueField.Row>
				<LabelValueField.Column
					label={tCommon('begaranYttrande.orsakTillBegaran.label')}
					value={tCommon('begaranYttrande.orsakTillBegaran.orsak', { context: yttrande.begaranYttrande.orsakBegaran })}
				/>
				<LabelValueField.Column label={t('labels.tidpunktBegaran')} value={formatDateTime(yttrande.begaranYttrande.tidpunktBegaran)} />
			</LabelValueField.Row>

			<LabelValueField.Row>
				<LabelValueField.Column label={tCommon('glossary.ePost')} value={yttrande.begaranYttrande.epostadress} />
				<LabelValueField.Column label={tCommon('glossary.mobilnummer')} value={yttrande.begaranYttrande.mobilnummer} />
			</LabelValueField.Row>
			<LabelValueField.Row>
				<LabelValueField.Column label={t('labels.malMedStudier')} value={yttrande.begaranYttrande?.malMedStudier} />
				<Box flex={1} mb={2} display={'flex'}>
					<Stack>
						<Typography variant="subtitle1" gutterBottom={false}>
							Kopplad till studiestödsansökan
						</Typography>
						<StudieansokanBackendLink ansokanId={yttrande.ansokanId} />
					</Stack>
				</Box>
			</LabelValueField.Row>
		</Box>
	)
}

export default YttrandeFranCsnInfo
