import Cookies from 'js-cookie'

import { CreatingAccountLoader } from 'Components'
import { useEffect } from 'react'
import { useGetCollectumQuery } from 'State/Api/Collectum'
import { useSliceDispatch, useSliceStateSelector } from 'State/Slices'
import { userTypes } from 'State/Slices/inputSlice/constants'
import { pageFlows } from 'State/Slices/pageSlice/constants'
import { useIsFeatureEnabled } from '@trr/app-shell-data'

const convertToCollectumUser = {
  [userTypes.tjansteman]: userTypes.tjanstemanWithCollectum,
} as const

const collectumUserInitialPage = {
  [userTypes.tjanstemanWithCollectum]: pageFlows.tjanstemanWithCollectum[0],
} as const

const LoadCollectumPage = () => {
  const trigger = useSliceDispatch()
  // We know that we can only be of type tjansteman here, because of our page flow, extend with more pageflows if needed
  const userType = useSliceStateSelector(
    (state) => state.slices.inputs.userType
  ) as typeof userTypes.tjansteman
  const isCollectumEdition =
    Cookies.get('loginflow-collectum-edition') === 'true'

  const hasEnabledCollectumFlag = useIsFeatureEnabled(
    'LoginFlow-MicroFrontend_collectum_temp_250117'
  )
  const { isSuccess, isError } = useGetCollectumQuery(undefined, {
    skip: !isCollectumEdition && !hasEnabledCollectumFlag,
  })

  useEffect(() => {
    // Remove when collectum is live
    if (!isCollectumEdition && !hasEnabledCollectumFlag) {
      trigger('goToPage', { direction: 'next', userType })
    }
  })

  useEffect(() => {
    const convertedUser = convertToCollectumUser[userType]
    if (
      isSuccess &&
      convertToCollectumUser[userType] &&
      collectumUserInitialPage[convertedUser]
    ) {
      trigger('setUserType', convertedUser)
      trigger('goToSpecificPage', collectumUserInitialPage[convertedUser])
    }
    if (isError) {
      trigger('goToPage', { direction: 'next', userType })
    }
  }, [isSuccess, isError, userType, trigger])

  return (
    <CreatingAccountLoader testSelector="loading">
      Hämtar dina uppgifter...
    </CreatingAccountLoader>
  )
}

export default LoadCollectumPage
