import { Avatar, Typography } from '@mui/material'
import { useCachedUserProfileState } from 'state/PublicWebApi/UserProfile'
import { StyledSecondaryActionButton as StyledAvatarButton } from '../NewHeader.styles'
import { getLoggedInProfileUrl } from '../NewHeader.hooks'

interface AvatarButtonProps {
  visitorContext: string
  onClickAction?: () => void
}

const AvatarButton = ({ visitorContext, onClickAction }: AvatarButtonProps) => {
  const { preferredFirstName, profileImages, imageFileName } =
    useCachedUserProfileState()

  const firstLetter = preferredFirstName?.slice(0, 1)

  return (
    <StyledAvatarButton
      href={getLoggedInProfileUrl(visitorContext)}
      isMobile
      onClick={onClickAction}
      disableRipple
      key={'mobile-in-menu-avatar-button'}
      data-testid={'mobile-in-menu-avatar-button'}
      data-gtm-label="mobile-in-menu-avatar-button"
    >
      <Avatar
        sx={{ height: '24px', width: '24px', bgcolor: 'primary.main' }}
        {...(imageFileName && {
          src: profileImages?.small,
          alt: 'Profilbild',
        })}
        data-testid="loggedin-avatar"
      >
        <Typography variant="subtitle2">{firstLetter}</Typography>
      </Avatar>
      {preferredFirstName}
    </StyledAvatarButton>
  )
}

export default AvatarButton
