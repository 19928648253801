import { baseApi } from 'State/Api'
import { CollectumResponse } from './types'

export const api = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getCollectum: builder.query<CollectumResponse, void>({
      query: () => '/user/employment-history',
    }),
  }),
})

export const { useGetCollectumQuery } = api

export default api
