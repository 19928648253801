import { IUniqueFile } from '@local/Components/FileUpload/FileUpload.types'
import { trrDateStrict } from '@local/Utils/Helpers/formatDate'
import {
  IKompletteraFormValues,
  OrsakerTillUppsagning,
} from '@local/Views/TidigareSysselsattningar/KompletteraForm/KompletteraForm.types'
import { ITidigareSysselsattningDraftsResponseModel } from '@local/services/API/tidigareSysselsattningar/Types'

export const mapTidigareSysselsattningarDraftsToFormValues = (
  tidigareSysselsattningar: ITidigareSysselsattningDraftsResponseModel[]
): IKompletteraFormValues => ({
  kompletteringar: [
    ...tidigareSysselsattningar.map((tidigareSysselsattning) => ({
      id: tidigareSysselsattning.id,
      sysselsattningstyp: tidigareSysselsattning.kompletteringstyp,
      foretagsnamn: tidigareSysselsattning.anstallning?.foretagsnamn,
      organisationsnummer:
        tidigareSysselsattning.anstallning?.organisationsnummer,
      orsakTillUppsagning: tidigareSysselsattning.anstallning
        ?.uppsagningsorsak as OrsakerTillUppsagning,
      anstallningStartDatum: trrDateStrict(
        tidigareSysselsattning.sysselsattningStart
      ),
      anstallningSlutDatum: trrDateStrict(
        tidigareSysselsattning.sysselsattningSlut
      ),
      isTjansteman: String(tidigareSysselsattning.anstallning?.isTjansteman),
      isVdEllerUndantagsgrupp: String(
        tidigareSysselsattning.anstallning?.isVdEllerUndantagsgrupp
      ),
      arbetsgivarintyg: [] as IUniqueFile[],
      forsakratUppgifter: false,
    })),
  ],
})
