import kompetensApi from '../kompetensApi'

interface TextRequest {
  id: string
  text: string
  lastUpdated?: string
}

interface TextResponse {
  id: string
  text: string
  lastUpdated?: string
}

export const competenceApi = kompetensApi.injectEndpoints({
  endpoints: (build) => ({
    summarizeText: build.mutation<TextResponse, TextRequest>({
      query: (body) => {
        return {
          timeout: 7000,
          url: `/prognosportal/summarize/`,
          method: 'POST',
          body,
        }
      },
    }),
    formatText: build.mutation<TextResponse, TextRequest>({
      query: (body) => {
        return {
          timeout: 30000,
          url: `/prognosportal/format/`,
          method: 'POST',
          body,
        }
      },
    }),
  }),
})

export const { useSummarizeTextMutation, useFormatTextMutation } = competenceApi
