import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Box, Typography } from '@mui/material';
import EmptyIllustration from './EmptyIllustration';
var EmptyResult = function (_a) {
    var heading = _a.heading, description = _a.description;
    return (_jsxs(Box, { textAlign: "center", sx: {
            svg: {
                maxWidth: '400px',
                py: 5,
            },
        }, children: [_jsx(EmptyIllustration, { "aria-hidden": true }), _jsx(Typography, { variant: "h2", children: heading }), _jsx(Typography, { variant: "preamble", children: description })] }));
};
export default EmptyResult;
