import { store } from 'store'
import { getUserGroup } from './getUserGroup'
import { getWashedUserRoles } from './getWashedUserRoles'
import { type customEventData } from '@trr/app-shell-communication'
import { BuildEventDataLayer } from '../Types/dataLayer.types'
import {
  extractSubViewUrlPart,
  extractSyntheticPathFromCustomEvent,
} from './extractSyntheticPathFromCustomEvent'
import washUserId from './washUserId'
import { getFilteredTrackedRoles } from './getFilteredTrackedRoles'

export const buildSyntheticPageViewFromCustomEvent = (e: customEventData) => {
  const subViewUrlPart = extractSubViewUrlPart(e)

  if (!subViewUrlPart) return

  const { roles, sub } = store.getState().slices.auth.profile
  const { visitorContextStatus } = store.getState().slices.visitorContexts
  const washedRoles = getWashedUserRoles(roles)
  const dataLayer: BuildEventDataLayer = {
    event: 'view_page',
    event_item: 'page',
    event_action: 'view',
    event_context: {
      page: {
        domain: location.hostname,
        path: extractSyntheticPathFromCustomEvent(e, window.location.href),
        referrer: document.referrer,
      },
      mfName: undefined, // We need to set it to undefined otherwise it will be persisted from the previously loaded mf
      visitorContextStatus,
      user: {
        group: washedRoles?.length > 0 ? getUserGroup(washedRoles) : undefined,
        role:
          washedRoles?.length > 0
            ? getFilteredTrackedRoles(washedRoles)
            : undefined,
        id: washUserId(sub, roles),
      },
    },
  }

  return dataLayer
}
