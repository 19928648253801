import { JSX } from 'react'
import { Grid2 as Grid, Typography } from '@mui/material'
import Gutter from '@local/Components/Gutter'
import { useGetPreambleContent } from '@local/Utils/Hooks/TidigareSysselsattningar'
import { isNil } from 'ramda'

const Preamble = (): JSX.Element => {
  const preambleContent = useGetPreambleContent()

  if (isNil(preambleContent)) {
    return null
  }

  return (
    <Grid container>
      <Grid size={{ md: 8 }}>
        <Typography variant="preamble">{preambleContent.preamble}</Typography>

        <Gutter offset_xs={40} />
      </Grid>

      <Grid size={{ md: 8 }}>{preambleContent.element}</Grid>
    </Grid>
  )
}

export default Preamble
