import { Box, styled } from '@mui/material'

export const SmallCardImageWrapper = styled(Box)(() => ({
  minHeight: '100px',
  maxWidth: '100px',
  aspectRatio: '1/1',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'hidden',
  borderRadius: '8px',
  img: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    verticalAlign: 'middle',
  },
  '.LazyLoadWrapper': {
    height: '100%',
    width: '100%',
  },
}))
