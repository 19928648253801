import React, { useEffect } from 'react'
import FormErrorMessage from '@local/Components/FormErrorMessage'
import Gutter from '@local/Components/Gutter'
import { AnsokanKeys } from '@local/Types'
import { RadioChoice } from '@local/Types/form.types'
import { usePickEpiContent } from '@local/Utils/Hooks'
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material'
import { useFormikContext } from 'formik'
import { isNil } from 'ramda'
import { replaceEpiVariables } from '@local/Utils/Helpers'
import { useGetAnsokanQuery } from '@local/Services/API/hamtaAnsokanApi'
import HTMLMapper from '@local/Components/HTMLMapper'
import Tooltip from '@local/Components/Tooltip'
import NumberInput from '@local/Components/NumberInput'
import { ITjanstemanIAnstallningFormValues } from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/TjanstemanIAnstallningForm/types'
import { ITjanstemanIAnstallningContent } from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/TjanstemanIAnstallning.types'

const Agarandel = () => {
  const { data } = useGetAnsokanQuery()

  const formikContext = useFormikContext<ITjanstemanIAnstallningFormValues>()
  const {
    handleChange,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    errors,
    touched,
    values,
  } = formikContext

  const {
    arbetsplats: { agandeAndel, agandedel, tooltipAgandedel },
  } = usePickEpiContent<ITjanstemanIAnstallningContent>()

  const handlePercentageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseFloat(e.target.value.replace(/,/g, '.'))
    formikContext.handleChange({
      ...e,
      target: {
        ...e.target,
        value: value,
        name: AnsokanKeys.Agarandel,
      },
    })
  }

  useEffect(() => {
    if (values.harAgandeskap === RadioChoice.No) {
      setFieldValue(AnsokanKeys.Agarandel, null).catch((err) => {
        console.log(err)
      })
      setFieldTouched(AnsokanKeys.Agarandel, false).catch((err) => {
        console.log(err)
      })
    }
  }, [values.harAgandeskap, setFieldValue, setFieldTouched])

  return (
    <>
      <FormControl
        error={touched.harAgandeskap && !isNil(errors.harAgandeskap)}
        variant="standard"
        fullWidth
      >
        <Tooltip
          heading={replaceEpiVariables(agandedel.titel, {
            foretag: data.foretag.namn ?? agandedel.placeholder,
          })}
          tooltipContent={<HTMLMapper body={tooltipAgandedel.mainBody} />}
        />

        <RadioGroup
          onChange={handleChange}
          onBlur={handleBlur}
          defaultValue={values.harAgandeskap}
        >
          <FormControlLabel
            value={RadioChoice.Yes}
            control={<Radio color="primary" />}
            label={agandedel.ja}
            name={AnsokanKeys.HarAgandeskap}
            id={`${AnsokanKeys.HarAgandeskap}.ja`}
            checked={values.harAgandeskap === RadioChoice.Yes}
          />
          <FormControlLabel
            value={RadioChoice.No}
            control={<Radio color="primary" />}
            label={agandedel.nej}
            name={AnsokanKeys.HarAgandeskap}
            id={`${AnsokanKeys.HarAgandeskap}.nej`}
            checked={values.harAgandeskap === RadioChoice.No}
          />
        </RadioGroup>
        {touched.harAgandeskap && !isNil(errors.harAgandeskap) && (
          <FormErrorMessage>{errors.harAgandeskap}</FormErrorMessage>
        )}
      </FormControl>

      {values.harAgandeskap === RadioChoice.Yes && (
        <>
          <Gutter xs={32} />

          <Typography variant="h6">{agandeAndel.titel}</Typography>

          <Gutter xs={16} />

          <NumberInput
            formikContext={{
              ...formikContext,
              handleChange: handlePercentageChange,
            }}
            label={agandeAndel.textFaltTitel}
            name={AnsokanKeys.Agarandel}
            value={values.agarandel}
            error={touched?.agarandel && !isNil(errors?.agarandel)}
            errorMsg={errors?.agarandel}
          />
        </>
      )}
    </>
  )
}

export default Agarandel
