import { RootState } from '@local/Store/configureStore'
import { IAnsokanDetailsFormValues } from '@local/Views/AnsokanDetails/AnsokanDetailsForm/AnsokanDetailsForm.types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface IAnsokanDetailsState {
  values: IAnsokanDetailsFormValues
  formIsDirty: boolean
}

const initialState: IAnsokanDetailsState = {
  values: null,
  formIsDirty: false,
}

export const ansokanDetailsFormSlice = createSlice({
  name: 'ansokanDetailsForm',
  initialState,
  reducers: {
    setValues: (state, action: PayloadAction<IAnsokanDetailsFormValues>) => {
      state.values = action.payload
    },
    setFormIsDirty: (state, action: PayloadAction<boolean>) => {
      state.formIsDirty = action.payload
    },
    resetState: () => initialState,
  },
})

export const { setValues, setFormIsDirty, resetState } =
  ansokanDetailsFormSlice.actions

export const selectAnsokanDetailsForm = (state: RootState) =>
  state.ansokanDetailsForm

export default ansokanDetailsFormSlice.reducer
