import { Box, Chip, Typography } from '@mui/material'

export interface TagWithDetailsProps {
  knowledgeLevel?: string
  selectedPageType?: string
  timeSpan?: number
}

const pageTypeColors: Record<string, string> = {
  Artikel: 'surface.green',
  Guide: 'surface.blue',
}

const chipTextColor: Record<string, string> = {
  Artikel: 'secondary.dark',
  Guide: 'info.dark',
}

const TagWithDetails = ({
  knowledgeLevel,
  selectedPageType,
  timeSpan,
}: TagWithDetailsProps) => {
  const timeSpanString = timeSpan + ' minuter'
  const readingDetails = () => {
    if (knowledgeLevel && timeSpan) {
      return knowledgeLevel + ' • ' + timeSpanString
    }
    if (knowledgeLevel) {
      return knowledgeLevel
    }
    if (timeSpan) {
      return timeSpanString
    }
  }

  return (
    <Box display="flex" alignItems="center" gap={2} pt={1.5}>
      {selectedPageType && (
        <Chip
          label={selectedPageType}
          sx={{
            backgroundColor:
              pageTypeColors[selectedPageType] || pageTypeColors.default,
            color: chipTextColor[selectedPageType] || chipTextColor.default,
            border: 'none',
          }}
        />
      )}
      {readingDetails() && (
        <Typography variant="caption">{readingDetails()}</Typography>
      )}
    </Box>
  )
}

export default TagWithDetails
